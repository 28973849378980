<div class="comments-widget"></div>
<div (contextmenu)="onContextMenu($event)" id="{{ itemData.id }}">
  <div class="image-placeholder" *ngIf="!getImageUrl()">
    <mat-icon>insert_photo</mat-icon>
  </div>
  <div class="image-holder">
    <img [attr.src]="getImageUrl() | secureImage | async" />
  </div>
  <div class="absolute top-4 right-7">
    <app-item-pinned-comments [item]="itemData"></app-item-pinned-comments>
  </div>
  <div class="item-details">
    <div class="title" [matTooltip]="itemData?.properties.itemName">{{ itemData.properties.itemName }}</div>
    <div *ngIf="itemData.properties.optionName" class="optionName">
      {{ itemData.properties.optionName }}
      <img
        src="\assets\primary_color_indicator.png"
        *ngIf="itemData?.properties?.primary === 'Yes'"
        class="primary_color_indicator"
      />
    </div>

    <div class="meta" *ngIf="metaViewDefinition?.properties.length > 0">
      <div *ngFor="let prop of metaViewDefinition?.properties">
        <app-property-value
          *ngIf="itemData?.properties[prop.slug]"
          [value]="itemData?.properties[prop.slug]"
          [property]="prop.propertyDefinition"
          [propertyType]="prop.propertyType"
        >
        </app-property-value>
      </div>
    </div>
  </div>
</div>
<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>
<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent let-target="target">
    <button mat-menu-item (click)="openComment($event)">Comments</button>
    <button mat-menu-item (click)="viewItemDetails()">Details</button>
    <button mat-menu-item (click)="removeItem()">Remove</button>
  </ng-template>
</mat-menu>
