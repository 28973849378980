import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  Input,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements AfterViewInit, OnDestroy {
  @ViewChild('inputField') inputField: ElementRef;

  @Input() lgGray = false;
  @Input() placeholder = '';
  @Input() preventPropogation: boolean = false;
  @Output() valueChange = new EventEmitter<string>();
  searchInput = new UntypedFormControl();
  private sub: Subscription;

  constructor() {}

  ngAfterViewInit(): void {
    this.sub = this.searchInput.valueChanges
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap((text: string) => {
          this.valueChange.emit(text);
        }),
      )
      .subscribe();
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public clear() {
    this.searchInput.setValue(null);
  }

  public focus() {
    this.inputField.nativeElement.focus();
  }

  public preventKeyPropogation(event: Event) {
    if (this.preventPropogation) {
      event.stopPropagation();
    }
  }
}
