import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ContentService } from '../content.service';
import { AuthService } from '@common/auth/auth.service';
import { AppNotificationsService } from '@common/app-notifications/app-notifications.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-content-preview',
  template: `
    <div class="content-preview flex-center mx-auto relative" [ngStyle]="{ width: size, height: size }">
      <ng-container *ngIf="content && is3d">
        <app-webgl-viewer [size]="size" [content]="content" *ngIf="useVibeViewer"></app-webgl-viewer>
        <iframe
          *ngIf="!useVibeViewer"
          [src]="content?.embedLink | safe: 'resourceUrl'"
          class="w-full h-full"
          frameborder="0"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </ng-container>

      <ng-container *ngIf="content && previewUrl">
        <canvas *ngIf="zoomable" id="zoomImageCanvas" [attr.width]="size" [attr.height]="size"></canvas>
        <img
          *ngIf="(zoomable && imageLoading) || !zoomable"
          [src]="previewUrl | secureImage | async"
          class="w-full h-full object-contain absolute"
          draggable="false"
          alt="content image"
          (click)="imageLoading = false"
        />

        <div *ngIf="zoomable" class="canvas-buttons" [class.cursor-none]="imageLoading" (click)="imageLoading = false">
          <span class="material-icons" matTooltip="Zoom in" id="zoomIn" (click)="zoomIn()">zoom_in</span>
          <span class="material-icons" matTooltip="Zoom out" id="zoomOut" (click)="zoomOut()">zoom_out</span>
          <span class="material-icons" matTooltip="Reset" id="resetView" (click)="resetView()"
            >settings_backup_restore</span
          >
        </div>
      </ng-container>

      <h6 *ngIf="!is3d && !previewUrl">Preview Not Available</h6>

      <button mat-icon-button class="!absolute !top-3 !right-3" *ngIf="!is3d || useVibeViewer">
        <mat-icon [matMenuTriggerFor]="contextMenu">more_vert</mat-icon>
      </button>
      <mat-menu #contextMenu="matMenu" class="menu-md">
        <ng-template matMenuContent let-target="target">
          <!-- Custom download options.. -->
          <button mat-menu-item (click)="downloadViewable('primary')"><mat-icon>download</mat-icon> Original</button>
          <button mat-menu-item (click)="downloadViewable('large')"><mat-icon>download</mat-icon> Large</button>
          <button mat-menu-item (click)="downloadViewable('medium')"><mat-icon>download</mat-icon> Medium</button>
          <button mat-menu-item (click)="downloadViewable('small')"><mat-icon>download</mat-icon> Small</button>
          <button mat-menu-item (click)="downloadViewable('tiny')"><mat-icon>download</mat-icon> Tiny</button>
        </ng-template>
      </mat-menu>
    </div>
  `,
  styles: [
    `
      .canvas-buttons {
        @apply flex items-center absolute left-1 bottom-1 bg-white70 rounded;
        span {
          @apply m-1 text-black24 cursor-pointer;
          user-select: none;
          -moz-user-select: none;
          -webkit-user-drag: none;
          -webkit-user-select: none;
          -ms-user-select: none;
        }
      }
    `,
  ],
})
export class ContentPreviewComponent implements OnInit, OnChanges, OnDestroy {
  private destroy$ = new Subject();

  @Input() content: any;
  @Input() size = '678px';
  @Input() zoomable = true;

  public is3d = false;
  private enableCustomAssetViewer: boolean = false; // org managed - can update in ADMIN app.
  public useVibeViewer = true; // if enableCustomAssetViewer is false, useVibeViewer must be TRUE

  public previewUrl: string;
  public shouldHavePreview: boolean;
  public noPreview: boolean;

  imageLoading = true;
  canvasLoaded = false;
  canvas;
  ctx;
  zoomImg;
  fullScale = 1;
  lastX;
  lastY;
  dragStart;
  dragged;
  resetPos = null;
  p1;
  p2;

  constructor(
    private authService: AuthService,
    private notificationService: AppNotificationsService,
  ) {
    this.enableCustomAssetViewer = this.authService.getCurrentOrg().orgConfig?.enableCustomAssetViewer;
  }

  ngOnInit(): void {}

  async ngOnChanges() {
    URL.revokeObjectURL(this.previewUrl);
    this.previewUrl = null;

    this.is3d = this.content?.primaryFile?.contentType?.indexOf('gltf-binary') > -1;
    this.useVibeViewer =
      this.is3d && this.enableCustomAssetViewer && this.content?.embedLink && !this.content?.useVibeViewer
        ? false
        : true;

    if (!this.is3d) {
      await this.setImagePreviewUrl();
    }
    this.imageLoading = true;

    setTimeout(() => {
      if (this.zoomable && this.content && this.previewUrl) {
        this.resetPos = null;
        this.loadCanvasImage();
      } else {
        this.imageLoading = false;
        this.canvasLoaded = false;
        this.fullScale = 1;
        this.resetPos = null;
        this.p1 = null;
        this.p2 = null;
      }
    }, 10);
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
    URL.revokeObjectURL(this.previewUrl);
  }

  private async setImagePreviewUrl() {
    const isImage = ContentService.isImage(this.content);
    const isAsyncViewable = ContentService.isAsyncViewableContentType(this.content);
    if (isImage) {
      if (ContentService.isSVG(this.content)) {
        this.previewUrl = this.content?.primaryFile?.downloadUrl;

        const response = await fetch(this.content?.primaryFile?.downloadUrl);
        if (response.status !== 200) {
          this.previewUrl = null; // image loading error
          return;
        }
        let svg = await response.text();

        // remove width/height=100% because these cause the svg to render at an incorrect size
        const width100Regex = / width\=([\"\'])(100%)([\"\'])/;
        const width100 = svg.match(width100Regex);
        if (width100 && width100.length) svg = svg.replace(width100[0], '');

        const height100Regex = / height\=([\"\'])(100%)([\"\'])/;
        const height100 = svg.match(height100Regex);
        if (height100 && height100.length) svg = svg.replace(height100[0], '');

        const svgRegex = /<\s*svg[^>]*>/;
        const svgTag = svg.match(svgRegex)[0];
        const isWidth = svgTag.includes('width');
        const isHeight = svgTag.includes('height');

        if (!isWidth || !isHeight) {
          const viewBoxRegex = /\s*viewBox="[^>]*"/;
          const viewBox = svgTag.match(viewBoxRegex)[0];
          const xy = viewBox.split(' ');
          const width = xy[3];
          const height = xy[4];
          let newViewBox = viewBox;
          if (!isWidth && width) {
            newViewBox = newViewBox + ` width="${width}"`;
          }
          if (!isHeight && height) {
            newViewBox = newViewBox + ` height="${height} `;
          }
          const newSVG = svg.replace(viewBox, newViewBox);
          const blob = new Blob([newSVG], { type: 'image/svg+xml' });
          this.previewUrl = URL.createObjectURL(blob);
        }
      } else {
        this.previewUrl = this.content?.largeViewableUrl || this.content?.primaryFile?.downloadUrl;
      }
    } else if (isAsyncViewable) {
      this.previewUrl = this.content?.largeViewableUrl;
    }
  }

  async loadCanvasImage() {
    this.canvas = document.getElementById('zoomImageCanvas') as HTMLCanvasElement;
    const size = parseInt(this.size); // canvas width/height square
    this.canvas.width = size;
    this.canvas.height = size;

    let url = this.previewUrl;
    if (this.previewUrl.indexOf('api.vibeiq.com') > -1 || this.previewUrl.indexOf('api.dev.vibeiq.com') > -1) {
      const authContext = await this.authService.getAuthContext();
      const blobRes = await fetch(this.previewUrl, {
        headers: {
          'x-api-key': authContext.token,
          'x-api-org': authContext.currentOrg.orgSlug,
        },
      });
      url = blobRes.url;
    }

    const touch = document.documentElement.ontouchstart;
    const event_start = touch ? 'touchstart' : 'mousedown';
    const event_move = touch ? 'touchmove' : 'mousemove';
    const event_end = touch ? 'touchend' : 'mouseup';

    this.ctx = this.canvas.getContext('2d');
    this.trackTransforms(this.ctx);

    this.zoomImg = new Image();
    this.zoomImg.src = url;
    this.zoomImg.onload = () => {
      const ratio = Math.min(size / this.zoomImg.width, size / this.zoomImg.height);
      this.fullScale = ratio;
      this.ctx.scale(ratio, ratio);

      this.lastX = size / 2;
      this.lastY = size / 2;
      this.p1 = this.ctx.transformedPoint(0, 0);
      this.p2 = this.ctx.transformedPoint(size, size);

      if (this.zoomImg.width !== this.zoomImg.height) {
        this.dragStart = this.ctx.transformedPoint(this.lastX, this.lastY);

        if (this.zoomImg.width > this.zoomImg.height) {
          const height = (size * this.zoomImg.height) / this.zoomImg.width;
          // const dy = Math.abs(height - size);
          this.lastY = (size + Math.abs(height - size)) / 2;
        } else if (this.zoomImg.width < this.zoomImg.height) {
          const width = (size * this.zoomImg.width) / this.zoomImg.height;
          // const dx = Math.abs(width - size);
          this.lastX = (size + Math.abs(width - size)) / 2;
        }
        const pt = this.ctx.transformedPoint(this.lastX, this.lastY);
        this.resetPos = {
          x: pt.x - this.dragStart.x,
          y: pt.y - this.dragStart.y,
          lastX: this.lastX,
          lastY: this.lastY,
        };
        this.ctx.translate(this.resetPos.x, this.resetPos.y);
        this.dragStart = null;
      }

      this.ctx.clearRect(this.p1.x, this.p1.y, this.p2.x - this.p1.x, this.p2.y - this.p1.y);
      this.ctxSaveRestore();
      this.ctx.drawImage(this.zoomImg, 0, 0);

      if (!this.canvasLoaded) {
        this.canvas.addEventListener(event_start, (event) => this.eventStart(event), true);
        this.canvas.addEventListener(event_move, (event) => this.eventMove(event), true);
        this.canvas.addEventListener(event_end, (event) => this.eventEnd(event), true);
        this.canvas.addEventListener('DOMMouseScroll', (event) => this.handleScroll(event), true);
        this.canvas.addEventListener('mousewheel', (event) => this.handleScroll(event), true);

        this.canvasLoaded = true;
      }
      // this.imageLoading = false;     // after image selection(click)
    };
  }

  private ctxSaveRestore() {
    this.ctx.save();
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.restore();
  }

  zoomIn() {
    this.zoom(2, true);
  }
  zoomOut() {
    this.zoom(-2, true);
  }
  resetView() {
    const size = parseInt(this.size);
    this.canvas.width = size;
    this.canvas.height = size;

    this.ctx = this.canvas.getContext('2d');
    this.trackTransforms(this.ctx);

    const ratio = Math.min(size / this.zoomImg.width, size / this.zoomImg.height);
    this.fullScale = ratio;
    this.ctx.scale(ratio, ratio);

    this.lastX = size / 2;
    this.lastY = size / 2;

    if (this.resetPos) {
      this.dragStart = this.ctx.transformedPoint(this.lastX, this.lastY);
      this.lastX = this.resetPos.lastX;
      this.lastY = this.resetPos.lastY;
      this.ctx.translate(this.resetPos.x, this.resetPos.y);
      this.dragStart = null;
    }

    this.ctx.clearRect(this.p1.x, this.p1.y, this.p2.x - this.p1.x, this.p2.y - this.p1.y);
    this.ctxSaveRestore();
    this.ctx.drawImage(this.zoomImg, 0, 0);
  }

  private eventStart(evt) {
    this.lastX = evt.offsetX || evt.pageX - this.canvas.offsetLeft;
    this.lastY = evt.offsetY || evt.pageY - this.canvas.offsetTop;
    this.dragStart = this.ctx.transformedPoint(this.lastX, this.lastY);
    this.dragged = false;
  }

  private eventMove(evt) {
    this.lastX = evt.offsetX || evt.pageX - this.canvas.offsetLeft;
    this.lastY = evt.offsetY || evt.pageY - this.canvas.offsetTop;
    this.dragged = true;
    if (this.dragStart) {
      const pt = this.ctx.transformedPoint(this.lastX, this.lastY);
      this.ctx.translate(pt.x - this.dragStart.x, pt.y - this.dragStart.y);
      this.ctx.clearRect(this.p1.x, this.p1.y, this.p2.x - this.p1.x, this.p2.y - this.p1.y);

      this.ctxSaveRestore();
      this.ctx.drawImage(this.zoomImg, 0, 0);
    }
  }

  private eventEnd(evt) {
    this.dragStart = null;
    if (!this.dragged) this.zoom(evt.shiftKey ? -1 : 1);
  }

  private handleScroll(evt) {
    const delta = evt.wheelDelta ? evt.wheelDelta / 40 : evt.detail ? -evt.detail : 0;
    if (delta) this.zoom(delta);
    return evt.preventDefault() && false;
  }

  private zoom(clicks, btn = false) {
    const scaleFactor = 1.05;
    const factor = Math.pow(scaleFactor, clicks);

    if (this.fullScale * factor > 20 || this.fullScale * factor < 0.04) {
      return;
    }
    if (btn) {
      const size = parseInt(this.size);
      this.lastX = size / 2;
      this.lastY = size / 2;
    }
    const pt = this.ctx.transformedPoint(this.lastX, this.lastY);
    this.ctx.translate(pt.x, pt.y);
    this.fullScale = this.fullScale * factor;
    this.ctx.scale(factor, factor);
    this.ctx.translate(-pt.x, -pt.y);
    this.ctx.clearRect(this.p1.x, this.p1.y, this.p2.x - this.p1.x, this.p2.y - this.p1.y);

    this.ctxSaveRestore();

    this.ctx.drawImage(this.zoomImg, 0, 0);
  }

  private trackTransforms(ctx) {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    let xform = svg.createSVGMatrix();
    ctx.getTransform = () => xform;

    const savedTransforms = [];
    const save = ctx.save;
    ctx.save = () => {
      savedTransforms.push(xform.translate(0, 0));
      return save.call(ctx);
    };

    const restore = ctx.restore;
    ctx.restore = () => {
      xform = savedTransforms.pop();
      return restore.call(ctx);
    };

    const scale = ctx.scale;
    ctx.scale = (sx, sy) => {
      xform = xform.scaleNonUniform(sx, sy);
      return scale.call(ctx, sx, sy);
    };

    const rotate = ctx.rotate;
    ctx.rotate = (radians) => {
      xform = xform.rotate((radians * 180) / Math.PI);
      return rotate.call(ctx, radians);
    };

    const translate = ctx.translate;
    ctx.translate = (dx, dy) => {
      xform = xform.translate(dx, dy);
      return translate.call(ctx, dx, dy);
    };

    const transform = ctx.transform;
    ctx.transform = (a, b, c, d, e, f) => {
      const m2 = svg.createSVGMatrix();
      m2.a = a;
      m2.b = b;
      m2.c = c;
      m2.d = d;
      m2.e = e;
      m2.f = f;
      xform = xform.multiply(m2);
      return transform.call(ctx, a, b, c, d, e, f);
    };

    const setTransform = ctx.setTransform;
    ctx.setTransform = (a, b, c, d, e, f) => {
      xform.a = a;
      xform.b = b;
      xform.c = c;
      xform.d = d;
      xform.e = e;
      xform.f = f;
      return setTransform.call(ctx, a, b, c, d, e, f);
    };

    const pt = svg.createSVGPoint();
    ctx.transformedPoint = (x, y) => {
      pt.x = x;
      pt.y = y;
      return pt.matrixTransform(xform.inverse());
    };
  }

  downloadViewable(size) {
    let fileUrl;
    switch (size) {
      case 'primary':
        fileUrl = this.content?.primaryFile?.downloadUrl;
        break;
      case 'large':
        fileUrl = this.content?.largeViewable?.downloadUrl;
        break;
      case 'medium':
        fileUrl = this.content?.mediumViewable?.downloadUrl;
        break;
      case 'small':
        fileUrl = this.content?.smallViewable?.downloadUrl;
        break;
      case 'tiny':
        fileUrl = this.content?.tinyViewable?.downloadUrl;
        break;
    }

    if (fileUrl) {
      window.open(fileUrl, '_blank');
    } else {
      this.notificationService.showConfirmationMessage('No source file available for download.');
    }
  }
}
