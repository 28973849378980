import { createSelector } from '@ngrx/store';
import { AssortmentUtil } from '../assortment-util';
import { Assortment, assortmentsEntityAdapter } from './assortments.state';

export const { selectAll, selectEntities, selectIds, selectTotal } = assortmentsEntityAdapter.getSelectors();

const assortmentEntities = (state: any) => state.assortments.assortments.entities;
export const assortments = createSelector(assortmentEntities, (state) => Object.values(state) as Array<Assortment>);
export const sourceAssortment = (state: any) => state.assortments.sourceAssortment; // API RESPONSE FOR LOADED ASSORTMENT
export const sourceAssortmentId = (state: any) => state.assortments.sourceAssortment?.id;
export const sourceAssortmentLoaded = (state: any) => state.assortments.sourceAssortmentLoaded;

export const sourceAssortmentItemData = createSelector(sourceAssortment, (sourceAssortmentObj) => {
  //    console.log('sourceAssortmentItemData selector: ', sourceAssortmentObj);
  let assortmentItems = sourceAssortmentObj?.assortmentItems;
  if (assortmentItems) {
    assortmentItems = AssortmentUtil.convertAssortmentItemsToItemData(assortmentItems);
    return assortmentItems.sort((i1, i2) => (i1.item?.name > i2.item?.name ? 1 : -1));
  }

  return null;
});
export const sourceAssortmentItemDataHiearchy = createSelector(sourceAssortment, (sourceAssortmentObj) => {
  const assortmentItems = sourceAssortmentObj?.assortmentItems;
  if (assortmentItems) {
    return AssortmentUtil.convertAssortmentItemsFamilyItemData(assortmentItems);
  }
  return null;
});
export const selectSourceItem = (itemId: string) =>
  createSelector(sourceAssortmentItemData, (itemDataCollection) => {
    if (itemDataCollection) {
      return itemDataCollection.find((item) => item.id === itemId);
    }
    return null;
  });
export const selectSourceItems = (itemIds: string[]) =>
  createSelector(sourceAssortmentItemData, (itemDataCollection) => {
    if (itemDataCollection) {
      return itemDataCollection.filter((item) => itemIds.includes(item.id));
    }
    return null;
  });
export const selectSourceFamily = (itemId: string) =>
  createSelector(sourceAssortmentItemDataHiearchy, (itemDataCollection) => {
    if (itemDataCollection) {
      return itemDataCollection.find((item) => item.id === itemId);
    }
    return null;
  });
