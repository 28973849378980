import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import * as Sentry from '@sentry/angular';
// import { Integrations } from '@sentry/tracing';
// Sentry.init({
//   dsn: 'https://0bfaadb829114bbd9ab2b91dd804ae60@o467489.ingest.sentry.io/5493996',
//   integrations: [
//     new Integrations.BrowserTracing({
//       tracingOrigins: [],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],
//   environment: environment.name,

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
