import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ListModalComponent } from './list-modal/list-modal.component';
@Injectable({
  providedIn: 'root',
})
export class ListModalLauncher {
  constructor(public dialog: MatDialog) {}

  openModal(params: Object): void {
    const dialogRef = this.dialog.open(ListModalComponent, {
      maxWidth: '100%',
      //width: '92vw',
      disableClose: true,
      autoFocus: true,
      panelClass: 'full-screen-modal',
      data: params,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
}
