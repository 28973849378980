import { Injectable } from '@angular/core';
import { EVENT_CATEGORY } from '@common/analytics/user-analytics.service';
import { PropertyValueFormatter, TypeProperty } from '@contrail/types';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { AnalyticsService } from 'src/app/common/analytics/analytics.service';
import { LocalizationService } from 'src/app/common/localization/localization.service';

@Injectable({
  providedIn: 'root',
})
export class ExporterService {
  private propertyValueFormatter;
  private subscriptions: Array<Subscription> = [];
  constructor(
    private analyticsService: AnalyticsService,
    private localizationService: LocalizationService,
  ) {
    this.subscriptions.push(
      this.localizationService.localizationConfig.subscribe((config) => {
        this.propertyValueFormatter = new PropertyValueFormatter();
        this.propertyValueFormatter.setLocalConfig({
          currencyCode: config.currencyCode,
          locale: config.locale,
        });
      }),
    );
  }
  downloadFile(data: any, filename = 'data', typeProperties: Array<TypeProperty>, currentAssortmentId: any) {
    this.analyticsService.emitEvent({
      eventName: 'CSV_EXPORT',
      eventTarget: `assortment:${currentAssortmentId}`,
      eventLabel: filename,
      eventCategory: EVENT_CATEGORY.EXPORT,
    });
    const csvHeaders = [];
    const csvSlugs = [];
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    /*header.splice(header.indexOf('createdByAccount'), 1);
    header.splice(header.indexOf('updatedByAccount'), 1);*/
    const propertyTypes = new Map<string, string>();
    typeProperties.forEach((property) => {
      csvHeaders.push(property.label);
      csvSlugs.push(property.slug);
      propertyTypes[property.slug] = property.propertyType;
    });

    const csv = data.map((row) => {
      return csvSlugs
        .map((fieldName) => {
          const fieldValue = this.propertyValueFormatter.formatValue(row[fieldName], propertyTypes[fieldName]);
          return JSON.stringify(fieldValue, replacer);
        })
        .join(',');
    });
    csv.unshift(csvHeaders.join(','));
    const csvArray = csv.join('\r\n');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(blob, filename + this.getCurrentDateStr() + '.csv');
  }

  getCurrentDateStr() {
    const date = new Date();
    const dateString =
      '_' +
      date.getFullYear() +
      '-' +
      date.getMonth() +
      '-' +
      date.getDate() +
      '_' +
      date.getHours() +
      '_' +
      date.getMinutes() +
      '_' +
      date.getSeconds();
    return dateString;
  }
}
