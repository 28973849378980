<div class="selector-body" [ngClass]="{ 'stand-alone': !params?.embedded }">
  <button class="btn-36" mat-button>
    <mat-icon
      svgIcon="contrail_assortment"
      matBadge="{{ (currentAssortment$ | async)?.items?.length }}"
      matBadgeColor="warn"
    ></mat-icon>
  </button>

  <div class="myList-menu">
    <button mat-button [matMenuTriggerFor]="menu" class="selected-assort">
      {{ (currentAssortment$ | async)?.name }}
      <mat-icon> keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button
        *ngFor="let sessionAssortment of sessionAssortments$ | async"
        (click)="selectAssortment(sessionAssortment)"
        mat-menu-item
      >
        {{ sessionAssortment.name }}
      </button>
      <mat-divider></mat-divider>
      <div class="add-new-list">
        <button
          mat-button
          value="underline"
          (click)="createNewAssortment()"
          aria-label="createMyList"
          title="Create a New Assortment"
          class="new-list-button"
        >
          <mat-icon>add</mat-icon>
          Create New
        </button>
      </div>
    </mat-menu>
  </div>
  <!-- <div class="item-count" *ngIf="params.displayCount">
    {{(currentAssortment$ | async)?.itemColors?.length }} Items
  </div> -->
</div>
