import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Entities } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { forkJoin, from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { Assortment } from '../../assortments/assortment';
import { ChooserSourceOption } from '../source-option';

@Component({
  selector: 'app-item-data-source-selector',
  templateUrl: './item-data-source-selector.component.html',
  styleUrls: ['./item-data-source-selector.component.scss'],
})
export class ItemDataSourceSelectorComponent implements OnInit, OnChanges {
  @Output() cancel = new EventEmitter();
  @Output() selectSource: EventEmitter<ChooserSourceOption> = new EventEmitter(null);
  @Input() sourceAssortment: any;
  public assortmentFormControl = new UntypedFormControl({ value: null, disabled: true }, Validators.required);
  public sourceWorkspaceControl = new UntypedFormControl({ value: null, disabled: false }, Validators.required);
  public sourceAssortments$: Observable<Array<any>>;
  public sourceWorkspaces$: Observable<Array<any>>;
  public sourceWorkspaces: any[];
  public selectedSource: ChooserSourceOption;
  public step = 'OPTION_TYPE';
  public optionTypesList: any;

  constructor(private store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.sourceWorkspaces$ = this.store.select(WorkspacesSelectors.workspaces);
    this.sourceWorkspaces$.subscribe((sourceWorkspaces) => (this.sourceWorkspaces = sourceWorkspaces));
    this.store.select(WorkspacesSelectors.currentWorkspace).subscribe((currentWorkspace) => {
      if (this.sourceWorkspaces) {
        const workspace = this.sourceWorkspaces.find((sourceWorkspace) => currentWorkspace.id === sourceWorkspace.id);
        this.sourceWorkspaceControl.setValue(workspace);
        this.initSourceAssortments(workspace.id);
        this.assortmentFormControl.enable();
      }
    });
  }

  ngOnChanges() {
    this.initOptionsTypeList();
  }

  private initOptionsTypeList() {
    this.optionTypesList = [];
    this.optionTypesList.push({
      name: 'Item Library',
      sourceType: 'ITEM_LIBRARY',
      img: 'assets/images/library_icon.svg',
      description: 'Search for items within the library.',
    });
    if (this.sourceAssortment) {
      this.optionTypesList.push({
        name: 'Source Assortment',
        sourceType: 'SOURCE_ASSORTMENT',
        img: 'assets/images/plan_icon.svg',
        description: 'Select from the items available in the related source assortment.',
      });
    }
    this.optionTypesList.push({
      name: 'Showcase  (Coming Soon)',
      sourceType: 'SHOWCASE_ASSORTMENT',
      img: 'assets/images/showcase_icon.svg',
      disabled: true,
      description: 'Select from items in a showcase.',
    });
    this.optionTypesList.push({
      name: 'Board (Coming Soon)',
      sourceType: 'BOARD',
      img: 'assets/images/board_icon.svg',
      disabled: true,
      description: 'Select from items within a board.',
    });
    this.optionTypesList.push({
      name: 'Assortment',
      sourceType: 'ASSORTMENT',
      img: 'assets/images/plan_icon.svg',
      description: 'Select from items within another assortment.',
    });
  }

  handleCancel() {
    this.cancel.emit();
  }

  selectSourceOptionType(optionType) {
    console.log('selectSourceOptionType: ', optionType);
    if (optionType.sourceType === 'ITEM_LIBRARY') {
      this.selectSource.emit({
        sourceType: 'ITEM_LIBRARY',
        name: 'Item Library',
      });
    } else if (optionType.sourceType === 'SOURCE_ASSORTMENT') {
      this.selectSource.emit({
        sourceType: 'SOURCE_ASSORTMENT',
        name: this.sourceAssortment?.name,
        workspaceId: this.sourceAssortment?.workspaceId,
        entityReference: 'assortment:' + this.sourceAssortment?.id,
      });
    } else if (optionType.sourceType === 'ASSORTMENT') {
      this.step = 'ASSORTMENT_SELECT';
    }
  }

  initSourceAssortments(workspaceId) {
    const planTypes$ = from(
      new Entities().get({
        entityName: 'assortment',
        criteria: { rootWorkspaceId: workspaceId, isArchived: false, isTrashed: false, assortmentType: 'PLAN' },
        take: 500,
      }),
    );
    const integrationTypes$ = from(
      new Entities().get({
        entityName: 'assortment',
        criteria: { rootWorkspaceId: workspaceId, isArchived: false, isTrashed: false, assortmentType: 'INTEGRATION' },
        take: 500,
      }),
    );
    this.sourceAssortments$ = forkJoin([planTypes$, integrationTypes$]).pipe(
      map((res) => {
        let assortments = [];
        const planAssortments = res[0]
          .filter((a) => a.name?.indexOf('Backing') < 0)
          .sort((a1, a2) => (a1.name?.toUpperCase() > a2.name?.toUpperCase() ? 1 : -1));
        const integrationAssortments = res[1].sort((a1, a2) =>
          a1.name?.toUpperCase() > a2.name?.toUpperCase() ? 1 : -1,
        );
        if (planAssortments?.length) assortments.push(...planAssortments);
        if (integrationAssortments?.length) assortments.push(...integrationAssortments);
        return assortments;
      }),
    );
  }
  handleWorkspaceChange(workspace) {
    if (workspace) {
      this.initSourceAssortments(workspace.id);
      this.assortmentFormControl.enable();
    } else {
      this.assortmentFormControl.disable();
    }
  }

  handleAssortmentChange() {
    const assortment = this.assortmentFormControl.value;
    console.log('handleAssortmentChange: ', assortment);
    this.selectAssortment(assortment);
  }
  selectAssortment(assortment) {
    this.selectedSource = {
      workspaceId: assortment.workspaceId,
      sourceType: 'ASSORTMENT',
      name: assortment.name,
      entityReference: 'assortment:' + assortment.id,
      id: assortment.id,
    };
  }

  /** Finish the selection. */
  handleSelectSource() {
    if (!this.selectedSource) {
      return;
    }
    this.selectSource.emit(this.selectedSource);
  }
}
