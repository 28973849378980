<div *ngIf="viewSize != null">
  <span
    class="!absolute flex items-center justify-center cursor-pointer z-20 w-8 h-8 bg-white rounded-tl-[50%] rounded-tr-[50%] rounded-br-[50%] rounded-bl-sm shadow-md"
    matBadge="{{ pinnedComment.count }}"
    matBadgeColor="primary"
    [matBadgeSize]="'small'"
    [matBadgeHidden]="pinnedComment.count <= 1"
    *ngFor="let pinnedComment of pinnedComments$ | async; trackBy: trackByGroupKey"
    [ngStyle]="pinnedComment.style"
    (click)="showComments(pinnedComment.firstComment, pinnedComment.documentElement)"
  >
    <app-avatar [user]="pinnedComment.firstComment.createdBy" size="24px"></app-avatar>
  </span>
</div>
