import { Entities, Request } from '@contrail/sdk';
import { ItemData } from '../item-data/item-data';

export class Assortment {
  public name?;
  public isArchived;
  public items: Array<ItemData> = [];
  public isLoaded = false;

  constructor(
    public id: string,
    private filteredIds?: Array<string>,
  ) {}
  async load(useCache: boolean = false): Promise<null> {
    let assortment: any;

    const params: any = {
      entityName: 'assortment',
      id: this.id,
      relations: [
        'assortmentItems',
        'assortmentItems.item',
        'assortmentItems.projectItem',
        'assortmentItems.item.primaryViewable',
        'assortmentItems.item.primaryViewable.primaryFile,',
        'assortmentItems.item.primaryViewable.smallViewable',
      ],
    };

    if (useCache) {
      params.cacheMode = 'USE';
    }
    assortment = await new Entities().get(params);

    if (assortment.itemsDownloadURL) {
      const response = await fetch(assortment.itemsDownloadURL);
      const assortmentItems = await response.json();
      assortment.assortmentItems = assortmentItems;
    }

    this.items = []; // assortment.items || [];

    // convert the  assortment items to  itemdata so that  the same data format is used across the app
    if (assortment.assortmentItems.length) {
      this.items = this.convertItemData(assortment.assortmentItems);
    }

    this.name = assortment.name;
    this.isLoaded = true;
    return;
  }

  getItemDataForItem(id: string) {
    const item: ItemData = this.items.filter((item: ItemData) => item.id === id).shift();
    return item;
  }

  async addItems(inputItems: Array<ItemData>): Promise<any[]> {
    const selectedItemIds = [];
    for (const itemData of inputItems) {
      selectedItemIds.push(itemData.id);
    }

    const options = {
      entityName: 'assortment',
      id: this.id,
      relation: 'items',
      object: { itemIds: selectedItemIds },
    };

    try {
      await new Entities().create(options);
      for (const itemData of inputItems) {
        const match = this.items.filter((i) => i.id === itemData.id);
        if (!match || match.length === 0) {
          this.items.push(itemData);
        }
      }
    } catch (e) {
      console.error(`error adding item to assortment: ${JSON.stringify(e)} \n ${e.message}`);
      throw e;
    }
    return Promise.resolve(inputItems);
  }

  async removeItem(itemId: string): Promise<null> {
    const options = {
      entityName: 'assortment',
      id: this.id,
      relation: 'items',
      relationId: itemId,
    };
    await new Entities().delete(options);
    this.items = this.items.filter((item: ItemData) => item.id !== itemId);
    return Promise.resolve(null);
  }

  private convertItemData(data) {
    const itemData = [];

    for (const obj of data) {
      const options = this.getItemOptions(obj, data);
      const newItemData = new ItemData(obj);
      newItemData.options = options;

      itemData.push(newItemData);
    }
    return itemData;
  }

  getItemOptions(assortItem, assortmentData) {
    let options = [];
    if (assortItem.item.roles.includes('family')) {
      // current element is a family
      options = assortmentData
        .filter((assortmentItem) => {
          if (
            assortmentItem.item.roles.includes('option') && // options for the current item
            assortmentItem.item.itemFamilyId === assortItem.itemId
          ) {
            return true;
          }
          return false;
        })
        .map((option) => new ItemData(option));
    }
    return options;
  }
}
