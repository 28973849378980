<div
  class="cursor-pointer"
  *ngIf="isMouseInsideActionDiv || isMouseInsideComponent"
  (click)="openInfo()"
  (contextmenu)="openMenu($event)"
>
  <div class="absolute top-0 left-0 z-10 flex items-center justify-end" id="{{ svgId }}" [ngStyle]="styles">
    <div class="flex absolute top-3 right-1 items-center">
      <app-item-data-selection-toggle
        *ngIf="(itemData$ | async) && ['EDIT'].includes(editorMode)"
        [itemData]="itemData$ | async"
      ></app-item-data-selection-toggle>
    </div>
    <span
      *ngIf="
        showcaseService.showSourceAssortmentWarning &&
        (sourceAssortment$ | async)?.id &&
        itemData$ &&
        !(itemData$ | async)
      "
      class="flex justify-end h-1/2 w-full"
      matTooltip="Missing from source assortment"
    >
    </span>
  </div>
</div>
