<div class="loading-in-progress" *ngIf="numberOfPendingJobs !== 0">
  <mat-toolbar
    class="toolbar"
    style="font-family: Roboto, Arial, sans-serif; font-size: 11pt; text-align: center"
    [matBadge]="numberOfPendingJobs"
    [matBadgeDisabled]="true"
  >
    Preparing PDF for download
    <span> <img class="logo" src="assets/images/download.gif" /></span>
  </mat-toolbar>
</div>
