<!-- Spinner-->
<app-mat-spinner [overlay]="true" [loading]="isLoading"></app-mat-spinner>
<form>
  <div class="info">{{ selectedItems.length }} product(s) selected.</div>
  <div class="selector">
    <mat-form-field appearance="fill">
      <mat-label>Select Assortment</mat-label>
      <mat-select [formControl]="assortmentSelectionControl" name="defaultSelected">
        <!-- <mat-option>--</mat-option> -->
        <mat-option
          *ngFor="let assortment of assortments"
          [value]="assortment"
          (onSelectionChange)="selectAssortment(assortment)"
        >
          {{ assortment.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="actions">
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="addToList(assortmentSelectionControl.value)" cdkFocusInitial>
      Add
    </button>
  </div>
</form>
