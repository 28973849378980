import { SizeDefinition } from '@contrail/documents';
import { createAction, props } from '@ngrx/store';
import { Presentation, ShowcasePresentation, ShowcasePresentationPanel } from '../presentation';

export enum PresentationActionTypes {
  LOAD_PRESENTATION = '[Presentation] Load Presentation',
  LOAD_PRESENTATION_SUCCESS = '[Presentation] Load Presentation Success',
  LOAD_PRESENTATION_FAILURE = '[Presentation] Load Presentation Failure',

  SET_SHOWCASE_PRESENTATION = '[Presentation] Set Showcase Presentation',
  SET_CURRENT_SHOWCASE_PRESENTATION_PANEL = '[Presentation] Set Curret Showcase Presentation Panel',
  SET_PRESENTATION_SIZE = '[Presentation] Set Presentation Size',
  SET_CURRENT_FRAME_ID = '[Presentation] Set Current Frame Id',
}

export const loadPresentation = createAction(
  PresentationActionTypes.LOAD_PRESENTATION,
  props<{ showcaseId: string }>(),
);

export const loadPresentationSuccess = createAction(
  PresentationActionTypes.LOAD_PRESENTATION_SUCCESS,
  props<{ presentation: Presentation }>(),
);

export const loadPresentationFailure = createAction(
  PresentationActionTypes.LOAD_PRESENTATION_FAILURE,
  props<{ error: any }>(),
);

export const setShowcasePresentation = createAction(
  PresentationActionTypes.SET_SHOWCASE_PRESENTATION,
  props<{ showcasePresentation: ShowcasePresentation }>(),
);

export const setCurrentShowcasePresentationPanel = createAction(
  PresentationActionTypes.SET_CURRENT_SHOWCASE_PRESENTATION_PANEL,
  props<{ showcasePresentationPanel: ShowcasePresentationPanel }>(),
);

export const setPresentationSize = createAction(
  PresentationActionTypes.SET_PRESENTATION_SIZE,
  props<{ presentationSize: SizeDefinition }>(),
);

export const setCurrentFrameId = createAction(
  PresentationActionTypes.SET_CURRENT_FRAME_ID,
  props<{ currentFrameId: string }>(),
);
