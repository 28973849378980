export const ITEM_SORT_OPTIONS = [
  {
    label: 'Name',
    value: 'itemName',
    isSelectable: true,
  },
  {
    label: 'Division',
    value: 'division',
    isSelectable: true,
  },
  {
    label: 'Collection',
    value: 'collection',
    isSelectable: true,
  },
  {
    label: 'Project Type',
    value: 'projectType',
    isSelectable: true,
  },
  {
    label: 'Intro Date',
    value: 'introDate',
    isSelectable: true,
  },
  // VIBE-136 - Product Explorer: Remove color from "Sort by" drop down
  // {
  //   label: 'Color', value: 'color', isSelectable: false,
  // }
];
