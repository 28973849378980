import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ItemModalLauncher } from '@common/items/item-modal-launcher';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { ItemData } from 'src/app/common/item-data/item-data';
import { ViewManagerService } from 'src/app/common/views/view-manager.service';
import { ViewDefinition } from '@contrail/client-views';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { CommentsActions } from '@common/comments/comments-store';

@Component({
  selector: 'app-grid-board-item',
  templateUrl: './grid-board-item.component.html',
  styleUrls: ['./grid-board-item.component.scss'],
})
export class GridBoardItemComponent implements OnInit {
  private readonly PINNED_COMMENT_OFFSET = 4;
  metaViewDefinition: ViewDefinition;
  @Input() itemData: ItemData;
  @Output() removeItemEvent = new EventEmitter();

  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };

  ownerInfo = {};

  constructor(
    private store: Store<RootStoreState.State>,
    private modalLauncher: ItemModalLauncher,
    private viewsService: ViewManagerService,
  ) {}

  async ngOnInit() {
    const name =
      `${this.itemData.properties.itemName} ${this.itemData.item.optionName ? '(' + this.itemData.item.optionName + ')' : ''}`.trim();
    this.ownerInfo = {
      id: this.itemData.id,
      name,
      entityType: 'item',
      modalTitle: name,
    };
    console.log('GridBoardItemComponent: ', this.itemData);
    // this.metaViewDefinition = await this.viewsService.getView('showcase:item_card', this.itemData.item.typeId);
    this.metaViewDefinition = await this.viewsService.getView('showcase:item_card');
  }

  getImageUrl() {
    const viewable = this.itemData?.item.primaryViewable;
    const image = viewable?.primaryFile?.downloadUrl || this.itemData?.thumbnail;
    return image;
  }

  viewItemDetails() {
    this.modalLauncher.openModal(this.itemData);
  }

  removeItem() {
    this.removeItemEvent.emit(this.itemData);
  }

  onContextMenu(event: MouseEvent) {
    event.preventDefault();
    this.contextMenuPosition.x = event.offsetX + 'px';
    this.contextMenuPosition.y = event.offsetY + 'px';
    this.contextMenu.menuData = { target: this.itemData };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  openComment(event) {
    this.contextMenu.closeMenu();
    event.stopPropagation();
    const pinnedCommentEl = document.getElementById(this.itemData.id);
    if (!pinnedCommentEl) {
      return;
    }
    const pinnedCommentPosition = pinnedCommentEl.getBoundingClientRect();
    this.store.dispatch(
      CommentsActions.showCommentOverlay({
        ownerInfo: this.ownerInfo,
        position: {
          x: pinnedCommentPosition.left,
          y: pinnedCommentPosition.bottom + this.PINNED_COMMENT_OFFSET,
        },
      }),
    );
  }
}
