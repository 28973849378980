import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  ViewChild,
} from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Types } from '@contrail/sdk';
import { PropertyType, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util/lib/object-util/object-util';
import { Store } from '@ngrx/store';
import { SortDefinition, SortDirection } from '../components/sort/sort-definition';
import { VirtualScrollerComponent } from '@iharbeck/ngx-virtual-scroller';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, startWith, tap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { AssortmentsSelectors } from '../assortments/assortments-store';
import { SearchBarComponent } from '../components/search-bar/search-bar.component';
import { ItemData } from '../item-data/item-data';
import { FilterCriteria } from '../types/filters/filter-criteria';
import { FilterDefinition, FilterPropertyDefinition } from '../types/filters/filter-definition';
import { AssortmentChooserDataSource } from './chooser-sources/assortment-chooser-data-source';
import { ChooserFilterConfig, ItemDataChooserDataSource } from './chooser-sources/item-data-chooser-data-source';
import { ItemLibraryChooserDataSource } from './chooser-sources/item-library-chooser-data-source';
import { SourceAssortmentChooserDataSource } from './chooser-sources/source-assortment-chooser-data-source';
import { ChooserSourceOption } from './source-option';
import { BackingAssortmentChooserDataSource } from './chooser-sources/backing-assortment-chooser-data-source';
import { AuthSelectors } from '@common/auth/auth-store';

const DEFAULT_SORTS: SortDefinition[] = [
  { direction: SortDirection.ASCENDING, propertySlug: 'name', propertyType: PropertyType.String },
  { direction: SortDirection.ASCENDING, propertySlug: 'optionName', propertyType: PropertyType.String },
];
@Component({
  selector: 'app-item-data-chooser',
  templateUrl: './item-data-chooser.component.html',
  styleUrls: ['./item-data-chooser.component.scss'],
})
export class ItemDataChooserComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {
  public title = "Didn't find anything...";
  public icon = 'item-placeholder';
  public footer = 'Try different search criteria';
  public actionLabel = '';
  constructor(
    private changeRef: ChangeDetectorRef,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {}
  @Input() resultsHeight = '100%';
  @Input() draggable = true;
  @Input() allowSourceChange = true;
  @Input() currentAssortmentItemData: Array<ItemData> = [];
  @Input() existingItemIds: Set<string>;
  @Input() showSearchBox = true;
  @Input() showAllControl = true;
  @Input() showCount = true;
  @Input() showHeader = true;
  @Input() showFilter = true;
  @Input() allowAddMultipleEntities = false;
  @Input() criteria: any = { roles: 'option', isPrimary: true };
  @Input() QUICK_SEARCH_OPTIONS =
    'name, item.name, item.atts.styleNumber, atts.colorCode, item.atts.collection, item.atts.division';
  @Input() itemChooserFilterTemplateName = 'item_chooser_filter';
  @Input() itemCardViewTemplateName = 'item_card_meta';
  @Input() allowAddEntity: false;
  @Input() allowAddDuplicate: true;
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;
  @ViewChild(VirtualScrollerComponent) virtualScroller: VirtualScrollerComponent;

  @Output() entityClicked: EventEmitter<any> = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() addEntity = new EventEmitter();
  @Output() addEntities = new EventEmitter();
  public level = 'option';
  public hasSourceAssortment = false;
  public chooserSourceOption$: BehaviorSubject<ChooserSourceOption> = new BehaviorSubject(null);

  private dataSub: Subscription;
  public data: Array<any>;
  public filteredResults$ = new Subject<Array<ItemData>>();
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public filterDefinition: FilterDefinition;
  private filterDefinitionSubject: BehaviorSubject<FilterDefinition> = new BehaviorSubject(null);
  private sortConfigSubject: BehaviorSubject<SortDefinition[]> = new BehaviorSubject(null);
  public dateFilter: FilterDefinition;
  private dateFilterSubject: BehaviorSubject<FilterDefinition> = new BehaviorSubject(null);
  public count = -1;
  public showAllSubject = new BehaviorSubject(true);
  public existingItemIdsSubject = new BehaviorSubject(new Set());
  public showSelectSource = false;
  public sourceAssortment: any;
  private sourceAssortmentSubscription: Subscription;
  public dataSource: ItemDataChooserDataSource;
  public dataSourceType: string;
  private chooserSourceSubscription: Subscription;
  private filterConfigSubject: BehaviorSubject<ChooserFilterConfig> = new BehaviorSubject(null);
  public selectedItems: any[] = [];
  public selectAll = false;
  public numberOfEligibleItems = 0;
  public propertyTypeDefaultFilterConditions: any = {};
  public sortProperties: Array<SortDefinition>;
  public currentSorts: Array<SortDefinition> = [];
  public showAll = true;
  public dateFilterAttribute = {
    label: 'Recently added',
    attribute: 'createdOn',
  };
  searchBarSubscription: Subscription;

  ngOnInit() {
    if (this.criteria.roles === 'family') {
      this.level = 'family';
    }
    this.store.select(AuthSelectors.currentOrg).subscribe((org) => {
      if (org.orgConfig?.propertyTypeDefaultFilterConditions) {
        this.propertyTypeDefaultFilterConditions = org.orgConfig.propertyTypeDefaultFilterConditions;
      }
    });
  }

  ngOnChanges(changes: any) {
    this.existingItemIdsSubject.next(this.existingItemIds);
    if (changes.criteria) {
      if (ObjectUtil.compareDeep(changes.criteria.currentValue, changes.criteria.previousValue, '').length > 0) {
        this.initFilterObservable();
      }
    }
  }

  ngOnDestroy() {
    this.sourceAssortmentSubscription.unsubscribe();
    this.chooserSourceSubscription.unsubscribe();
    this.dataSub.unsubscribe();
  }
  ngAfterViewInit(): void {
    // this.initFilterDefinition();
    this.initResultsObservable();
  }
  async initFilterDefinition(sourceType: string) {
    this.dataSourceType = sourceType;
    let filterProperties: Array<TypeProperty>;
    if (sourceType === 'SOURCE_ASSORTMENT' || sourceType === 'ASSORTMENT') {
      const itemType = await new Types().getType({ root: 'item', path: 'item', relations: ['typeProperties'] });
      const assortmentItemType = await new Types().getType({
        root: 'assortment-item',
        path: 'assortment-item',
        relations: ['typeProperties'],
      });
      const projectItemType = await new Types().getType({
        root: 'project-item',
        path: 'project-item',
        relations: ['typeProperties'],
      });
      filterProperties = [
        ...itemType?.typeProperties,
        ...assortmentItemType?.typeProperties,
        ...projectItemType?.typeProperties,
      ];
    } else {
      const itemType = await new Types().getType({ root: 'item', path: 'item', relations: ['typeProperties'] });
      filterProperties = [...itemType?.typeProperties];
    }
    filterProperties = filterProperties.sort((p1, p2) => (p1.label < p2.label ? -1 : 1));
    this.filterDefinition = {
      filterPropertyDefinitions: filterProperties as Array<FilterPropertyDefinition>,
      filterCriteria: {
        propertyCriteria: [],
      },
    };
    this.sortProperties = filterProperties.map((property) => {
      return {
        propertySlug: property.slug,
        propertyLabel: property.label,
        propertyType: property.propertyType,
        direction: SortDirection.ASCENDING,
      };
    });
  }
  initResultsObservable(): void {
    /**
     * Respond to changes in chooser source.  Create a new data source based on the selection.
     * Each data source is responsible for fetching & filtering data as appropriate based
     * on changes in the choosers 'filter config', which includes the search term and filter definition.
     */
    this.chooserSourceSubscription = this.chooserSourceOption$.subscribe((chooserSourceOption) => {
      console.log('ChooserComponent: chooserSourceOption change: ', chooserSourceOption);
      if (!chooserSourceOption) {
        return;
      }

      this.dataSource?.cleanUp();
      this.dataSub?.unsubscribe();
      delete this.dataSource;
      if (chooserSourceOption.sourceType === 'BACKING_ASSORTMENT') {
        this.dataSource = new BackingAssortmentChooserDataSource(
          this.store,
          this.filterConfigSubject,
          this.sortConfigSubject,
          this.existingItemIdsSubject,
          this.showAllSubject,
          this.dateFilterSubject,
        );
      } else if (chooserSourceOption.sourceType === 'SOURCE_ASSORTMENT') {
        console.log('ChooserComponent: creating SourceAssortmentChooserDataSource');
        this.dataSource = new SourceAssortmentChooserDataSource(
          this.store,
          this.filterConfigSubject,
          this.sortConfigSubject,
          this.existingItemIdsSubject,
          this.showAllSubject,
          this.dateFilterSubject,
        );
      } else if (chooserSourceOption.sourceType === 'ITEM_LIBRARY') {
        console.log('ChooserComponent: creating ItemLibraryChooserDataSource');
        this.dataSource = new ItemLibraryChooserDataSource(
          this.store,
          this.filterConfigSubject,
          this.sortConfigSubject,
          this.existingItemIdsSubject,
          this.showAllSubject,
        );
      } else if (chooserSourceOption.sourceType === 'ASSORTMENT') {
        console.log('ChooserComponent: creating AssortmentChooserDataSource');
        this.dataSource = new AssortmentChooserDataSource(
          this.store,
          this.filterConfigSubject,
          this.sortConfigSubject,
          this.existingItemIdsSubject,
          this.showAllSubject,
          this.dateFilterSubject,
          chooserSourceOption.id,
        );
      }
      if (this.dataSource) {
        this.dataSub = this.dataSource.results$.subscribe((results) => {
          this.data = results;
          this.numberOfEligibleItems = this.data.filter((item) => !this.testExisting(item)).length;
          this.selectedItems = [];
        });
      }
      this.initFilterDefinition(chooserSourceOption.sourceType);
      this.sortData();
    });

    /**
     * Respond to change in contextual source assortment
     */
    this.sourceAssortmentSubscription = this.store
      .select(AssortmentsSelectors.sourceAssortment)
      .pipe(
        tap((source) => {
          console.log('ChooserComponent: sourceAssortment change');
          if (source) {
            this.sourceAssortment = source;
            this.chooserSourceOption$.next({
              sourceType: 'SOURCE_ASSORTMENT',
              name: source?.name,
              workspaceId: source?.workspaceId,
              entityReference: 'assortment:' + source?.id,
            });
          } else {
            this.chooserSourceOption$.next({
              sourceType: 'ITEM_LIBRARY',
              name: 'Item Library',
            });
          }
          this.initFilterObservable();
        }),
      )
      .subscribe();
  }

  initFilterObservable() {
    if (this.searchBar) {
      this.searchBarSubscription = combineLatest([
        this.searchBar.valueChange.pipe(startWith(''), distinctUntilChanged()),
        this.filterDefinitionSubject.asObservable(),
      ])
        .pipe(
          tap(async ([searchTerm, filterDefintion]) => {
            const filterConfig = {
              searchTerm,
              filterDefintion,
              baseCriteria: this.criteria,
            };
            console.log('Chooser.... filterChange: ', filterConfig, this.dataSource);
            this.selectedItems = [];
            this.filterConfigSubject.next(filterConfig);
          }),
        )
        .subscribe();
    }
  }

  startDrag(event) {
    const id = event.target.id;
    const itemData: ItemData = this.data.filter((e) => e.id === id).shift();
    let entity;
    if (itemData.assortmentItem) {
      entity = {
        entityType: 'assortment-item',
        id: itemData.id,
        item: {
          ...itemData.item,
          thumbnail: itemData.thumbnail,
        },
        projectItem: {
          ...itemData.projectItem,
        },
        ...itemData.assortmentItem,
      };
    } else {
      entity = {
        entityType: 'item',
        id: itemData.id,
        ...itemData.item,
        thumbnail: itemData.thumbnail,
      };
    }
    event.dataTransfer.setData('entity', JSON.stringify(entity));
    event.dataTransfer.setData('itemDataId', entity.id);
    event.dataTransfer.setData('dataObject', JSON.stringify(entity));
  }

  getImage(obj) {
    return obj.thumbnail;
  }
  handleClick(object) {
    this.entityClicked.emit(object);
  }

  addItemData(itemData: ItemData) {
    console.log('Add Entity: ', itemData);
    this.addEntity.emit(itemData);
  }

  toggleSelection(selection: any) {
    if (!selection.selected) {
      this.selectAll = false;
      this.selectedItems.splice(
        this.selectedItems.findIndex((selectedItem) => selectedItem.id === selection.itemData.id),
        1,
      );
    } else {
      this.selectedItems.push(ObjectUtil.cloneDeep(selection.itemData));
    }
    // this.virtualScroller.refresh();
  }

  setFilterCriteria(filterCriteria: FilterCriteria) {
    if (filterCriteria) {
      this.filterDefinition.filterCriteria = filterCriteria;
      this.filterDefinitionSubject.next(this.filterDefinition);
    }
  }
  clearFilters() {
    this.filterDefinition.filterCriteria.propertyCriteria = [];
    this.filterDefinitionSubject.next(this.filterDefinition);
    this.searchBar.clear();
  }

  testExisting(itemData) {
    if (this.allowAddDuplicate) {
      return false;
    }
    if (this.existingItemIds) {
      return this.existingItemIds.has(itemData.id);
    }
  }
  toggleShowAll(event) {
    this.showAll = !event.checked;
    this.showAllSubject.next(!event.checked);
  }

  trackByFn(index, item: ItemData) {
    return item.id;
  }

  toggleSourceSourceSelector(val) {
    this.searchBarSubscription.unsubscribe();
    this.showSelectSource = val;
    setTimeout(() => {
      this.initFilterObservable();
    }, 1);
  }

  handleSourceChange(source) {
    this.toggleSourceSourceSelector(false);
    console.log('source change: ', source);
    this.chooserSourceOption$.next(source);
    this.selectedItems = [];
    setTimeout(() => {
      this.initFilterObservable();
    }, 1);
  }

  handleAddSelectedItems(event) {
    if (this.selectedItems.length > 50) {
      this.snackBar.open('You can only add up to 50 items at a time. Please add a filter and retry.', '', {
        duration: 5000,
      });
    } else {
      this.addEntities.emit(this.selectedItems);
      this.selectedItems = [];
      this.selectAll = false;
    }
  }

  handleToggleSelectAll() {
    this.selectAll = !this.selectAll;
    if (this.selectAll) {
      this.selectedItems = [];
      this.data.forEach((item) => {
        if (!this.testExisting(item)) {
          this.selectedItems.push(ObjectUtil.cloneDeep(item));
        }
      });
    } else {
      this.selectedItems = [];
    }
  }

  getSelectedItemIndex(dataObj) {
    if (this.selectedItems.length === 0) {
      return 0;
    }
    return this.selectedItems?.findIndex((selectedItem) => selectedItem.id === dataObj.id) + 1;
  }

  performSort(event) {
    this.currentSorts = event.sorts;
    this.sortData();
  }

  sortData() {
    const sorts = this.currentSorts.length > 0 ? this.currentSorts : DEFAULT_SORTS;
    this.sortConfigSubject.next(sorts);
  }

  dateFilterChanged(dateFilter) {
    this.dateFilter = dateFilter;
    this.dateFilterSubject.next(dateFilter);
  }
}
