import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ShowcaseNotFoundComponent } from './showcase-not-found/showcase-not-found.component';
@NgModule({
  declarations: [ShowcaseNotFoundComponent],
  imports: [CommonModule, BrowserModule],
  exports: [ShowcaseNotFoundComponent],
})
export class ShowcaseModule {}
