import { Injectable } from '@angular/core';
import { Entities, Types } from '@contrail/sdk';
import { DocumentElement } from '@contrail/documents';
import { Presentation, ShowcasePresentation } from './presentation';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { ObjectUtil } from '@contrail/util';
import { AssortmentsSelectors } from '../common/assortments/assortments-store';
import { firstValueFrom } from 'rxjs';
import { PresentationPropertyPoliciesService } from './presentation-property-policies/presentation-property-policies.service';

@Injectable()
export class PresentationService {
  // presentation.module.ts -> PresentationModule -> Providers
  constructor(
    private store: Store<RootStoreState.State>,
    private presentationPropertyPoliciesService: PresentationPropertyPoliciesService,
  ) {}

  public async getPresentationByShowcaseId(showcaseId: string) {
    const ownedByReference = `showcase:${showcaseId}`;
    const presentations: Array<Presentation> = await new Entities().get({
      entityName: 'presentation',
      criteria: { ownedByReference },
      relations: ['showcase', 'sourceAssortment', 'workspace', 'createdBy', 'updatedBy', 'createdBy'],
    });
    await this.presentationPropertyPoliciesService.getTypes();
    await Promise.all(presentations.map((presentation) => this.hydratePresentation(presentation)));

    return presentations[0];
  }

  public async generateShowcasePresentation(sourcePresentation: Presentation) {
    const presentation = ObjectUtil.cloneDeep(sourcePresentation);
    let componentElements: DocumentElement[] = [];
    const showcasePresentation: ShowcasePresentation = { panels: [] };
    let panelId = 0;
    // const itemType = await new Types().getType({ root: 'item', path: 'item' });
    for (const frame of presentation?.frames) {
      if (frame.disabled) {
        continue;
      }
      // console.log('frame: ', frame);
      if (frame.type === 'document') {
        frame.name = frame.name || 'Document';
        showcasePresentation.panels.push({ sequence: panelId++, frame });
        componentElements = componentElements.concat(
          frame.document.elements.filter((element) => element && element.type === 'component'),
        );
      } else if (frame.type === 'collection' || frame.type === 'grid') {
        // frame.name = PresentationCollectionHelper.computeFilterLabel(frame.collection.filter, itemType.typeProperties);
        const enabled = frame?.collection?.set.filter((el) => el.enabled);
        if (frame.type === 'collection') {
          for (const element of enabled) {
            showcasePresentation.panels.push({ sequence: panelId++, frame, collectionElement: element });
          }
        } else {
          showcasePresentation.panels.push({ sequence: panelId++, frame, collectionElement: enabled[0] });
        }
      } else if (frame.type === 'iframe') {
        showcasePresentation.panels.push({ sequence: panelId++, frame });
      }
    }
    showcasePresentation.presentation = presentation;
    return showcasePresentation;
  }

  async getStatusMessages(itemIds) {
    let missingItems = [];
    if (itemIds?.length > 0) {
      const itemCollectionData = await firstValueFrom(
        this.store.select(AssortmentsSelectors.selectSourceItems(itemIds)),
      );
      const existingItemIds = itemCollectionData?.map((item) => item?.id);
      missingItems = itemIds.filter((itemId) => !existingItemIds?.includes(itemId));
    }
    return missingItems;
  }

  public async hydratePresentation(presentation: Presentation) {
    if (presentation?.framesDownloadURL) {
      // console.log('Hydrating presentation: ', presentation.id);
      const response = await fetch(presentation.framesDownloadURL);
      const frames = await response.json();
      presentation.frames = frames || [];
    }
  }
}
