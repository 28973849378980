import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs';
import { Types } from '@contrail/sdk';
import { TypeConstraintsHelper } from '@contrail/type-validation';
import { TypePropertyOption } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { SearchBarComponent } from '@components/search-bar/search-bar.component';
import { TypePropertyFormFieldBaseComponent } from './type-property-form-field-base.component';

@Component({
  selector: 'app-type-property-form-field-multi-select',
  template: `
    <mat-form-field
      [appearance]="appearance"
      *ngIf="formControl"
      [floatLabel]="propertyFormConfiguration?.isFilter ? 'never' : 'always'"
    >
      <mat-label>{{ propertyFormConfiguration.typeProperty.label }}</mat-label>
      <mat-select [formControl]="formControl" multiple="true">
        <ng-container *ngIf="showSearchBar">
          <div class="filter-search-widget">
            <app-search-bar [placeholder]="'Search'" [preventPropogation]="true"></app-search-bar>
            <div class="selection-options" *ngIf="showSelectAll">
              <div (click)="selectAllOptions()" class="text-primary mr-1">Select all</div>
              <div class="text-primary mr-1">|</div>
              <div (click)="deselectAllOptions()" class="text-primary">Clear</div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngFor="let option of propertyFormConfiguration.typeProperty.options">
          <mat-option
            *ngIf="!isInvalidOption(option) || isInvalidOptionButInValue(option)"
            [attr.data-test]="'property-form-option-' + option.value"
            [value]="option"
            [ngStyle]="{ display: isOptionHidden(option) ? 'none' : 'flex' }"
          >
            <span [ngClass]="{ 'text-rose-600': isInvalidOption(option) }">{{ option.display }}</span>
          </mat-option>
        </ng-container>
      </mat-select>
      <mat-error *ngIf="formControl.hasError('error')">{{ formControl.getError('error') }}</mat-error>
      <app-type-property-form-validation-error
        *ngIf="formControl.hasError('error') && formControl.disabled"
        [errorMessage]="formControl.getError('error')"
      >
      </app-type-property-form-validation-error>
    </mat-form-field>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
      .select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 20px;
        cursor: pointer;
        min-width: 80px;
      }
      mat-form-field {
        width: 100%;
      }
      .filter-search-widget {
        padding: 12px 15px 8px;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #ffffff;
        max-width: 250px;
        min-width: 100%;
      }
      .selection-options {
        margin-top: 2px;
        margin-bottom: -8px;
        display: flex;
        cursor: pointer;
        font-size: 12px !important;
      }
    `,
  ],
})
export class TypePropertyFormFieldMultiSelectComponent
  extends TypePropertyFormFieldBaseComponent
  implements AfterViewInit
{
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;

  options: TypePropertyOption[];

  constructor(private changeRef: ChangeDetectorRef) {
    super();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.subscribeToSearch();
    });
  }

  async initFormControl() {
    if (!this.propertyFormConfiguration?.typeProperty) {
      return;
    }
    this.options = await this.getOptions();
    const options = this.propertyFormConfiguration.typeProperty.options?.filter((opt) =>
      this.value?.includes(opt.value),
    );
    const disabled = await this.isDisabled();
    this.formControl = new UntypedFormControl(
      { value: options, disabled },
      {
        updateOn: 'blur',
      },
    );
    this.formControl.valueChanges.subscribe((opts) => {
      if (!opts || !opts.length) {
        this.value = null;
      } else {
        this.value = opts.map((opt) => opt.value);
      }
      this.handleChange();
    });
  }

  subscribeToSearch() {
    if (!this.searchBar || !this.showSearchBar) return;

    this.searchBar.valueChange
      .pipe(
        startWith(''),
        map((searchTerm) => {
          const keys = 'display';
          const options = this.propertyFormConfiguration.typeProperty.options;
          if (options) {
            options.forEach((option) => {
              if (!option.additionalValues) {
                option.additionalValues = {};
              }

              const isValueVisible =
                !searchTerm ||
                keys
                  .split(',')
                  .some((key) => option.hasOwnProperty(key) && new RegExp(searchTerm, 'gi').test(option[key]));

              if (!isValueVisible) {
                option.additionalValues.hidden = true;
              } else {
                option.additionalValues.hidden = false;
              }
            });

            this.searchBar.focus();
          }
          this.changeRef.detectChanges();
        }),
      )
      .subscribe();
  }

  isInvalidOption(selectedOption) {
    return !this.options?.map((option) => option.value).includes(selectedOption.value);
  }

  isOptionHidden(selectedOption) {
    return Boolean(selectedOption.additionalValues?.hidden);
  }

  isInvalidOptionButInValue(selectedOption) {
    return (
      !this.options?.map((option) => option.value).includes(selectedOption.value) &&
      this.value?.includes(selectedOption.value)
    );
  }

  private async getOptions(): Promise<Array<TypePropertyOption>> {
    if (!this.entity?.typeId) {
      return ObjectUtil.cloneDeep(this.propertyFormConfiguration.typeProperty.options);
    }
    const type = await new Types().getType({ id: this.entity.typeId });
    const eligibleOptions = await TypeConstraintsHelper.getValidOptionSets(
      type,
      this.propertyFormConfiguration.typeProperty,
      this.entity,
    );
    return ObjectUtil.cloneDeep(eligibleOptions);
  }

  public selectAllOptions() {
    this.formControl.setValue(
      this.formControl.value.concat(
        this.propertyFormConfiguration.typeProperty.options.filter((option) => !option.additionalValues.hidden),
      ),
    );
  }

  public deselectAllOptions() {
    const availableOptions = this.propertyFormConfiguration.typeProperty.options
      .filter((option) => !option.additionalValues.hidden)
      .map((option) => option.value);
    this.formControl.setValue(
      this.formControl.value.filter((currentValue) => !availableOptions.includes(currentValue.value)),
    );
  }
}
