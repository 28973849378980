<app-mat-spinner [overlay]="true" [loading]="isLoading" *ngIf="isLoading"></app-mat-spinner>

<div class="item">
  <div class="row-selector">
    <app-item-data-assortment-toggle
      [itemData]="itemData"
      (isLoading)="handleInProgressOverlay($event)"
    ></app-item-data-assortment-toggle>
  </div>
  <div class="row-content">
    <div (click)="openModal()" class="item-details">
      <div class="thumbnail"><img [src]="getImageUrl()" /></div>
      <div class="item-info">
        <div class="title">{{ itemData?.properties.itemName }} - {{ itemData?.properties.styleNumber }}</div>
        <div class="title" *ngIf="itemData.properties.itemColorName">{{ itemData?.properties.itemColorName }}</div>
        <div class="meta">{{ itemData?.properties.collection }} ({{ itemData?.properties.division }})</div>
        <div class="meta">{{ itemData?.properties.colorCode }}</div>
      </div>
    </div>
  </div>
</div>
