import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { PresentationCollectionElement, ShowcasePresentationPanel } from '../../presentation';
import { RootStoreState } from 'src/app/root-store';
import { Store } from '@ngrx/store';
import { PresentationSelectors } from '../../presentation-store';
import { AssortmentsSelectors } from 'src/app/common/assortments/assortments-store';
import { ObjectUtil } from '@contrail/util';
import { map } from 'rxjs/operators';
import { ViewManagerService } from 'src/app/common/views/view-manager.service';
import { PropertyValueFormatter } from '@contrail/types';
import { PresentationService } from '../../presentation.service';
import { AnnotationOption } from '../../document/document-annotation/document-annotation-options';
import { DocumentAnnotationService } from '../../document/document-annotation/document-annotation-service';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
import { ShowcaseService } from 'src/app/showcase/showcase.service';

@Component({
  selector: 'app-presentation-grid-panel',
  templateUrl: './presentation-grid-panel.component.html',
  styleUrls: ['./presentation-grid-panel.component.scss'],
})
export class PresentationGridPanelComponent implements OnInit, OnDestroy, OnChanges {
  public presentation: any;
  @Input() panel: ShowcasePresentationPanel;
  @Input() visible = true;
  backingAssortmentItems$: Observable<Array<any>>;
  backingAssortmentItems: Array<any>;
  sourceAssortmentItems: Array<any>;
  editorMode$: Observable<any>;
  public collectionElements: Array<PresentationCollectionElement>;
  countTotal = 0;
  sectionElements: PresentationCollectionElement[];
  public viewDefinition;
  private subscriptions = new Subscription();
  itemMap: any = {};
  itemsMissingFromSource: any[] = [];
  annotationOptions: AnnotationOption[];

  constructor(
    private store: Store<RootStoreState.State>,
    private viewService: ViewManagerService,
    private showcaseService: ShowcaseService,
    private presentationService: PresentationService,
    private documentAnnotationService: DocumentAnnotationService,
  ) {
    this.annotationOptions = this.documentAnnotationService.getAnnotationOptions();
    this.subscriptions.add(
      this.store.select(PresentationSelectors.currentShowcasePresentation).subscribe((showcasePresentation) => {
        this.presentation = showcasePresentation.presentation;
      }),
    );
    this.editorMode$ = this.store.select(EditorModeSelectors.editorMode);
    this.backingAssortmentItems$ = this.store.select(AssortmentsSelectors.backingAssortmentItemData);
    this.subscriptions.add(
      combineLatest([this.backingAssortmentItems$, this.store.select(AssortmentsSelectors.sourceAssortmentItemData)])
        .pipe(
          map(([backingAssortmentItems, sourceAssortmentItems]) => {
            this.backingAssortmentItems = backingAssortmentItems;
            this.sourceAssortmentItems = sourceAssortmentItems;
          }),
        )
        .subscribe(),
    );
  }

  async ngOnInit(): Promise<void> {}

  async initiate() {
    if (this.visible) {
      if (!this.viewDefinition) {
        const contextReference = this.panel?.frame?.hasCustomViewDefinition
          ? `presentation-frame:${this.panel?.frame?.id}`
          : this.presentation.ownedByReference;
        this.viewDefinition = await this.viewService.getView('showcase:grid_frame', null, contextReference);
      }
      this.initCollection();
      await this.generateItemMap();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!this.panel?.collectionElement || !this.visible) {
      return;
    }
    await this.initiate();
  }

  async initCollection() {
    this.collectionElements = ObjectUtil.cloneDeep(this.panel.frame.collection.set);
    this.sectionElements = this.collectionElements.filter((element) => element.type === 'section');
    this.countTotal = this.sectionElements.reduce(
      (previousValue, sectionElement) => previousValue + sectionElement.children.length,
      0,
    );
  }

  async generateItemMap() {
    const formatter = new PropertyValueFormatter();
    const allChildren = [];
    this.sectionElements?.forEach((section) => {
      section.children.forEach((child) => {
        allChildren.push(child.value);
        const backingAssortmentItem = this.backingAssortmentItems.find((itemData) => itemData.id === child.value);
        const sourceAssortmentItem = this.sourceAssortmentItems?.find((itemData) => itemData.id === child.value);
        const itemObj = backingAssortmentItem?.properties;
        const projectItemObj = backingAssortmentItem?.projectItem;
        const keyValueMap = { annotations: [] };
        this.viewDefinition?.properties.forEach((property) => {
          if (property.typeRootSlug === 'item' && itemObj) {
            keyValueMap[property.slug] = formatter.getDisplayValue(itemObj, property.propertyDefinition);
          } else if (property.typeRootSlug === 'project-item' && projectItemObj) {
            keyValueMap[property.slug] = formatter.getDisplayValue(projectItemObj, property.propertyDefinition);
          } else if (property.typeRootSlug === 'assortment-item' && sourceAssortmentItem) {
            keyValueMap[property.slug] = formatter.getDisplayValue(
              sourceAssortmentItem.properties,
              property.propertyDefinition,
            );
          }
        });
        if (backingAssortmentItem) {
          this.itemMap[itemObj.id] = keyValueMap;
          if (itemObj.thumbnail) {
            // tslint:disable-next-line: no-string-literal
            keyValueMap['thumbnail'] = itemObj.thumbnail;
          }
          this.annotationOptions?.forEach((annotationOption) => {
            if (backingAssortmentItem.assortmentItem[annotationOption.property]) {
              keyValueMap.annotations.push(annotationOption.svgIcon);
            }
          });
        }
      });
    });
    if (this.showcaseService.showSourceAssortmentWarning) {
      this.itemsMissingFromSource = this.sourceAssortmentItems
        ? await this.presentationService.getStatusMessages(allChildren)
        : [];
    } else {
      this.itemsMissingFromSource = [];
    }
  }
}
