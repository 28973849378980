import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/common/auth/auth.service';
import { ObjectUtil } from '@contrail/util';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { AnnotationOption, AnnotationOptions } from './document/document-annotation/document-annotation-options';

@Injectable({
  providedIn: 'root',
})
export class AnnotationLoader {
  public static annotations: AnnotationOption[];
  private loadedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loaded: Observable<boolean> = this.loadedSubject.asObservable();

  constructor(
    private authService: AuthService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    AnnotationLoader.init(this.authService, this.matIconRegistry, this.domSanitizer, this.loadedSubject);
  }

  private static async init(
    authService: AuthService,
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    loadedSubject: BehaviorSubject<boolean>,
  ) {
    this.annotations = ObjectUtil.cloneDeep(AnnotationOptions);
    const currentOrg = authService.getCurrentOrg();
    if (currentOrg?.orgSlug) {
      await this.loadCustomAnnotations(`/annotations/${currentOrg.orgSlug}.json`, matIconRegistry, domSanitizer);
    }
    loadedSubject.next(true);
  }

  private static fetchTextFile(href) {
    return fetch(href)
      .then((res) => {
        if (!res?.ok) {
          return null;
        }
        return res?.text();
      })
      .catch((e) => null);
  }

  private static async loadCustomAnnotations(
    annotationName: string,
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
  ) {
    try {
      const annotationsString = await this.fetchTextFile(annotationName);
      console.log(annotationsString);
      const annotations = JSON.parse(annotationsString);
      if (annotations?.length) {
        annotations.forEach((annotation) => {
          matIconRegistry.addSvgIcon(
            annotation.svgIcon,
            domSanitizer.bypassSecurityTrustResourceUrl(`..${annotation.image}`),
          );
        });
        this.annotations = annotations;
      }
    } catch (e) {
      console.log(e);
    }
  }
}
