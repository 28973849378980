<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
  <!-- <app-comment-user-header *ngIf="!isUpdate" [user]="(authContext$ | async)?.user"></app-comment-user-header> -->
  <!-- <mat-label><b>{{commentHeaderLbl}}</b></mat-label> -->
  <mat-form-field appearance="outline" class="comment-box">
    <textarea
      matInput
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="2"
      cdkAutosizeMaxRows="2"
      placeholder="Enter Comment"
      formControlName="comment"
      id="comment"
      #commentField
    ></textarea>
  </mat-form-field>
  <div [ngClass]="{ 'footer-action': !ownerInfo?.property?.propertyType, footer: ownerInfo?.property?.propertyType }">
    <app-comment-form-suggestion
      [property]="ownerInfo?.property"
      [value]="suggestedValue"
      (valueChange)="handleSuggestedValueChange($event)"
      *ngIf="ownerInfo?.property?.propertyType"
    ></app-comment-form-suggestion>
    <div>
      <div [ngClass]="{ 'actions-update-position': comment?.id, actions: !comment?.id }">
        <button
          data-test="cancel-comment-update"
          mat-stroked-button
          color="basic"
          type="button"
          [disabled]="isCommentSubmitted"
          class="cancel"
          (click)="cancelUpdate()"
          *ngIf="comment"
        >
          Cancel
        </button>
        <button
          data-test="submit-comment-update"
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="isCommentSubmitted || !(form.controls['comment'].value || form.controls['newValue'].value)"
          class="save"
        >
          {{ saveBtnLabel }}
        </button>
      </div>
    </div>
  </div>
  <app-mat-spinner [overlay]="true" [loading]="isCommentSubmitted"></app-mat-spinner>
</form>
