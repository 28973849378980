import { Injectable } from '@angular/core';
import { DocumentElement } from '@contrail/documents';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { DocumentSelectors } from '../document-store';
import { ANNOTATION_IMG_SIZE, ANNOTATION_PADDING_Y } from '../document-annotation/document-annotation-service';

@Injectable({
  providedIn: 'root',
})

/**
 * This service is a helper service for items and component document elements.
 * Important: do not inject DocumentService or orther service that injects DocumentService
 * to avoid circular dependency.
 */
export class DocumentItemService {
  private annotatedElements: any[];

  constructor(private store: Store<RootStoreState.State>) {
    this.store.select(DocumentSelectors.annotatedElements).subscribe((annotatedElements) => {
      this.annotatedElements = annotatedElements;
    });
  }

  /**
   * Builds the set of document elements that should go into
   * an edited component, based on what elements are currently
   * enabled/selected in the component editor.
   * Handles spacing of text elements based on font size.
   * @param propertyElements: The list of property elements available for selection
   */
  public updateSizeAndPositionForPropertyElements(propertyElements: any[], componentElement: DocumentElement): any {
    const newElements = [];
    let lastYPosition = 0;
    let width = 200;
    const imageElements = propertyElements.filter((element) => {
      return element.type === 'image';
    });
    if (imageElements.length > 0) {
      if (imageElements[0].size) {
        width = imageElements[0].size.width;
      } else {
        imageElements[0].size = { width: 200, height: 200 };
      }
    }
    propertyElements.forEach((element, index) => {
      // Skip if not enabled/selected
      if (element.enabled === false) {
        return;
      }
      element.position = {
        x: 0,
        y: lastYPosition,
      };
      lastYPosition = lastYPosition + this.getYMargin(element);
      if (element.type === 'text') {
        if (!element.style?.font) {
          element.style = {
            ...element.style,
            font: { size: 8 },
          };
        }
        if (!element?.size) {
          element.size = {};
        }

        element.size.height = element.style.font.size + 6;
      } else if (element.type === 'annotation') {
        if (!element.style?.font) {
          element.style = {
            ...element.style,
            font: { size: ANNOTATION_IMG_SIZE },
          };
        }
        if (index === 0) {
          element.position = {
            x: 0,
            y: -15,
          };
          lastYPosition = element.position.y;
        }
        const annotationHeight = this.calculateAnnotationHeight(element, componentElement, width);
        lastYPosition = lastYPosition + annotationHeight;
        if (annotationHeight === 0 && index === 0) {
          lastYPosition = lastYPosition + ANNOTATION_IMG_SIZE;
        }
      }
      element.size.width = width;
      newElements.push(element);
    });
    return newElements;
  }

  private getYMargin(element: DocumentElement) {
    let marginY = 11;
    if (element.type === 'image') {
      marginY = element.size.height;
    } else if (element.type === 'text') {
      if (element.size && element.size.height) {
        marginY = element.size.height;
      } else if (element.style && element.style.font) {
        marginY = element.style.font.size + 6;
      }
    }
    return marginY;
  }

  public static isItemComponet(element: DocumentElement): boolean {
    return element?.type === 'component' && element?.modelBindings?.item;
  }

  private calculateAnnotationHeight(
    element: DocumentElement,
    componentElement: DocumentElement,
    width: number,
  ): number {
    const annotationSize = element.style?.font?.size || ANNOTATION_IMG_SIZE;
    let height = annotationSize + ANNOTATION_PADDING_Y;
    let xPos = 0;
    const annotatedElement = this.annotatedElements.find((elem) => elem.id === componentElement.id);
    if (!annotatedElement || element.isHidden) {
      return height;
    }
    const annotations = annotatedElement.annotations.filter((annotation) => annotation.category === 'property');

    if (annotations.length > 0) {
      let count = 0;
      annotations?.forEach((annotation) => {
        if ((count + 1) * annotationSize * 1.25 > width) {
          count = 0;
          xPos = -width * 0.5;
          height = height + annotationSize + ANNOTATION_PADDING_Y;
        }
        xPos = xPos + annotationSize * 1.25;
        count++;
      });
    }
    return height;
  }
}
