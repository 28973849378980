<div style="width: 100%" #presentationBody>
  <ng-container *ngIf="viewMode === 'SCROLL'">
    <!-- SCROLL MODE -->
    <div *ngFor="let panel of presentation?.panels" [attr.id]="panel?.frame?.id + panel?.sequence">
      <app-presentation-panel [panel]="panel" [viewMode]="viewMode"></app-presentation-panel>
      <div class="frame-page-break"></div>
    </div>
  </ng-container>

  <ng-container *ngIf="viewMode === 'SLIDE'">
    <!-- SLIDE MODE -->
    <app-presentation-panel [panel]="currentPanel" [viewMode]="viewMode"></app-presentation-panel>
  </ng-container>
</div>

<app-comment-overlay></app-comment-overlay>
<app-presentation-navigator class="w-full flex-center"></app-presentation-navigator>
