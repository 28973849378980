import { Action, createReducer, on } from '@ngrx/store';
import * as PresentationActions from './presentation.actions';
import { initialState, State } from './presentation.state';

const setCurrentPresentation = (state: State, { presentation }) => {
  return {
    ...state,
    currentPresentation: presentation,
  };
};
const setCurrentPresentationPanel = (state: State, { showcasePresentationPanel }) => {
  return {
    ...state,
    currentShowcasePresentationPanel: showcasePresentationPanel,
  };
};
const setCurrentShowcasePresentation = (state: State, { showcasePresentation }) => {
  return {
    ...state,
    currentShowcasePresentation: showcasePresentation,
  };
};
const setPresentationSize = (state: State, { presentationSize }) => {
  return {
    ...state,
    presentationSize,
  };
};

const setCurrentFrameId = (state: State, { currentFrameId }) => {
  return {
    ...state,
    currentFrameId,
  };
};
export const presentationReducer = createReducer(
  initialState,
  on(PresentationActions.loadPresentationSuccess, setCurrentPresentation),
  on(PresentationActions.setCurrentShowcasePresentationPanel, setCurrentPresentationPanel),
  on(PresentationActions.setShowcasePresentation, setCurrentShowcasePresentation),
  on(PresentationActions.setPresentationSize, setPresentationSize),
  on(PresentationActions.setCurrentFrameId, setCurrentFrameId),
);

export function reducer(state: State | undefined, action: Action): any {
  return presentationReducer(state, action);
}
