import { Component, OnInit } from '@angular/core';
import { AuthService } from '../common/auth/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public user;
  constructor(private authService: AuthService) {}
  async ngOnInit() {
    this.user = await this.authService.getCurrentUser();
  }

  logout() {
    this.authService.logout();
  }

  goToAdminApp() {
    window.open('https://app-development.vibesolutions.net/', '_blank');
  }
}
