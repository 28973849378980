import { Injectable } from '@angular/core';
import { Types } from '@contrail/sdk';

@Injectable({
  providedIn: 'root',
})
export class PresentationPropertyPoliciesService {
  public restrictedViewablePropertySlugs;

  constructor() {}

  public async getTypes() {
    const itemType = await new Types().getType({ path: 'item' });
    const projectItemType = await new Types().getType({ path: 'project-item' });
    const assortmentItemType = await new Types().getType({ path: 'assortment-item' });
    if (
      !itemType.typePropertyPolicies &&
      !projectItemType.typePropertyPolicies &&
      !assortmentItemType.typePropertyPolicies
    ) {
      // if none of the properties is restricted, keep the restrictedViewablePropertySlugs as null.
      return;
    }
    this.restrictedViewablePropertySlugs = itemType.typeProperties
      .map((prop) => `item.${prop.slug}`)
      .concat(projectItemType.typeProperties.map((prop) => `projectItem.${prop.slug}`))
      .concat(assortmentItemType.typeProperties.map((prop) => `assortmentItem.${prop.slug}`));
  }
}
