import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DocumentElementEvent } from '@contrail/documents';
import { DocumentService } from '../document/document.service';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { ItemData } from 'src/app/common/item-data/item-data';
import { AssortmentsSelectors } from 'src/app/common/assortments/assortments-store';

@Component({
  selector: 'app-presentation-hot-spot-widget',
  templateUrl: './presentation-hot-spot-widget.component.html',
  styleUrls: ['./presentation-hot-spot-widget.component.scss'],
})
export class PresentationHotSpotWidgetComponent implements OnInit, OnDestroy {
  constructor(
    private presentationDocumentService: DocumentService,
    private store: Store<RootStoreState.State>,
  ) {}

  @Input() svgId: string;
  @Input() document;

  left = 0;
  top = 0;
  width = 0;

  showHotspotItem = true;
  public itemData$: Observable<ItemData>;
  private eventSub: Subscription;

  ngOnInit(): void {
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  private subscribe() {
    this.eventSub = this.presentationDocumentService.documentElementEvents.subscribe((event) => {
      if (!event || event.element.type !== 'hot_spot') {
        return;
      }
      if (event.eventType === 'mouseenter') {
        this.showWidget(event);
      } else if (event.eventType === 'mouseleave') {
        // this.hideWidget(event);
      }
    });
  }
  unsubscribe() {
    this.eventSub.unsubscribe();
  }

  showWidget(event: DocumentElementEvent) {
    if (
      !this.document ||
      !this.presentationDocumentService.isValidActionElement(this.document.elements, event.element.id)
    ) {
      console.log(` ${this.svgId} - not my hotspot. ignoring `);
      return;
    }

    this.showHotspotItem = true;
    const body = document.body;
    const html = document.documentElement;
    const height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

    const yFactor = height === body.offsetHeight ? -200 : scrollTop - 55; // html.scrollHeight - body.scrollHeight
    const xFactor = 0;

    this.top = event.renderedElementPosition.y + yFactor;
    this.left = event.renderedElementPosition.x + xFactor;
    this.width = event.renderedElementSize.width - 10;
    console.log(`left - ${this.left} --  top - ${this.top}`);

    const entityRef = event.element.modelBindings?.entityReference;
    console.log('entityRef: ', entityRef);
    if (entityRef?.includes(':')) {
      const itemId = entityRef ? entityRef.split(':') : [];
      this.itemData$ = this.store.select(AssortmentsSelectors.selectSourceItem(itemId[1]));
    }
  }
  setStyles() {
    const style = {
      left: this.left + 'px',
      top: this.top + 'px',
      width: this.width + 'px',
    };
    return style;
  }

  hideWidget() {
    this.showHotspotItem = false;
  }
}
