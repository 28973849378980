import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: '[visible]',
})
export class VisibilityDirective implements AfterViewInit, OnDestroy {
  @Output()
  visible: EventEmitter<any> = new EventEmitter();
  private currentlyVisible = false;

  elementPos: number;
  elementHeight: number;

  scrollPos: number;
  windowHeight: number;

  subscriptionScroll: Subscription;
  subscriptionResize: Subscription;

  constructor(private element: ElementRef) {
    console.log('AppearDirective: constructor');
  }

  saveDimensions() {
    this.elementPos = this.getOffsetTop(this.element.nativeElement);
    this.elementHeight = this.element.nativeElement.offsetHeight;
    this.windowHeight = window.innerHeight;
  }
  saveScrollPos() {
    this.scrollPos = document.querySelector('app-body').scrollTop;
  }
  getOffsetTop(element: any) {
    let offsetTop = element.offsetTop || 0;
    if (element.offsetParent) {
      offsetTop += this.getOffsetTop(element.offsetParent);
    }
    return offsetTop;
  }
  checkVisibility() {
    //console.log('checkVisibility: ', this.isVisible())
    if (!this.currentlyVisible && this.isVisible()) {
      this.currentlyVisible = true;
      //this.unsubscribe();
      this.visible.emit(true);
    } else if (this.currentlyVisible && !this.isVisible()) {
      this.currentlyVisible = false;
      this.visible.emit(false);
    }
  }
  isVisible() {
    const lowEnough = this.scrollPos + this.windowHeight >= this.elementPos - 400; // -400 lets us load ahead of the sroll
    const tooLow = this.scrollPos >= this.elementPos + this.elementHeight + 400;
    const visible = lowEnough && !tooLow;
    //console.log("isVisible: ", this.scrollPos, this.elementPos, this.windowHeight, this.elementHeight, visible)
    return visible;

    //return this.scrollPos >= this.elementPos || (this.scrollPos + this.windowHeight) >= (this.elementPos + this.elementHeight);
  }

  subscribe() {
    this.subscriptionScroll = fromEvent(document.querySelector('app-body'), 'scroll').subscribe(() => {
      this.saveDimensions();
      this.saveScrollPos();
      this.checkVisibility();
    });
    this.subscriptionResize = fromEvent(window, 'resize').subscribe(() => {
      this.saveDimensions();
      this.checkVisibility();
    });
  }
  unsubscribe() {
    if (this.subscriptionScroll) {
      this.subscriptionScroll.unsubscribe();
    }
    if (this.subscriptionResize) {
      this.subscriptionResize.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.saveDimensions();
    this.saveScrollPos();
    this.subscribe();
    this.checkVisibility();
  }
  ngOnDestroy() {
    this.unsubscribe();
  }
}
