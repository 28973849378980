import { Injectable } from '@angular/core';
import { Observable, debounceTime } from 'rxjs';
import { Store } from '@ngrx/store';
import { ObjectUtil } from '@contrail/util';
import { State } from '../root-store/root-state';
import { Presentation } from '../presentation/presentation';
import {
  AnnotatedElement,
  AnnotationOption,
} from '../presentation/document/document-annotation/document-annotation-options';
import { DocumentAnnotationService } from '../presentation/document/document-annotation/document-annotation-service';
import { ShowcaseSelectors } from './showcase-store';
import { AnnotationLoader } from '../presentation/annotations-loader';
import { CollectionStatusMessage } from './showcase-store/collection-status-messages/collection-status-message.service';
import { DocumentStatusMessageService } from '../presentation/document/document-status/document-status-message.service';
import { setLoading } from '@common/loading-indicator/loading-indicator-store/loading-indicator.actions';
import { Entities } from '@contrail/sdk';

@Injectable({
  providedIn: 'root',
})
export class ShowcaseAnnotationService {
  presentationObject: Presentation;
  elementAnnotationsAlreadySet = false;
  currentFrameObject;
  backingAssortmentItems;
  showSourceAssortmentWarning = true;
  private presentationObservable: Observable<Presentation>;
  private existingElementAnnotations: {
    string?: AnnotationOption[];
  } = {};

  constructor(
    private store: Store<State>,
    private documentAnnotationService: DocumentAnnotationService,
    private annotationMessageService: DocumentStatusMessageService,
  ) {}

  // init(presentationObservable: Observable<Presentation>) {
  //   this.presentationObservable = presentationObservable;
  //   this.presentationObservable.subscribe(presentationObject => {
  //     this.presentationObject = presentationObject;
  //   });
  // }

  public setElementAnnotations(statusMessages: CollectionStatusMessage[]) {
    // first if condition is to make sure that the frames are not updated if no warning status message is returned by the store
    if ((statusMessages.length > 0 && !this.elementAnnotationsAlreadySet) || this.elementAnnotationsAlreadySet) {
      this.elementAnnotationsAlreadySet = true;
      this.addAnnotation(statusMessages);
    }
  }

  setElementAnnotationByAssortmentItems(backingAssortmentItems: any[]) {
    const annotationItemMap = {};
    AnnotationLoader.annotations?.forEach((annotationOption) => {
      const affectedAssortmentItems = backingAssortmentItems.filter((item) => item[annotationOption.property] === true);
      affectedAssortmentItems.forEach((assortmentItem) => {
        const itemId = 'item:' + assortmentItem.itemId;
        if (!annotationItemMap[itemId]) {
          annotationItemMap[itemId] = [];
        }
        annotationItemMap[itemId].push(annotationOption.type);
      });
    });
    this.addAnnotation(null, annotationItemMap);
  }

  private addAnnotation(statusMessages: CollectionStatusMessage[] = null, annotationItemMap: any = null) {
    const annotatedElements: AnnotatedElement[] = [];
    this.presentationObject?.frames.forEach((frame) => {
      if (frame.document) {
        frame.document.elements.forEach((element) => {
          if (element && element.type === 'component') {
            const item = element.modelBindings.item;
            if (item) {
              let annotations = this.existingElementAnnotations.hasOwnProperty(element.id)
                ? ObjectUtil.cloneDeep(this.existingElementAnnotations[element.id])
                : [];
              // statusMessages only
              if (statusMessages) {
                const existingStatusAnnotationIndex = annotations.findIndex(
                  (annotation) => annotation.category === 'status',
                );
                if (existingStatusAnnotationIndex > -1) {
                  annotations.splice(existingStatusAnnotationIndex, 1);
                }
              }
              if (this.showSourceAssortmentWarning) {
                statusMessages?.forEach((statusMessage) => {
                  if ('item:' + statusMessage.collectionElementId === item) {
                    annotations.push({
                      type: statusMessage.type,
                      category: 'status',
                      detail: { messageId: statusMessage.id },
                    });
                  }
                });
              }

              // assortmentItems only
              if (annotationItemMap) {
                annotations = annotations.filter((annotation) => annotation.category === 'status');
                if (annotationItemMap[element.modelBindings.item]) {
                  annotations = annotations.concat(
                    annotationItemMap[element.modelBindings.item].map((annotationType) => {
                      const annotationOption = AnnotationLoader.annotations?.find(
                        (annotation) => annotation.type === annotationType,
                      );
                      return {
                        category: annotationOption.category,
                        type: annotationType,
                        detail: { itemId: element.modelBindings.item },
                      };
                    }),
                  );
                }
              }

              annotatedElements.push({
                id: element.id,
                annotations,
              });
              this.existingElementAnnotations[element.id] = annotations;
            }
          }
        });
      }
    });
    if (annotatedElements.length > 0) {
      this.documentAnnotationService.annotatedElementsSubject.next(annotatedElements);
      this.documentAnnotationService.setAnnotatedElements(annotatedElements);
    }
  }
}
