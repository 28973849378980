import { Action, createReducer, on } from '@ngrx/store';
import * as SessionAssortmentsActions from './session-assortments.actions';
import { initialState, State } from './session-assortments.state';

const setSessionId = (state: State, { sessionId }) => {
  return {
    ...state,
    currentSessionId: sessionId,
  };
};
const loadSessionAssortments = (state: State, { sessionAssortments }) => {
  return {
    ...state,
    sessionAssortments: sessionAssortments.filter((assortment) => assortment.isArchived !== true),
  };
};
const addSessionAssortment = (state: State, { sessionAssortment }) => {
  return {
    ...state,
    sessionAssortments: [...state.sessionAssortments, sessionAssortment],
  };
};
const removeSessionAssortment = (state: State, { sessionAssortment }) => {
  return {
    ...state,
    sessionAssortments: state.sessionAssortments.filter((ass) => ass.id !== sessionAssortment.id),
  };
};
const updateSessionAssortment = (state: State, { sessionAssortment }) => {
  const index = state.sessionAssortments.findIndex((sa: any) => sa.id === sessionAssortment.id);
  let newArray = [...state.sessionAssortments];
  newArray[index] = sessionAssortment;
  return {
    ...state,
    sessionAssortments: newArray,
  };
};

const setCurrentSessionAssortment = (state: State, { sessionAssortment }) => {
  return {
    ...state,
    currentSessionAssortment: sessionAssortment,
  };
};

export const sessionAssortmentsReducer = createReducer(
  initialState,
  on(SessionAssortmentsActions.setSessionAssortments, loadSessionAssortments),
  on(SessionAssortmentsActions.setSessionId, setSessionId),
  on(SessionAssortmentsActions.createSessionAssortmentSuccess, addSessionAssortment),
  on(SessionAssortmentsActions.copySessionAssortmentSuccess, addSessionAssortment),
  on(SessionAssortmentsActions.addSessionAssortment, addSessionAssortment),
  on(SessionAssortmentsActions.updateSessionAssortmentSuccess, updateSessionAssortment),
  on(SessionAssortmentsActions.archiveSessionAssortmentSuccess, removeSessionAssortment),
  on(SessionAssortmentsActions.setCurrentSessionAssortment, setCurrentSessionAssortment),
);

export function reducer(state: State | undefined, action: Action): any {
  return sessionAssortmentsReducer(state, action);
}
