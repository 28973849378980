import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ItemModalComponent } from './item-modal/item-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ItemModalLauncher {
  constructor(public dialog: MatDialog) {}

  openModal(itemData, editorMode = 'EDIT'): void {
    const dialogRef = this.dialog.open(ItemModalComponent, {
      maxWidth: '100%',
      width: '92vw',
      autoFocus: true,
      panelClass: 'full-screen-modal',
      data: {
        itemData,
        editorMode,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
}
