import { UserSessionStoreState } from '../common/user-sessions/user-sessions-store';
import { AuthStoreState } from '../common/auth/auth-store';
import { CommentsStoreState } from '../common/comments/comments-store';
import { SessionAssortmentsStoreState } from '../session/session-assortments-store';
import { LoadingIndicatorStoreState } from '../common/loading-indicator/loading-indicator-store';
import { ShowcaseStoreState } from '../showcase/showcase-store';
import { PresentationStoreState } from '../presentation/presentation-store';
import { WorkspacesStoreState } from '@common/workspaces/workspaces-store';
import { AssortmentsStoreState } from '../common/assortments/assortments-store';
import { EditorModeStoreState } from '@common/editor-mode/editor-mode-store';
export interface State {
  userSessions: UserSessionStoreState.State;
  auth: AuthStoreState.State;
  comments: CommentsStoreState.State;
  sessionAssortments: SessionAssortmentsStoreState.State;
  loadingIndicator: LoadingIndicatorStoreState.State;
  assortments: AssortmentsStoreState.State;
  showcase: ShowcaseStoreState.State;
  presentation: PresentationStoreState.State;
  editorMode: EditorModeStoreState.State;
  workspaces: WorkspacesStoreState.State;
}
