import { Assortment } from 'src/app/common/assortments/assortment';
import { SessionAssortment } from '../session-assortments.service';

export interface State {
  currentSessionId: string;
  currentAssortment: Assortment;
  currentSessionAssortment: SessionAssortment;
  sessionAssortments: Array<SessionAssortment>;
}
export const initialState: State = {
  currentSessionId: null,
  currentSessionAssortment: null,
  currentAssortment: null,
  sessionAssortments: [],
};
