import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { CommentOwnerInfo } from 'src/app/common/comments/comments.service';
import { DocumentElement, DocumentElementEvent, PositionDefinition } from '@contrail/documents';
import { ItemData } from 'src/app/common/item-data/item-data';
import { DocumentService } from '../document/document.service';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { filter, map, take, tap } from 'rxjs/operators';
import { ItemModalLauncher } from '@common/items/item-modal-launcher';
import { AssortmentsSelectors } from 'src/app/common/assortments/assortments-store';
import { PresentationActions } from '../presentation-store';
import { CanvasUtil } from '@contrail/canvas';
import { ShowcaseService } from 'src/app/showcase/showcase.service';

@Component({
  selector: 'app-presentation-item-widget',
  templateUrl: './presentation-item-widget.component.html',
  styleUrls: ['./presentation-item-widget.component.scss'],
})
export class PresentationItemWidgetComponent implements OnInit, OnDestroy {
  constructor(
    public showcaseService: ShowcaseService,
    private presentationDocumentService: DocumentService,
    private modalLauncher: ItemModalLauncher,
    private store: Store<RootStoreState.State>,
  ) {}

  @Input() svgId: string;
  @Input() document;
  @Input() frameId;
  @Input() editorMode = 'EDIT';

  isMouseInsideActionDiv = false;
  isMouseInsideComponent = false;

  public commentOwnerInfo$: Observable<CommentOwnerInfo>;
  public itemData$: Observable<ItemData>;
  public itemId: string;
  private eventSub: Subscription;
  public styles: any;
  element: DocumentElement;
  public sourceAssortment$: Observable<any>;
  ngOnInit(): void {
    this.isMouseInsideActionDiv = false;
    this.isMouseInsideComponent = false;
    this.subscribe();
    this.sourceAssortment$ = this.store.select(AssortmentsSelectors.sourceAssortment);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }
  subscribe() {
    this.eventSub = this.presentationDocumentService.documentElementEvents.subscribe((event) => {
      if (!event || event.element.type !== 'component') {
        return;
      }
      if (event.eventType === 'mouseenter') {
        const elementIndex = this.document?.elements.findIndex((element) => element.id === event.element.id);
        if (elementIndex > -1) {
          // make sure that the current element belongs to this frame
          this.showWidget(event);
        }
      } else if (event.eventType === 'mouseleave' && !this.isMouseInsideActionDiv) {
        this.isMouseInsideComponent = false;
      }
    });
  }
  unsubscribe() {
    this.eventSub.unsubscribe();
  }

  showWidget(event: DocumentElementEvent) {
    console.log('event: ', event);
    this.element = event.element;
    this.setStyles(event);
    const binding = event.element.modelBindings.item || ':'; // Presumtive...
    console.log(`event.element.modelBindings ${JSON.stringify(event.element.modelBindings)}`);
    console.log(`binding ${JSON.stringify(binding)}`);

    const model = binding.split(':');
    this.itemId = model[1];
    this.itemData$ = this.store.select(AssortmentsSelectors.selectSourceItem(this.itemId));
    this.commentOwnerInfo$ = this.itemData$.pipe(
      filter((itemData) => !!itemData),
      map((itemData) => {
        const itemName = itemData.properties.name;
        const itemColorName = itemData.properties.optionName;
        return {
          entityType: 'item',
          id: itemData.id,
          name: `${itemName}(${itemColorName})`,
          modalTitle: `${itemName}(${itemColorName})`,
        };
      }),
    );
    this.isMouseInsideComponent = true;
  }

  setStyles(event: DocumentElementEvent) {
    const position = Object.assign({}, event.element.position);
    const svgElementRect = document.getElementById(this.svgId).getBoundingClientRect();
    svgElementRect.x = 0;
    svgElementRect.y = 0;

    const windowPosition: PositionDefinition = CanvasUtil.toWindowPosition(
      position.x - 12,
      position.y - 12,
      svgElementRect,
      {
        x: svgElementRect.width / this.document.size.width,
        y: svgElementRect.height / this.document.size.height,
      },
      svgElementRect,
    );
    this.styles = {
      top: `${windowPosition.y - 15}px`,
      left: `${windowPosition.x}px`,
      width: `${event.renderedElementSize.width}px`,
      height: `${event.renderedElementSize.height}px`,
    };
  }

  @HostListener('mouseenter', ['$event'])
  handleMouseEnter(event) {
    this.isMouseInsideActionDiv = true;
  }

  @HostListener('mouseleave', ['$event'])
  handleMouseOut(event) {
    this.isMouseInsideActionDiv = false;
  }

  openInfo() {
    combineLatest([
      this.store.select(AssortmentsSelectors.selectSourceItem(this.itemId)),
      this.store.select(AssortmentsSelectors.selectItemFromBackingAssortment(this.itemId)),
    ])
      .pipe(
        take(1),
        tap(([sourceItem, backingItem]) => {
          if (sourceItem) {
            this.modalLauncher.openModal(sourceItem, this.editorMode);
          } else if (backingItem) {
            this.modalLauncher.openModal(new ItemData(backingItem), this.editorMode);
          }
        }),
      )
      .subscribe();
  }

  openMenu(event) {
    event.preventDefault();
    const documentElementEvent: DocumentElementEvent = {
      element: this.element,
      eventType: 'contextmenu',
      sourceMouseEvent: event,
    };
    this.store.dispatch(PresentationActions.setCurrentFrameId({ currentFrameId: this.frameId }));
    this.presentationDocumentService.handleDocumentElementEvent(documentElementEvent);
  }
}
