import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FilterConditionType } from '@common/types/filters/filter-condition-types';
import { FilterCriteria } from '@common/types/filters/filter-criteria';
import { FilterDefinition, FilterPropertyDefinition } from '@common/types/filters/filter-definition';
import { TypePropertyFormConfiguration } from '@common/types/forms/type-property-form/type-property-form.component';
import { PropertyType, PropertyValueFormatter } from '@contrail/types';

export enum SearchOption {
  TODAY = 'Today',
  YESTERDAY = ' Yesterday',
  LAST_7_DAYS = 'Last 7 days',
  LAST_14_DAYS = 'Last 14 days',
  LAST_30_DAYS = 'Last 30 days',
  CUSTOM_RANGE = 'Custom Range',
}

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent implements OnInit {
  dateCriteria: any;
  selectedSearchOption: SearchOption = SearchOption.TODAY;
  searchOptions: SearchOption[] = [
    SearchOption.TODAY,
    SearchOption.YESTERDAY,
    SearchOption.LAST_7_DAYS,
    SearchOption.LAST_14_DAYS,
    SearchOption.LAST_30_DAYS,
    SearchOption.CUSTOM_RANGE,
  ];
  dateFilterActive = false;
  customRangeActive = false;
  fromDateValue;
  toDateValue;
  fromDatePropertyDefinition: TypePropertyFormConfiguration;
  toDatePropertyDefinition: TypePropertyFormConfiguration;
  errorMessage: string;
  @Input() dateFilterAttribute: any;
  @Input() toggle = true;
  @Output() dateFilterChanged = new EventEmitter();
  constructor() {}

  ngOnInit(): void {
    this.fromDatePropertyDefinition = {
      typeProperty: {
        slug: 'from_' + this.dateFilterAttribute.attribute,
        label: 'From',
        propertyType: PropertyType.Date,
      },
    };
    this.toDatePropertyDefinition = {
      typeProperty: {
        slug: 'to_' + this.dateFilterAttribute.attribute,
        label: 'To',
        propertyType: PropertyType.Date,
      },
    };
    if (!this.toggle) {
      this.selectedSearchOption = SearchOption.LAST_7_DAYS;
      this.setSearchOption({ value: this.selectedSearchOption });
    }
  }

  toggleFilterByDate(toggle) {
    this.dateFilterActive = toggle.checked;
    if (toggle.checked) {
      this.selectedSearchOption = SearchOption.TODAY;
      this.setSearchOption({ value: this.selectedSearchOption });
    } else {
      this.setSearchOption({ value: null });
    }
  }

  setSearchOption(option) {
    let fromDate = this.getDate(new Date());
    const toDate = this.getDate(new Date());
    this.selectedSearchOption = option.value;
    this.fromDateValue = null;
    this.toDateValue = null;
    this.customRangeActive = false;
    switch (option.value) {
      case SearchOption.CUSTOM_RANGE:
        this.customRangeActive = true;
        break;
      case SearchOption.TODAY:
        this.setDateFilterDefinition(fromDate);
        break;
      case SearchOption.YESTERDAY:
        fromDate.setDate(fromDate.getDate() - 1);
        fromDate = this.getDate(fromDate);
        this.setDateFilterDefinition(fromDate, toDate);
        break;
      case SearchOption.LAST_7_DAYS:
        fromDate.setDate(fromDate.getDate() - 7);
        fromDate = this.getDate(fromDate);
        this.setDateFilterDefinition(fromDate, toDate);
        break;
      case SearchOption.LAST_14_DAYS:
        fromDate.setDate(fromDate.getDate() - 14);
        fromDate = this.getDate(fromDate);
        this.setDateFilterDefinition(fromDate, toDate);
        break;
      case SearchOption.LAST_30_DAYS:
        fromDate.setDate(fromDate.getDate() - 30);
        fromDate = this.getDate(fromDate);
        this.setDateFilterDefinition(fromDate, toDate);
        break;
      default:
        this.dateFilterChanged.emit(null);
        break;
    }
  }

  getDate(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  setDateFilterDefinition(fromDate, toDate?) {
    const filterPropertyDefinition: FilterPropertyDefinition = {
      slug: this.dateFilterAttribute.attribute,
      label: '',
      propertyType: PropertyType.Date,
    };
    const filterCriteria: FilterCriteria = {
      propertyCriteria: [
        {
          criteriaValue: new PropertyValueFormatter().parseDate(fromDate),
          filterConditionType: FilterConditionType.GREATER_THAN_OR_EQUAL,
          filterPropertyDefinition,
        },
      ],
    };
    if (toDate) {
      let refinedToDate = new Date(toDate);
      refinedToDate.setDate(refinedToDate.getDate() + 1);

      filterCriteria.propertyCriteria.push({
        criteriaValue: new PropertyValueFormatter().parseDate(refinedToDate),
        filterConditionType: FilterConditionType.LESS_THAN_OR_EQUAL,
        filterPropertyDefinition,
      });
    }
    const dateFilterDefinition: FilterDefinition = {
      filterPropertyDefinitions: [filterPropertyDefinition],
      filterCriteria,
    };
    this.dateFilterChanged.emit(dateFilterDefinition);
  }

  handleValueChange(dateObj) {
    this.errorMessage = null;
    if (dateObj.propertySlug === 'from_' + this.dateFilterAttribute.attribute) {
      this.fromDateValue = dateObj.value;
    } else {
      this.toDateValue = dateObj.value;
    }
    if (this.fromDateValue && this.toDateValue) {
      if (this.toDateValue > this.fromDateValue) {
        this.setDateFilterDefinition(this.fromDateValue, this.toDateValue);
      } else {
        this.errorMessage = 'To date must be after Before date.';
      }
    }
  }

  clearValue() {
    this.dateFilterActive = false;
    this.customRangeActive = false;
  }
}
