import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { PresentationActions, PresentationSelectors } from '../presentation-store';
import {
  ShowcasePresentationPanel,
  ShowcasePresentation,
  PresentationFrame,
  PresentationCollectionElement,
} from '../presentation';
import { SessionService } from 'src/app/session/session.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PresentationNavigatorService {
  private presentation: ShowcasePresentation;
  private currentPanel: ShowcasePresentationPanel;
  private frames: Array<PresentationFrame>;
  private isFullscreen: boolean;
  private currentLocationURL = window.location.href;

  private currentSlidePanel: ShowcasePresentationPanel = null;
  private currentSlidePanelSubject: BehaviorSubject<ShowcasePresentationPanel> = new BehaviorSubject(
    this.currentSlidePanel,
  );
  public currentSlidePanel$: Observable<ShowcasePresentationPanel> = this.currentSlidePanelSubject.asObservable();

  constructor(
    private store: Store<RootStoreState.State>,
    private sessionService: SessionService,
  ) {
    this.store.select(PresentationSelectors.currentShowcasePresentation).subscribe((presentation) => {
      if (!presentation) {
        return;
      }
      this.presentation = presentation;
      this.frames = this.presentation.presentation.frames.filter((frame) => !frame.disabled);
    });

    this.store.select(PresentationSelectors.currentShowcasePresentationPanel).subscribe((panel) => {
      this.currentPanel = panel;
    });

    this.sessionService.fullscreenMode.subscribe((fullScreenMode) => {
      this.isFullscreen = fullScreenMode;
      console.log('isFullscreen', this.isFullscreen);
    });
  }

  public moveToFrame(panel) {
    if (this.isFullscreen) {
      return;
    }

    const frame = panel.frame;
    let selectedFrame = '';
    switch (frame?.type) {
      case 'home':
        selectedFrame = 'home';
        break;
      case 'collection':
      case 'grid':
        selectedFrame = frame?.id + panel?.sequence;
        break;
      default:
        selectedFrame = frame?.id + panel?.sequence;
        break;
    }

    if (window.location.hash) {
      this.currentLocationURL = window.location.href.replace(window.location.hash, '');
    }

    console.log(` ~~~~~~ selectedFrame ${selectedFrame}`);
    window.location.href = this.currentLocationURL + '#' + selectedFrame;
  }

  public setCurrentSlidePanel(showcasePresentationPanel) {
    this.currentSlidePanel = showcasePresentationPanel;
    this.currentSlidePanelSubject.next(showcasePresentationPanel);
    this.store.dispatch(PresentationActions.setCurrentShowcasePresentationPanel({ showcasePresentationPanel }));
  }

  public goToFrame(frame: PresentationFrame) {
    const panels = this.presentation.panels.filter((panel) => panel.frame.id === frame.id);
    if (panels.length) {
      this.store.dispatch(
        PresentationActions.setCurrentShowcasePresentationPanel({ showcasePresentationPanel: panels[0] }),
      );
      this.moveToFrame(panels[0]);
    }
  }

  public goToSetElement(frame: PresentationFrame, element: PresentationCollectionElement) {
    const panels = this.presentation.panels.filter(
      (panel) => panel.frame.id === frame.id && panel.collectionElement?.value === element.value,
    );
    if (panels.length) {
      // const loc = (frame.type === 'home' ? 'home' : frame.id);
      // console.log(`element ${JSON.stringify(element)}`);
      this.store.dispatch(
        PresentationActions.setCurrentShowcasePresentationPanel({ showcasePresentationPanel: panels[0] }),
      );
      this.moveToFrame(panels[0]);
    }
  }

  public restartPresentation() {
    const panels = this.presentation.panels.filter((panel) => panel.sequence === 0); // go to the first panel/home
    if (panels.length) {
      this.store.dispatch(
        PresentationActions.setCurrentShowcasePresentationPanel({ showcasePresentationPanel: panels[0] }),
      );
      this.moveToFrame(panels[0]);
    }
  }

  public next() {
    const panels = this.presentation.panels.filter((panel) => panel.sequence === this.currentPanel.sequence + 1);
    if (panels.length) {
      this.store.dispatch(
        PresentationActions.setCurrentShowcasePresentationPanel({ showcasePresentationPanel: panels[0] }),
      );
      this.moveToFrame(panels[0]);
    }
  }

  public prev() {
    const panels = this.presentation.panels.filter((panel) => panel.sequence === this.currentPanel.sequence - 1);
    if (panels.length) {
      this.store.dispatch(
        PresentationActions.setCurrentShowcasePresentationPanel({ showcasePresentationPanel: panels[0] }),
      );
      this.moveToFrame(panels[0]);
    }
  }
}
