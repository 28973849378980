import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Document } from '@contrail/documents';
import { ShowcasePresentationPanel } from '../../presentation';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { DomSanitizer } from '@angular/platform-browser';
import { PresentationSelectors } from '../../presentation-store';

@Component({
  selector: 'app-presentation-iframe-panel',
  templateUrl: './presentation-iframe-panel.component.html',
  styleUrls: ['./presentation-iframe-panel.component.scss'],
})
export class PresentationIframePanelComponent implements AfterViewInit, OnInit {
  @Input() panel: ShowcasePresentationPanel;
  @Input() visible = true;

  url: any;
  public document: Document;
  frameHeight: 900;
  private initialized = false;
  constructor(
    private store: Store<RootStoreState.State>,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.panel.frame.url);
  }

  ngAfterViewInit(): void {
    this.initialized = true;
    this.store.select(PresentationSelectors.presentationSize).subscribe((size) => {
      this.frameHeight = size.height;
    });
  }
}
