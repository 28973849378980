import { Component, OnInit } from '@angular/core';
import { ExporterService } from './exporter.service';

@Component({
  selector: 'app-exporter',
  templateUrl: './exporter.component.html',
  styleUrls: ['./exporter.component.scss'],
})
export class ExporterComponent implements OnInit {
  constructor(private exporterService: ExporterService) {}
  ngOnInit() {}
}
