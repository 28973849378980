import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { fromEvent, Subscription, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { SizeDefinition } from '@contrail/documents';
import { ShowcasePresentation, ShowcasePresentationPanel } from './presentation';
import { Store } from '@ngrx/store';
import { RootStoreState } from '../root-store';
import { PresentationActions, PresentationSelectors } from './presentation-store';
import { PresentationNavigatorService } from './presentation-navigator/presentation-navigator.service';
import { AnnotationLoader } from './annotations-loader';
import { DocumentAnnotationService } from './document/document-annotation/document-annotation-service';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
import { CustomFontsLoader } from './custom-fonts-loader';

@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.scss'],
})
export class PresentationComponent implements AfterViewInit, OnDestroy {
  public presentation: ShowcasePresentation;
  public currentPanel: ShowcasePresentationPanel;
  public viewMode = 'SCROLL';
  private currentSlidePanel: ShowcasePresentationPanel;

  @ViewChild('presentationBody') presentationBody: ElementRef;
  constructor(
    private store: Store<RootStoreState.State>,
    private navService: PresentationNavigatorService,
    private documentAnnotationService: DocumentAnnotationService,
    private annotationLoader: AnnotationLoader,
    private customFontsLoader: CustomFontsLoader,
  ) {
    this.subscribe();
  }

  private presentationSub: Subscription;
  private presentationPanelSub: Subscription;
  private subscription = new Subscription();
  private screenResizes = fromEvent(window, 'resize').pipe(debounce(() => timer(500)));
  private screenResizesSub: Subscription;
  private editorMode;

  private subscribe() {
    this.subscription.add(
      this.store.select(PresentationSelectors.currentShowcasePresentation).subscribe((pres) => {
        this.presentation = pres;
        if (!this.currentSlidePanel && this.presentation?.panels?.length > 0) {
          this.setCurrentSlidePanel(this.presentation?.panels[0]);
        }
      }),
    );
    this.subscription.add(
      this.store.select(PresentationSelectors.currentShowcasePresentationPanel).subscribe((panel) => {
        this.currentPanel = panel;
      }),
    );
    this.subscription.add(
      this.screenResizes.subscribe(() => {
        this.updatePresentationSize();
      }),
    );
    this.subscription.add(
      this.annotationLoader.loaded.subscribe((loaded) => {
        this.documentAnnotationService.annotationOptions = AnnotationLoader.annotations;
      }),
    );
    this.subscription.add(
      this.store.select(EditorModeSelectors.editorMode).subscribe((editorMode) => {
        this.editorMode = editorMode;
      }),
    );
    this.subscribeToCurrentPanel();
    document.documentElement.addEventListener('fullscreenchange', this.handleFullScreenChange.bind(this));
  }
  private unsubscribe() {
    this.subscription.unsubscribe();
    document.documentElement.removeEventListener('fullscreenchange', this.handleFullScreenChange.bind(this));
  }

  private subscribeToCurrentPanel() {
    const body = document.querySelector('app-body');
    this.subscription.add(
      fromEvent(body, 'scroll').subscribe(() => {
        if (this.viewMode === 'SCROLL') {
          this.presentation?.panels.forEach((panel) => {
            const el = document.getElementById(panel?.frame?.id + panel?.sequence);
            if (el) {
              const scrollTop = body.scrollTop + 64;
              if (
                scrollTop >= el.offsetTop - window.innerHeight / 2 &&
                scrollTop <= el.offsetTop + window.innerHeight / 2 - 60
              ) {
                this.setCurrentSlidePanel(panel);
              }
            }
          });
        }
      }),
    );
  }

  private setCurrentSlidePanel(panel: ShowcasePresentationPanel) {
    if (!this.currentSlidePanel || this.currentSlidePanel?.frame?.id !== panel?.frame?.id) {
      console.log('Current active slide panel: ', panel);
      this.currentSlidePanel = panel;
      this.navService.setCurrentSlidePanel(this.currentSlidePanel);
    }
  }

  handleFullScreenChange(event) {
    if (document.fullscreenElement) {
      setTimeout(() => {
        this.viewMode = 'SLIDE';
        this.updatePresentationSize();
      }, 50);
    } else {
      this.viewMode = 'SCROLL';
    }
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updatePresentationSize();
    }, 200);
  }
  updatePresentationSize() {
    const screenHeight = window.innerHeight;
    const screenWidth = window.innerWidth - (document?.fullscreenElement ? 0 : 12); // 12 is the scrollbar width
    const boundingBox = this.presentationBody.nativeElement.getBoundingClientRect();

    const availableHeight = screenHeight - boundingBox.top;
    const availableWidth = screenWidth;
    const availableRatio = availableWidth / availableHeight;
    const desiredRatio = 16 / 9;
    //console.log("updatePresentationSize: ", screenHeight, screenWidth, availableHeight, availableWidth)
    let newHeight = 0;
    let newWidth = 0;
    if (availableRatio > desiredRatio) {
      // USE HEIGHT TO DETERMINE WIDTH
      newHeight = availableHeight;
      newWidth = newHeight * desiredRatio;
    } else {
      // USE WIDTH TO DETERMINE HEIGHT
      newWidth = availableWidth;
      newHeight = newWidth / desiredRatio;
    }
    const desiredSize: SizeDefinition = { width: newWidth, height: newHeight };
    //console.log("new size: ", desiredSize)
    this.store.dispatch(PresentationActions.setPresentationSize({ presentationSize: desiredSize }));
  }
}
