<div
  *ngIf="textHyperlinkDetails"
  class="text-hyperlink"
  [ngClass]="{ 'text-hyperlink-visible': showOverlay }"
  [ngStyle]="{
    top: textHyperlinkDetails?.position?.y + yPositionAdjustment + 'px',
    left: textHyperlinkDetails?.position?.x + 'px'
  }"
>
  <div class="flex py-[18px]" *ngIf="mode === 'view'">
    <div class="flex items-center">
      <mat-icon class="mr-3">link</mat-icon>
      <a [href]="textHyperlinkDetails.url" target="_blank">{{ textHyperlinkDetails.url }}</a>
    </div>
  </div>
</div>
