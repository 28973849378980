import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { ShowcaseSelectors } from './showcase-store';
import { DocumentStatusMessageService } from '../presentation/document/document-status/document-status-message.service';
import { CollectionStatusMessage } from './showcase-store/collection-status-messages/collection-status-message.service';
import { Observable, combineLatest, debounceTime } from 'rxjs';
import { ShowcaseAnnotationService } from './showcase-annotation.service';
import { Presentation } from '../presentation/presentation';
import { setLoading } from '@common/loading-indicator/loading-indicator-store/loading-indicator.actions';
import { AssortmentsSelectors } from '@common/assortments/assortments-store';

@Injectable({
  providedIn: 'root',
})
export class ShowcaseService {
  constructor(
    private store: Store<RootStoreState.State>,
    private annotationMessageService: DocumentStatusMessageService,
    private showcaseAnnotationService: ShowcaseAnnotationService,
  ) {
    this.store.select(ShowcaseSelectors.currentShowcase).subscribe(async (showcase) => {
      if (!showcase) {
        return;
      }

      this.annotationMessageService.init(
        this.store.select(ShowcaseSelectors.collectionStatusMessages) as Observable<Array<CollectionStatusMessage>>,
      );
      combineLatest(
        this.store.select(AssortmentsSelectors.backingAssortmentItems),
        this.annotationMessageService.statusMessages.pipe(debounceTime(1000)),
      ).subscribe(([backingAssortmentItems, statusMessages]) => {
        if (statusMessages) {
          this.showcaseAnnotationService.setElementAnnotations(statusMessages);
        }
        this.showcaseAnnotationService.setElementAnnotationByAssortmentItems(backingAssortmentItems);
      });
      await this.loadPresentation(showcase.id);
    });
  }

  public setShowSourceAssortmentWarning(showSourceAssortmentWarning: boolean) {
    this.showcaseAnnotationService.showSourceAssortmentWarning = showSourceAssortmentWarning;
  }
  get showSourceAssortmentWarning() {
    return this.showcaseAnnotationService.showSourceAssortmentWarning;
  }

  public async getShowcaseById(id) {
    return new Entities().get({
      entityName: 'showcase',
      id,
      relations: [
        'sourceAssortment',
        'showcaseSessions',
        'showcaseSessions.showcaseSessionAssortments',
        'showcasePresentations',
        'workspace',
        'createdBy',
        'updatedBy',
        // 'presentation'
      ],
    });
  }

  async loadPresentation(showcaseId) {
    try {
      let presentation: Presentation = {};
      this.store.dispatch(setLoading({ loading: true, message: 'Loading your Presentation. Please wait...' }));

      const presentations = await new Entities().get({
        entityName: 'presentation',
        criteria: {
          ownedByReference: `showcase:${showcaseId}`,
        },
      });
      if (presentations?.length) {
        await Promise.all(presentations.map((presentation) => this.hydratePresentation(presentation)));
        presentation = presentations[0];
      }

      this.showcaseAnnotationService.presentationObject = presentation;

      if (!this.showcaseAnnotationService.presentationObject.frames) {
        this.showcaseAnnotationService.presentationObject.frames = [];
      }
      if (!this.showcaseAnnotationService.presentationObject.presentationFrameOrder?.length) {
        this.showcaseAnnotationService.presentationObject.presentationFrameOrder = [];
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.store.dispatch(setLoading({ loading: false, message: '' }));
    }
  }

  public async hydratePresentation(presentation: Presentation) {
    if (presentation?.framesDownloadURL) {
      console.log('Hydrating presentation: ', presentation.id);
      const response = await fetch(presentation.framesDownloadURL);
      const frames = await response.json();
      presentation.frames = frames || [];
    }
  }
}
