import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CommentsSelectors } from 'src/app/common/comments/comments-store';
import { Comment } from 'src/app/common/comments/comments.service';
import { DocumentElement, PositionDefinition } from '@contrail/documents';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ShowcaseSelectors } from 'src/app/showcase/showcase-store';
import { Showcase } from 'src/app/showcase/showcase';
import { PresentationNavigatorService } from '../presentation-navigator/presentation-navigator.service';
import { PresentationSelectors } from '../presentation-store';
import { ShowcasePresentationPanel, ShowcasePresentation } from '../presentation';
import { AddPinnedCommentsService } from './add-pinned-comments.service';
import { CommentsModalLauncher } from 'src/app/common/comments/comments-modal-launcher';

@Injectable({
  providedIn: 'root',
})
export class ShowPinnedCommentsListenerService {
  private showcase: Showcase;
  private elements: Array<DocumentElement>;
  private currentPanel: ShowcasePresentationPanel;
  private presentation: ShowcasePresentation;
  private svgId: string;
  private commentsAccessLevel = 'EDIT';

  constructor(
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
    private presentationNavigatorService: PresentationNavigatorService,
    private addPinnedCommentsService: AddPinnedCommentsService,
    public launcher: CommentsModalLauncher,
  ) {
    this.init();
  }

  private init() {
    this.store.select(ShowcaseSelectors.currentShowcase).subscribe((showcase) => (this.showcase = showcase));
    this.store
      .select(CommentsSelectors.selectedComment)
      .subscribe((selectedComment) => this.showSelectedComment(selectedComment));
    this.store.select(PresentationSelectors.currentShowcasePresentationPanel).subscribe((panel) => {
      this.currentPanel = panel;
      this.elements = this.currentPanel.frame?.document?.elements;
      this.svgId = `FRAME${this.currentPanel?.frame?.id}`;
    });
    this.store.select(PresentationSelectors.currentShowcasePresentation).subscribe((presentation) => {
      if (!presentation) {
        return;
      }
      this.presentation = presentation;
    });
    this.store
      .select(CommentsSelectors.commentsAccessLevel)
      .subscribe((commentsAccessLevel) => (this.commentsAccessLevel = commentsAccessLevel));
  }

  /**
   * Navigate to the @selectedComment
   * Possible options:
   * - comment on the document element within a frame
   * - comment at a document position within a frame
   * - comment on a deleted element within a frame
   * - showcase specific comment
   * - frame preview specific comment
   * - item family within a frame
   * - item option within a frame
   * First, navigate to the frame then show comment.
   * @param selectedComment
   */
  private showSelectedComment(selectedComment: Comment) {
    if (selectedComment) {
      const frameId = selectedComment?.subContextReference?.split(':')[1];
      // Comment is not from a current frame
      if (frameId && frameId != this.currentPanel?.frame?.id) {
        const selectedFrame = this.presentation.presentation?.frames.find((frame) => frame.id === frameId);
        if (!selectedFrame) {
          this.snackBar.open('Frame no longer exists.', '', { duration: 4000 });
        } else {
          this.presentationNavigatorService.goToFrame(selectedFrame);
          setTimeout(() => this.showSelectedCommentOnCurrentFrame(selectedComment, frameId), 200);
        }
      } else if (frameId && frameId === this.currentPanel?.frame?.id) {
        this.showSelectedCommentOnCurrentFrame(selectedComment, frameId);
      } else {
        this.snackBar.open('Showcase specific comment.', '', { duration: 5000 });
      }
    }
  }

  private showSelectedCommentOnCurrentFrame(selectedComment: Comment, frameId: string) {
    const ownedByReference = selectedComment?.ownedByReference?.split(':');
    if (selectedComment.documentElementId) {
      const element = this.getDocumentElement(selectedComment.documentElementId);
      if (!element) {
        this.snackBar.open('Element no longer exists.', '', { duration: 4000 });
      } else {
        this.navigateToElementComment(selectedComment, { id: element.id, position: element.position });
      }
    } else if (selectedComment.documentPosition) {
      this.navigateToElementComment(selectedComment);
    } else if (ownedByReference?.length === 2 && ownedByReference[0] === 'item') {
      this.navigateToItemComment(selectedComment, frameId);
    }
  }

  /**
   * Show comment overlay that belongs to a document element or document position
   * @param comment
   * @param documentElement
   */
  private navigateToElementComment(
    comment: Comment,
    documentElement: { id: string; position: PositionDefinition } = null,
  ) {
    const commentOverlayDocumentPosition = {
      x: comment.documentPosition.x + (documentElement ? documentElement.position.x : 0),
      y: comment.documentPosition.y + (documentElement ? documentElement.position.y : 0),
    };

    this.addPinnedCommentsService.addCommentToDocumentElement(
      {
        entityType: 'showcase',
        id: this.showcase.id,
        documentPosition: comment.documentPosition,
        documentElementId: comment.documentElementId,
        subContextReference: comment.subContextReference,
      },
      commentOverlayDocumentPosition,
      this.svgId,
      this.commentsAccessLevel,
    );
  }

  private getDocumentElement(documentElementId?: string): DocumentElement {
    return documentElementId ? this.elements.find((element) => element.id === documentElementId) : null;
  }

  /**
   * Show comment overlay that belongs to an item option
   * @param itemOptionValue
   */
  private navigateToItemComment(comment: Comment, frameId) {
    const [entityType, id] = comment.ownedByReference.split(':');

    if (!this.getItemFromCollection(id)) {
      this.snackBar.open('Item no longer exists.', '', { duration: 4000 });
      return;
    }

    this.addPinnedCommentsService.addItemComment(id, frameId, this.commentsAccessLevel);

    // const name = `${comment?.ownedBy.name} ${comment?.ownedBy?.optionName ? '(' + comment?.ownedBy?.optionName + ')' : ''}`.trim();
    // this.launcher.openModal({
    //   ownerInfo: {
    //     entityType,
    //     id,
    //     modalTitle: name,
    //     name
    //   }
    // });
  }

  private getItemFromCollection(itemValue) {
    return (
      this.currentPanel?.frame?.collection?.set.find((itemFamily) => itemFamily.value === itemValue) ||
      []
        .concat(...this.currentPanel?.frame?.collection?.set.map((itemFamily) => itemFamily.children))
        .find((itemOption) => itemOption.value === itemValue)
    );
  }
}
