import { createAction, props } from '@ngrx/store';
import { Item } from 'src/app/common/assortments/item';
import { PresentationCollectionElement } from 'src/app/presentation/presentation';
import { Showcase } from './showcase.state';

export enum ShowcaseActionTypes {
  ASSIGN_SHOWCASE_SOURCE_ASSORTMENT = '[Showcase] Assign Showcase Source Assortment',
  ASSIGN_SHOWCASE_SOURCE_ASSORTMENT_SUCCESS = '[Showcase] Assign Showcase Source Assortment Success',
  CLEAR_SHOWCASE_SOURCE_ASSORTMENT = '[Showcase] Clear Showcase Source Assortment',
  CLEAR_SHOWCASE_SOURCE_ASSORTMENT_SUCCESS = '[Showcase] Clear Showcase Source Assortment Success',

  LOAD_CURRENT_SHOWCASE = '[Showcase] Load Current Showcase',
  LOAD_CURRENT_SHOWCASE_SUCCESS = '[Showcase] Load Current Showcase Success',
  LOAD_CURRENT_SHOWCASE_FAILURE = '[Showcase] Load Current Showcase Failure',

  CLEAR_CURRENT_SHOWCASE = '[Showcase] Clear Current Showcase',
  SET_ITEMS_USED_IN_SHOWCASE = '[Showcase] Load Item ids added to the showcase',

  SET_GRID_FRAME_ACTIVE_CONTAINER = '[Showcase] Set Grid Frame Active Container',
  SET_COLLECTION_FRAME_ACTIVE_CONTAINER = '[Showcase] Set Collection Frame Active Container',

  SET_ANNOTATION_TYPE = '[Showcase] Set Annotation Type',

  SET_STATUS_MESSAGE_ELEMENT = '[Showcase] Set Status Message Element',

  SET_SHOW_SOURCE_ASSORTMENT_WARNING = '[Showcase] Set Show Source Assortment Warning',
}

////////////////////////////////////////////////////////////////////////////////

export const loadCurrentShowcase = createAction(ShowcaseActionTypes.LOAD_CURRENT_SHOWCASE, props<{ id: string }>());

export const loadCurrentShowcaseSuccess = createAction(
  ShowcaseActionTypes.LOAD_CURRENT_SHOWCASE_SUCCESS,
  props<{ showcase: Showcase }>(),
);

export const loadCurrentShowcaseFailure = createAction(
  ShowcaseActionTypes.LOAD_CURRENT_SHOWCASE_FAILURE,
  props<{ error: any }>(),
);

export const clearCurrentShowcase = createAction(ShowcaseActionTypes.CLEAR_CURRENT_SHOWCASE);

export const setItemsUsedInShowcase = createAction(
  ShowcaseActionTypes.SET_ITEMS_USED_IN_SHOWCASE,
  props<{ itemsUsedInShowcase: Array<Item> }>(),
);

export const setGridFrameActiveContainer = createAction(
  ShowcaseActionTypes.SET_GRID_FRAME_ACTIVE_CONTAINER,
  props<{ gridFrameActiveContainer: PresentationCollectionElement }>(),
);

export const setCollectionFrameActiveContainer = createAction(
  ShowcaseActionTypes.SET_COLLECTION_FRAME_ACTIVE_CONTAINER,
  props<{ collectionFrameActiveContainer: PresentationCollectionElement }>(),
);

export const setAnnotationType = createAction(
  ShowcaseActionTypes.SET_ANNOTATION_TYPE,
  props<{ annotationType: string }>(),
);

export const setStatusMessageElement = createAction(
  ShowcaseActionTypes.SET_STATUS_MESSAGE_ELEMENT,
  props<{ statusMessageElement: any }>(),
);

export const assignShowcaseAssortment = createAction(
  ShowcaseActionTypes.ASSIGN_SHOWCASE_SOURCE_ASSORTMENT,
  props<{ id: string; sourceAssortmentId: string }>(),
);

export const assignShowcaseAssortmentSuccess = createAction(
  ShowcaseActionTypes.ASSIGN_SHOWCASE_SOURCE_ASSORTMENT_SUCCESS,
  props<{ showcase: any }>(),
);

export const clearShowcaseSourceAssortment = createAction(
  ShowcaseActionTypes.CLEAR_SHOWCASE_SOURCE_ASSORTMENT,
  props<{ id: string }>(),
);

export const clearShowcaseSourceAssortmentSuccess = createAction(
  ShowcaseActionTypes.CLEAR_SHOWCASE_SOURCE_ASSORTMENT_SUCCESS,
  props<{ showcase: any }>(),
);

export const setShowSourceAssortmentWarning = createAction(
  ShowcaseActionTypes.SET_SHOW_SOURCE_ASSORTMENT_WARNING,
  props<{ showSourceAssortmentWarning: boolean }>(),
);
