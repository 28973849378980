import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../../root-store/root-state';
import { DocumentElement } from '@contrail/documents';
import { CollectionStatusMessage } from 'src/app/showcase/showcase-store/collection-status-messages/collection-status-message.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentStatusMessageService {
  messages$: Observable<Array<CollectionStatusMessage>>;
  messages: Array<CollectionStatusMessage>;
  private statusMessagesSubject: BehaviorSubject<Array<CollectionStatusMessage>> = new BehaviorSubject(null);
  public statusMessages: Observable<Array<CollectionStatusMessage>> = this.statusMessagesSubject.asObservable();
  constructor(private store: Store<State>) {}

  init(statusMessageObservable: Observable<Array<CollectionStatusMessage>>) {
    this.messages$ = statusMessageObservable;
    this.messages$.subscribe((data) => {
      this.messages = data;
      this.statusMessagesSubject.next(this.messages);
    });
  }

  getMessages() {
    return this.messages;
  }

  getMessage(element: DocumentElement) {
    let statusMessage = '';
    if (this.messages.length > 0) {
      const filteredMessages = this.messages.filter((message) => message.id === element.id);
      if (filteredMessages.length > 0) {
        statusMessage = filteredMessages[0].message;
      }
    }
    return statusMessage;
  }
}
