import { Component, OnInit } from '@angular/core';
import { AuthContext, AuthService } from 'src/app/common/auth/auth.service';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss'],
})
export class HeaderNavComponent implements OnInit {
  isExpanded: boolean;
  authContext: AuthContext;
  navItems: Array<any>;
  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.authContext.subscribe((context) => {
      this.authContext = context;
    });

    const navItems = [];
    navItems.push({ icon: 'play_arrow', routerLink: 'presentation', label: 'Presentation' });
    navItems.push({ svg: true, icon: 'contrail_compass', routerLink: 'explorer', label: 'Explorer' });
    // if (['archergrey', 'demo'].includes(this.authContext?.currentOrg?.orgSlug)) {
    //   navItems.push({ svg: true, icon: 'contrail_showcase', routerLink: 'virtual-showcase', label: 'Virtual Showroom'});
    // }
    // navItems.push({ svg: true, icon: 'contrail_media', routerLink: 'content', label: 'Media'});
    navItems.push({ svg: true, icon: 'contrail_assortment', routerLink: 'session', label: 'Assort' });
    this.navItems = navItems;
  }
}
