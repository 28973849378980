import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import {
  DocumentElement,
  DocumentElementEvent,
  DocumentNavigationEvent,
  DocumentSVGElementEvent,
  DocumentTextElementEvent,
  StyleDefinition,
} from '@contrail/documents';
import { ActionRequest } from '@contrail/actions';
import { AssortmentsSelectors } from '@common/assortments/assortments-store';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { AnnotatedElement } from './document-annotation/document-annotation-options';
import { CanvasElement, CoordinateBox, Frame, Group, Mask } from '@contrail/canvas';
import { DocumentAnnotationService } from './document-annotation/document-annotation-service';
import { EntityReference } from '@contrail/sdk';
import { DocumentItemService } from './document-item/document-item.service';

export interface DocumentRenderer {
  applyChanges: (changes: any) => void;
  addElements: (elements: Array<DocumentElement>, select?: boolean) => Promise<any>;
  clear: () => void;
  loadDocument: (document: Document) => void;
  getSelectedElements: () => Array<DocumentElement>;
  getCanvasElementById: (id) => CanvasElement;
  getElementIndex: (id) => number;
  getVisibleElements: () => CanvasElement[];
  removeElements: (ids: Array<string>) => void;
  setInteractionMode: (mode: string) => void;
  getInteractionMode: () => string;
  getImageCacheMetrics: () => any;
  copySelectedElement: () => void;
  activateComponentAndImageInteraction: (allow: boolean) => void;
  applyTextChanges: (changes: DocumentTextElementEvent) => void;
  applyStickyNoteChanges: (changes: DocumentTextElementEvent) => void;
  deselectAll: () => void;
  selectAll: () => void;
  selectElement: (element: DocumentElement) => void;
  getTextFontProperties: () => any;
  updateSizeAndPositionForPropertyElements: (documentElements: any[], componentElement: DocumentElement) => any;
  updateSizeAndPositionForColorElements(element: any, { width, height }): any;
  reorderElements: (elements) => any;
  setAnnotations: (annotatedElements: AnnotatedElement[]) => void;
  getFrameForElement: (element: DocumentElement) => Frame;
  getFrames: () => Map<string, Frame>;
  getFrameElements: () => Map<string, string>;
  getGroupById: (id: string) => Group;
  getGroupByMemberId: (id: string) => Group;
  getAllElementsInGroup: (id: string, includeFrameElements: boolean, includeMaskMembers: boolean) => DocumentElement[];
  editFrameName: (id) => void;
  addElementsOnFrame: (frameId: string, elements: DocumentElement[]) => void;
  removeElementsFromFrame: (elements: DocumentElement[]) => void;
  isOnlyFrameSelected: () => any;
  isOnlyFrameInElements: (elements: DocumentElement[]) => any;
  onlyFramesInElements: (elements: DocumentElement[]) => any;
  getViewBox: () => any;
  highlightElement: (id, color?) => void;
  dehighlightElement: (id) => void;
  elementsAdded$: any;
  getAllCommonBounds: () => CoordinateBox;
  isMask: (element: DocumentElement) => boolean;
  isMaskOrMaskMember: (id: string) => Mask;
  getMaskMembers: (id) => CanvasElement[];
  isEditingMask: (id) => boolean;
  getMaskByMemberId: (id) => Mask;
  mode: string;
}

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  annotationOptions: any;
  public documentRenderer: DocumentRenderer;

  private documentElementEventsSubject: BehaviorSubject<DocumentElementEvent> = new BehaviorSubject(null);
  public documentElementEvents: Observable<DocumentElementEvent> = this.documentElementEventsSubject.asObservable();

  // Text element events, streaming from the edited text element
  private documentTextElementEventsSubject: BehaviorSubject<any> = new BehaviorSubject({ element: [], textFormat: {} });
  public documentTextElementEvents: Observable<any> = this.documentTextElementEventsSubject.asObservable();

  private documentSVGElementEventsSubject: Subject<DocumentSVGElementEvent> = new Subject();
  public documentSVGElementEvents: Observable<DocumentSVGElementEvent> =
    this.documentSVGElementEventsSubject.asObservable();

  // Navigation events (zoom, pan)
  private documentNavigationEventsSubject: Subject<DocumentNavigationEvent> = new Subject();
  public documentNavigationEvents: Observable<DocumentNavigationEvent> =
    this.documentNavigationEventsSubject.asObservable();

  textElementActions: any;

  public documentTableService;
  constructor(
    private store: Store<RootStoreState.State>,
    private documentAnnotationService: DocumentAnnotationService,
    private documentItemService: DocumentItemService,
  ) {
    this.annotationOptions = this.documentAnnotationService.getAnnotationOptions();
  }

  setRenderer(renderer: DocumentRenderer) {
    this.documentRenderer = renderer;
  }

  handleDocumentUpdated(elements: Array<DocumentElement>): void {}
  handleElementSelection(elements: Array<DocumentElement>): void {}
  handleDocumentElementEvent(event: DocumentElementEvent): void {
    this.documentElementEventsSubject.next(event);
  }

  handleDocumentActions(actions: Array<any>) {}

  handleActionRequest(request: ActionRequest) {}

  getTextFontProperties() {}

  getNewFrameNumber() {
    return 0;
  }
  saveLastAppliedTextFormat(format: any) {}
  lastAppliedTextFormat = null;
  public lastAppliedStyle: Map<string, StyleDefinition> = new Map();
  setAnnotations(annotatedElements: AnnotatedElement[]) {
    this.documentRenderer?.setAnnotations(annotatedElements);
  }

  public updateSizeAndPositionForPropertyElements(documentElements: any[], componentElement: DocumentElement): any {
    return this.documentItemService?.updateSizeAndPositionForPropertyElements(documentElements, componentElement);
  }

  updateSizeAndPositionForColorElements(element: any, { width, height }): any {
    return null;
  }

  public handleDocumentTextElementEvent(event: any) {
    this.documentTextElementEventsSubject.next(event);
  }

  handleDocumentSvgElementEvent(event: DocumentSVGElementEvent): void {} //sends event from svg editor
  handleDocumentNavigationEvent(event: DocumentNavigationEvent): void {} //sends event from zoom-pan-handler
  public async updateContentElement(
    element: DocumentElement,
    svgHtmlString: string,
    contentId: string,
    fileName?: string,
  ): Promise<DocumentElement> {
    return;
  }

  // used for hotspot and other mouse over on svg elements
  isValidActionElement(ele, currElementId) {
    for (const element of ele) {
      if (element.id === currElementId) {
        return true;
      }
      if (element.elements) {
        if (this.isValidActionElement(element.elements, currElementId)) {
          return true;
        }
      }
    }
    return false;
  }

  async setComponentAnnotations(componentElements) {
    if (componentElements.length > 0) {
      const componentElemMap = {};
      console.log('componentElements', componentElements);
      componentElements.forEach((element) => {
        if (element.modelBindings.item) {
          const model = element.modelBindings.item.split(':');
          const itemId = model[1];
          componentElemMap[itemId] = element;
        }
      });

      await combineLatest([
        this.store.select(AssortmentsSelectors.selectSourceItems(Object.keys(componentElemMap))),
        this.store.select(AssortmentsSelectors.backingAssortmentItems),
      ])
        .pipe(
          tap(async ([sourceItems, backingAssortmentItems]) => {
            const annotationItemMap = {};
            this.annotationOptions.forEach((annotationOption) => {
              const affectedAssortmentItems: any[] = backingAssortmentItems.filter(
                (item) => item[annotationOption.property] === true,
              );
              affectedAssortmentItems.forEach((assortmentItem) => {
                if (!annotationItemMap[assortmentItem.itemId]) {
                  annotationItemMap[assortmentItem.itemId] = [];
                }
                annotationItemMap[assortmentItem.itemId].push(annotationOption.type);
              });
            });

            const existingItemIds = sourceItems?.map((item) => item.id);
            componentElements.forEach((componentElement) => {
              const itemId = new EntityReference(componentElement.modelBindings.item)?.id;
              let annotations = [];
              if (annotationItemMap[itemId]) {
                annotations = annotations.concat(
                  annotationItemMap[itemId].map((annotationType) => {
                    const annotationOption = this.annotationOptions.find(
                      (annotation) => annotation.type === annotationType,
                    );
                    return {
                      category: annotationOption.category,
                      type: annotationType,
                      detail: { itemId },
                    };
                  }),
                );
              }
              if (existingItemIds?.length > 0 && !existingItemIds?.includes(itemId)) {
                annotations.push({
                  type: 'WARNING',
                  category: 'status',
                  detail: {},
                });
              }
              componentElement.annotations = annotations;
            });
          }),
        )
        .subscribe();
    }
  }
}
