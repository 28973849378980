<div [attr.id]="itemData?.id" class="header">
  <div class="title">{{ itemData?.properties.itemName }}</div>
  <div class="sub-title">{{ focusedItemColor?.item?.optionName }}</div>
</div>

<div class="body flex gap-x-2 mt-3" *ngIf="itemData && visible">
  <div class="left flex-grow">
    <div>
      <div class="section-title">Summary</div>
      <div class="properties narrow-scroll">
        <app-item-properties-panel [itemData]="itemData" [viewDefinition]="viewDefinition"></app-item-properties-panel>
      </div>
    </div>

    <div>
      <div class="section-title">Options</div>
      <div class="color-list narrow-scroll">
        <app-item-colors-list
          [itemsMissingFromSource]="itemsMissingFromSource"
          [includeCommentWidget]="includeCommentWidget"
          [dataSelectionToggleComponent]="dataSelectionToggleComponent"
          [itemPinnedCommentsComponent]="itemPinnedCommentsComponent"
          [itemColors]="itemData?.options"
          [itemPresentationElement]="itemPresentationElement"
          (itemColorFocusEvent)="handleItemColorFocusEvent($event)"
          [frameId]="frameId"
          [editorMode]="editorMode"
          (menuEvent)="handleMenuEvent($event)"
        ></app-item-colors-list>
      </div>
    </div>
  </div>

  <div class="right">
    <app-item-image-viewer
      *ngIf="focusedItemColor"
      [itemData]="itemData"
      [itemColor]="focusedItemColor"
    ></app-item-image-viewer>
  </div>
</div>
