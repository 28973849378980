<div (visible)="setVisible($event)" class="presentation-panels">
  <app-presentation-collection-panel
    [panel]="panel"
    *ngIf="panel?.frame.type === 'collection'"
    [visible]="isVisible"
  ></app-presentation-collection-panel>
  <app-presentation-grid-panel
    [panel]="panel"
    *ngIf="panel?.frame.type === 'grid'"
    [visible]="isVisible"
  ></app-presentation-grid-panel>
  <app-presentation-svg-panel
    [panel]="panel"
    *ngIf="panel?.frame.type === 'document'"
    [visible]="isVisible"
  ></app-presentation-svg-panel>
  <app-presentation-iframe-panel
    [panel]="panel"
    *ngIf="panel?.frame.type === 'iframe'"
    [visible]="isVisible"
  ></app-presentation-iframe-panel>
</div>
