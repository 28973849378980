import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { SizeDefinition, Document } from '@contrail/documents';
import { DocumentRenderer, DocumentService } from '../../document/document.service';
import { ShowcasePresentationPanel } from '../../presentation';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { PresentationSelectors } from '../../presentation-store';
import { AuthService } from 'src/app/common/auth/auth.service';
import { DocumentAnnotationService } from '../../document/document-annotation/document-annotation-service';
import { Actions, ofType } from '@ngrx/effects';
import { AssortmentsActions } from '@common/assortments/assortments-store';
// import { CanvasDocument } from '../../canvas/canvas-document';
import { CanvasDocument, CANVAS_MODE } from '@contrail/canvas';
import { environment } from 'src/environments/environment';
import { Observable, debounceTime, Subject, takeUntil } from 'rxjs';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
import { PresentationPropertyPoliciesService } from '../../presentation-property-policies/presentation-property-policies.service';
import { CustomFontsSelectors } from '@common/custom-fonts/custom-fonts-store';

@Component({
  selector: 'app-presentation-svg-panel',
  templateUrl: './presentation-svg-panel.component.html',
  styleUrls: ['./presentation-svg-panel.component.scss'],
})
export class PresentationSvgPanelComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {
  @Input() panel: ShowcasePresentationPanel;
  @Input() visible = true;

  canvasDocument: CanvasDocument;
  public document: Document;
  private currentSize: SizeDefinition = { height: 900, width: 1600 };
  public svgId = 'SVG' + Math.floor(Math.random() * 10000);
  public editorMode$: Observable<any>;
  private initialized = false;
  private renderer: DocumentRenderer;
  private customFonts: string;
  private destroy$ = new Subject();
  constructor(
    private store: Store<RootStoreState.State>,
    private presentationDocumentService: DocumentService,
    private authService: AuthService,
    private documentAnnotationService: DocumentAnnotationService,
    private presentationPropertyPoliciesService: PresentationPropertyPoliciesService,
    actions$: Actions,
  ) {
    // Listens to the completion of the loading of backingAssortment
    actions$.pipe(takeUntil(this.destroy$), ofType(AssortmentsActions.loadBackingAssortmentSuccess)).subscribe(() => {
      this.setAnnotations();
    });
    this.store
      .select(CustomFontsSelectors.customFonts)
      .pipe(takeUntil(this.destroy$))
      .subscribe((customFonts) => {
        this.customFonts = customFonts;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.checkLoadSVG();
    }, 0);
  }
  ngOnInit(): void {
    this.editorMode$ = this.store.select(EditorModeSelectors.editorMode);
    if (this.panel?.frame?.id) {
      this.svgId = `FRAME${this.panel?.frame?.id}`; // ENSURE NOT START WITH NUMBER
    }
  }

  ngAfterViewInit(): void {
    this.initialized = true;
    console.log('PresentationSvgFrameComponent: ngAfterViewInit ');
    setTimeout(async () => {
      await this.checkLoadSVG();
    }, 0);
    this.store.select(PresentationSelectors.presentationSize).subscribe((size) => {
      // console.log('svg panel: size change: ', size);
      this.currentSize = size;
      this.setDocumentSize();
    });
  }

  async checkLoadSVG() {
    if (!this.initialized) {
      return; // checks that DOM exists.
    }
    if (!this.panel?.frame?.document) {
      console.log('SVGPanel: No document.');
      return;
    }
    if (!this.visible) {
      console.log('SVGPanel: Not visible.');
      return;
    }
    await this.loadSVG();
  }

  setDocumentSize() {
    if (this.canvasDocument) {
      this.canvasDocument.syncState(
        this.document.size,
        { width: this.document.size.width, height: this.document.size.height, x: 0, y: 0 },
        this.currentSize,
        { width: this.document.size.width, height: this.document.size.height, x: 0, y: 0 },
      );
    }
  }

  private async loadSVG() {
    console.log('loadSVG: ', this.panel.frame.name);
    this.document = ObjectUtil.cloneDeep(this.panel.frame.document);

    this.canvasDocument = new CanvasDocument(
      this.svgId,
      this.document,
      this.presentationDocumentService,
      CANVAS_MODE.PRESENT,
      this.authService,
      { imageHost: environment.imageHost },
      this.documentAnnotationService.getAnnotationOptions(),
      this.customFonts,
      this.presentationPropertyPoliciesService.restrictedViewablePropertySlugs,
    );
    this.renderer = this.canvasDocument as any;
    this.canvasDocument.syncState(
      this.document.size,
      { width: this.document.size.width, height: this.document.size.height, x: 0, y: 0 },
      this.currentSize,
      { width: this.document.size.width, height: this.document.size.height, x: 0, y: 0 },
    );
    this.canvasDocument.elementsAdded$.subscribe((elementsAdded) => {
      if (elementsAdded) {
        this.setAnnotations();
      }
    });

    this.documentAnnotationService.annotatedElements$.pipe(debounceTime(500)).subscribe((annotatedElements) => {
      if (annotatedElements?.length > 0) {
        this.renderer.setAnnotations(annotatedElements);
      }
    });
  }

  private async setAnnotations() {
    const componentElements = ObjectUtil.cloneDeep(
      this.panel.frame.document.elements.filter((element) => element && element.type === 'component'),
    );
    await this.presentationDocumentService.setComponentAnnotations(componentElements);
    this.canvasDocument?.setAnnotations(componentElements);
  }
}
