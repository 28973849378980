import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  Type,
  ViewChild,
} from '@angular/core';
import { CommentOwnerInfo } from 'src/app/common/comments/comments.service';
import { ItemData } from 'src/app/common/item-data/item-data';
import { DataSelectionToggleDirective } from '../data-selection-toggle/data-selection-toggle.directive';
import { DataSelectionToggleComponent } from '../data-selection-toggle/data-selection-toggle.component';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { ItemPinnedCommentsDirective } from '../item-pinned-comments/item-pinned-comments.directive';
import { ItemPinnedCommentsComponent } from '../item-pinned-comments/item-pinned-comments.component';

@Component({
  selector: 'app-item-color',
  templateUrl: './item-color.component.html',
  styleUrls: ['./item-color.component.scss'],
})
export class ItemColorComponent implements OnInit, OnChanges {
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  @ViewChild(DataSelectionToggleDirective, { static: true }) dataSelectionToggle!: DataSelectionToggleDirective;
  @ViewChild(ItemPinnedCommentsDirective, { static: true }) itemPinnedComments!: ItemPinnedCommentsDirective;
  @Input() itemColor: ItemData;
  @Input() image: any;
  @Input() dataSelectionToggleComponent: Type<any>;
  @Input() itemPinnedCommentsComponent: Type<any>;
  @Input() metaViewDefinition: any;
  @Output() itemColorFocusEvent = new EventEmitter();
  @Input() includeCommentWidget = false;
  @Input() itemMissingFromSource = false;
  @Input() frameId: string;
  @Input() editorMode = 'EDIT';
  @Output() menuEvent = new EventEmitter();
  contextMenuPosition = { x: '0px', y: '0px' };

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }

  ngOnInit(): void {
    this.loadComponent();
  }

  loadComponent() {
    if (this.dataSelectionToggleComponent && this.editorMode === 'EDIT') {
      const viewContainerRef = this.dataSelectionToggle.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent<DataSelectionToggleComponent>(
        this.dataSelectionToggleComponent,
      );
      componentRef.instance.itemData = this.itemColor;
    }
    if (this.itemPinnedCommentsComponent) {
      const viewContainerRef = this.itemPinnedComments.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent<ItemPinnedCommentsComponent>(
        this.itemPinnedCommentsComponent,
      );
      componentRef.instance.item = this.itemColor;
      componentRef.instance.frameId = this.frameId;
      componentRef.instance.commentsAccessLevel = this.editorMode;
    }
  }

  focusItemColor($event) {
    $event.stopPropagation();
    this.itemColorFocusEvent.emit(this.itemColor);
  }

  getCommentOwnerInfo(): CommentOwnerInfo {
    return {
      id: this.itemColor.id,
      name: this.itemColor?.getName(),
      entityType: 'item',
      modalTitle: this.itemColor?.getName(),
    };
  }

  onContextMenu(event: MouseEvent, itemId) {
    if (this.itemPinnedCommentsComponent) {
      event.preventDefault();
      this.contextMenuPosition.x = event.clientX + 'px';
      this.contextMenuPosition.y = event.clientY + 'px';
      this.contextMenu.menuData = { item: itemId };
      this.contextMenu.menu.focusFirstItem('mouse');
      this.contextMenu.openMenu();
    }
  }

  addItemComment(itemId, event) {
    this.contextMenu.closeMenu();
    this.menuEvent.emit({ menuType: 'comments', itemId, sourceEvent: event });
  }

  viewItem(itemId, event) {
    this.contextMenu.closeMenu();
    this.menuEvent.emit({ menuType: 'viewItem', itemId, sourceEvent: event });
  }
}
