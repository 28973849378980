import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Store } from '@ngrx/store';
import { ObjectUtil } from '@contrail/util';
import { PresentationCollectionElement } from 'src/app/presentation/presentation';
import { RootStoreState } from 'src/app/root-store';
import { CommentOwnerInfo } from 'src/app/common/comments/comments.service';
import { AddPinnedCommentsService } from 'src/app/presentation/presentation-pinned-comments/add-pinned-comments.service';
import { ItemModalLauncher } from '@common/items/item-modal-launcher';
import { AssortmentsSelectors } from '@common/assortments/assortments-store';
import { take, tap } from 'rxjs/operators';
import { Observable, combineLatest, firstValueFrom } from 'rxjs';
import { ItemData } from '@common/item-data/item-data';

const ITEMS_PER_GROUP = 10;

@Component({
  selector: 'app-presentation-grid-section',
  templateUrl: './presentation-grid-section.component.html',
  styleUrls: ['./presentation-grid-section.component.scss'],
})
export class PresentationGridSectionComponent implements OnInit, OnChanges {
  optionPreviewClass = 'option';
  @Input() section: PresentationCollectionElement;
  @Input() backingAssortmentItemData: Array<any>;
  @Input() itemIndex: number;
  @Input() viewDefinition: any;
  @Input() panelName: string;
  @Input() countTotal: number;
  @Input() sectionIndex: number;
  @Input() itemMap: any;
  @Input() itemsMissingFromSource: any[] = [];
  @Input() frameId: string;
  @Input() editorMode = 'EDIT';
  @Output() updateCollectionItem = new EventEmitter();
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  public focusedItemId;
  public focusedItem: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  sectionGroups: any[];
  hoveredOver = false;
  properties: any[] = ['optionName'];
  @Output() openMenu = new EventEmitter();

  constructor(
    private addPinnedCommentsService: AddPinnedCommentsService,
    private modalLauncher: ItemModalLauncher,
    private store: Store<RootStoreState.State>,
  ) {}

  ngOnInit(): void {
    this.generateSectionGroups();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.viewDefinition) {
      this.properties = this.viewDefinition.properties;
    }
    if (changes.itemMap) {
      this.generateSectionGroups();
    }
  }

  generateSectionGroups() {
    this.sectionGroups = ObjectUtil.cloneDeep(this.section.children).reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / ITEMS_PER_GROUP);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }
      resultArray[chunkIndex].push(item.value);
      return resultArray;
    }, []);
  }

  getThumbnail(itemId) {
    if (this.itemMap[itemId]) {
      return this.itemMap[itemId].thumbnail;
    } else {
      return null;
    }
  }

  getPropertyValue(itemId, property) {
    if (this.itemMap[itemId]) {
      return this.itemMap[itemId][property.slug];
    } else {
      return null;
    }
  }

  getCommentOwnerInfo(id): CommentOwnerInfo {
    const item = this.backingAssortmentItemData.find((itemData) => itemData.id === id);
    if (item) {
      return {
        id: item.id,
        name: item?.getName(),
        entityType: 'item',
        modalTitle: item?.getName(),
      };
    }
    return null;
  }

  mouseEnter(id) {
    this.focusedItemId = id;
    this.focusedItem = this.backingAssortmentItemData.find((itemData) => itemData.id === id);

    // let item = await firstValueFrom(this.store.select(AssortmentsSelectors.selectSourceItem(itemId)));
    // if (!item) {
    //   const backingItem = await firstValueFrom(this.store.select(AssortmentsSelectors.selectItemFromBackingAssortment(itemId)));
    //   item = new ItemData(backingItem);
    // }

    // const item = this.itemMap[itemId]
  }

  mouseLeave() {
    this.focusedItemId = null;
    this.focusedItem = null;
  }

  onContextMenu(event: MouseEvent, itemId) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { item: itemId };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  addItemComment(itemId) {
    this.contextMenu.closeMenu();
    this.addPinnedCommentsService.addItemComment(itemId, this.frameId, this.editorMode);
  }

  openInfo(itemId) {
    combineLatest([
      this.store.select(AssortmentsSelectors.selectSourceItem(itemId)),
      this.store.select(AssortmentsSelectors.selectItemFromBackingAssortment(itemId)),
    ])
      .pipe(
        take(1),
        tap(([sourceItem, backingItem]) => {
          if (sourceItem) {
            this.modalLauncher.openModal(sourceItem, this.editorMode);
          } else if (backingItem) {
            this.modalLauncher.openModal(new ItemData(backingItem), this.editorMode);
          }
        }),
      )
      .subscribe();
  }
}
