import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ShowcaseService } from 'src/app/showcase/showcase.service';

import { SessionService } from 'src/app/session/session.service';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { SessionAssortmentsSelectors } from 'src/app/session/session-assortments-store';

@Component({
  selector: 'app-add-item-to-list-modal',
  templateUrl: './add-item-to-list-modal.component.html',
  styleUrls: ['./add-item-to-list-modal.component.scss'],
})
export class AddItemToListModalComponent {
  isLoading = false;
  @Input() selectedItems = [];
  @Output() closeModal = new EventEmitter();

  public assortments;
  public selectedAssortment;
  assortmentSelectionControl;
  defaultSelected: any;

  constructor(
    private sessionService: SessionService,
    private showcaseService: ShowcaseService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {
    this.store.select(SessionAssortmentsSelectors.selectSessionAssortments).subscribe((assortments) => {
      this.assortments = assortments;
    });

    this.defaultSelected = this.assortments[0];
    this.assortmentSelectionControl = new UntypedFormControl(this.defaultSelected);
  }

  cancel() {
    this.closeModal.emit();
  }
  selectAssortment(assortment) {
    this.selectedAssortment = assortment;
    console.log('Selected assortment: ', assortment);
  }

  async addToList(assortment) {
    this.isLoading = true;
    console.log(` this.selectedItems ${this.selectedItems} \n\nthis.selectedId ${assortment.assortmentId}`);
    await this.sessionService.addItems(this.selectedItems, assortment.assortmentId);

    this.isLoading = false;
    this.openSnackBar(`Successfully added selected items to ${assortment.name}`, '');
    this.closeModal.emit();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
