import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './common/auth/auth.guard';
import { LoginComponent } from './common/auth/login/login.component';
import { MainComponent } from './main/main.component';
import { UserOrgResolver } from './common/auth/user-org.resolver';
import { HomeComponent } from './home/home.component';
//import { VirtualShowcaseComponent } from './virtual-showcase/virtual-showcase.component';
import { ExplorerComponent } from './explorer/explorer.component';
import { SessionComponent } from './session/session.component';
import { NoOrgsRegisteredComponent } from './common/auth/no-orgs-registered/no-orgs-registered.component';
import { ShowcaseNotFoundComponent } from './showcase/showcase-not-found/showcase-not-found.component';
import { ShareTokenResolver } from './common/auth/shared-link.resolver';
import { PresentationComponent } from './presentation/presentation.component';
import { ShowcaseEditorModeResolver } from './showcase/showcase-editor-mode-resolver';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'showcase-not-found', component: ShowcaseNotFoundComponent },
  {
    path: 'org/:org',
    canActivate: [AuthGuard],
    resolve: { user: UserOrgResolver },
    children: [
      {
        path: 'sc/:id',
        component: MainComponent,
        resolve: { editorMode: ShowcaseEditorModeResolver },
        children: [
          { path: '', redirectTo: 'presentation', pathMatch: 'full' },
          { path: 'explorer', component: ExplorerComponent },
          { path: 'session', component: SessionComponent },
          { path: 'presentation', component: PresentationComponent },
          // { path: 'content', component: ContentComponent },
        ],
      },
    ],
  },

  { path: 'no-org-found', component: NoOrgsRegisteredComponent },
  { path: 'login', component: LoginComponent },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
