import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { AddItemToListModalComponent } from '../add-item-to-list-modal/add-item-to-list-modal.component';

@Component({
  selector: 'app-add-item-to-list-modal-launcher',
  templateUrl: './add-item-to-list-modal-launcher.component.html',
  styleUrls: ['./add-item-to-list-modal-launcher.component.scss'],
})
export class AddItemToListModalLauncherComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddItemToListModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {}

  closeModal() {
    this.dialogRef.close();
  }
}
