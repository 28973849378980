import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentsCountComponent } from './comments-count/comments.count.component';
import { MatIcon, MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { CommentsCardComponent } from './comments-card/comments-card.component';
import { CommentsModalComponent } from './comments-modal/comments-modal.component';
import { ListCommentsComponent } from './list-comments/list-comments.component';
import { ComponentsModule } from '../components/components.module';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { HeaderCommentsComponent } from './header-comments/header-comments.component';
import { SideNavCommentsComponent } from './side-nav-comments/side-nav-comments.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CommentsService } from './comments.service';
import { CommentFormComponent } from './comment-form/comment-form.component';
import { DomSanitizer } from '@angular/platform-browser';
import { CommentsMessageHandler } from './comments-message-handler';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { ContextCommentsListComponent } from './context-comments-list/context-comments-list.component';
import { CommentOverlay } from './comment-overlay/comment-overlay.component';
import { CommentUserHeader } from './comment-user-header/comment-user-header.component';
import { CommentFormSuggestionComponent } from './comment-form/comment-form-suggestion/comment-form-suggestion.component';
import { TypesModule } from '../types/types.module';
import { MatBadgeModule } from '@angular/material/badge';
import { CommentSuggestedChangesDisplayComponent } from './comments-card/comment-suggested-changes-display/comment-suggested-changes-display.component';
import { CommentColorChip } from './comments-card/comment-color-chip/comment-color-chip.component';
import { CommentsFilterPanelComponent } from './comments-filter/comments-filter-panel.component';
import { FilterModule } from '../components/filter/filter.module';
import { SortModule } from '../components/sort/sort.module';
import { DateFilterModule } from '@common/components/date-filter/date-filter.module';
import { PinnedCommentsToggleComponent } from './pinned-comments/pinned-comments-toggle/pinned-comments-toggle.component';

@NgModule({
  declarations: [
    CommentsCountComponent,
    CommentsCardComponent,
    CommentsModalComponent,
    ListCommentsComponent,
    CommentFormComponent,
    HeaderCommentsComponent,
    SideNavCommentsComponent,
    ContextCommentsListComponent,
    CommentOverlay,
    CommentUserHeader,
    CommentFormSuggestionComponent,
    CommentSuggestedChangesDisplayComponent,
    CommentColorChip,
    CommentsFilterPanelComponent,
    PinnedCommentsToggleComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatBadgeModule,
    MatInputModule,
    MatButtonModule,
    ComponentsModule,
    MatSidenavModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatChipsModule,
    VirtualScrollerModule,
    TypesModule,
    FilterModule,
    SortModule,
    DateFilterModule,
  ],
  providers: [CommentsService],
  exports: [
    CommentsCountComponent,
    CommentsCardComponent,
    CommentsModalComponent,
    ListCommentsComponent,
    HeaderCommentsComponent,
    SideNavCommentsComponent,
    ContextCommentsListComponent,
    CommentOverlay,
  ],
})
export class CommentsModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private commentsMessageHandler: CommentsMessageHandler,
  ) {
    this.registerIcons();
  }
  async registerIcons() {
    this.registerIcon('contrail_comment', '../assets/images/comment_icon.svg');
  }
  registerIcon(name, path) {
    this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
  }
}
