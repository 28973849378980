import * as ShowcaseActions from './showcase.actions';
import * as ShowcaseSelectors from './showcase.selectors';
import * as ShowcaseStoreState from './showcase.state';

import * as CollectionStatusMessagesActions from './collection-status-messages/collection-status-messages.actions';
import * as CollectionStatusMessagesSelectors from './collection-status-messages/collection-status-messages.selectors';

export { ShowcaseStoreModule } from './showcase-store.module';

const actions = {
  ...ShowcaseActions,
  ...CollectionStatusMessagesActions,
};

const selectors = {
  ...ShowcaseSelectors,
  ...CollectionStatusMessagesSelectors,
};
export { actions as ShowcaseActions, selectors as ShowcaseSelectors, ShowcaseStoreState };
