<div class="header-left">
  <app-page-header></app-page-header>
  <mat-divider vertical class="divider-line" *ngIf="['EDIT'].includes(editorMode$ | async)"></mat-divider>
  <app-header-nav *ngIf="['EDIT'].includes(editorMode$ | async)"></app-header-nav>
</div>
<div class="right-menu relative">
  <app-header-comments></app-header-comments>
  <mat-divider vertical class="divider-line"></mat-divider>
  <app-user-sessions-avatars></app-user-sessions-avatars>
  <app-download-content-indicator class="mr-3"></app-download-content-indicator>
  <app-help-selector *ngIf="false" class="mr-1"></app-help-selector>
  <app-header-user-menu></app-header-user-menu>
</div>
