<div class="page-header">
  <img
    *ngIf="(pageHeaderConfig | async).logo"
    routerLink="presentation"
    class="logo"
    [src]="(pageHeaderConfig | async).logo"
  />
  <mat-divider vertical *ngIf="(pageHeaderConfig | async).logo" class="divider-line"></mat-divider>
  <div id="title">{{ (pageHeaderConfig | async).title | uppercase }}</div>
  <mat-divider [vertical]="true" *ngIf="(pageHeaderConfig | async).logo" class="separator"></mat-divider>
</div>
