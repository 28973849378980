import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageHeaderService } from './header/page-header/page-header.service';
import { ShowcaseService } from '../showcase/showcase.service';
import { SessionService } from '../session/session.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Store } from '@ngrx/store';
import { RootStoreState } from '../root-store';
import { map, startWith } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { ShowcaseActions, ShowcaseSelectors } from '../showcase/showcase-store';
import { AssortmentsSelectors } from '../common/assortments/assortments-store';
import { CustomFontsSelectors } from '@common/custom-fonts/custom-fonts-store';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [
    trigger('screenModeChangeHeader', [
      state(
        'fullscreen',
        style({
          display: 'none',
        }),
      ),
      state(
        'normal',
        style({
          display: 'flex',
        }),
      ),
      transition('fullscreen <=> normal', [animate('0.3s')]),
    ]),
    // trigger('screenModeChangeSidebar', [
    //   state('fullscreen', style({
    //     'margin-left': '-74px',
    //   })),
    //   state('normal', style({
    //     'margin-left': '10px',
    //   })),
    //   transition('fullscreen <=> normal', [animate('0.3s')]),
    // ]),
  ],
})
export class MainComponent implements OnInit {
  public screenMode = 'normal';
  public loaded$: Observable<boolean> = new BehaviorSubject(false).asObservable();

  constructor(
    private route: ActivatedRoute,
    private pageHeaderService: PageHeaderService,
    private showcaseService: ShowcaseService,
    private sessionService: SessionService,
    private store: Store<RootStoreState.State>,
  ) {
    this.sessionService.fullscreenMode.subscribe((fullScreenMode) => {
      this.screenMode = fullScreenMode ? 'fullscreen' : 'normal';
      console.log(`Main - fullscreenMode ${this.screenMode}`);
    });
    this.loaded$ = combineLatest([
      this.store.select(AssortmentsSelectors.sourceAssortmentLoaded),
      this.store.select(CustomFontsSelectors.customFontsLoaded),
    ]).pipe(
      map(([assortmentLoaded, customFontsLoaded]) => {
        return !!assortmentLoaded && customFontsLoaded;
      }),
    );
  }

  ngOnInit() {
    // ------------ we load showcase in resolver ------------------
    // this.route.params.subscribe(params => {
    //   const id = params.id;
    //   this.store.dispatch(ShowcaseActions.loadCurrentShowcase({ id }));
    // });

    this.store.select(ShowcaseSelectors.currentShowcase).subscribe((showcase) => this.handleShowcaseLoad(showcase));
  }

  private handleShowcaseLoad(showcase) {
    console.log('this.handleShowcaseLoad: ', showcase);
    if (showcase) {
      this.pageHeaderService.setPageConfig({ title: showcase.name, logo: showcase.headerLogo });
    }
  }
}
