import { Component, EventEmitter, Input, OnChanges, OnInit, Output, Type } from '@angular/core';
import { CommentOwnerInfo } from 'src/app/common/comments/comments.service';
import { ViewManagerService } from 'src/app/common/views/view-manager.service';
import { ItemData } from 'src/app/common/item-data/item-data';
import { ViewDefinition } from '@contrail/client-views';
import { Item } from '../../assortments/item';

@Component({
  selector: 'app-item-colors-list',
  templateUrl: './item-colors-list.component.html',
  styleUrls: ['./item-colors-list.component.scss'],
})
export class ItemColorsListComponent implements OnChanges, OnInit {
  @Input() includeCommentWidget = false;
  @Input() dataSelectionToggleComponent: Type<any>;
  @Input() itemPinnedCommentsComponent: Type<any>;
  @Input() itemColors: Array<Item>;
  @Input() itemPresentationElement: any;
  @Input() itemsMissingFromSource: any[];
  @Input() frameId: string;
  @Input() editorMode = 'EDIT';
  @Output() menuEvent = new EventEmitter();
  images: any = {};
  @Output() itemColorFocusEvent = new EventEmitter();
  itemColorsFromConfig = [];
  metaViewDefinition: ViewDefinition;
  // used to show the mat spinner
  isLoading = false;

  constructor(private viewService: ViewManagerService) {}

  async ngOnChanges() {
    this.applyInit();
  }
  collectImages() {
    if (this.itemColors?.length) {
      this.itemColors.map((itemColor) => {
        this.images[itemColor.id] = this.determineImage(itemColor);
      });
    }
  }

  determineImage(itemColor) {
    const viewable = itemColor.properties.primaryViewable;
    let image;
    image = itemColor.thumbnail || viewable?.primaryFile?.downloadUrl;
    return image;
  }

  getImage(itemColor) {
    return this.images[itemColor.id];
  }

  focusItemColor(itemColor) {
    this.itemColorFocusEvent.emit(itemColor);
  }

  ngOnInit(): void {
    this.applyInit();
  }

  async applyInit() {
    this.collectImages();
    this.applyPresentationConfig();

    const firstItem = this.itemColors.slice(0, 1);
    let typeId;
    if (firstItem?.length) {
      typeId = firstItem[0]?.typeId;
    }
    this.metaViewDefinition = (await this.viewService.getView('showcase:item_card')) || { properties: [] };
    // this.metaViewDefinition = await this.viewService.getView('showcase:item_card', typeId) || { properties: []};
    this.isLoading = false;
  }

  getCommentOwnerInfo(itemColor: ItemData): CommentOwnerInfo {
    return {
      id: itemColor.id,
      name: itemColor?.getName(),
      entityType: 'item',
      modalTitle: itemColor?.getName(),
    };
  }
  // get item colors from the presentation config for the current item  - Ravi
  applyPresentationConfig() {
    if (!this.itemPresentationElement?.children?.length) {
      this.itemColorsFromConfig = this.itemColors;
      return;
    }
    this.itemColorsFromConfig = [];
    if (!this.itemColors?.length) {
      return;
    }

    const itemId = this.itemColors?.[0].itemId;
    this.itemPresentationElement?.children?.map((element) => {
      if (element.enabled) {
        // check if the item color is enabled
        this.itemColors.map((itemOption) => {
          if (itemOption.id === element.value) {
            // select the corresponding itemcolor maintaining the config order
            this.itemColorsFromConfig.push(itemOption);
          }
        });
      }
    });
  }
  handleInProgressOverlay(event) {
    console.log(`handleInProgressOverlay ${event}`);
    this.isLoading = event;
  }

  handleMenuEvent(event) {
    this.menuEvent.emit(event);
  }
}
