import { NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemCardComponent } from './item-card/item-card.component';
import { ItemDetailsComponent } from './item-details/item-details.component';
import { ItemModalComponent } from './item-modal/item-modal.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { ItemsListComponent } from './items-list/items-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ItemListItemComponent } from './item-list-item/item-list-item.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import {
  MatLegacyDialogModule as MatDialogModule,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { ComponentsModule } from '@common/components/components.module';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ExporterModule } from '@common/components/service/exporter/exporter.module';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { AddItemToListModalComponent } from './add-item-to-list/add-item-to-list-modal/add-item-to-list-modal.component';
import { AddItemToListComponent } from './add-item-to-list/add-item-to-list.component';
import { AddItemToListModalLauncherComponent } from './add-item-to-list/add-item-to-list-modal-launcher/add-item-to-list-modal-launcher.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { ItemDataSelectionToggleComponent } from './item-data-selection-toggle/item-data-selection-toggle.component';
import { TypesModule } from '@common/types/types.module';
import { CommentsModule } from '@common/comments/comments.module';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { ViewsModule } from '@common/views/views.module';
import { FilterModule } from '@common/components/filter/filter.module';
import { SortModule } from '@common/components/sort/sort.module';
import { ItemPresentationModule } from '@common/item-presentation/item-presentation.module';
import { PipesModule } from '@common/pipes/pipes.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { ItemPinnedComments } from './item-pinned-comments/item-pinned-comments.component';
import { ContentModule } from '@common/content/content.module';

@NgModule({
  declarations: [
    ItemCardComponent,
    ItemDetailsComponent,
    ItemModalComponent,
    AddItemToListComponent,
    ItemsListComponent,
    ItemListItemComponent,
    AddItemToListModalComponent,
    AddItemToListModalLauncherComponent,
    ItemDataSelectionToggleComponent,
    ItemPinnedComments,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatDialogModule,
    FormsModule,
    ComponentsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatSnackBarModule,
    ExporterModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatTabsModule,
    TypesModule,
    CommentsModule,
    MatPaginatorModule,
    FilterModule,
    ViewsModule,
    SortModule,
    ItemPresentationModule,
    PipesModule,
    ContentModule,
    MatTooltipModule,
    MatBadgeModule,
  ],
  providers: [{ provide: MAT_DIALOG_DATA, useValue: {} }],
  exports: [
    ItemCardComponent,
    ItemsListComponent,
    AddItemToListComponent,
    ItemDetailsComponent,
    ItemDataSelectionToggleComponent,
    ItemPinnedComments,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ItemsModule {}
