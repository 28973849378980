import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { showcaseReducers } from './showcase.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ShowcaseEffects } from './showcase-effects';
import { CollectionStatusMessagesEffects } from './collection-status-messages/collection-status-messages.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('showcase', showcaseReducers),
    EffectsModule.forFeature([ShowcaseEffects, CollectionStatusMessagesEffects]),
  ],
})
export class ShowcaseStoreModule {}
