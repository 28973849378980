import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { GridsterConfig, GridsterItem, GridType } from 'angular-gridster2';
import { GridBoardService } from './grid-board.service';
import { Observable } from 'rxjs';
import { AuthService } from '@common/auth/auth.service';
import { ViewManagerService } from '@common/views/view-manager.service';

@Component({
  selector: 'app-grid-board',
  templateUrl: './grid-board.component.html',
  styleUrls: ['./grid-board.component.scss'],
})
export class GridBoardComponent implements OnInit, OnChanges {
  @Input() items;
  @Input() addedItems: Observable<any>;
  @Output() removeItemEvent = new EventEmitter();
  options: GridsterConfig;
  dashboard: Array<GridsterItem>;
  gridsterItem: GridsterItem;
  component = this;

  constructor(
    private gridBoardService: GridBoardService,
    private authService: AuthService,
    private viewService: ViewManagerService,
  ) {}

  public dashboardChange(dashboardItem) {
    console.log('dashboardChange: ', dashboardItem);
    const item = dashboardItem.item;
    if (item) {
      this.gridBoardService.setItemCoordinates(item.id, { x: dashboardItem.x, y: dashboardItem.y });
    }
  }

  async ngOnInit() {
    const metaViews = await this.viewService.getView('showcase:item_card');
    this.options = {
      itemChangeCallback: this.dashboardChange.bind(this),
      gridType: GridType.Fixed,
      fixedRowHeight: 240 + 8 * (metaViews?.properties?.length || 0),
      fixedColWidth: 50,
      maxCols: 21,
      maxRows: 40,
      swap: true,
      draggable: {
        enabled: true,
      },
    };
    this.dashboard = [];

    this.initGrid();
    if (this.addedItems) {
      this.addedItems.subscribe((item) => {
        if (item) {
          this.addItemToGrid(item);
        }
      });
    }
  }

  ngOnChanges() {
    this.initGrid();
  }

  initGrid() {
    this.dashboard = [];
    if (this.items?.length > 0) {
      for (const item of this.items) {
        this.addItemToGrid(item);
      }
    }
  }

  addItemToGrid(item) {
    const location = this.gridBoardService.getItemCoordinates(item.id);
    let x = 0;
    let y = 0;
    if (location) {
      x = location.x;
      y = location.y;
    }
    this.dashboard.push({ x, y, rows: 1, cols: 3, dragEnabled: true, item, type: 'item' });
    this.gridBoardService.setDashboard(this.dashboard);
  }

  removeItem(item) {
    this.dashboard = this.dashboard.filter((dbItem) => {
      return !dbItem.item || dbItem.item.id !== item.id;
    });
    this.removeItemEvent.emit(item);
  }
}
