import { formatDate } from '@angular/common';
import { FilterConditionType, FilterCriteria, FilterDefinition, FilterPropertyCriteria } from '@contrail/filters';
import { PropertyType } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';

export const STRING_PROP_TYPES = [PropertyType.String, PropertyType.Text, PropertyType.Url, PropertyType.Formula];

export class FilterObjects {
  public static filter(data: Array<any>, filterDefinition: FilterDefinition) {
    if (!filterDefinition?.filterCriteria) {
      return data;
    }
    const criteria: FilterCriteria = filterDefinition.filterCriteria;

    const results: Array<any> = data.filter((obj) => {
      // Loop over critera in the filter definition and ensure that the
      // object matches the filter.
      return this.testAndCriteria(obj, criteria);
      // And --> Must match all

      // Or --> Must match at least one.
    });
    return results;
  }
  /** Returns true if the obejct passes ALL of the supplied property criteria. */
  public static testAndCriteria(object: any, criteria: FilterCriteria): boolean {
    let test = true;
    if (!criteria.propertyCriteria?.length) {
      return true;
    }
    criteria.propertyCriteria.forEach((propertyCriteria: FilterPropertyCriteria) => {
      test = test && this.testPropertyCriteria(object, propertyCriteria);
    });
    return test;
  }
  /** Returns true if the obejct passes ANY of the supplied property criteria. */
  public static testOrCriteria(object: any, criteria: FilterCriteria): boolean {
    let test = true;
    if (!criteria.propertyCriteria?.length) {
      return true;
    }
    criteria.propertyCriteria.forEach((propertyCriteria: FilterPropertyCriteria) => {
      test = test || this.testPropertyCriteria(object, propertyCriteria);
    });
    return test;
  }
  public static testPropertyCriteria(object: any, propertyCriteria: FilterPropertyCriteria): boolean {
    const propType = propertyCriteria.filterPropertyDefinition.propertyType;
    let criteria = propertyCriteria.criteriaValue;
    if (
      (criteria === undefined || criteria === null) &&
      propertyCriteria.filterConditionType !== FilterConditionType.IS_EMPTY
    ) {
      return true;
    }
    let value = object[propertyCriteria.filterPropertyDefinition.slug];
    if (!value && object.properties) {
      value = object.properties[propertyCriteria.filterPropertyDefinition.slug];
    }

    if (value && propType === PropertyType.ObjectReference) {
      // object reference attribute will be an object
      value = value[propertyCriteria.filterPropertyDefinition.referencedObjName || 'name']; // get the display value of the object
    }

    // To support 'ItemData'
    if (!value && object.properties) {
      value = object.properties[propertyCriteria.filterPropertyDefinition.slug];
    }

    // Process value fr dates, numbers, etc
    if (propType === PropertyType.Date) {
      if (value) {
        value = formatDate(new Date(value), 'yyyy-MM-dd', 'en_US');
      }
      if (criteria) {
        criteria = formatDate(new Date(criteria), 'yyyy-MM-dd', 'en_US');
      }
    }

    if (criteria && value && STRING_PROP_TYPES.includes(propType)) {
      if (typeof value === 'number') {
        value = value + ''; // need to transform formula value that is a number to string (why?)
      }

      // Alter criteria
      if (Array.isArray(criteria)) {
        criteria = criteria.map((val) => val.toLowerCase());
      } else {
        if (typeof criteria === 'number') {
          criteria = criteria + ''; // need to transform formula value that is a number to string (why?)
        }
        criteria = criteria.toLowerCase();
      }

      value = value.toLowerCase();
    }

    let test = true;
    switch (propertyCriteria.filterConditionType) {
      case FilterConditionType.EQUALS: {
        if (propType === PropertyType.Boolean) {
          value = !(!value || value === null || value === undefined || value.toString().trim() === '');
        }

        // for arrays, order of values in the array should not affect comparison
        if (value && criteria && Array.isArray(value) && Array.isArray(criteria)) {
          const sortedValue = ObjectUtil.cloneDeep(value);
          sortedValue.sort();
          value = sortedValue.join();
          const sortedCriteria = ObjectUtil.cloneDeep(criteria);
          sortedCriteria.sort();
          criteria = sortedCriteria.join();
        }
        test = criteria === value;
        break;
      }
      case FilterConditionType.IS_EMPTY: {
        test = !value || value === undefined || value.toString().trim() === '';
        break;
      }

      case FilterConditionType.NOT_EQUAL_TO: {
        if (propType === PropertyType.Boolean) {
          value = !(!value || value === null || value === undefined || value.toString().trim() === '');
        }
        test = criteria !== value;
        break;
      }
      case FilterConditionType.GREATER_THAN: {
        if (!value) {
          test = false;
          break;
        }
        test = value > criteria;
        break;
      }
      case FilterConditionType.LESS_THAN: {
        if (!value) {
          test = false;
          break;
        }
        test = value < criteria;
        break;
      }
      case FilterConditionType.GREATER_THAN_OR_EQUAL: {
        if (!value) {
          test = false;
          break;
        }
        test = value >= criteria;
        break;
      }
      case FilterConditionType.LESS_THAN_OR_EQUAL: {
        if (!value) {
          test = false;
          break;
        }
        test = value <= criteria;
        break;
      }
      case FilterConditionType.STARTS_WITH: {
        if (!value) {
          test = false;
          break;
        }
        const valueString = '' + value;
        const criteriaString = '' + criteria;
        test = valueString.startsWith(criteriaString);
        break;
      }
      case FilterConditionType.ENDS_WITH: {
        if (!value) {
          test = false;
          break;
        }
        const valueString = '' + value;
        const criteriaString = '' + criteria;
        test = valueString.endsWith(criteriaString);
        break;
      }
      case FilterConditionType.CONTAINS: {
        if (!value) {
          test = false;
          break;
        }
        const valueString = '' + value;
        const criteriaString = '' + criteria;
        test = valueString.indexOf(criteriaString) > -1;
        break;
      }
      case FilterConditionType.IS_ANY_OF: {
        if (!Array.isArray(criteria)) {
          console.log('Illeage criteria type for IS_ANY_OF: ', criteria);
          return true;
        }
        if (Array.isArray(value)) {
          test = !!value.find((val) => criteria.includes(val));
        } else {
          test = criteria.includes(value);
        }
        break;
      }
      case FilterConditionType.IS_NONE_OF: {
        if (!Array.isArray(criteria)) {
          console.log('Illeage criteria type for IS_NONE_OF: ', criteria);
          return true;
        }
        if (Array.isArray(value)) {
          test = !value.find((val) => criteria.includes(val));
        } else {
          test = !criteria.includes(value);
        }
        break;
      }
    }

    return test;
  }
}
