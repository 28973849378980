import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommentsActions, CommentsSelectors } from 'src/app/common/comments/comments-store';
import { Comment, CommentOwnerInfo } from 'src/app/common/comments/comments.service';
import { PinnedComment } from 'src/app/presentation/presentation-pinned-comments/presentation-pinned-comments.component';
import { ItemData } from '@common/item-data/item-data';

export const ITEM_PINNED_COMMENT_ID_SUFFIX = 'item-pinned-comment';

@Component({
  selector: 'app-item-pinned-comments',
  templateUrl: './item-pinned-comments.component.html',
  styleUrls: ['./item-pinned-comments.component.scss'],
})
export class ItemPinnedComments implements OnInit {
  @Input() item?: ItemData; // Item can be item family or item option - functionality is the same.

  private subscription: Subscription = new Subscription();
  private readonly PINNED_COMMENT_OFFSET = 4;
  pinnedComment$: Observable<PinnedComment>;
  elementId: string;
  ownerInfo: CommentOwnerInfo;

  constructor(private store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.elementId = `${ITEM_PINNED_COMMENT_ID_SUFFIX}-${this.item.id}`;
    this.pinnedComment$ = this.store.select(CommentsSelectors.selectContextComments).pipe(
      map((comments) => comments.filter((comment) => comment.status !== 'closed' && this.isItemComment(comment))),
      map((comments) => Object.values(this.groupByDocumentElement(comments))?.pop()),
    );

    this.ownerInfo = {
      id: this.item.id,
      entityType: 'item',
    };
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Item comments is defined by:
   * subContextReference: 'presentation-frame:<frameId>' - belongs to a particular frame
   * ownedByReference: 'item:<itemId>' - can be item family or item option
   * documentElementId and documentPositions should be undefined
   * @param comment
   */
  private isItemComment(comment: Comment) {
    return (
      !comment.documentElementId &&
      !comment.documentPosition &&
      !comment.subContextReference &&
      comment?.ownedByReference === `item:${this.item.id}`
    );
  }

  private groupByDocumentElement(comments: Array<Comment>) {
    return comments.reduce((counter, comment) => {
      const groupKey = comment.ownedByReference;
      if (counter.hasOwnProperty(groupKey)) {
        counter[groupKey].count = counter[groupKey].count + 1;
      } else {
        counter[groupKey] = {
          groupKey,
          count: 1,
          firstComment: comment,
        };
      }
      return counter;
    }, {});
  }

  showComments($event) {
    $event.stopPropagation();
    const pinnedCommentEl = document.getElementById(this.item.id);
    if (!pinnedCommentEl) {
      return;
    }
    const pinnedCommentPosition = pinnedCommentEl.getBoundingClientRect();
    this.store.dispatch(
      CommentsActions.showCommentOverlay({
        ownerInfo: this.ownerInfo,
        position: {
          x: pinnedCommentPosition.left,
          y: pinnedCommentPosition.bottom + this.PINNED_COMMENT_OFFSET,
        },
      }),
    );
  }
}
