import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AnalyticsService } from 'src/app/common/analytics/analytics.service';
import { SessionService } from 'src/app/session/session.service';
import { ShowcaseService } from 'src/app/showcase/showcase.service';
import { ItemData } from 'src/app/common/item-data/item-data';
import { Assortment } from 'src/app/common/assortments/assortment';
import { DataSelectionToggleComponent } from 'src/app/common/item-presentation/data-selection-toggle/data-selection-toggle.component';
import { EVENT_CATEGORY } from '@common/analytics/user-analytics.service';
import { Subject, takeUntil } from 'rxjs';
@Component({
  selector: 'app-item-data-selection-toggle',
  template: `
    <mat-icon class="cursor-pointer item-selection-toggle" (click)="updateAssortment($event)">
      {{ inAssortment ? 'add_circle' : 'add_circle_outline' }}
    </mat-icon>
  `,
  styles: [
    `
      :host {
        display: flex;
      }
    `,
  ],
})
export class ItemDataSelectionToggleComponent implements DataSelectionToggleComponent, OnInit, OnDestroy {
  private destroy$ = new Subject();

  @Input() itemData: ItemData;
  @Output() isLoading = new EventEmitter<boolean>();
  public inAssortment = false;
  private currentAssort: Assortment;
  public assortmentName: string;

  constructor(
    private sessionService: SessionService,
    private showcaseService: ShowcaseService,
    private analyticsService: AnalyticsService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.sessionService.currentAssortment.pipe(takeUntil(this.destroy$)).subscribe((assort) => {
      if (!assort) {
        this.inAssortment = false;
        return;
      }
      if (this.currentAssort?.id === assort.id) {
        return;
      }
      this.currentAssort = assort;
      this.assortmentName = assort.name;
      this.setState();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  setState() {
    if (!this.currentAssort || !this.itemData) {
      return false;
    }
    const data = this.currentAssort?.items || [];
    const match = data.filter((obj: ItemData) => obj.id === this.itemData.id);
    this.inAssortment = match?.length > 0;
  }

  updateAssortment(event) {
    event.stopPropagation();
    this.inAssortment ? this.removeFromList() : this.addToList();
  }

  public async addToList() {
    try {
      this.analyticsService.emitEvent({
        eventName: 'ADD_TO_ASSORTMENT_FROM_TOGGLE',
        eventTarget: `item:${this.itemData.id}`,
        eventCategory: EVENT_CATEGORY.OTHER,
      });
      console.log('addToList: ', this.itemData);
      this.isLoading.emit(true);
      await this.sessionService.addItem(this.itemData, this.currentAssort.id);
      this.setState();
      this.openSnackBar(
        `Successfully added ${this.itemData.getName()} to your assortment ${this.currentAssort.name}`,
        'Success',
      );
    } catch (err) {
      this.openSnackBar(
        `Sorry. Could not process your request. Error while adding to ${this.currentAssort.name}`,
        'Error',
        5000,
      );
      console.error(`error ${err.message} `);
    } finally {
      this.isLoading.emit(false);
    }
  }

  public async removeFromList() {
    try {
      this.analyticsService.emitEvent({
        eventName: 'REMOVE_FROM_ASSORTMENT_FROM_TOGGLE',
        eventCategory: EVENT_CATEGORY.OTHER,
        eventTarget: `item:${this.itemData.id}`,
      });
      console.log('removeFromList: ', this.itemData);
      this.isLoading.emit(true);
      await this.sessionService.removeItem(this.itemData);
      this.setState();
      this.openSnackBar(
        `Successfully removed ${this.itemData.getName()} from your assortment ${this.currentAssort.name}`,
        'Success',
      );
    } catch (err) {
      this.openSnackBar(
        `Sorry. Could not process your request. Error while removing ${this.itemData.getName()} from ${this.currentAssort.name}`,
        'Error',
        5000,
      );
      console.error(`error ${err.message} `);
    } finally {
      this.isLoading.emit(false);
    }
  }

  openSnackBar(message: string, action: string, ttl = 2000) {
    this.snackBar.open(message, action, {
      duration: ttl,
    });
  }
}
