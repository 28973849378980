import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { AssortmentsSelectors } from '../../assortments/assortments-store';
import { AuthSelectors } from '../../auth/auth-store';
import { FilterObjects } from '../../components/filter/filter-objects';
import { FilterUtil } from '../../util/filter-util';
import { ChooserFilterConfig, ItemDataChooserDataSource } from './item-data-chooser-data-source';
import { SortDefinition } from '../../components/sort/sort-definition';
import { FilterDefinition } from '../../types/filters/filter-definition';
export class SourceAssortmentChooserDataSource extends ItemDataChooserDataSource {
  constructor(
    protected store: Store<RootStoreState.State>,
    protected filterConfigSubject: Observable<ChooserFilterConfig>,
    protected sortConfigSubject: Observable<SortDefinition[]>,
    protected existingItemIdsSubject: Observable<any>,
    protected showAllSubject: Observable<any>,
    protected dateFilterSubject: Observable<FilterDefinition>,
  ) {
    super(store, filterConfigSubject, sortConfigSubject, existingItemIdsSubject, showAllSubject, dateFilterSubject);
    this.initFilteredDataObservable();
    this.initResultsObservable();
  }

  protected async initFilteredDataObservable() {
    this.filteredData$ = combineLatest([
      this.filterConfigSubject,
      this.dateFilterSubject,
      this.sortConfigSubject,
      this.store.select(AssortmentsSelectors.sourceAssortmentItemData),
      this.store.select(AssortmentsSelectors.sourceAssortmentItemDataHiearchy),
      this.store.select(AuthSelectors.selectAuthContext),
    ]).pipe(
      switchMap(
        async ([
          filterConfig,
          dateFilter,
          sortConfig,
          sourceAssortmentItemData,
          sourceAssortmentItemDataHiearchy,
          auth,
        ]) => {
          console.log('SourceAssortmentChooserDataSource: filteredDataObservable');

          let data;
          const criteria = filterConfig.baseCriteria;
          const searchTerm = filterConfig.searchTerm;
          const filterDefinition = filterConfig.filterDefintion;

          // SOURCE ASSORTMENT CONSTRAINT, OPTION LEVEL

          if (criteria.roles === 'option') {
            data = sourceAssortmentItemData;
            data = this.filterLocalItemData({ searchTerm, itemFamilyId: criteria.itemFamilyId }, data);
          } else if (criteria.roles === 'family') {
            data = sourceAssortmentItemDataHiearchy;
            data = this.filterLocalItemData({ searchTerm }, data);
          }
          if (filterDefinition) {
            data = FilterObjects.filter(data, filterDefinition);
          }
          if (dateFilter) {
            data = this.filterByAssortmentItems(data, dateFilter);
          }
          data = FilterUtil.filterDroppedItems(data, auth?.currentOrg?.orgSlug);
          this.sortData(data, sortConfig);
          console.log('sourceAssortmentSearch: ', data);
          return data;
        },
      ),
    );
  }
}
