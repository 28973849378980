import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { RootStoreState } from 'src/app/root-store';
import { AuthSelectors } from '../../auth/auth-store';
import { AuthContext } from '../../auth/auth.service';
import { CommentsActions, CommentsSelectors } from '../comments-store';
import { ChangeSuggestion, Comment, CommentOwnerInfo, CommentsService } from '../comments.service';

@Component({
  selector: 'app-comment-form',
  templateUrl: './comment-form.component.html',
  styleUrls: ['./comment-form.component.scss'],
})
export class CommentFormComponent implements OnInit, OnDestroy {
  @Input() ownerInfo: CommentOwnerInfo;
  @Input() comment: Comment;
  @Input() isCommentOverlay: boolean = false;
  @Output() emitCancelUpdate = new EventEmitter<boolean>();
  @ViewChild('commentField') commentInput: ElementRef;

  public suggestedValue: any;
  public authContext$: Observable<AuthContext>;
  public showCommentOverlay$: Observable<boolean>;
  public isCommentSubmitted = false;
  public form: UntypedFormGroup;
  public isUpdate = false;
  public saveBtnLabel = 'Publish';
  public commentHeaderLbl = 'Enter new comment:';
  public commentContext = 'Showcase';
  private subscription: Subscription = new Subscription();

  constructor(
    public service: CommentsService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {
    this.authContext$ = this.store.select(AuthSelectors.selectAuthContext);
    this.showCommentOverlay$ = store.select(CommentsSelectors.showCommentOverlay);
  }

  setSaveBtnLabel() {
    if (this.comment) {
      this.saveBtnLabel = 'Save';
      this.commentHeaderLbl = ''; // blank out the label for update comment
    } else {
      this.saveBtnLabel = 'Post';
      this.commentHeaderLbl = `Enter new comment:`;
    }
  }

  cancelUpdate() {
    this.emitCancelUpdate.emit(true);
  }

  async ngOnInit() {
    if (this.comment && !this.ownerInfo) {
      const slugTypeProperty = await this.service.getProperty(this.comment, this.comment.ownedByPropertySlug);
      const property = { ...slugTypeProperty }; // PROPERTY_MAP[this.comment.ownedByPropertySlug];
      const value =
        (this.comment?.changeSuggestion?.changeDetails && this.comment.changeSuggestion.changeDetails.oldValue) || '';

      const entityTypeId = this.comment.ownedByReference?.split(':');
      if (entityTypeId.length > 1) {
        const entityType = entityTypeId[0];
        const id = entityTypeId[1];
        this.ownerInfo = { entityType, id, property, value };
      }
    }

    if (this.comment) {
      this.isUpdate = true;
      this.suggestedValue = this.comment?.changeSuggestion?.changeDetails?.newValue;
    }
    this.form = new UntypedFormGroup({
      comment: new UntypedFormControl(this.comment?.text),
      newValue: new UntypedFormControl(''),
    });
    if (this.ownerInfo) {
      if (this.ownerInfo.entityType === 'item-color') {
        this.commentContext = 'Colorway';
      } else if (this.ownerInfo.entityType === 'item') {
        this.commentContext = 'Product';
      }
    }
    this.setSaveBtnLabel();

    // Focus on the form input when comment overlay becomes visible
    this.subscription.add(
      this.showCommentOverlay$.subscribe((bool) => {
        if (bool && this.isCommentOverlay) {
          setTimeout(() => this.commentInput && this.commentInput.nativeElement.focus(), 200);
        }
      }),
    );
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  hasError(controlName: string, errorName: string) {
    return this.form.controls[controlName].hasError(errorName);
  }

  async onSubmit(formData) {
    try {
      this.isCommentSubmitted = true;
      let changeSuggestion: ChangeSuggestion = null;

      if (this.ownerInfo?.property && (this.suggestedValue || this.suggestedValue === false)) {
        changeSuggestion = {
          changeType: 'PROPERTY_CHANGE',
          changeDetails: {
            propertySlug: this.ownerInfo.property.slug,
            oldValue: this.ownerInfo.value,
            newValue: this.suggestedValue,
          },
          changeStatus: 'pending',
        };
      }

      console.log(` changeSuggestion ${JSON.stringify(changeSuggestion)}`);

      if (this.comment?.id) {
        let updateComment = Object.assign({}, this.comment);
        updateComment.text = formData.comment;
        updateComment.changeSuggestion = changeSuggestion;
        if (changeSuggestion?.changeStatus === 'pending') {
          updateComment.status = 'open';
        }
        updateComment = {
          ...updateComment,
          text: formData.comment,
          changeSuggestion,
        };

        await this.store.dispatch(CommentsActions.updateComment({ comment: updateComment }));
        this.cancelUpdate();
      } else {
        console.log(` ~ createComment changeSuggestion: ${JSON.stringify(changeSuggestion)}`);
        await this.store.dispatch(
          CommentsActions.createComment({ text: formData.comment, ownerInfo: this.ownerInfo, changeSuggestion }),
        );
        this.resetForm();
      }
    } catch (err) {
      this.openSnackBar(`Oops! Failed to add your comment`, 'Error');
      console.error(err);
    } finally {
      this.isCommentSubmitted = false;
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  resetForm() {
    this.form.reset();
    this.suggestedValue = null;
  }
  handleSuggestedValueChange(event) {
    console.log('handleSuggestedValueChange: ', event.value);
    this.suggestedValue = event.value;
    this.form.controls.newValue.setValue(event.value);
  }
}
