import { Component } from '@angular/core';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  editorMode$: Observable<any>;

  constructor(private store: Store<RootStoreState.State>) {
    this.editorMode$ = this.store.select(EditorModeSelectors.editorMode);
  }
}
