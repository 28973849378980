import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UserAnalyticsService } from '@common/analytics/user-analytics.service';
import { WebSocketService } from './common/web-socket/web-socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  // register the custom mat-icons
  constructor(
    private matIconRegistry: MatIconRegistry,
    private webSocketService: WebSocketService,
    private userAnalyticsService: UserAnalyticsService,
    private domSanitizer: DomSanitizer,
  ) {
    this.matIconRegistry.addSvgIcon(
      'showcase-app-action',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/showcase-app-action.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'showcase_reload_action',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/presentation-reload-action.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'showcase_download_action',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/download-presentation-action.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'help_outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/help_outline.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'comments_placeholder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/comments_placeholder.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'item-placeholder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/item-placeholder.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'sort_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/sort_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'filter_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/filter_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'warning-alert',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/warning_alert.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'dropped',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/dropped.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'primary',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/primary.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'carryover',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/carryover.svg'),
    );

    this.userAnalyticsService.setEventApplication('SHOWCASE_VIEWER');
    this.setRequestedUrl();
  }
  async setRequestedUrl() {
    if (window.location.href.indexOf('/sc') > 0) {
      localStorage.setItem('requestedShowcaseURL', window.location.href);
    }
  }
}
