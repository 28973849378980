import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DownloadService } from '../download.service';

@Component({
  selector: 'app-download-content-indicator',
  templateUrl: './download-content-indicator.component.html',
  styleUrls: ['./download-content-indicator.component.scss'],
})
export class DownloadContentIndicatorComponent implements OnInit {
  private PDF_GEN_TIMEOUT_MESSAGE = 'Error while generating PDF. Please try again.';

  public numberOfPendingJobs = 0;

  public numberOfCompletedJobs = 0;

  constructor(
    private downloadService: DownloadService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.downloadService.pendingJobsObservable.subscribe((data) => {
      if (!data) {
        this.numberOfPendingJobs = 0;
        return;
      }
      console.log(`download service has received ${JSON.stringify(data)}  - ${data?.length} job ids`);
      this.numberOfPendingJobs = data?.length;
    });

    this.downloadService.completedJobsObservable.subscribe((data) => {
      if (!data) {
        this.numberOfCompletedJobs = 0;
        return;
      } else {
        //window.open(data[0].path, '_blank)
        window.open(data, '_blank');
      }
      console.log(`download service has received ${JSON.stringify(data)}  - ${data?.length} job ids`);
      this.numberOfCompletedJobs = data?.length;
    });

    this.downloadService.errorObservable.subscribe((data) => {
      if (!data) {
        return;
      } else {
        this.openSnackBar(this.PDF_GEN_TIMEOUT_MESSAGE, 'Error');
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
