<!-- <button mat-raised-button color="primary" (click)="showAssortmentList()">
  Add To List
  <mat-icon>add</mat-icon>
</button> -->
<app-mat-spinner [overlay]="true" [loading]="isLoading" *ngIf="isLoading"></app-mat-spinner>
<div class="showList">
  <div class="selector">
    <mat-form-field appearance="fill">
      <mat-label>Select Assortment</mat-label>
      <mat-select [formControl]="assortmentSelectionControl" name="defaultSelected">
        <!-- <mat-option (onSelectionChange)="selectedAssortment()">--</mat-option> -->
        <mat-option
          *ngFor="let assortment of assortments"
          [value]="assortment"
          (onSelectionChange)="selectedAssortment(assortment)"
        >
          {{ assortment.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="actions">
    <button mat-raised-button color="primary" (click)="addToList(assortmentSelectionControl.value)">
      Add to Assortment
    </button>
  </div>
</div>
