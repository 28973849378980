<div class="ml-5" *ngIf="visible">
  <app-presentation-grid-section
    class="list-value"
    [id]="element.value"
    *ngFor="let element of sectionElements; let i = index"
    [sectionIndex]="i"
    [panelName]="panel.frame.name"
    [countTotal]="countTotal"
    [backingAssortmentItemData]="backingAssortmentItems$ | async"
    [section]="element"
    [viewDefinition]="viewDefinition"
    [itemMap]="itemMap"
    [itemsMissingFromSource]="itemsMissingFromSource"
    [frameId]="panel?.frame.id"
    [editorMode]="editorMode$ | async"
  ></app-presentation-grid-section>
</div>
