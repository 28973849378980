import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';

import { TextHyperlinkComponent } from './document-text/text-hyperlink/text-hyperlink.component';

@NgModule({
  declarations: [TextHyperlinkComponent],
  imports: [CommonModule, MatIconModule],
  exports: [TextHyperlinkComponent],
})
export class DocumentModule {}
