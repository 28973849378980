<div data-test="comments-list" class="comments-info" *ngIf="comments?.length">
  <virtual-scroller #scroll [items]="comments" class="narrow-scroll">
    <app-comments-card
      [attr.data-test]="comment.id + '-comment-card'"
      *ngFor="let comment of scroll.viewPortItems"
      [accessLevel]="accessLevel"
      [comment]="comment"
      [ownerInfo]="ownerInfo"
      [showOwnerName]="showOwnerName"
    ></app-comments-card>
  </virtual-scroller>
</div>

<div class="no-comments" *ngIf="!comments?.length">
  <h6 class="mb-3 title">What's on your mind?</h6>
  <mat-icon svgIcon="comments_placeholder"></mat-icon>
</div>

<div class="new-comment">
  <mat-divider></mat-divider>
  <app-comment-form
    class="px-2 pb-2"
    data-test="comments-list-new-comment"
    [ownerInfo]="ownerInfo"
    *ngIf="accessLevel !== 'VIEW'"
  ></app-comment-form>
</div>
