import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserSessionsStoreModule } from '../common/user-sessions/user-sessions-store';
import { AuthStoreModule } from '../common/auth/auth-store';
import { CommentsStoreModule } from '../common/comments/comments-store';
import { SessionAssortmentsStoreModule } from '../session/session-assortments-store';
import { LoadingIndicatorStoreModule } from '../common/loading-indicator/loading-indicator-store';
import { ShowcaseStoreModule } from '../showcase/showcase-store';
import { PresentationStoreModule } from '../presentation/presentation-store';
import { WorkspacesStoreModule } from '@common/workspaces/workspaces-store';
import { AssortmentsStoreModule } from '../common/assortments/assortments-store';
import { EditorModeStoreModule } from '@common/editor-mode/editor-mode-store';
import { DocumentStoreModule } from '../presentation/document/document-store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    UserSessionsStoreModule,
    AuthStoreModule,
    CommentsStoreModule,
    SessionAssortmentsStoreModule,
    LoadingIndicatorStoreModule,
    AssortmentsStoreModule,
    ShowcaseStoreModule,
    PresentationStoreModule,
    WorkspacesStoreModule,
    EditorModeStoreModule,
    DocumentStoreModule,
  ],
})
export class RootStoreModule {}
