<img class="logo" src="assets/images/vibeiq_logo.jpg" />
<div class="header-text">Virtual Showcase</div>
<div *ngIf="user">
  <div class="message">
    Welcome to the Vibe Virtual Showcase.
    <br />
    To view a showcase, you must use a <br />'Showcase Viewer Link'.
  </div>
  <div class="message">For assistance, please talk with your brand contact or company administrator.</div>
  <div class="logout message" (click)="logout()">Log in as a different user</div>
  <div class="logout message" (click)="goToAdminApp()">Go to the Showcase Administration Application</div>
</div>
