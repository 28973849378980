import { createAction, props } from '@ngrx/store';
import { CollectionStatusMessage } from './collection-status-message.service';

export enum CollectionStatusMessagesActionTypes {
  CLEAR_STATUS_MESSAGES = '[CollectionStatusMessages] Clear Messages',
  ADD_STATUS_MESSAGE_ENTITY = '[CollectionStatusMessages] Add Status Message',
  REMOVE_STATUS_MESSAGE_ENTITY = '[CollectionStatusMessages] Remove Status Message',
}

////////////////////////////////////////////////////////////////////////////////
export const clearStatusMessages = createAction(CollectionStatusMessagesActionTypes.CLEAR_STATUS_MESSAGES);
export const addStatusMessage = createAction(
  CollectionStatusMessagesActionTypes.ADD_STATUS_MESSAGE_ENTITY,
  props<{ message: CollectionStatusMessage }>(),
);
////////////////////////////////////////////////////////////////////////////////
export const removeStatusMessage = createAction(
  CollectionStatusMessagesActionTypes.REMOVE_STATUS_MESSAGE_ENTITY,
  props<{ id: any }>(),
);
