import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AnnotatedElement } from './document-annotation-options';
import { DocumentActions } from '../document-store';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';

export const ANNOTATION_IMG_SIZE = 15;
export const ANNOTATION_PADDING_Y = 8;

@Injectable({
  providedIn: 'root',
})
export class DocumentAnnotationService {
  private annotationEventsSubject: BehaviorSubject<any> = new BehaviorSubject({});
  public annotationEvents: Observable<any> = this.annotationEventsSubject.asObservable();

  public annotatedElementsSubject: BehaviorSubject<Array<AnnotatedElement>> = new BehaviorSubject(null);
  public annotatedElements$: Observable<Array<AnnotatedElement>> = this.annotatedElementsSubject.asObservable();
  public annotationOptions: any[];

  constructor(private store: Store<RootStoreState.State>) {}

  getAnnotationOptions() {
    return this.annotationOptions;
  }

  setAnnotatedElements(annotatedElements) {
    this.store.dispatch(DocumentActions.setAnnotatedElements({ annotatedElements }));
  }
}
