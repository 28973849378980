import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Subscription } from 'rxjs';
import { DocumentElement } from '@contrail/documents';
import { DocumentElementEvent } from '@contrail/documents';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AddPinnedCommentsService } from '../presentation-pinned-comments/add-pinned-comments.service';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { ShowcaseSelectors } from 'src/app/showcase/showcase-store';
import { Showcase } from 'src/app/showcase/showcase';
import { PresentationSelectors } from '../presentation-store';
// import { CanvasDocument } from '../canvas/canvas-document';
// import { CanvasUtil } from '../canvas/canvas-util';
import { CanvasUtil, CanvasDocument } from '@contrail/canvas';
import { DocumentService } from '../document/document.service';

@Component({
  selector: 'app-presentation-context-menu',
  templateUrl: './presentation-context-menu.component.html',
  styleUrls: ['./presentation-context-menu.component.scss'],
})
export class PresentationContextMenuComponent implements AfterViewInit, OnDestroy {
  constructor(
    private documentService: DocumentService,
    private addPinnedCommentsService: AddPinnedCommentsService,
    private store: Store<RootStoreState.State>,
    private matDialog: MatDialog,
  ) {}

  @ViewChild(MatMenuTrigger) contextMenuTrigger: MatMenuTrigger;
  @ViewChild('contextMenu') contextMenu: ElementRef;
  @Input() frameId: string;
  @Input() canvasDocument: CanvasDocument;

  public element: DocumentElement;
  public sourceEvent: DocumentElementEvent;
  private subscription: Subscription = new Subscription();
  currentShowcase: Showcase;
  currentFrameId: string;
  ngAfterViewInit(): void {
    this.subscribe();
  }
  ngOnDestroy() {
    this.unsubscribe();
  }
  subscribe() {
    this.subscription.add(
      this.documentService.documentElementEvents.subscribe((event) => {
        if (!event) {
          return;
        }
        if (event.eventType === 'contextmenu') {
          if (this.frameId === this.currentFrameId) {
            this.showMenu(event);
          }
        }
      }),
    );

    this.subscription.add(
      this.store.select(ShowcaseSelectors.currentShowcase).subscribe((showcase) => {
        this.currentShowcase = showcase;
      }),
    );

    this.subscription.add(
      this.store.select(PresentationSelectors.currentFrameId).subscribe((currentFrameId) => {
        this.currentFrameId = currentFrameId;
      }),
    );
  }
  unsubscribe() {
    this.subscription.unsubscribe();
  }

  showMenu(event: DocumentElementEvent) {
    this.sourceEvent = event;
    this.element = event.element;
    const top = event.sourceMouseEvent.clientY;
    const left = event.sourceMouseEvent.clientX;
    this.contextMenu.nativeElement.setAttribute('style', `left:${left}px; top:${top}px;`);
    this.contextMenu.nativeElement.classList.add('visible');
    this.contextMenuTrigger.openMenu();
  }

  showComments(event) {
    const windowPosition = {
      x: this.sourceEvent.sourceMouseEvent.clientX,
      y: this.sourceEvent.sourceMouseEvent.clientY,
    };
    const documentPosition = CanvasUtil.toDocumentPosition(
      windowPosition.x,
      windowPosition.y,
      this.canvasDocument.getViewBox(),
      this.canvasDocument.getViewScale(),
      this.canvasDocument.getBoundingClientRect(),
    );
    documentPosition.x = documentPosition.x - this.element.position.x;
    documentPosition.y = documentPosition.y - this.element.position.y;
    const commentOverlayDocumentPosition = {
      x: this.sourceEvent.sourceMouseEvent.clientX,
      y: this.sourceEvent.sourceMouseEvent.clientY + 5,
    };
    this.addPinnedCommentsService.addCommentToDocumentElement(
      {
        entityType: 'showcase',
        id: this.currentShowcase.id,
        documentElementId: this.element.id,
        documentPosition,
        subContextReference: 'presentation-frame:' + this.frameId,
      },
      commentOverlayDocumentPosition,
      this.element.id,
    );
  }
}
