import { PositionDefinition, ViewBox } from '@contrail/documents';

export interface SideMenuOverlay {
  showChooser?: boolean;
  label?: string;
  slug?: string;
  icon?: string;
}

export interface DocumentViewSize {
  viewBox: ViewBox;
  viewScale: PositionDefinition;
  boundingClientRect: DOMRect;
}

export interface State {
  toggleChooser: SideMenuOverlay;
  lastKnownMousePosition?: PositionDefinition;
  annotatedElements: any[];
}

export const documentInitialState = {
  toggleChooser: null,
  annotatedElements: [],
};
