<div *ngIf="viewDefinition">
  <ng-container *ngFor="let property of viewDefinition.properties">
    <div class="property" *ngIf="property.propertyDefinition">
      <div
        [style.font-size.px]="property.style?.font?.size"
        [style.color]="property.style?.color"
        *ngIf="property?.includeLabel"
        class="label"
      >
        {{ property.propertyDefinition?.label }}
      </div>
      <div [style.font-size.px]="property.style?.font?.size" [style.color]="property.style?.color" class="value">
        <app-property-value
          [value]="itemData.properties[property.slug]"
          [property]="property.propertyDefinition"
          [propertyType]="property.propertyDefinition?.propertyType"
        >
        </app-property-value>
      </div>
    </div>
  </ng-container>
</div>
