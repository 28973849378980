import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemPropertiesPanelComponent } from './item-properties-panel/item-properties-panel.component';
import { ItemImageViewerComponent } from './item-image-viewer/item-image-viewer.component';
import { ItemColorsListComponent } from './item-colors-list/item-colors-list.component';
import { PresentationItemDetailsComponent } from './presentation-item-details/presentation-item-details.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatDividerModule } from '@angular/material/divider';
import { ComponentsModule } from '../components/components.module';
import { CommentsModule } from '../comments/comments.module';
import { TypesModule } from '../types/types.module';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { DataSelectionToggleDirective } from './data-selection-toggle/data-selection-toggle.directive';
import { ItemColorComponent } from './item-color/item-color.component';
import { PipesModule } from '@common/pipes/pipes.module';
import { WebglModule } from '@common/webgl/webgl.module';
import { ItemPinnedCommentsDirective } from './item-pinned-comments/item-pinned-comments.directive';

@NgModule({
  declarations: [
    ItemPropertiesPanelComponent,
    ItemImageViewerComponent,
    ItemColorsListComponent,
    PresentationItemDetailsComponent,
    ImageViewerComponent,
    DataSelectionToggleDirective,
    ItemColorComponent,
    ItemPinnedCommentsDirective,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTabsModule,
    MatDividerModule,
    ComponentsModule,
    CommentsModule,
    TypesModule,
    PipesModule,
    WebglModule,
  ],
  exports: [PresentationItemDetailsComponent, ItemPropertiesPanelComponent, ItemImageViewerComponent],
})
export class ItemPresentationModule {}
