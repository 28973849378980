import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ItemData } from 'src/app/common/item-data/item-data';
import { ItemsService } from '../items.service';
@Component({
  selector: 'app-item-image-viewer',
  templateUrl: './item-image-viewer.component.html',
  styleUrls: ['./item-image-viewer.component.scss'],
})
export class ItemImageViewerComponent implements OnInit, OnChanges {
  @Input() itemData: ItemData;
  @Input() itemColor: any;

  public viewables: Array<any>;

  constructor(private itemsService: ItemsService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.itemData?.isFirstChange() ||
      changes?.itemColor?.currentValue !== this.itemColor ||
      changes?.itemData?.currentValue !== this.itemData
    ) {
      this.setViewables();
    }
  }
  getItemColor() {
    return this.itemColor;
  }

  private async setViewables() {
    this.viewables = null;
    let itemId;
    if (!this.itemData && !this.itemColor) {
      return;
    }
    const contextualItem = this.itemColor || this.itemData;
    itemId = contextualItem.id;

    const itemContent: any =
      this.itemColor?.content || this.itemData?.content || (await this.getItemColorContent(itemId));
    if (this.itemColor) {
      this.itemColor.content = itemContent;
    } else if (this.itemData) {
      this.itemData.content = itemContent;
    }

    let viewables = [];
    itemContent.sort((c1, c2) => {
      return new Date(c1.createdOn).getTime() - new Date(c2.createdOn).getTime();
    });
    for (const contentObj of itemContent) {
      // if (contentObj.contentType.includes('svg')) {
      //   console.log('hey >>> contentObj', contentObj) // TODO: confirm download url with @BTP
      // }
      const is3d = contentObj?.primaryFile?.contentType?.indexOf('gltf-binary') > -1;

      // itemData is item family, so primaryViewableId is in properties
      const isPrimary = contentObj.id === this.itemData.properties.primaryViewableId;
      if (is3d) {
        viewables.push({
          type: 'glb',
          primary: isPrimary,
          content: contentObj,
        });
      } else {
        const isSVG = contentObj.contentType.includes('svg'); // TODO: contentObj.primaryFile.downloadUrl >>> svg s3 bucket image is different and CORS error
        viewables.push({
          type: 'image',
          primary: isPrimary,
          location: isSVG ? contentObj.largeViewableUrl : contentObj.primaryFile.downloadUrl, // obj.mediumViewable?.downloadUrl || contentObj.primaryFile.downloadUrl
          thumbnailLocation: this.getThumbnailFromContent(contentObj),
        });
      }
    }
    const modelUrl = this.itemColor ? this.itemColor.properties['3Durl'] : this.itemData.properties['3Durl'];
    if (modelUrl) {
      viewables.push({
        location: modelUrl,
        type: '3D',
      });
    }
    this.viewables = viewables.sort((v1, v2) => (v1.primary ? -1 : 1));
  }

  private getThumbnailFromContent(viewable) {
    let thumb;
    if (viewable) {
      thumb =
        // viewable.smallViewable?.downloadUrl ||
        viewable.mediumViewable?.downloadUrl || viewable.primaryFile?.downloadUrl;
    }
    return thumb;
  }

  private getItemColorContent(itemId: any) {
    return this.itemsService.getItemContent(itemId);
  }
}
