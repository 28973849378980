import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { Subject } from 'rxjs/internal/Subject';

export interface DownloadedJob {
  jobId: string;
  path: string | Blob;
  name?: string;
}

export enum ExportJobStatus {
  COMPLETED = 'completed',
  PENDING = 'pending',
  FAILED = 'failed',
}

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  // total timeout = 60 seconds
  private TIMEOUT_POLLING_COUNT_LIMIT = 40;
  private DEBOUNCE_TIME = 2000;

  private jobPollingCount = new Map<string, number>();

  private pendingJobs: Array<string> = [];
  private pendingJobsSubject: Subject<string[]> = new BehaviorSubject(null);
  pendingJobsObservable: Observable<string[]> = this.pendingJobsSubject.asObservable();

  // private completedJobs:Array<any> = [];
  // private completedJobsSubject: Subject<any[]> = new BehaviorSubject(null);
  // completedJobsObservable: Observable<any[]> = this.completedJobsSubject.asObservable();

  private completedJobs: DownloadedJob = null;
  private completedJobsSubject: Subject<DownloadedJob> = new BehaviorSubject(null);
  completedJobsObservable$: Observable<DownloadedJob> = this.completedJobsSubject.asObservable();

  private errorSubject: Subject<boolean> = new BehaviorSubject(null);
  errorObservable$: Observable<boolean> = this.errorSubject.asObservable();

  private initiateDownloadSubject: Subject<string> = new BehaviorSubject(null);
  initiateDownloadObservable$: Observable<string> = this.initiateDownloadSubject.asObservable();

  public downloading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {
    this.initiateDownloadObservable$.pipe(debounceTime(this.DEBOUNCE_TIME)).subscribe((value) => {
      if (value) {
        this.getDownloadedURL(value);
      }
    });
  }

  public addPendingJob(jobId: string) {
    this.pendingJobs.push(jobId);
    this.pendingJobsSubject.next(this.pendingJobs);
  }

  public setError(jobId) {
    this.pendingJobs = this.pendingJobs.filter((data) => data !== jobId);
    this.pendingJobsSubject.next(this.pendingJobs);

    this.errorSubject.next(true);
    this.downloading$.next(false);
  }

  public completePendingJob(jobId: string, jobStatusData: DownloadedJob) {
    this.pendingJobs = this.pendingJobs.filter((data) => data !== jobId);

    this.completedJobs = jobStatusData;

    this.completedJobsSubject.next(this.completedJobs);
    this.pendingJobsSubject.next(this.pendingJobs);
    this.downloading$.next(false);
  }

  initDownloadPolling(jobId: string) {
    this.pendingJobs.push(jobId);
    this.pendingJobsSubject.next(this.pendingJobs);

    this.initiateDownloadSubject.next(jobId);
    this.updatePollingCount(jobId);
  }

  async getDownloadedURL(jobId) {
    this.downloading$.next(true);
    console.log(`getDownloadedURL - jobId ${JSON.stringify(jobId)}`);
    const option = {
      entityName: 'export',
      id: jobId,
    };
    const jobStatusData = await new Entities().get(option);
    console.log(` jobStatusData ${JSON.stringify(jobStatusData)}`);

    if (jobStatusData && jobStatusData?.status === ExportJobStatus.COMPLETED) {
      this.completePendingJob(jobId, { jobId, path: jobStatusData?.path });
    }

    if (jobStatusData?.status === ExportJobStatus.FAILED) {
      this.setError(jobId);
    }

    if (this.pendingJobs.length > 0) {
      const nextPendingJob = this.pendingJobs[0];
      try {
        this.updatePollingCount(nextPendingJob);
        this.initiateDownloadSubject.next(nextPendingJob);
      } catch (err) {
        console.error(' Error : for jobId ' + nextPendingJob, err);

        // this.pollingCount.forEach((value, key) => {
        //   console.log(`Key ${key} value ${value}`);
        // });

        this.setError(nextPendingJob);

        if (this.pendingJobs?.length) {
          this.initiateDownloadSubject.next(this.pendingJobs[0]);
        }
      }
    }
  }

  private updatePollingCount(jobId) {
    let count = 0;
    if (this.jobPollingCount.has(jobId)) {
      count = this.jobPollingCount.get(jobId);
    }

    count += 1;
    this.jobPollingCount.set(jobId, count);
    if (count > this.TIMEOUT_POLLING_COUNT_LIMIT) {
      throw Error(
        ` Export  timed out for jobid ${jobId}. Exceeded ${this.TIMEOUT_POLLING_COUNT_LIMIT} polling attempts with debounce duration of ${this.DEBOUNCE_TIME / 1000} seconds`,
      );
    }
  }

  public resetJobQueue() {
    this.pendingJobsSubject.next([]);
    this.completedJobsSubject.next(null);
    this.errorSubject.next(false);

    this.jobPollingCount = new Map<string, number>();
    this.pendingJobs = [];
  }
}
