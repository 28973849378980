import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components/components.module';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { OverlayModule } from '@angular/cdk/overlay';
import { TypesModule } from '../types/types.module';

import { ItemDataChooserComponent } from './item-data-chooser.component';
import { ItemDataChooserCardComponent } from './item-data-chooser-card/item-data-chooser-card.component';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { FilterModule } from '../components/filter/filter.module';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { ItemDataSourceSelectorComponent } from './item-data-source-selector/item-data-source-selector.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { SortModule } from '../components/sort/sort.module';
import { DateFilterModule } from '../components/date-filter/date-filter.module';
import { PipesModule } from '@common/pipes/pipes.module';
import { DirectivesModule } from '@directives/directives.module';

@NgModule({
  declarations: [ItemDataChooserComponent, ItemDataChooserCardComponent, ItemDataSourceSelectorComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    MatSlideToggleModule,
    MatIconModule,
    MatButtonModule,
    OverlayModule,
    TypesModule,
    MatFormFieldModule,
    VirtualScrollerModule,
    FilterModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatDividerModule,
    SortModule,
    DateFilterModule,
    TypesModule,
    PipesModule,
    DirectivesModule,
  ],
  providers: [],
  exports: [ItemDataChooserComponent, ItemDataChooserCardComponent],
})
export class ItemDataChooserModule {}
