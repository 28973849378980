import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthModule } from './common/auth/auth.module';
import { MainModule } from './main/main.module';
import { UserProfileModule } from './common/user-profile/user-profile.module';
import { HomeModule } from './home/home.module';
import { SessionModule } from './session/session.module';
import { ExplorerModule } from './explorer/explorer.module';
import { AnalyticsModule } from './common/analytics/analytics.module';
// import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { RootStoreModule } from './root-store/root-store.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment'; // Angular CLI environment
import { AppNotificationsModule } from './common/app-notifications/app-notifications.module';
import { storeDevTools } from './build-specifics';
@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    MainModule,
    HomeModule,
    SessionModule,
    ExplorerModule,
    AuthModule,
    UserProfileModule,
    AnalyticsModule,
    RootStoreModule,
    AppNotificationsModule,
    storeDevTools,
  ],

  providers: [
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: false,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => { },
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // }
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
