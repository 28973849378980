import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of as observableOf, from } from 'rxjs';
import { catchError, map, mergeMap, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { WebSocketService } from 'src/app/common/web-socket/web-socket.service';
import { RootStoreState } from 'src/app/root-store';
import { PresentationActions } from '.';
import { PresentationService } from '../presentation.service';
import { ViewManagerService } from '@common/views/view-manager.service';

@Injectable()
export class PresentationEffects {
  constructor(
    private actions$: Actions,
    private webSocketService: WebSocketService,
    private presentationService: PresentationService,
    private store: Store<RootStoreState.State>,
    private viewService: ViewManagerService,
  ) {}

  loadPresentation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PresentationActions.PresentationActionTypes.LOAD_PRESENTATION),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.presentationService.getPresentationByShowcaseId(action.showcaseId)).pipe(
          map((presentation) => {
            return PresentationActions.loadPresentationSuccess({ presentation });
          }),
          tap((res) => {
            const customViews = res?.presentation?.frames
              ?.filter((frame) => frame?.hasCustomViewDefinition && ['collection', 'grid'].includes(frame.type))
              .map((frame) => {
                return {
                  viewSlug: `showcase:${frame.type === 'grid' ? 'grid_frame' : 'item_details'}`,
                  contextReference: `presentation-frame:${frame.id}`,
                };
              });

            if (customViews?.length) {
              // load Frame Level custom view definitions for collection and grid frames
              // load showcase level default viewDefinitions in showcase-editor-mode-resolver.ts
              customViews.forEach((customView) => {
                this.viewService.getView(customView.viewSlug, null, customView.contextReference);
              });
            }
          }),
          catchError((error) => observableOf(PresentationActions.loadPresentationFailure({ error }))),
        );
      }),
    ),
  );
  loadPresentationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PresentationActions.PresentationActionTypes.LOAD_PRESENTATION_SUCCESS),
        tap(async (action: any) => {
          const presentation = action.presentation;
          const showcasePresentation = await this.presentationService.generateShowcasePresentation(presentation);
          this.store.dispatch(
            PresentationActions.setShowcasePresentation({ showcasePresentation: showcasePresentation }),
          );
          this.store.dispatch(
            PresentationActions.setCurrentShowcasePresentationPanel({
              showcasePresentationPanel: showcasePresentation.panels[0],
            }),
          );
        }),
      ),
    { dispatch: false },
  );
}
