<div class="flex items-center" *ngIf="sectionIndex === 0">
  <h6>{{ panelName }}</h6>
  <div class="total-count">{{ countTotal }}</div>
</div>

<div class="container">
  <div *ngIf="section.value !== 'placeholder'" class="title-container">
    <div class="count">{{ section.children.length }}</div>
    <div *ngIf="section.value !== 'placeholder'" class="title" [matTooltip]="section.label">
      {{ section.label }}
    </div>
  </div>

  <div class="w-full" *ngIf="section.children.length > 0">
    <div
      class="item-container"
      [attr.id]="section.value + '|' + i"
      *ngFor="let sectionGroup of sectionGroups; let i = index"
    >
      <ng-container *ngFor="let itemId of sectionGroup; let i = index">
        <div
          [attr.id]="frameId + '-' + itemId"
          class="option"
          data-test="item-grid-frame"
          (mouseenter)="mouseEnter(itemId)"
          (mouseleave)="mouseLeave()"
          (contextmenu)="onContextMenu($event, itemId)"
          (click)="openInfo(itemId)"
        >
          <div class="annotations" *ngIf="itemMap[itemId]">
            <mat-icon *ngFor="let annotation of itemMap[itemId]['annotations']" [svgIcon]="annotation"></mat-icon>
          </div>
          <div
            *ngIf="editorMode === 'EDIT' && focusedItemId && focusedItemId === itemId"
            class="absolute top-1 right-1"
          >
            <app-item-data-selection-toggle [itemData]="focusedItem"></app-item-data-selection-toggle>
          </div>
          <div
            class="thumbnail"
            [ngClass]="{ 'opacity-60': itemsMissingFromSource.includes(itemId) }"
            *ngIf="getThumbnail(itemId)"
          >
            <img [attr.src]="itemMap[itemId]['thumbnail'] | secureImage | async" />
            <div
              class="absolute"
              *ngIf="itemsMissingFromSource.includes(itemId)"
              matTooltip="Missing from source assortment"
            >
              <mat-icon svgIcon="warning-alert"></mat-icon>
            </div>
          </div>
          <div
            class="thumbnail-placeholder"
            [ngClass]="{ 'opacity-60': itemsMissingFromSource.includes(itemId) }"
            *ngIf="!getThumbnail(itemId)"
          >
            <div class="h-[56px] w-[56px]"><mat-icon class="text-[56px]">panorama</mat-icon></div>
            <div
              class="absolute"
              *ngIf="itemsMissingFromSource.includes(itemId)"
              matTooltip="Missing from source assortment"
            >
              <mat-icon svgIcon="warning-alert"></mat-icon>
            </div>
          </div>
          <ng-container *ngFor="let property of properties">
            <div
              *ngIf="property.propertyDefinition"
              class="option-label"
              [style.line-height.px]="property.style?.font?.size"
              [style.min-height.px]="property.style?.font?.size"
              [style.font-size.px]="property.style?.font?.size"
              [style.color]="property.style?.color"
              [matTooltip]="getPropertyValue(itemId, property)"
            >
              {{ property?.includeLabel ? property.propertyDefinition?.label + ': ' : ''
              }}{{ getPropertyValue(itemId, property) }}
            </div>
          </ng-container>
          <div class="absolute top-6 right-7">
            <app-presentation-item-pinned-comments
              [commentsAccessLevel]="editorMode"
              [frameId]="frameId"
              [item]="{ id: itemId }"
            ></app-presentation-item-pinned-comments>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div
  class="invisible fixed"
  id="optionMenuTrigger"
  #optionMenuTrigger
  [matMenuTriggerFor]="contextMenu"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
></div>
<mat-menu #contextMenu="matMenu" class="grid-item-context-menu" id="optionMenuTrigger">
  <ng-template matMenuContent let-item="item">
    <button
      class="context-menu-button"
      mat-menu-item
      (click)="addItemComment(item)"
      data-test="collection-item-add-comment"
    >
      <mat-icon class="icons" svgIcon="contrail_comment"></mat-icon>
      <span>Comment</span>
    </button>
  </ng-template>
</mat-menu>
