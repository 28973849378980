import { Component, OnInit, Input, ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { combineLatest, Observable, Subscription, fromEvent, ReplaySubject } from 'rxjs';
import { map, debounceTime, tap } from 'rxjs/operators';
import { CommentsSelectors } from 'src/app/common/comments/comments-store';
import { Comment } from 'src/app/common/comments/comments.service';
import { DocumentElement, PositionDefinition, DocumentElementEvent, SizeDefinition } from '@contrail/documents';
import { ShowcaseSelectors } from 'src/app/showcase/showcase-store';
import { Showcase } from 'src/app/showcase/showcase';
import { ShowPinnedCommentsListenerService } from './show-pinned-comments-listener-service';
import { AddPinnedCommentsService } from './add-pinned-comments.service';
// import { CanvasUtil } from '../canvas/canvas-util';
import { CanvasUtil } from '@contrail/canvas';

export interface PinnedComment {
  groupKey: string; // key by which comments are grouped, for example 'showcase:300:400', 'elementId:100:50'
  count: number;
  firstComment: Comment;
  style: any;
  documentElement?: {
    id: string;
    position: PositionDefinition;
  };
}

@Component({
  selector: 'app-presentation-pinned-comments',
  templateUrl: './presentation-pinned-comments.component.html',
  styleUrls: ['./presentation-pinned-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PresentationPinnedCommentsComponent implements OnInit {
  @Input() frameId: string;
  @Input() elements: Array<DocumentElement>;
  @Input() svgId: string;
  @Input() documentSize: SizeDefinition;
  @Input() editorMode = 'EDIT';

  private showcase: Showcase;
  private subscription: Subscription = new Subscription();
  private viewSize$ = new ReplaySubject<any>();
  private readonly PINNED_COMMENT_WIDTH = 34;

  public pinnedComments$: Observable<Array<PinnedComment>>;
  public viewSize: any;
  public showPinnedComments: boolean;

  constructor(
    private elementRef: ElementRef,
    private store: Store<RootStoreState.State>,
    private addPinnedCommentsService: AddPinnedCommentsService,
    private showPinnedCommentsListenerService: ShowPinnedCommentsListenerService,
  ) {}

  ngOnInit(): void {
    this.setDocumentSize();
    this.elementRef.nativeElement.classList.add('!visible');

    this.subscription.add(
      this.store.select(ShowcaseSelectors.currentShowcase).subscribe((showcase) => {
        this.showcase = showcase;
      }),
    );

    this.subscription.add(
      fromEvent(window, 'resize')
        .pipe(
          tap(() => this.elementRef.nativeElement.classList.remove('!visible')),
          debounceTime(800),
        )
        .subscribe(() => {
          this.elementRef.nativeElement.classList.add('!visible');
          this.setDocumentSize();
        }),
    );

    this.subscription.add(
      this.store.select(CommentsSelectors.showPinnedComments).subscribe((showPinnedComments) => {
        if (showPinnedComments) {
          this.elementRef.nativeElement.classList.add('!visible');
        } else {
          this.elementRef.nativeElement.classList.remove('!visible');
        }
      }),
    );

    this.initPinnedComments();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes) {
    if (changes?.frameId?.currentValue !== changes?.frameId?.previousValue) {
      // Full screen mode
      this.initPinnedComments();
    }
  }

  private setDocumentSize() {
    const svgElementRect = document.getElementById(this.svgId).getBoundingClientRect();
    this.viewSize = {
      viewScale: {
        x: svgElementRect.width / this.documentSize.width,
        y: svgElementRect.height / this.documentSize.height,
      },
      viewBox: { x: 0, y: 0 },
      boundingClientRect: { x: 0, y: 0 },
    };
    this.viewSize$.next(this.viewSize);
  }

  // Pinned comments - combine latest:
  // - all comments
  // - when elements are dragged (need to change comments position)
  // - when elements are deleted (need to hide elements from the showcase)
  // Filter comments that have either documentElementId or documentPosition
  // Group comments by each position on the screen or element
  // showcase:500:400, elementId:100:200, etc
  private initPinnedComments(event?: DocumentElementEvent) {
    this.pinnedComments$ = combineLatest(
      this.store.select(CommentsSelectors.selectContextComments),
      this.viewSize$,
    ).pipe(
      map(([comments]) => {
        return comments.filter(
          (comment) =>
            comment.status !== 'closed' &&
            (comment.documentElementId || comment.documentPosition) &&
            comment?.subContextReference === `presentation-frame:${this.frameId}`,
        );
      }),
      map((comments) => Object.values(this.groupByDocumentElement(comments, event))),
    );
  }

  /**
   * Group a list of @comments into buckets of each comment group. For example:
   * 'showcase:300:500': {},
   * 'showcase:100:500': {},
   * 'elementId123:50:300': {},
   * 'elementId123:150:200': {},
   * 'elementId456:100:20': {}
   * @param comments
   * @param event
   */
  private groupByDocumentElement(comments: Array<Comment>, event?: DocumentElementEvent) {
    return comments.reduce((counter, comment) => {
      const groupKey = `${comment.documentElementId || 'showcase'}:${comment.documentPosition.x},${comment.documentPosition.y}`;
      if (counter.hasOwnProperty(groupKey)) {
        counter[groupKey].count = counter[groupKey].count + 1;
      } else {
        const element = this.getDocumentElement(comment.documentElementId);
        if (comment.documentElementId && element === undefined) {
          // if element was deleted do not show the comment on the showcase
          return counter;
        }
        counter[groupKey] = {
          groupKey,
          count: 1,
          firstComment: comment,
          documentElement: element
            ? {
                id: element.id,
                position:
                  event && event.element.id === element.id && event.renderedElementPosition
                    ? event.renderedElementPosition
                    : element.position,
              }
            : null,
        };
        counter[groupKey].style = this.getStyle(counter[groupKey].firstComment, counter[groupKey].documentElement);
      }
      return counter;
    }, {});
  }

  private getDocumentElement(documentElementId?: string): DocumentElement {
    return documentElementId ? (this.elements || []).find((element) => element.id === documentElementId) : null;
  }

  private toWindowPosition(position: PositionDefinition): PositionDefinition {
    return CanvasUtil.toWindowPosition(
      position.x,
      position.y,
      this.viewSize.viewBox,
      this.viewSize.viewScale,
      this.viewSize.boundingClientRect,
    );
  }

  /**
   * Get top and left style for the comment bubble
   * @param comment
   * @param documentElement
   */
  getStyle(comment: Comment, documentElement?: { id: string; position: PositionDefinition }) {
    const position = {
      x: comment.documentPosition.x + (documentElement ? documentElement.position.x : 0),
      y: comment.documentPosition.y + (documentElement ? documentElement.position.y : 0),
    };
    const windowPosition: PositionDefinition = this.toWindowPosition(position);
    return {
      top: `${windowPosition.y - this.PINNED_COMMENT_WIDTH}px`,
      left: `${windowPosition.x}px`,
    };
  }

  /**
   * Show comment overlay with comment bubble is clicked
   * @param comment
   * @param documentElement
   */
  showComments(comment: Comment, documentElement?: { id: string; position: PositionDefinition }) {
    const commentOverlayDocumentPosition = {
      x: comment.documentPosition.x + (documentElement ? documentElement.position.x : 0),
      y: comment.documentPosition.y + (documentElement ? documentElement.position.y : 0),
    };
    this.addPinnedCommentsService.addCommentToDocumentElement(
      {
        entityType: 'showcase',
        id: this.showcase.id,
        documentPosition: comment.documentPosition,
        documentElementId: comment.documentElementId,
        subContextReference: comment.subContextReference,
      },
      commentOverlayDocumentPosition,
      this.svgId,
      this.editorMode,
    );
  }

  trackByGroupKey(index, item) {
    return item.groupKey;
  }
}
