<div
  (click)="focusItemColor($event)"
  id="{{ itemColor.id }}"
  class="itemColor"
  (contextmenu)="onContextMenu($event, itemColor.id)"
>
  <div class="top-widgets">
    <div class="annotations">
      <mat-icon *ngFor="let annotation of itemColor['annotations']" [svgIcon]="annotation"></mat-icon>
    </div>
    <div class="mt-0.5 mr-0.5"><ng-template dataSelectionToggle #vv></ng-template></div>
  </div>

  <div *ngIf="image" class="image-holder" [ngClass]="{ 'opacity-60': itemMissingFromSource }">
    <img [attr.src]="image | secureImage | async" />
    <div class="absolute" *ngIf="itemMissingFromSource" matTooltip="Missing from source assortment">
      <mat-icon svgIcon="warning-alert"></mat-icon>
    </div>
  </div>
  <div class="image-placeholder" *ngIf="!image" [ngClass]="{ 'opacity-60': itemMissingFromSource }">
    <div class="h-14 w-14"><mat-icon>panorama</mat-icon></div>
    <div class="absolute" *ngIf="itemMissingFromSource" matTooltip="Missing from source assortment">
      <mat-icon svgIcon="warning-alert"></mat-icon>
    </div>
  </div>
  <div class="meta">
    <div class="option-name">
      {{ itemColor?.properties.optionName }}
      <img
        src="\assets\primary_color_indicator.png"
        *ngIf="itemColor?.properties.primary === 'Yes'"
        class="primary_color_indicator"
      />
    </div>
    <div class="item-meta">
      <app-property-value
        *ngFor="let prop of metaViewDefinition?.properties"
        [value]="itemColor?.properties[prop.slug]"
        [property]="prop.propertyDefinition"
        [propertyType]="prop.propertyType"
      ></app-property-value>
    </div>
  </div>
  <div class="absolute top-6 right-7">
    <ng-template itemPinnedComments #comments></ng-template>
  </div>
</div>
<div
  class="invisible fixed"
  [style.left]="contextMenuPosition.x"
  #optionMenuTrigger
  id="optionMenuTrigger"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>
<mat-menu #contextMenu="matMenu" class="collection-item-context-menu" id="optionMenuTrigger">
  <ng-template matMenuContent let-item="item">
    <button
      class="context-menu-button"
      mat-menu-item
      (click)="addItemComment(itemColor.id, $event)"
      data-test="collection-item-add-comment"
    >
      <mat-icon class="icons" svgIcon="contrail_comment"></mat-icon>
      <span>Comment</span>
    </button>
    <button
      class="context-menu-button"
      mat-menu-item
      (click)="viewItem(itemColor.id, $event)"
      data-test="collection-item-add-comment"
    >
      <mat-icon class="icons">info</mat-icon>
      <span>Details</span>
    </button>
  </ng-template>
</mat-menu>
