import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { ComponentsModule } from '../common/components/components.module';
import { SessionModule } from '../session/session.module';
import { PresentationComponent } from './presentation.component';
import { PresentationFramePanel } from './presentation-panel/presentation-panel.component';
import { PresentationCollectionPanelComponent } from './presentation-panel/presentation-collection-panel/presentation-collection-panel.component';
import { PresentationSvgPanelComponent } from './presentation-panel/presentation-svg-panel/presentation-svg-panel.component';
import { VisibilityDirective } from './presentation-panel/visibility-directive';
import { PresentationNavigatorComponent } from './presentation-navigator/presentation-navigator.component';
import { PresentationItemWidgetComponent } from './presentation-item-widget/presentation-item-widget.component';
import { CommentsModule } from '../common/comments/comments.module';
import { PresentationHotSpotWidgetComponent } from './presentation-hot-spot-widget/presentation-hot-spot-widget.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ItemPresentationModule } from '../common/item-presentation/item-presentation.module';
import { AssortmentsModule } from '../common/assortments/assortments.module';
import { PresentationGridPanelComponent } from './presentation-panel/presentation-grid-panel/presentation-grid-panel.component';
import { PresentationGridSectionComponent } from './presentation-panel/presentation-grid-panel/presentation-grid-section/presentation-grid-section.component';
import { PipesModule } from '@common/pipes/pipes.module';
import { PresentationPinnedCommentsComponent } from './presentation-pinned-comments/presentation-pinned-comments.component';
import { PresentationContextMenuComponent } from './presentation-context-menu/presentation-context-menu.component';
import { PresentationItemPinnedComments } from './presentation-item-pinned-comments/presentation-item-pinned-comments.component';
import { ItemsModule } from '@common/items/items.module';
import { PresentationCommentsListComponent } from './presentation-comments/presentation-comments-list.component';
import { PresentationIframePanelComponent } from './presentation-panel/presentation-iframe-panel/presentation-iframe-panel.component';
import { ExportsModule } from '@common/exports/exports.module';
import { DocumentModule } from './document/document.module';
import { PresentationService } from './presentation.service';

@NgModule({
  declarations: [
    VisibilityDirective,
    PresentationComponent,
    PresentationFramePanel,
    PresentationCollectionPanelComponent,
    PresentationGridPanelComponent,
    PresentationGridSectionComponent,
    PresentationSvgPanelComponent,
    PresentationNavigatorComponent,
    PresentationItemWidgetComponent,
    PresentationHotSpotWidgetComponent,
    PresentationPinnedCommentsComponent,
    PresentationContextMenuComponent,
    PresentationItemPinnedComments,
    PresentationCommentsListComponent,
    PresentationIframePanelComponent,
  ],
  imports: [
    CommonModule,
    ItemsModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTabsModule,
    MatDividerModule,
    SessionModule,
    ComponentsModule,
    CommentsModule,
    MatTooltipModule,
    MatBadgeModule,
    MatSidenavModule,
    RouterModule,
    ItemPresentationModule,
    AssortmentsModule,
    ExportsModule,
    PipesModule,
    DocumentModule,
  ],
  exports: [PresentationItemPinnedComments, PresentationCommentsListComponent],
  providers: [PresentationService],
})
export class PresentationModule {}
