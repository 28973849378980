import { Injectable } from '@angular/core';
import { Entities, EntityReference } from '@contrail/sdk';
import { WorkspaceEntityHelper } from './workspace-entity-helper';
import { Workspace } from './workspaces-store/workspaces.state';

@Injectable({
  providedIn: 'root',
})
export class WorkspacesService {
  constructor() {}

  public async getWorkspaces() {
    const workspaces = await new Entities().get({
      entityName: 'workspace',
      relations: ['workspacePrincipals'],
      criteria: { isArchived: false },
    });
    workspaces.sort((ws1, ws2) => (ws1.name > ws2.name ? 1 : -1));
    return workspaces;
  }
  public async getWorkspaceById(id: string) {
    return new Entities().get({ entityName: 'workspace', id, relations: ['workspacePrincipals'] });
  }
  public async createWorkspace(workspace: Workspace) {
    return new Entities().create({ entityName: 'workspace', object: workspace });
  }
  public async deleteWorkspace(workspace: Workspace) {
    await new Entities().delete({ entityName: 'workspace', id: workspace.id });
    return workspace;
  }
  public async updateWorkspace(id: string, changes: Workspace) {
    return new Entities().update({ entityName: 'workspace', id, object: changes });
  }

  public async getWorkspaceEntities(workspace: Workspace) {
    const workspaceEntities = await new Entities().get({
      entityName: 'workspace-entity',
      criteria: { workspaceId: workspace.id, isTrashed: false },
      relations: ['entity'],
    });
    WorkspaceEntityHelper.postProcessWorkspaceEntities(workspaceEntities, workspace);
    return workspaceEntities;
  }
  public async getWorkspaceFolders(workspace: Workspace) {
    const workspaceEntities = await new Entities().get({
      entityName: 'workspace-entity',
      criteria: { workspaceId: workspace.id, entityType: 'workspace' },
      relations: ['entity'],
    });
    WorkspaceEntityHelper.postProcessWorkspaceEntities(workspaceEntities, workspace);
    return workspaceEntities;
  }
}
