import { createAction, props } from '@ngrx/store';
import { ChangeSuggestion, Comment, CommentOwnerInfo } from '../comments.service';
import { CommentsActions } from '.';

export enum CommentsActionTypes {
  LOAD_COMMENTS = '[Comments] Load Comments',
  LOAD_COMMENTS_SUCCESS = '[Comments] Load Comments Success',
  LOAD_COMMENTS_FAILURE = '[Comments] Load Comments Failure',

  CREATE_COMMENT = '[Comments] Create Comment',
  CREATE_COMMENT_SUCCESS = '[Comments] Create Comment Success',
  CREATE_COMMENT_FAILURE = '[Comments] Create Comment Failure',

  DELETE_COMMENT = '[Comments] Delete Comment',
  DELETE_COMMENT_SUCCESS = '[Comments] Delete Comment Success',
  DELETE_COMMENT_FAILURE = '[Comments] Delete Comment Failure',

  UPDATE_COMMENT = '[Comments] Update Comment',
  UPDATE_COMMENT_SUCCESS = '[Comments] Update Comment Success',
  UPDATE_COMMENT_FAILURE = '[Comments] Update Comment Failure',

  ADD_COMMENT = '[Comments] Add Comment',
  MODIFY_COMMENT = '[Comments] Modify Comment',
  REMOVE_COMMENT = '[Comments] Remove Comment',

  TOGGLE_COMMENTS_SIDE_BAR = '[Comments] Toggle Comments Side Bar',

  SHOW_COMMENT_OVERLAY = '[Comments] Show Comment Overlay',
  HIDE_COMMENT_OVERLAY = '[Comments] Hide Comment Overlay',
  UPDATE_COMMENT_OVERLAY_POSITION = '[Comments] Update Comment Overlay Position',

  ACCEPT_COMMENT = '[Comments] Accept Comment',
  REJECT_COMMENT = '[Comments] Reject Comment',
  REOPEN_COMMENT = '[Comments] Reopen Comment',

  SET_COMMENTS_ACCESS_LEVEL = '[Comments] Set Comments Access Level',

  SELECT_COMMENT = '[Comments] Select Comment',

  SET_SHOW_PINNED_COMMENTS = '[Comments] Set Show Pinned Comments',
}
export const loadComments = createAction(CommentsActionTypes.LOAD_COMMENTS, props<{ contextReference: string }>());

export const loadCommentsSuccess = createAction(CommentsActionTypes.LOAD_COMMENTS_SUCCESS, props<{ data: any }>());

export const loadCommentsFailure = createAction(CommentsActionTypes.LOAD_COMMENTS_FAILURE, props<{ error: any }>());

export const createComment = createAction(
  CommentsActionTypes.CREATE_COMMENT,
  props<{ text: string; ownerInfo: CommentOwnerInfo; changeSuggestion?: ChangeSuggestion }>(),
);

export const createCommentSuccess = createAction(
  CommentsActionTypes.CREATE_COMMENT_SUCCESS,
  props<{ comment: Comment }>(),
);

export const createCommentFailure = createAction(CommentsActionTypes.CREATE_COMMENT_FAILURE, props<{ error: any }>());

export const updateComment = createAction(CommentsActionTypes.UPDATE_COMMENT, props<{ comment: Comment }>());

export const updateCommentSuccess = createAction(
  CommentsActionTypes.UPDATE_COMMENT_SUCCESS,
  props<{ comment: Comment }>(),
);

export const modifyComment = createAction(CommentsActionTypes.MODIFY_COMMENT, props<{ comment: Comment }>());

export const updateCommentFailure = createAction(CommentsActionTypes.UPDATE_COMMENT_FAILURE, props<{ error: any }>());

export const deleteComment = createAction(CommentsActionTypes.DELETE_COMMENT, props<{ comment: Comment }>());

export const deleteCommentSuccess = createAction(
  CommentsActionTypes.DELETE_COMMENT_SUCCESS,
  props<{ comment: Comment }>(),
);
export const removeComment = createAction(CommentsActionTypes.REMOVE_COMMENT, props<{ comment: Comment }>());

export const deleteCommentFailure = createAction(CommentsActionTypes.DELETE_COMMENT_FAILURE, props<{ error: any }>());

export const addComment = createAction(CommentsActionTypes.ADD_COMMENT, props<{ comment: Comment }>());
export const showCommentOverlay = createAction(
  CommentsActionTypes.SHOW_COMMENT_OVERLAY,
  props<{ ownerInfo: CommentOwnerInfo; position: { x: number; y: number } }>(),
);
export const updateCommentOverlayPosition = createAction(
  CommentsActionTypes.UPDATE_COMMENT_OVERLAY_POSITION,
  props<{ position: { x: number; y: number } }>(),
);
export const hideCommentOverlay = createAction(CommentsActionTypes.HIDE_COMMENT_OVERLAY);

export const toggleCommentsSideBar = createAction(CommentsActionTypes.TOGGLE_COMMENTS_SIDE_BAR);

export const acceptComment = createAction(CommentsActionTypes.ACCEPT_COMMENT, props<{ comment: Comment }>());

export const rejectComment = createAction(CommentsActionTypes.REJECT_COMMENT, props<{ comment: Comment }>());

export const reopenComment = createAction(CommentsActionTypes.REOPEN_COMMENT, props<{ comment: Comment }>());

export const setCommentsAccessLevel = createAction(
  CommentsActionTypes.SET_COMMENTS_ACCESS_LEVEL,
  props<{ commentsAccessLevel: string }>(),
);

export const selectComment = createAction(CommentsActionTypes.SELECT_COMMENT, props<{ comment: Comment }>());

export const setShowPinnedComments = createAction(
  CommentsActionTypes.SET_SHOW_PINNED_COMMENTS,
  props<{ showPinnedComments: boolean }>(),
);
