<app-presentation-item-details
  [dataSelectionToggleComponent]="dataSelectionToggleComponent"
  [itemPinnedCommentsComponent]="itemPinnedCommentsComponent"
  [includeCommentWidget]="includeCommentWidget"
  [presentation]="presentation"
  [itemData]="itemData$ | async"
  [itemPresentationElement]="itemPresentationElement"
  [itemsMissingFromSource]="itemsMissingFromSource"
  [visible]="visible"
  [frameId]="panel?.frame.id"
  [hasCustomViewDefinition]="panel?.frame?.hasCustomViewDefinition"
  [editorMode]="editorMode$ | async"
  (menuEvent)="handleMenuEvent($event)"
></app-presentation-item-details>
