import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ItemsService } from '../items.service';
import { ItemModalLauncher } from '../item-modal-launcher';
import { ItemData } from 'src/app/common/item-data/item-data';

@Component({
  selector: 'app-item-list-item',
  templateUrl: './item-list-item.component.html',
  styleUrls: ['./item-list-item.component.scss'],
})
export class ItemListItemComponent implements OnInit {
  constructor(
    private itemsService: ItemsService,
    private modalLauncher: ItemModalLauncher,
  ) {}

  @Input()
  itemData: ItemData;

  isLoading = false;

  ngOnInit(): void {
    this.isLoading = false;
  }

  getImageUrl() {
    return this.itemsService.getImageUrl(this.itemData.properties.thumbnail);
  }

  openModal() {
    this.modalLauncher.openModal(this.itemData);
  }
  handleInProgressOverlay(event) {
    console.log(`handleInProgressOverlay ${event}`);
    this.isLoading = event;
  }
}
