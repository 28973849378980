import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/common/analytics/analytics.service';
import { ListModalLauncher } from 'src/app/session/save-list/save-list-modal-launcher';
import { ListAction, SessionService } from 'src/app/session/session.service';
import { SessionAssortmentsActions, SessionAssortmentsSelectors } from '../session-assortments-store';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { Observable } from 'rxjs';
import { Assortment } from 'src/app/common/assortments/assortment';
import { EVENT_CATEGORY } from '@common/analytics/user-analytics.service';
import { Showcase } from 'src/app/showcase/showcase';
import { ShowcaseSelectors } from 'src/app/showcase/showcase-store';

@Component({
  selector: 'app-current-assortment-selector',
  templateUrl: './current-assortment-selector.component.html',
  styleUrls: ['./current-assortment-selector.component.scss'],
  // animations: [
  //   trigger('isCurrentAssortUpdated', [
  //     // ...
  //     transition('* => completed', [
  //       animate('0.4s ease-out', style({ transform: 'scale(1.0)' }))
  //     ]),
  //     transition('* => init', [
  //       animate('0.2s ease-in', style({ transform: 'scale(1.05)' }))
  //     ]),
  //   ])]
})
export class CurrentAssortmentSelectorComponent implements OnInit {
  @Input() params: any = {
    embedded: false,
    displayCount: true,
    notifyMeOfAssortSelection: false,
  };
  public sessionAssortments$: Observable<Array<any>>;
  public currentAssortment$: Observable<Assortment>;
  public selectedAssortmentData: Assortment;
  public itemDataCount = 0;
  public showcase: Showcase;

  constructor(
    private sessionService: SessionService,
    private listModalLauncher: ListModalLauncher,
    private analyticsService: AnalyticsService,
    private store: Store<RootStoreState.State>,
  ) {}
  ngOnInit() {
    this.sessionAssortments$ = this.store.select(SessionAssortmentsSelectors.selectSessionAssortments);
    this.currentAssortment$ = this.sessionService.currentAssortment;
    this.store.select(ShowcaseSelectors.currentShowcase).subscribe((showcase) => {
      this.showcase = showcase;
    });
  }
  async selectAssortment(sessionAssortment) {
    this.store.dispatch(SessionAssortmentsActions.setCurrentSessionAssortment({ sessionAssortment }));
    this.analyticsService.emitEvent({
      eventName: 'SELECT_ASSORTMENT',
      eventCategory: EVENT_CATEGORY.OTHER,
      eventTarget: `session-assortment:${sessionAssortment.id}`,
      eventContext: `showcase:${this.showcase.id}`,
    });
  }

  createNewAssortment() {
    const params = {
      action: ListAction.CREATE,
      actionLabel: 'created',
      modalTitle: 'Add a New Assortment',
    };
    this.listModalLauncher.openModal(params);
  }
}
