import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BodyComponent } from './body/body.component';
import { MainComponent } from './main.component';
import { AuthModule } from '../common/auth/auth.module';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatCommonModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrgSelectorComponent } from './header/org-selector/org-selector.component';
import { HeaderUserMenuComponent } from './header/header-user-menu/header-user-menu.component';
import { RouterModule } from '@angular/router';
import { PageHeaderComponent } from './header/page-header/page-header.component';
import { PageHeaderService } from './header/page-header/page-header.service';
import { HelpSelectorComponent } from './header/header-help/help-selector.component';
import { DownloadContentIndicatorComponent } from './header/download/download-content-indicator/download-content-indicator.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatBadgeModule } from '@angular/material/badge';
import { ComponentsModule } from '../common/components/components.module';
import { CommentsModule } from '../common/comments/comments.module';
import { DomSanitizer } from '@angular/platform-browser';
import { HeaderNavComponent } from './header/header-nav/header-nav.component';
import { UserSessionsModule } from '../common/user-sessions/user-sessions.module';
import { LoadingIndicatorModule } from '../common/loading-indicator/loading-indicator.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { PresentationModule } from '../presentation/presentation.module';
import { UserProfileModule } from '@common/user-profile/user-profile.module';
import { CustomFontsModule } from '@common/custom-fonts/custom-fonts.module';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    BodyComponent,
    MainComponent,
    OrgSelectorComponent,
    HeaderUserMenuComponent,
    PageHeaderComponent,
    HelpSelectorComponent,
    DownloadContentIndicatorComponent,
    HeaderNavComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AuthModule,
    MatCommonModule,
    BrowserAnimationsModule,
    MatListModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatDividerModule,
    MatTooltipModule,
    ComponentsModule,
    CommentsModule,
    UserSessionsModule,
    LoadingIndicatorModule,
    PresentationModule,
    UserProfileModule,
    CustomFontsModule,
  ],
  providers: [PageHeaderService],
  exports: [MainComponent, BodyComponent],
})
export class MainModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.registerIcons();
  }
  async registerIcons() {
    this.registerIcon('contrail_showcase', '../assets/images/showroom_icon.svg');
    this.registerIcon('contrail_compass', '../assets/images/compass_icon.svg');
    this.registerIcon('contrail_feedback', '../assets/images/feedback_icon.svg');
    this.registerIcon('contrail_media', '../assets/images/media_icon.svg');
    this.registerIcon('contrail_canvas', '../assets/images/canvas_icon.svg');
    this.registerIcon('contrail_assortment', '../assets/images/assortment.svg');
  }
  registerIcon(name, path) {
    this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
  }
}
