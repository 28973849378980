import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { CommentsActions, CommentsSelectors } from '../comments-store';
import { Comment, CommentOwnerInfo, CommentsService } from '../comments.service';

@Component({
  selector: 'app-comment-overlay',
  template: ` <div
    class="overlay"
    #commentoverlay
    [ngClass]="{ visible: (showCommentOverlay$ | async) }"
    [ngStyle]="{ width: this.commentOverlayWidth + 'px' }"
    [style]="getStyle(commentOverlayPosition$ | async)"
  >
    <app-comments-card
      [accessLevel]="accessLevel$ | async"
      [comment]="comment"
      [ownerInfo]="ownerInfo$ | async"
      [disableShowComment]="true"
      *ngFor="let comment of commentSet$ | async"
    >
      {{ comment.text }}
    </app-comments-card>
    <app-comment-form
      *ngIf="(accessLevel$ | async) !== 'VIEW'"
      [ownerInfo]="ownerInfo$ | async"
      isCommentOverlay="true"
    ></app-comment-form>
  </div>`,
  styles: [
    `
      .overlay {
        display: none;
        width: 100%;
        position: fixed;
        background-color: white;
        padding: 10px;
        border-radius: 8px !important;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 22px !important;
        z-index: 1000;
      }
      .visible {
        display: block;
      }
    `,
  ],
})
export class CommentOverlay implements OnInit, OnDestroy {
  showCommentOverlay$: Observable<boolean>;
  commentOverlayPosition$: Observable<{ x: number; y: number }>;
  commentSet$: Observable<Array<Comment>>;
  ownerInfo$: Observable<CommentOwnerInfo>;
  accessLevel$: Observable<string>;
  public showOverlay = false;
  public commentOverlayWidth = 400;

  private validUXNavElements = ['mat-button', 'mat-option', 'mat-menu', 'mat-calendar', 'comment-color-chip'];

  private showResolvedComments = false;

  @ViewChild('commentoverlay') commentOverlayEl: ElementRef;

  constructor(
    private store: Store<RootStoreState.State>,
    private elementRef: ElementRef,
  ) {
    this.showCommentOverlay$ = store.select(CommentsSelectors.showCommentOverlay);
    this.commentOverlayPosition$ = store.select(CommentsSelectors.commentOverlayPosition);
    this.ownerInfo$ = this.store.select(CommentsSelectors.commentOwnerInfo);
    this.accessLevel$ = this.store.select(CommentsSelectors.commentsAccessLevel);
    this.commentSet$ = combineLatest([
      this.ownerInfo$,
      this.store.select(CommentsSelectors.selectContextComments),
    ]).pipe(
      map(([ownerInfo, comments]) => {
        console.log(ownerInfo, comments);
        return comments.filter((comment) => {
          if (!ownerInfo) {
            return [];
          }
          let showComment = true;
          if (!this.showResolvedComments) {
            showComment = !(comment.status === 'closed');
          }

          return showComment && CommentsService.filterByOwnerInfo(comment, ownerInfo);
        });
      }),
    );
  }

  private subscription: Subscription = new Subscription();
  ngOnInit() {
    this.subscription.add(
      this.showCommentOverlay$.subscribe((bool) =>
        setTimeout(() => {
          this.showOverlay = bool;
        }, 200),
      ),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  @HostListener('document:click', ['$event'])
  click(event) {
    this.checkClose(event);
  }
  @HostListener('document:contextmenu', ['$event'])
  contextmenu(event) {
    this.checkClose(event);
  }
  checkClose(event) {
    const src = event.srcElement;
    // console.log('src.className: ', src.getAttribute('class'));
    // DONT CLOSE IF CLICKING ON A MENU / SELECT

    const validNavs = this.validUXNavElements.filter((ele) => src.getAttribute('class')?.indexOf(ele) > -1);

    if (validNavs?.length) {
      return;
    }
    if (this.showOverlay && !this.elementRef.nativeElement.contains(event.target)) {
      this.store.dispatch(CommentsActions.hideCommentOverlay());
      this.store.dispatch(CommentsActions.selectComment({ comment: null }));
    }
  }

  /**
   * Adjust @commentOverlayPosition if it is too close to the edges of the document
   * @param commentOverlayPosition
   */
  getStyle(commentOverlayPosition) {
    if (this.showOverlay) {
      let position = { y: commentOverlayPosition?.y, x: commentOverlayPosition?.x };
      const commentOverlayHeight = this.commentOverlayEl?.nativeElement?.offsetHeight;
      if (
        commentOverlayHeight &&
        commentOverlayPosition?.y + commentOverlayHeight > window.innerHeight &&
        commentOverlayPosition?.y > commentOverlayHeight
      ) {
        position.y = position.y - commentOverlayHeight;
      }
      if (commentOverlayPosition?.x + this.commentOverlayWidth > window.innerWidth) {
        position.x = position.x - this.commentOverlayWidth - 2;
      }
      return { top: `${position?.y}px`, left: `${position?.x}px` };
    }
  }
}
