<form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
  <mat-form-field class="inputField">
    <input matInput type="text" placeholder="Assortment Name" formControlName="name" id="name" />
    <mat-error *ngIf="hasError('name', 'required')">Name is required</mat-error>
    <mat-error *ngIf="hasError('name', 'maxlength')">You have more than 60 characters</mat-error>
  </mat-form-field>
  <div class="actions">
    <button mat-raised-button color="primary" type="submit" [disabled]="isSubmitted || !form.valid">OK</button>
  </div>

  <app-mat-spinner [overlay]="true" [loading]="isSubmitted"></app-mat-spinner>
</form>
