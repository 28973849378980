import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { SessionAssortment, SessionService } from '../session/session.service';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { RootStoreState } from '../root-store';
import { SessionAssortmentsActions, SessionAssortmentsSelectors } from '../session/session-assortments-store';
import { Assortment } from '../common/assortments/assortment';
import { ObjectUtil } from '@contrail/util';

@Injectable({
  providedIn: 'root',
})
export class GridBoardService {
  private sessionAssortment: SessionAssortment;
  private layoutConfig;
  private dashboard;

  private layoutChangeSubject = new BehaviorSubject(null);
  public layoutChangeObservable = this.layoutChangeSubject.asObservable();

  private dashboardSubject = new BehaviorSubject(null);
  public dashboardObservable = this.dashboardSubject.asObservable();

  constructor(private store: Store<RootStoreState.State>) {
    this.dashboardObservable.subscribe((dashboard) => {
      this.dashboard = dashboard;
    });
    this.store.select(SessionAssortmentsSelectors.selectCurrentSessionAssortment).subscribe((sessionAssortment) => {
      if (sessionAssortment) {
        this.sessionAssortment = sessionAssortment;
        this.layoutConfig = Object.assign({}, this.sessionAssortment.layoutConfiguration);
      }
    });
    this.layoutChangeObservable
      .pipe(
        tap((value) => console.log('layoutChangeObservable', value)),
        debounceTime(2000),
      )
      .subscribe((value) => {
        if (value) {
          this.saveLayout();
        }
      });
  }
  public setItemCoordinates(id, coordinates: { x; y }) {
    if (!this.layoutConfig || !this.sessionAssortment) {
      return;
    }
    const currentCoords = this.getItemCoordinates(id);
    if (currentCoords && currentCoords.x === coordinates.x && currentCoords.y === coordinates.y) {
      return;
    }
    this.layoutConfig[id] = coordinates;
    // this.sessionAssortment.layoutConfiguration = this.layoutConfig;
    this.layoutChangeSubject.next(this.layoutConfig);
  }

  public removeItemCoordinates(id) {
    if (!this.layoutConfig || !this.sessionAssortment) {
      return;
    }
    delete this.layoutConfig[id];
    // this.sessionAssortment.layoutConfiguration = this.layoutConfig;
    this.layoutChangeSubject.next(this.layoutConfig);
  }

  public getItemCoordinates(id) {
    if (!this.layoutConfig) {
      return { x: 0, y: 0 };
    }
    return this.layoutConfig[id];
  }

  private async saveLayout() {
    console.log('grid-board service: save layout');
    console.log(this.sessionAssortment);
    const sessionAssortment = ObjectUtil.cloneDeep(this.sessionAssortment);
    delete sessionAssortment.createdOn;
    delete sessionAssortment.updatedOn;
    const object = {
      ...sessionAssortment,
      layoutConfiguration: Object.assign({}, this.layoutConfig),
    };
    // POTENTIALLY UPDATE TO A NEW 'LAYOUT UPDATE' ACTION THAT DOESN'T RESET THE ASSORTMENT
    this.store.dispatch(SessionAssortmentsActions.updateSessionAssortment({ sessionAssortment: object }));
  }

  public async setDashboard(dashboard) {
    this.dashboardSubject.next(dashboard);
  }

  removeItem(itemId) {
    this.removeItemCoordinates(itemId);
    return;
  }
  handleDroppedItem(itemId) {
    if (itemId) {
      // Check is already on the grid
      const existingItem = this.dashboard.find((dbItem) => {
        return dbItem.item.id === itemId;
      });
      this.setItemCoordinates(itemId, { x: existingItem.x, y: existingItem.y });
    }
  }
}
