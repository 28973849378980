<div class="session-wrap flex justify-between w-full h-full p-3">
  <div class="list-section overflow-x-auto" (dragover)="dragOver($event)" (drop)="dropItem($event)">
    <mat-toolbar class="toolbar">
      <div class="tool-bar-actions flex-between-center">
        <app-current-assortment-selector [params]="current_assort_selector_params"></app-current-assortment-selector>
        <a mat-button value="underline" (click)="initListEdit()" aria-label="editMyList" title="Edit">
          <mat-icon>edit</mat-icon>
        </a>
        <a mat-button value="underline" (click)="initNewList()" aria-label="createMyList" title="Create">
          <mat-icon>add</mat-icon>
        </a>
        <a mat-button value="underline" (click)="initListCopy()" aria-label="copyList" title="Copy">
          <mat-icon>file_copy</mat-icon>
        </a>
        <a mat-button value="underline" (click)="initListDelete()" aria-label="deleteList" title="Delete">
          <mat-icon>delete_forever</mat-icon>
        </a>
        <a mat-button value="underline" (click)="downloadExcel()" aria-label="downloadExcel" title="DownloadExcel">
          <mat-icon>download</mat-icon> Export to Excel
          <app-download-content-snack class="fixed bottom-3 right-6 z-[60]" />
        </a>
        <!-- <a mat-button value="underline" (click)="downloadPDF()" aria-label="downloadCSV" title="DownloadPDF">
          <mat-icon>picture_as_pdf</mat-icon> Export to PDF
        </a> -->
      </div>

      <!-- ALERT at bottom right -->

      <!-- <div style="font-family: Roboto, Arial, sans-serif; font-size: 11pt;">
        {{currentAssortmentItemData?.length}} Products
      </div> -->
    </mat-toolbar>

    <app-grid-board
      (removeItemEvent)="removeItem($event)"
      [addedItems]="addedItems"
      [items]="currentAssortmentItemData"
    >
    </app-grid-board>
  </div>

  <div class="w-[320px]">
    <app-item-data-chooser
      #itemDataChooser
      [allowSourceChange]="false"
      [showHeader]="false"
      [showFilter]="true"
      [allowAddEntity]="false"
      [allowAddDuplicate]="false"
      resultsHeight="100%"
      [draggable]="true"
      (addEntity)="dropItem($event)"
    ></app-item-data-chooser>
  </div>
</div>
