<div class="placeholder" *ngIf="dashboard.length === 0">
  <div class="title">Let's build an assortment!</div>
  <img class="graphic" src="../../assets/images/empty_state_graphic.svg" />
  <div class="message">
    <p>
      Build a custom assortments and export it to CSV or PDF. You can drag and drop products and re arrange them on the
      screen. Read
      <a
        href="https://intercom.help/archergrey/en/articles/4490391-working-with-lists"
        target="”_blank”"
        class="underline"
        >this</a
      >
      article for more information on how to work with custom assortments
    </p>
  </div>
</div>

<gridster [options]="options" id="printableGrid" *ngIf="dashboard.length > 0">
  <gridster-item
    [item]="gridItem"
    *ngFor="let gridItem of dashboard"
    [ngClass]="{ 'grid-border': gridItem.type === 'item' }"
  >
    <div class="page-heading" *ngIf="gridItem.type === 'pageHeading'">{{ gridItem.text }}</div>
    <app-grid-board-item
      (removeItemEvent)="removeItem($event)"
      *ngIf="gridItem.type === 'item'"
      [itemData]="gridItem.item"
    >
    </app-grid-board-item>
  </gridster-item>
</gridster>
<app-comment-overlay></app-comment-overlay>
