import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommentsSelectors } from 'src/app/common/comments/comments-store';
import { Comment } from 'src/app/common/comments/comments.service';
import { PinnedComment } from '../presentation-pinned-comments/presentation-pinned-comments.component';
import {
  AddPinnedCommentsService,
  FRAME_ID_PREFIX,
  ITEM_PINNED_COMMENT_ID_PREFIX,
} from '../presentation-pinned-comments/add-pinned-comments.service';
import { ItemPinnedCommentsComponent } from '@common/item-presentation/item-pinned-comments/item-pinned-comments.component';

@Component({
  selector: 'app-presentation-item-pinned-comments',
  templateUrl: './presentation-item-pinned-comments.component.html',
  styleUrls: ['./presentation-item-pinned-comments.component.scss'],
})
export class PresentationItemPinnedComments implements ItemPinnedCommentsComponent, OnInit {
  @Input() item: any;
  @Input() frameId?: string;
  @Input() commentsAccessLevel?: string;

  private subscription: Subscription = new Subscription();

  pinnedComment$: Observable<PinnedComment>;
  elementId: string;
  showPinnedComments: boolean;

  constructor(
    private store: Store<RootStoreState.State>,
    private addPinnedCommentsService: AddPinnedCommentsService,
  ) {}

  ngOnInit(): void {
    this.elementId = this.item.value || this.item.id;
    this.elementId = `${ITEM_PINNED_COMMENT_ID_PREFIX}${this.elementId}`;
    if (this.frameId) {
      this.elementId = `${FRAME_ID_PREFIX}${this.frameId}-${this.elementId}`;
    }
    this.pinnedComment$ = this.store.select(CommentsSelectors.selectContextComments).pipe(
      map((comments) => comments.filter((comment) => comment.status !== 'closed' && this.isItemComment(comment))),
      map((comments) => Object.values(this.groupByDocumentElement(comments))?.pop()),
    );

    this.subscription.add(
      this.store.select(CommentsSelectors.showPinnedComments).subscribe((showPinnedComments) => {
        this.showPinnedComments = showPinnedComments;
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Item comments is defined by:
   * subContextReference: 'presentation-frame:<frameId>' - belongs to a particular frame
   * ownedByReference: 'item:<itemId>' - can be item family or item option
   * documentElementId and documentPositions should be undefined
   * @param comment
   */
  private isItemComment(comment: Comment) {
    const itemId = this.item.value || this.item.id;
    return (
      !comment.documentElementId &&
      !comment.documentPosition &&
      comment?.subContextReference === `presentation-frame:${this.frameId}` &&
      comment?.ownedByReference === `item:${itemId}`
    );
  }

  private groupByDocumentElement(comments: Array<Comment>) {
    return comments.reduce((counter, comment) => {
      const groupKey = comment.ownedByReference;
      if (counter.hasOwnProperty(groupKey)) {
        counter[groupKey].count = counter[groupKey].count + 1;
      } else {
        counter[groupKey] = {
          groupKey,
          count: 1,
          firstComment: comment,
        };
      }
      return counter;
    }, {});
  }

  /**
   * Show comment overlay with comment bubble is clicked
   * @param comment
   */
  showComments(comment: Comment, event) {
    event.stopPropagation();
    const id = this.item.value || this.item.id;
    this.addPinnedCommentsService.addItemComment(id, this.frameId, this.commentsAccessLevel);
  }
}
