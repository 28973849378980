import { Action, createReducer, on } from '@ngrx/store';
import * as ShowcaseActions from './showcase.actions';
import { initialState, State } from './showcase.state';
import { collectionStatusMessagesReducers } from './collection-status-messages/collection-status-messages.reducers';

const setCurrentShowcase = (state: State, { showcase }) => {
  return {
    ...state,
    currentShowcase: showcase,
  };
};
const clearCurrentShowcase = (state: State) => {
  return {
    ...state,
    currentShowcase: null,
  };
};
const setItemsUsedInShowcase = (state: State, { itemsUsedInShowcase }) => {
  console.log(`itemsUsedInShowcase ${JSON.stringify(itemsUsedInShowcase)}`);

  return {
    ...state,
    itemsUsedInShowcase,
  };
};

const setGridFrameActiveContainer = (state: State, { gridFrameActiveContainer }) => {
  return {
    ...state,
    gridFrameActiveContainer,
  };
};

const setCollectionFrameActiveContainer = (state: State, { collectionFrameActiveContainer }) => {
  return {
    ...state,
    collectionFrameActiveContainer,
  };
};

const setAnnotationType = (state: State, { annotationType }) => {
  return {
    ...state,
    annotationType,
  };
};

const setStatusMessageElement = (state: State, { statusMessageElement }) => {
  return {
    ...state,
    statusMessageElement,
  };
};

const setShowSourceAssortmentWarning = (state: State, { showSourceAssortmentWarning }) => {
  return {
    ...state,
    showSourceAssortmentWarning,
  };
};

export const showcaseReducers = createReducer(
  initialState,
  ...collectionStatusMessagesReducers,
  on(ShowcaseActions.clearCurrentShowcase, clearCurrentShowcase),
  on(ShowcaseActions.loadCurrentShowcaseSuccess, setCurrentShowcase),
  on(ShowcaseActions.setItemsUsedInShowcase, setItemsUsedInShowcase),
  on(ShowcaseActions.setGridFrameActiveContainer, setGridFrameActiveContainer),
  on(ShowcaseActions.setCollectionFrameActiveContainer, setCollectionFrameActiveContainer),
  on(ShowcaseActions.setAnnotationType, setAnnotationType),
  on(ShowcaseActions.setStatusMessageElement, setStatusMessageElement),
  on(ShowcaseActions.setShowSourceAssortmentWarning, setShowSourceAssortmentWarning),
);

export function reducer(state: State | undefined, action: Action): any {
  return showcaseReducers(state, action);
}
