<div class="relative" [attr.id]="svgId">
  <app-presentation-item-widget
    [editorMode]="editorMode$ | async"
    [svgId]="svgId"
    [document]="document"
    [frameId]="panel.frame.id"
  ></app-presentation-item-widget>
  <app-presentation-pinned-comments
    [editorMode]="editorMode$ | async"
    *ngIf="document && visible"
    [frameId]="panel.frame.id"
    [elements]="panel.frame.document.elements"
    [documentSize]="document.size"
    [svgId]="svgId"
  ></app-presentation-pinned-comments>
</div>
<app-presentation-hot-spot-widget [svgId]="svgId" [document]="document"></app-presentation-hot-spot-widget>
<app-presentation-context-menu
  *ngIf="['EDIT'].includes(editorMode$ | async)"
  [frameId]="panel.frame.id"
  [canvasDocument]="canvasDocument"
></app-presentation-context-menu>
<app-text-hyperlink></app-text-hyperlink>
