<img class="logo" src="assets/images/vibeiq_logo.jpg" />
<div class="header-text">Virtual Showcase</div>
<div *ngIf="user">
  <div class="message">
    Whoops! Looks like you are trying to access a showcase that either does not exist, or is not accessible with your
    current login.
  </div>
  <div class="message">For assistance, please talk with your brand contact or company administrator.</div>
  <div class="logout message" (click)="logout()">Log in as a different user</div>
</div>
