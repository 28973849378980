import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/common/auth/auth.service';

@Component({
  selector: 'app-showcase-not-found',
  templateUrl: './showcase-not-found.component.html',
  styleUrls: ['./showcase-not-found.component.scss'],
})
export class ShowcaseNotFoundComponent implements OnInit {
  public user;
  constructor(private authService: AuthService) {}
  async ngOnInit() {
    this.user = await this.authService.getCurrentUser();
  }

  logout() {
    this.authService.logout();
  }

  goToAdminApp() {
    window.open('https://app-development.vibesolutions.net/', '_blank');
  }
}
