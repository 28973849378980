import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateAgoPipe } from './date-ago.pipe';
import { FilterPipe } from './filter.pipe';
import { RoundNumberPipe } from './round-number.pipe';
import { ShortenPipe } from './shorten.pipe';
import { SearchKeyPipe } from './search-key.pipe';
import { SecureImagePipe } from './secure-image.pipe';
import { SafePipe } from './safe.pipe';

@NgModule({
  declarations: [DateAgoPipe, FilterPipe, RoundNumberPipe, ShortenPipe, SearchKeyPipe, SecureImagePipe, SafePipe],
  imports: [CommonModule],
  exports: [DateAgoPipe, FilterPipe, RoundNumberPipe, ShortenPipe, SearchKeyPipe, SecureImagePipe, SafePipe],
})
export class PipesModule {}
