import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { WebSocketService } from 'src/app/common/web-socket/web-socket.service';
import { RootStoreState } from 'src/app/root-store';
import { SessionAssortment, SessionAssortmentsService } from '../session-assortments.service';
import { SessionAssortmentsActionTypes } from './session-assortments.actions';
import { SessionAssortmentsActions } from '../session-assortments-store';
import { AppNotificationsService } from 'src/app/common/app-notifications/app-notifications.service';

@Injectable()
export class SessionAssortmentsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private sessionAssortmentsService: SessionAssortmentsService,
    private appNotificationsService: AppNotificationsService,
  ) {}

  createSessionAssortment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionAssortmentsActionTypes.CREATE_SESSION_ASSORTMENT),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(
          this.sessionAssortmentsService.create(store.sessionAssortments.currentSessionId, action.sessionAssortment),
        ).pipe(
          tap((sessionAssortment) => {
            this.appNotificationsService.showConfirmationMessage(`Assortment '${sessionAssortment.name}' created.`);
          }),
          switchMap((sessionAssortment) => [
            SessionAssortmentsActions.createSessionAssortmentSuccess({ sessionAssortment }),
            SessionAssortmentsActions.setCurrentSessionAssortment({ sessionAssortment }),
          ]),
          catchError((error) => observableOf(SessionAssortmentsActions.createSessionAssortmentFailure({ error }))),
        );
      }),
    ),
  );
  updateSessionAssortment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionAssortmentsActions.SessionAssortmentsActionTypes.UPDATE_SESSION_ASSORTMENT),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(
          this.sessionAssortmentsService.update(store.sessionAssortments.currentSessionId, action.sessionAssortment),
        ).pipe(
          switchMap((sessionAssortment) => [
            SessionAssortmentsActions.updateSessionAssortmentSuccess({ sessionAssortment }),
          ]),
          catchError((error) => observableOf(SessionAssortmentsActions.updateSessionAssortmentFailure({ error }))),
        );
      }),
    ),
  );
  copySessionAssortment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionAssortmentsActions.SessionAssortmentsActionTypes.COPY_SESSION_ASSORTMENT),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(
          this.sessionAssortmentsService.copy(store.sessionAssortments.currentSessionId, action.sessionAssortment),
        ).pipe(
          switchMap((sessionAssortment) => [
            SessionAssortmentsActions.copySessionAssortmentSuccess({ sessionAssortment }),
            SessionAssortmentsActions.setCurrentSessionAssortment({ sessionAssortment }),
          ]),
          catchError((error) => observableOf(SessionAssortmentsActions.copySessionAssortmentFailure({ error }))),
        );
      }),
    ),
  );
  archiveSessionAssortment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionAssortmentsActions.SessionAssortmentsActionTypes.ARCHIVE_SESSION_ASSORTMENT),
      switchMap((action: any) => {
        return from(this.sessionAssortmentsService.archive(action.sessionAssortment)).pipe(
          withLatestFrom(this.store),
          switchMap(([sessionAssortment, store]: [SessionAssortment, RootStoreState.State]) => [
            SessionAssortmentsActions.archiveSessionAssortmentSuccess({ sessionAssortment }),
            SessionAssortmentsActions.setCurrentSessionAssortment({
              sessionAssortment: store.sessionAssortments.sessionAssortments[0],
            }),
          ]),

          catchError((error) => observableOf(SessionAssortmentsActions.archiveSessionAssortmentFailure({ error }))),
        );
      }),
    ),
  );
}
