import { SizeDefinition } from '@contrail/documents';
import { Presentation, ShowcasePresentation, ShowcasePresentationPanel } from '../presentation';
export interface State {
  currentShowcasePresentation: ShowcasePresentation;
  currentShowcasePresentationPanel: ShowcasePresentationPanel;
  currentPresentation: Presentation;
  presentationSize: SizeDefinition;
  currentFrameId: string;
}
export const initialState: State = {
  currentShowcasePresentation: null,
  currentShowcasePresentationPanel: null,
  currentPresentation: null,
  presentationSize: null,
  currentFrameId: null,
};
