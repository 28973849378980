import { Component, OnInit } from '@angular/core';
import { ItemData } from 'src/app/common/item-data/item-data';
import { ShowcaseService } from '../showcase/showcase.service';
import { AnalyticsService } from '../common/analytics/analytics.service';
import { LocalizationService, LocalizationConfig } from 'src/app/common/localization/localization.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { RootStoreState } from '../root-store';
import { AssortmentsSelectors } from '../common/assortments/assortments-store';
import { ObjectUtil } from '@contrail/util';
import { EVENT_CATEGORY } from '@common/analytics/user-analytics.service';

@Component({
  selector: 'app-explorer',
  templateUrl: './explorer.component.html',
  styleUrls: ['./explorer.component.scss'],
})
export class ExplorerComponent implements OnInit {
  constructor(
    private analyticsService: AnalyticsService,
    private localizationService: LocalizationService,
    private store: Store<RootStoreState.State>,
  ) {
    this.localizationService.localizationConfig.subscribe((config) => {
      this.localizationConfig = config;
    });
  }

  public itemData$: Observable<Array<ItemData>>;
  localizationConfig: LocalizationConfig;

  ngOnInit(): void {
    this.analyticsService.emitEvent({ eventName: 'VIEW_EXPLORER', eventCategory: EVENT_CATEGORY.VIEW });
    this.itemData$ = combineLatest([
      this.store.select(AssortmentsSelectors.backingAssortmentItemData),
      this.store.select(AssortmentsSelectors.sourceAssortmentItemData),
    ]).pipe(
      map(([backingAssortmentItems, sourceAssortmentItems]) => {
        backingAssortmentItems.forEach((backingAssortmentItem) => {
          const sourceAssortmentItem = sourceAssortmentItems.find(
            (sourceAssortmentItem) => sourceAssortmentItem.id === backingAssortmentItem.properties.itemId,
          );
          if (sourceAssortmentItem?.projectItem) {
            const mergedProperties = ObjectUtil.mergeDeep(
              backingAssortmentItem.properties,
              sourceAssortmentItem.projectItem,
            );
            backingAssortmentItem.properties = mergedProperties;
          }
        });
        return backingAssortmentItems;
      }),
    );
  }
}
