<div *ngIf="loaded$ | async" class="h-full w-full">
  <app-header [@screenModeChangeHeader]="screenMode"></app-header>
  <div class="w-full flex">
    <app-body class="square-scroll w-full" [class.!h-screen]="screenMode === 'fullscreen'"></app-body>
    <app-presentation-comments-list [class.!h-screen]="screenMode === 'fullscreen'"></app-presentation-comments-list>
  </div>
  <app-footer></app-footer>
</div>

<div *ngIf="!(loaded$ | async)" class="loading-page">
  <img class="logo" src="assets/images/vibeiq_logo.jpg" />
  <mat-spinner diameter="60"></mat-spinner>
  <span>Loading your showcase.</span>
</div>
<app-loading-indicator></app-loading-indicator>
