import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { Workspace } from '@common/workspaces/workspaces-store/workspaces.state';
import { Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, from, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { Assortment } from '../assortments-store/assortments.state';
import { AssortmentsService } from '../assortments.service';
@Component({
  selector: 'app-assortment-selector',
  templateUrl: './assortment-selector.component.html',
  styleUrls: ['./assortment-selector.component.scss'],
})
export class AssortmentSelectorComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  private destroy$ = new Subject();

  @Output() selectedAssortmentEvent = new EventEmitter();
  @Input() spanWorkspaces = false;
  @Input() outputType = 'ID';
  @Input() defaultCurrentWorkspaceId: any;
  @Input() defaultSourceAssortmentId: any;
  @Input() sourceAssortmentRequired: boolean = true;

  public workspaces$: Observable<Array<Workspace>>;
  private assortmentsSubject: BehaviorSubject<Array<Assortment>> = new BehaviorSubject([]);
  public assortments$: Observable<Array<Assortment>> = this.assortmentsSubject.asObservable();
  public assortmentFormControl = new UntypedFormControl({ value: null, disabled: true }, Validators.required);
  public sourceWorkspaceControl = new UntypedFormControl({ value: null, disabled: false }, Validators.required);
  public areAssortmentsLoading = false;

  constructor(
    private store: Store<RootStoreState.State>,
    private assortmentsService: AssortmentsService,
  ) {}

  async ngOnInit() {
    this.workspaces$ = this.store.select(WorkspacesSelectors.workspaces);

    if (!this.spanWorkspaces) {
      this.assortmentFormControl.enable();
      const workspace = await firstValueFrom(this.store.select(WorkspacesSelectors.currentWorkspace));
      this.initAssortments(workspace.id);
    } else {
      if (this.defaultCurrentWorkspaceId) {
        const workspaces = await firstValueFrom(this.workspaces$);
        const selectedWorkspace = workspaces.find((workspace) => workspace.id === this.defaultCurrentWorkspaceId);
        this.sourceWorkspaceControl.setValue(selectedWorkspace);
      }

      if (!this.sourceAssortmentRequired) {
        this.assortmentFormControl.clearValidators();
      }
    }
  }

  ngAfterViewInit() {
    this.sourceWorkspaceControl.valueChanges
      .pipe(debounceTime(600), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((workspace) => {
        this.handleWorkspaceChange(workspace);
      });

    this.assortmentFormControl.valueChanges
      .pipe(debounceTime(600), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((assortment) => {
        if (assortment) {
          this.handleAssortmentSelection(assortment);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sourceAssortmentRequired) {
      if (!changes.sourceAssortmentRequired.currentValue) {
        this.assortmentFormControl.clearValidators();
        this.assortmentFormControl.setValue(null); // reset value to null
      } else {
        this.assortmentFormControl.addValidators(Validators.required);
      }
      this.assortmentFormControl.updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  async initAssortments(workspaceId) {
    this.areAssortmentsLoading = true;
    const assortments = await this.assortmentsService.getAllAssortmentsBatched(workspaceId);
    this.assortmentsSubject.next(assortments);
    this.areAssortmentsLoading = false;

    if (this.defaultSourceAssortmentId) {
      const assortments = await firstValueFrom(this.assortments$);
      const currentAssortment =
        assortments.find((assortment) => assortment.id === this.defaultSourceAssortmentId) || null;
      this.assortmentFormControl.setValue(currentAssortment);
    }
  }
  async handleWorkspaceChange(workspace) {
    if (workspace) {
      this.initAssortments(workspace.id);
      this.assortmentFormControl.enable();
    } else if (this.spanWorkspaces) {
      this.assortmentFormControl.disable();
    }
  }

  private handleAssortmentSelection(assortment) {
    const output = this.outputType === 'ID' ? assortment?.id : assortment;
    this.selectedAssortmentEvent.emit(output);
  }
}
