import { Component, EventEmitter, Input, OnChanges, Output, Type, ViewChild } from '@angular/core';
import { EVENT_CATEGORY } from '@common/analytics/user-analytics.service';
import { AnalyticsService } from 'src/app/common/analytics/analytics.service';
import { ItemData } from 'src/app/common/item-data/item-data';
import { ViewManagerService } from 'src/app/common/views/view-manager.service';
import { ItemColorsListComponent } from '../item-colors-list/item-colors-list.component';

@Component({
  selector: 'app-presentation-item-details',
  templateUrl: './presentation-item-details.component.html',
  styleUrls: ['./presentation-item-details.component.scss'],
})
export class PresentationItemDetailsComponent implements OnChanges {
  public focusedItemColor: any;
  constructor(
    private analyticsService: AnalyticsService,
    private viewService: ViewManagerService,
  ) {}
  @Input() dataSelectionToggleComponent: Type<any>;
  @Input() itemPinnedCommentsComponent: Type<any>;
  @Input() presentation: any;
  @Input() itemPresentationElement: any;
  @Input() itemData: ItemData;
  @Input() includeCommentWidget = false;
  @Input() visible = true;
  @Input() itemsMissingFromSource: any[];
  @Input() frameId: string;
  @Input() hasCustomViewDefinition: boolean;
  @Input() editorMode: any;
  @Output() menuEvent = new EventEmitter();

  @ViewChild(ItemColorsListComponent, { static: false }) itemColorListComponent: ItemColorsListComponent;
  public viewDefinition;
  selectedItemsColors = [];

  itemColors = [];

  async ngOnChanges() {
    if (!this.itemData) {
      return;
    }
    const contextReference = this.hasCustomViewDefinition
      ? `presentation-frame:${this.frameId}`
      : this.presentation.ownedByReference;
    this.viewDefinition = await this.viewService.getView(
      'showcase:item_details',
      this.itemData.item.typeId,
      contextReference,
    );
    this.focusedItemColor = null;
    this.selectedItemsColors = [];
    if (this.itemData && this.itemData.id) {
      this.setFocusedItemColor();

      this.analyticsService.emitEvent({
        eventName: 'VIEW_ITEM_DETAILS',
        eventTarget: `item:${this.itemData.properties.itemId}`,
        eventLabel: this.itemData.properties.itemName,
        eventCategory: EVENT_CATEGORY.VIEW,
      });
      if (this.focusedItemColor) {
        this.analyticsService.emitEvent({
          eventName: 'VIEW_ITEM_COLOR_DETAILS',
          eventTarget: `item:${this.focusedItemColor.id}`,
          eventLabel: this.itemData.getName(),
          eventCategory: EVENT_CATEGORY.VIEW,
        });
      }
    }
  }

  setFocusedItemColor() {
    if (this.itemData.options.length) {
      const primary = this.itemData.options.filter((ic) => ic.properties.primary === 'Yes');

      if (primary && primary.length) {
        this.focusedItemColor = primary[0];
      } else {
        const firstEnabled = this.itemPresentationElement?.children?.find((el) => el.enabled);
        const option = this.itemData.options.find((opt) => opt.id === firstEnabled?.value);
        if (option) {
          this.focusedItemColor = option;
        } else if (this.itemData.options?.length) {
          this.focusedItemColor = this.itemData.options[0];
        }
      }
      this.handleItemColorFocusEvent(this.focusedItemColor);
    }
  }

  handleItemColorFocusEvent(itemColor) {
    if (!itemColor) {
      return;
    }
    this.focusedItemColor = itemColor;
    this.itemData.properties = Object.assign(this.itemData.properties, this.focusedItemColor.properties);
    this.analyticsService.emitEvent({
      eventName: 'VIEW_ITEM_COLOR_DETAILS',
      eventTarget: `item:${this.focusedItemColor.id}`,
      eventLabel: this.itemData.getName(),
      eventCategory: EVENT_CATEGORY.VIEW,
    });
  }

  handleMenuEvent(event) {
    this.menuEvent.emit(event);
  }
}
