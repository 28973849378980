import { Item } from 'src/app/common/assortments/item';
import { PresentationCollectionElement } from 'src/app/presentation/presentation';
import {
  collectionStatusMessagesInitialState,
  CollectionStatusMessagesState,
} from './collection-status-messages/collection-status-messages.state';

export interface Showcase {
  id?: string;
  isTemplate?: boolean;
  isArchived?: boolean;
  createdOn?: Date;
  updatedOn?: Date;
  createdBy?: any;
  updatedBy?: any;
  name?: string;
  workspaceId?: string;
  previewFile?: any;
  coverPhoto?: string;
  sourceAssortment?: any;
  sourceAssortmentId?: string;
  workspace?: any;
  backingAssortmentId?: string;
  backingAssortment?: any;
  annotationType?: string;
  showSourceAssortmentWarning?: boolean;
}

export interface State {
  currentShowcase: Showcase;
  collectionStatusMessages: CollectionStatusMessagesState;
  itemsUsedInShowcase: Array<Item>;
  gridFrameActiveContainer: PresentationCollectionElement;
  collectionFrameActiveContainer: PresentationCollectionElement;
  showSourceAssortmentWarning: boolean;
}

export const initialState = {
  currentShowcase: null,
  collectionStatusMessages: collectionStatusMessagesInitialState,
  itemsUsedInShowcase: [],
  gridFrameActiveContainer: null,
  collectionFrameActiveContainer: null,
  showSourceAssortmentWarning: true,
};
