import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ItemDataChooserComponent } from '@common/item-data-chooser/item-data-chooser.component';
import { Types } from '@contrail/sdk';
import { TypeProperty } from '@contrail/types';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AnalyticsService } from '../common/analytics/analytics.service';
import { Assortment } from '../common/assortments/assortment';
import { AssortmentsSelectors } from '../common/assortments/assortments-store';
import { ExporterService } from '../common/components/service/exporter/exporter.service';
import { ItemData } from '../common/item-data/item-data';
import { GridBoardService } from '../grid-board/grid-board.service';
import { ItemModalLauncher } from '@common/items/item-modal-launcher';
import { RootStoreState } from '../root-store';
import { ShowcaseSelectors } from '../showcase/showcase-store';
import { ShowcaseService } from '../showcase/showcase.service';
import { ListModalLauncher } from './save-list/save-list-modal-launcher';
import { SessionAssortmentsActions, SessionAssortmentsSelectors } from './session-assortments-store';
import { SessionAssortment } from './session-assortments.service';
import { ListAction, SessionService } from './session.service';
import { EVENT_CATEGORY } from '@common/analytics/user-analytics.service';
import { Showcase } from '../showcase/showcase-store/showcase.state';
import { ExportsService } from '@common/exports/exports.service';
@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss'],
})
export class SessionComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$ = new Subject();
  private showcase: Showcase;

  public currentAssortmentItemData: Array<any>;
  private addedItemsSubject = new BehaviorSubject(null);
  public addedItems: Observable<any> = this.addedItemsSubject.asObservable();
  @ViewChild(ItemDataChooserComponent) itemDataChooser: ItemDataChooserComponent;

  private sessionAssortments: Array<SessionAssortment>;
  private currentAssortment: Assortment;
  private backingAssortmentItemData: Array<ItemData>;
  private typeProperties: Array<TypeProperty> = [];

  private QUICK_SEARCH_OPTIONS = 'name, itemName, styleNumber';
  private itemChooserFilterTemplateName = 'item_chooser_filter';
  private itemCardViewTemplateName = 'showcase:item_card';

  public current_assort_selector_params = {
    embedded: true,
    displayCount: false,
    allowShowSpinner: false,
    notifyMeOfAssortSelection: true,
  };

  public pdfOptions = {
    pageHeight: 210, // A4
    pageWidth: 297, // A4
    noOfColumnsInGrid: 21, // Number of columns
    noOfRowsInGrid: 9, // Number of rows(considered per page)
  };
  public showSpinner = false;

  constructor(
    private sessionService: SessionService,
    private showcaseService: ShowcaseService,
    private itemModalLauncher: ItemModalLauncher,
    private listModalLauncher: ListModalLauncher,
    private exporterService: ExporterService,
    private store: Store<RootStoreState.State>,
    private gridBoardService: GridBoardService,
    private analyticsService: AnalyticsService,
    private exportService: ExportsService,
  ) {
    this.store
      .select(ShowcaseSelectors.currentShowcase)
      .pipe(takeUntil(this.destroy$))
      .subscribe((showcase) => {
        this.showcase = showcase;
      });

    this.sessionService.currentAssortment.pipe(takeUntil(this.destroy$)).subscribe((assortment) => {
      if (!assortment) {
        return;
      }

      this.currentAssortment = assortment;
      this.currentAssortmentItemData = JSON.parse(JSON.stringify(this.currentAssortment.items));
    });

    this.store
      .select(AssortmentsSelectors.backingAssortmentItemData)
      .pipe(
        tap((data) => {
          this.backingAssortmentItemData = data;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.store
      .select(SessionAssortmentsSelectors.selectSessionAssortments)
      .pipe(takeUntil(this.destroy$))
      .subscribe((sessionAssortments) => {
        this.sessionAssortments = sessionAssortments;
      });
  }

  async ngOnInit() {
    this.analyticsService.emitEvent({
      eventName: 'VIEW_SHOWCASE_SESSION_ASSORTMENTS',
      eventCategory: EVENT_CATEGORY.VIEW,
      eventTarget: `showcase:${this.showcase.id}`,
      eventContext: `showcase:${this.showcase.id}`,
    });
    this.typeProperties = (await this.getItemProperties()) || [];
    this.itemDataChooser.chooserSourceOption$.next({
      sourceType: 'BACKING_ASSORTMENT',
      name: 'backing_assortment',
    });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  async getItemProperties() {
    const itemType = await new Types().getType({ root: 'item', path: 'item', relations: ['typeProperties'] });
    const itemFilterProperties = itemType.typeProperties.sort((p1, p2) => (p1.label < p2.label ? -1 : 1));
    return itemFilterProperties;
  }

  async removeItem(item) {
    await this.sessionService.removeItem(item);
    this.gridBoardService.removeItem(item?.properties.itemId);
  }

  async dropItem(event) {
    event.stopPropagation();
    let droppedItem = event.dataTransfer.getData('dataObject');
    if (droppedItem) {
      droppedItem = JSON.parse(droppedItem);
    }
    const itemData: ItemData = this.getItemFromSource(droppedItem.item.id) as ItemData;
    if (itemData) {
      let added;
      const data = [itemData];

      added = await this.sessionService.addItems(data, this.currentAssortment.id);

      this.analyticsService.emitEvent({
        eventName: 'ADD_TO_ASSORTMENT_BY_DRAG_AND_DROP',
        eventCategory: EVENT_CATEGORY.OTHER,
        eventTarget: `assortment:${this.currentAssortment.id}`,
        eventContext: `showcase:${this.showcase.id}`,
      });
      if (added) {
        this.addedItemsSubject.next(itemData);
        this.gridBoardService.handleDroppedItem(itemData.id);
      }
    }
    return false;
  }

  dragOver(event) {
    event.stopPropagation();
    return false;
  }

  private getItemFromSource(id) {
    const itemDataSet = this.backingAssortmentItemData.filter((itemData) => itemData.id === id);
    return itemDataSet?.length ? itemDataSet[0] : {};
  }

  initNewList() {
    const params = {
      action: ListAction.CREATE,
      actionLabel: 'created',
      modalTitle: 'Add a New Assortment',
    };
    this.listModalLauncher.openModal(params);
  }

  viewItemDetails(itemData) {
    this.itemModalLauncher.openModal(itemData);
  }

  initListEdit() {
    const showcaseAssortment = this.sessionAssortments.filter(
      (data) => data.assortmentId === this.currentAssortment.id,
    )[0];
    const params = {
      action: ListAction.UPDATE,
      actionLabel: 'updated',
      modalTitle: `Update ${this.currentAssortment.name}`,
      name: this.currentAssortment.name,
      assortmentId: this.currentAssortment.id,
      id: showcaseAssortment.id,
    };
    this.listModalLauncher.openModal(params);
  }
  initListCopy() {
    const showcaseAssortment = this.sessionAssortments.filter(
      (data) => data.assortmentId === this.currentAssortment.id,
    )[0];
    const params = {
      action: ListAction.COPY,
      actionLabel: 'copied',
      modalTitle: `Create a Copy of ${this.currentAssortment.name}`,
      name: `Copy of ${this.currentAssortment.name}`,
      assortmentId: this.currentAssortment.id,
      id: showcaseAssortment.id,
    };
    this.listModalLauncher.openModal(params);
  }

  async initListDelete() {
    const sessionAssortment = this.sessionAssortments.find(
      (assortment) => assortment.assortmentId === this.currentAssortment.id,
    );
    if (confirm(`${this.currentAssortment.name} will be deleted. Are you sure?`)) {
      this.store.dispatch(SessionAssortmentsActions.archiveSessionAssortment({ sessionAssortment }));
    }
  }

  downloadExcel() {
    const options = { reportType: 'excel' };
    const payload = { entityReference: `assortment:${this.currentAssortment.id}` };
    this.exportService.initDownloadEntityReference(options, payload);
  }

  downloadCSV() {
    const fileName = this.showcase.name + ' - ' + this.currentAssortment.name;

    const data = [];
    this.currentAssortmentItemData.forEach((item) => {
      data.push(item.properties);
    });
    this.exporterService.downloadFile(data, fileName, this.typeProperties, this.currentAssortment.id);
  }

  async downloadPDF() {
    const showcaseAssortmentName = this.showcase.name + ' - ' + this.currentAssortment.name;
    const showcaseAssortment = this.sessionAssortments.filter(
      (data) => data.assortmentId === this.currentAssortment.id,
    )[0];
    const jobId = await this.sessionService.downloadPDF(showcaseAssortment.id, showcaseAssortmentName);
  }
}
