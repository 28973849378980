<mat-form-field class="!w-full" appearance="fill" *ngIf="spanWorkspaces">
  <mat-label>Source Project</mat-label>
  <mat-select [formControl]="sourceWorkspaceControl" data-test="select-workspace">
    <mat-option *ngFor="let workspace of workspaces$ | async" [value]="workspace">
      {{ workspace.name }}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field class="!w-full" appearance="fill">
  <mat-label>Source Assortment</mat-label>
  <mat-select
    [formControl]="assortmentFormControl"
    data-test="select-source-assortment"
    [disabled]="areAssortmentsLoading"
  >
    <mat-option *ngFor="let assortment of assortments$ | async" [value]="assortment">
      {{ assortment.name }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="assortmentFormControl.hasError('required')">Select Assortment</mat-error>
</mat-form-field>
<div class="absolute left-1/2 mt-3">
  <mat-progress-spinner diameter="25" mode="indeterminate" *ngIf="areAssortmentsLoading"> </mat-progress-spinner>
</div>
