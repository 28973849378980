import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Entities, Types } from '@contrail/sdk';
import { TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, from, Observable } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { FilterObjects } from 'src/app/common/components/filter/filter-objects';
import { SearchBarComponent } from 'src/app/common/components/search-bar/search-bar.component';
import { SortObjects } from 'src/app/common/components/sort/sort-objects';
import { ItemData } from 'src/app/common/item-data/item-data';
import { FilterCriteria } from 'src/app/common/types/filters/filter-criteria';
import { FilterDefinition, FilterPropertyDefinition } from 'src/app/common/types/filters/filter-definition';
import { RootStoreState } from 'src/app/root-store';
import { ShowcaseSelectors } from 'src/app/showcase/showcase-store';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { ExporterService } from '@common/components/service/exporter/exporter.service';
import { ITEM_SORT_OPTIONS } from '@common/items/item-sort-options';
import { EVENT_CATEGORY } from '@common/analytics/user-analytics.service';
import { AuthSelectors } from '@common/auth/auth-store';

@Component({
  selector: 'app-items-list',
  templateUrl: './items-list.component.html',
  styleUrls: ['./items-list.component.scss'],
})
export class ItemsListComponent implements OnInit, AfterViewInit, OnChanges {
  constructor(
    private exporterService: ExporterService,
    public dialog: MatDialog,
    private analyticsService: AnalyticsService,
    private store: Store<RootStoreState.State>,
  ) {
    this.store.select(ShowcaseSelectors.currentShowcase).subscribe((showcase) => {
      this.showcase = showcase;
    });
  }

  isLoading = false;
  isSticky = false;
  stickyYOffset = 120;

  @Input() itemData: Array<ItemData>;
  @Input() gridBoard: boolean;
  @Output() removeItemEvent = new EventEmitter();

  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;

  public view = 'grid';

  public sortByField = 'name';

  public filterDefinition: FilterDefinition;
  showcase: any;
  sortByData = ITEM_SORT_OPTIONS;

  public typeProperties: Array<TypeProperty> = [];

  private defaultPageSize = 25;
  pageSize = 0;
  pageEvent;

  public fromIndex = 0;
  public toIndex = 0;

  private filterDefinitionSubject: BehaviorSubject<FilterDefinition> = new BehaviorSubject(null);
  public filteredResults$: Observable<Array<ItemData>>;
  public filteredItemData: Array<ItemData> = [];

  private sortSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  public showCount = false;
  public propertyTypeDefaultFilterConditions: any = {};

  ngOnInit(): void {
    this.store.select(AuthSelectors.currentOrg).subscribe((org) => {
      if (org.orgConfig?.propertyTypeDefaultFilterConditions) {
        this.propertyTypeDefaultFilterConditions = org.orgConfig.propertyTypeDefaultFilterConditions;
      }
    });
  }

  async ngAfterViewInit() {}

  async ngOnChanges() {
    this.isSticky = false;
    await this.getItemProperties();
    console.log(`this.typeProperties ${JSON.stringify(this.typeProperties)}`);
    this.initFilterDefinition();
    this.initResultsObservable();
  }

  async getItemProperties() {
    const itemType = await new Types().getType({ root: 'item', path: 'item' });
    const properties = [...itemType.typeProperties];
    this.typeProperties = properties?.sort((p1, p2) => (p1.label < p2.label ? -1 : 1)) || [];
    // return itemFilterProperties;
  }

  async initFilterDefinition() {
    const itemType = await new Types().getType({ root: 'item', path: 'item', relations: ['typeProperties'] });
    const assortmentItemType = await new Types().getType({
      root: 'assortment-item',
      path: 'assortment-item',
      relations: ['typeProperties'],
    });
    const assortmentItemTypeProperties = assortmentItemType.typeProperties.filter(
      (property) => !['createdOn', 'updatedOn'].includes(property.slug),
    );
    const projectItemType = await new Types().getType({
      root: 'project-item',
      path: 'project-item',
      relations: ['typeProperties'],
    });
    const projectItemTypeProperties = projectItemType.typeProperties.filter(
      (property) => !['createdOn', 'updatedOn'].includes(property.slug),
    );
    let filterProperties: Array<TypeProperty> = [
      ...itemType?.typeProperties,
      ...assortmentItemTypeProperties,
      ...projectItemTypeProperties,
    ];
    filterProperties = filterProperties.sort((p1, p2) => (p1.label < p2.label ? -1 : 1));
    this.filterDefinition = {
      filterPropertyDefinitions: filterProperties as Array<FilterPropertyDefinition>,
      filterCriteria: {
        propertyCriteria: [],
      },
    };
  }

  initResultsObservable() {
    this.filteredResults$ = combineLatest([
      this.searchBar.valueChange.pipe(startWith('')),
      from([this.itemData]),
      this.filterDefinitionSubject.asObservable(),
      this.sortSubject.asObservable(),
    ]).pipe(
      switchMap(async ([searchTerm, sourceAssortmentItemData, filterDefinition, sorts]) => {
        let data;
        console.log('Filtering..  ', filterDefinition);
        // SOURCE ASSORTMENT CONSTRAINT, OPTION LEVEL
        if (sourceAssortmentItemData) {
          data = sourceAssortmentItemData;
          if (filterDefinition) {
            data = FilterObjects.filter(data, filterDefinition);
          }
        } else {
          const criteria = { roles: ['option'] };
          const relations = ['primaryViewable', 'primaryViewable.mediumViewable', 'primaryViewable.primaryFile'];
          data = await new Entities().get({ entityName: 'item', relations, criteria, take: 30, search: searchTerm });
          data = data.map((obj) => new ItemData(obj));
        }
        data = this.filterLocalItemData({ searchTerm }, data);
        this.filteredItemData = data;
        data = this.applySortBy(sorts);
        this.applyPagination();
        return data;
      }),
    );
  }

  setFilterCriteria(filterCriteria: FilterCriteria) {
    if (filterCriteria) {
      this.filterDefinition.filterCriteria = filterCriteria;
      this.filterDefinitionSubject.next(this.filterDefinition);
    }
  }

  clearFilters() {
    this.filterDefinition.filterCriteria.propertyCriteria = [];
    this.filterDefinitionSubject.next(this.filterDefinition);
    this.searchBar.clear();
  }

  public filterLocalItemData({ searchTerm }, data) {
    const searchOptions = ['properties.name', 'properties.optionName'];
    let filtered = [];
    if (data?.length) {
      filtered = data.filter((item) => {
        return (
          !searchTerm?.length ||
          searchOptions.some((key) => new RegExp(searchTerm, 'gi').test(ObjectUtil.getByPath(item, key.trim())))
        );
      });
    }
    return Object.assign([], filtered);
  }

  setPageSize() {
    this.pageSize = this.filteredItemData?.length || 0;
    if (this.pageSize > this.defaultPageSize) {
      this.pageSize = this.defaultPageSize;
      this.toIndex = this.pageSize;
    }
  }

  applyPagination(event?) {
    this.setPageSize();
    this.fromIndex = event?.fromIndex || 0;
    this.toIndex = event?.toIndex || this.pageSize;
    console.log(` data length ${this.filteredItemData.length} - fromIndex ${this.fromIndex} toIndex ${this.toIndex}`);
    console.log(`paging event ${JSON.stringify(event)}`);
    if (event) {
      this.pageSize = event.pageSize;
      this.pageEvent = event.pageEvent;
    }
  }
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= this.stickyYOffset;
  }

  applySortBy(sortSet = []) {
    if (sortSet?.length) {
      console.log(`sortset ${JSON.stringify(sortSet)}`);

      return SortObjects.sort(this.filteredItemData, sortSet);
    }
    return this.filteredItemData;
  }

  removeItem(item) {
    this.removeItemEvent.emit(item);
  }

  async downloadCSV() {
    const data = [];
    this.filteredItemData.forEach((item) => {
      data.push(item.properties);
    });

    this.exporterService.downloadFile(data, this.showcase.name, this.typeProperties, this.showcase.id);
  }

  /**
   * sets the view value (grid v/s list)
   * @param value grid/list
   */
  setDisplayView(value) {
    this.analyticsService.emitEvent({ eventName: 'TOGGLE_ITEMS_LIST_GRID_LIST', eventCategory: EVENT_CATEGORY.OTHER });
    this.view = value;
    this.isLoading = false;
  }

  applySort($event) {
    this.sortSubject.next($event.sorts);
  }
}
