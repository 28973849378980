<div class="header"><mat-icon (click)="handleCancel()">arrow_back_ios_new</mat-icon>Select source</div>

<div *ngIf="step === 'OPTION_TYPE'" class="source-type-selector">
  <div
    class="source-type-option"
    [ngClass]="{ disabled: option.disabled }"
    (click)="selectSourceOptionType(option)"
    *ngFor="let option of optionTypesList"
  >
    <div class="icon">
      <mat-icon *ngIf="option.icon" class="material-icons-outlined">{{ option.icon }}</mat-icon>
      <img *ngIf="option.img" [src]="option.img" />
    </div>
    <div class="option-description">
      <div class="name">{{ option.name }}</div>
      <div class="description">{{ option.description }}</div>
    </div>
  </div>
</div>

<div *ngIf="step === 'ASSORTMENT_SELECT'" class="source-selector">
  <mat-form-field class="no-margin" style="width: 100%" appearance="fill">
    <mat-label>Source Project</mat-label>
    <mat-select [formControl]="sourceWorkspaceControl" (ngModelChange)="handleWorkspaceChange($event)">
      <mat-option *ngFor="let workspace of sourceWorkspaces$ | async" [value]="workspace">
        {{ workspace.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div>
    <mat-form-field
      *ngIf="(sourceAssortments$ | async)?.length"
      class="no-margin"
      style="width: 100%"
      appearance="fill"
    >
      <mat-label>Assortment</mat-label>
      <mat-select
        [formControl]="assortmentFormControl"
        (ngModelChange)="handleAssortmentChange()"
        data-test="select-source-assortment"
      >
        <mat-select-trigger
          class="assortment-name-trigger"
          appOverflowTooltip
          matTooltip=""
          [matTooltipShowDelay]="300"
          matTooltipPosition="left"
        >
          {{ assortmentFormControl.value?.name }}
        </mat-select-trigger>
        <ng-container *ngFor="let assortment of sourceAssortments$ | async">
          <mat-option
            [value]="assortment"
            appOverflowTooltip
            [toolTipElement]="'.mat-option-text'"
            matTooltip=""
            matTooltipPosition="left"
          >
            <!-- mat-option uses `<span class="mat-option-text"></span>` to render value -->
            {{ assortment.name }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <div class="warning" *ngIf="(sourceAssortments$ | async)?.length === 0">
      The selected project does not contain an eligible assortment.
    </div>
  </div>
  <div class="selectionButton" *ngIf="selectedSource">
    <button mat-raised-button color="primary" (click)="handleSelectSource()">Ok</button>
  </div>
</div>
