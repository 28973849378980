<div
  *ngFor="let navItem of navItems"
  class="header-nav-item"
  [routerLink]="[navItem.routerLink]"
  queryParamsHandling="preserve"
  [matTooltip]="navItem.label"
>
  <mat-icon *ngIf="navItem.svg" [svgIcon]="navItem.icon" [attr.data-test]="'nav-' + navItem.label"></mat-icon>
  <mat-icon class="non-svg" *ngIf="!navItem.svg" data-test="nav-presentation">{{ navItem.icon }}</mat-icon>
</div>
