import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FilterCriteria } from '@common/types/filters/filter-criteria';
import { FilterDefinition, FilterOption, FilterPropertyDefinition } from '@common/types/filters/filter-definition';
import { FilterObjects } from '@components/filter/filter-objects';
import { SearchBarComponent } from '@components/search-bar/search-bar.component';
import { SortDefinition, SortDirection } from '@components/sort/sort-definition';
import { SortObjects } from '@components/sort/sort-objects';
import { PropertyType } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { COMMENT_COLOR_CHIP_OPTIONS } from '../comments-card/comment-color-chip/comment-color-chip.component';
import { CommentsSelectors } from '../comments-store';
import { Comment, CommentOwnerInfo } from '../comments.service';

export interface CommentsFilterConfig {
  searchTerm?: string;
  contextReference?: CommentsContextReferenceFilter;
  filterDefintion?: FilterDefinition;
}

export interface CommentsContextReferenceFilter {
  key: string;
  value: string;
  label: string;
  selected?: boolean;
  ownerInfo?: CommentOwnerInfo;
}

@Component({
  selector: 'app-context-comments-list',
  templateUrl: './context-comments-list.component.html',
  styleUrls: ['./context-comments-list.component.scss'],
})
export class ContextCommentsListComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() commentTypeOptions?: Array<{ value: string; display: string }>;
  @Input() getCommentType?: (value: any) => string;
  @Input() criteria: any = { roles: 'option', isPrimary: true };
  @Input() accessLevel = 'EDIT';
  @Input() contextReferenceTypesObservable?: Observable<Array<CommentsContextReferenceFilter>>;
  @Output() contextReferenceValueChange: EventEmitter<any> = new EventEmitter();
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;

  public ownerInfo: CommentOwnerInfo;

  public comments$: Observable<Array<Comment>>;
  private selectCommmentsContextReference: string;
  private selectCommmentsContextReferenceTitleCase: string;

  public filterDefinition: FilterDefinition;
  private filterDefinitionSubject: BehaviorSubject<FilterDefinition> = new BehaviorSubject(null);
  private filterConfigSubject: BehaviorSubject<CommentsFilterConfig> = new BehaviorSubject(null);

  public sortProperties: Array<SortDefinition>;
  public currentSorts: Array<SortDefinition> = [];
  private sortConfigSubject: BehaviorSubject<SortDefinition[]> = new BehaviorSubject(null);

  public selectedContextReferenceLabel: string;
  private contextReference$: BehaviorSubject<CommentsContextReferenceFilter> = new BehaviorSubject(null);
  private contextReferenceTypes: Array<CommentsContextReferenceFilter>;

  private subscriptions: Subscription = new Subscription();

  private readonly SEARCHABLE_PROPERTIES = [
    'text',
    'ownedBy.name',
    'ownedBy.optionName',
    'createdBy.email',
    'createdBy.firstName',
    'createdBy.lastName',
  ];
  private readonly DEFAULT_SORTS: SortDefinition[] = [
    { direction: SortDirection.DESCENDING, propertySlug: 'createdOn', propertyType: PropertyType.Date },
  ];

  private enteredByEmails: Array<FilterOption> = [];

  constructor(private store: Store<RootStoreState.State>) {
    this.subscriptions.add(
      this.store
        .select(CommentsSelectors.selectCommmentsContextReference)
        .subscribe((selectCommmentsContextReference) => {
          if (selectCommmentsContextReference) {
            this.selectCommmentsContextReference = selectCommmentsContextReference.split(':')[0];
            this.selectCommmentsContextReferenceTitleCase = this.selectCommmentsContextReference.replace(
              /\w\S*/g,
              (txt) => {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
              },
            );
          }
        }),
    );

    this.subscriptions.add(
      this.store.select(CommentsSelectors.selectContextComments).subscribe((comments) => {
        for (let i = 0; i < comments.length; i++) {
          const comment = comments[i];
          const createdByEmail = comment?.createdBy?.email;
          if (createdByEmail && this.enteredByEmails.findIndex((e) => e.value === createdByEmail) === -1) {
            this.enteredByEmails.push({
              value: createdByEmail,
              display: createdByEmail,
            });
          }
        }
      }),
    );
  }

  ngOnInit(): void {
    this.initFilterDefinition();
  }

  ngAfterViewInit(): void {
    this.initContextReferenceObservable();
    this.initResultsObservable();
    this.initFilterObservable();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public setContextReference(contextReferenceLabel) {
    this.selectedContextReferenceLabel = contextReferenceLabel;
    const contextReference = this.contextReferenceTypes.find((c) => c.label === this.selectedContextReferenceLabel);
    this.contextReference$.next(contextReference);
    this.contextReferenceValueChange.emit(contextReferenceLabel);
    this.ownerInfo = contextReference?.ownerInfo;
  }

  public setFilterCriteria(filterCriteria: FilterCriteria) {
    if (filterCriteria) {
      this.filterDefinition.filterCriteria = filterCriteria;
      this.filterDefinitionSubject.next(this.filterDefinition);
    }
  }

  public setSort(event) {
    this.currentSorts = event?.sorts;
    const sorts = this.currentSorts.length > 0 ? this.currentSorts : this.DEFAULT_SORTS;
    this.sortConfigSubject.next(sorts);
  }

  private initResultsObservable(): void {
    this.comments$ = combineLatest([
      this.filterConfigSubject,
      this.sortConfigSubject,
      this.store.select(CommentsSelectors.selectContextComments).pipe(
        map((data) => {
          const comments = [...data];
          return comments.map((c) => {
            const comment = { ...c };
            comment.createdByEmail = comment.createdBy?.email;
            if (this.getCommentType) {
              comment.commentType = this.getCommentType(comment);
            }
            if (!comment.colorHexCode) {
              comment.colorHexCode = '#ffffff';
            }
            return comment;
          });
        }),
      ),
    ]).pipe(
      switchMap(async ([filterConfig, sortConfig, comments]) => {
        const searchTerm = filterConfig?.searchTerm;
        const contextReference = filterConfig?.contextReference;
        const filterDefinition = filterConfig?.filterDefintion;
        let data = this.filterData({ searchTerm, contextReference }, comments, this.SEARCHABLE_PROPERTIES);
        if (filterDefinition) {
          data = FilterObjects.filter(data, filterDefinition);
        }
        this.sortData(data, sortConfig);
        return data;
      }),
    );
  }

  public sortData(data, sorts) {
    if (sorts) {
      const sortedProperties = SortObjects.sort(data, sorts);
      data.sort((a, b) => {
        return sortedProperties.indexOf(a.id) - sortedProperties.indexOf(b.id);
      });
    }
  }

  private filterData({ searchTerm, contextReference }: CommentsFilterConfig, data, properties): Array<Comment> {
    let filtered;
    filtered = data.filter((comment) => {
      return (
        (contextReference == null || comment[contextReference.key] === contextReference.value) &&
        (!searchTerm?.length ||
          properties.some((key) => new RegExp(searchTerm, 'gi').test(ObjectUtil.getByPath(comment, key.trim()))))
      );
    });
    return Object.assign([], filtered);
  }

  /**
   * Initialize context reference observable to watch changes of which
   * contextReference or subContextReference to filter on.
   */
  private initContextReferenceObservable(): void {
    if (this.contextReferenceTypesObservable) {
      this.subscriptions.add(
        this.contextReferenceTypesObservable?.subscribe((contextReferenceTypes) => {
          this.contextReferenceTypes = contextReferenceTypes;
          const selectedContextReference = contextReferenceTypes?.find((s) => s.selected);
          if (selectedContextReference) {
            this.selectedContextReferenceLabel = selectedContextReference.label;
            this.contextReference$.next(selectedContextReference);
            this.ownerInfo = selectedContextReference?.ownerInfo;
          }
        }),
      );
    }
  }

  /**
   * Initialize an observable to watch changes to search term, context reference
   * and filter definition.
   * Accumulate these changes and send them to @filterConfigSubject observable.
   */
  private initFilterObservable() {
    if (this.searchBar) {
      const searchBarSubscription = combineLatest([
        this.searchBar.valueChange.pipe(startWith(''), distinctUntilChanged()),
        this.contextReference$,
        this.filterDefinitionSubject.asObservable(),
      ])
        .pipe(
          tap(async ([searchTerm, contextReference, filterDefintion]) => {
            const filterConfig = {
              searchTerm,
              contextReference,
              filterDefintion,
            };
            this.filterConfigSubject.next(filterConfig);
          }),
        )
        .subscribe();
      this.subscriptions.add(searchBarSubscription);
    }
  }

  /**
   * Initialize filter property definitions and sort properties.
   */
  private initFilterDefinition() {
    let filterPropertyDefinitions: Array<FilterPropertyDefinition> = [
      {
        label: 'Color',
        slug: 'colorHexCode',
        propertyType: PropertyType.MultiSelect,
        options: COMMENT_COLOR_CHIP_OPTIONS,
      },
      {
        label: 'Type',
        slug: 'commentType',
        propertyType: PropertyType.MultiSelect,
        options: this.commentTypeOptions,
      },
      {
        label: 'Status',
        slug: 'status',
        propertyType: PropertyType.SingleSelect,
        options: [
          { value: 'closed', display: 'Resolved' },
          { value: 'open', display: 'Unresolved ' },
        ],
      },
      {
        label: 'Entered By',
        slug: 'createdByEmail',
        propertyType: PropertyType.MultiSelect,
        options: this.enteredByEmails,
      },
      {
        label: 'Created On',
        slug: 'createdOn',
        propertyType: PropertyType.Date,
      },
    ];

    if (!this.commentTypeOptions) {
      filterPropertyDefinitions.splice(
        filterPropertyDefinitions.findIndex((f) => f.slug === 'commentType'),
        1,
      );
    }

    this.filterDefinition = {
      filterPropertyDefinitions,
      filterCriteria: {
        propertyCriteria: [],
      },
    };
    this.sortProperties = filterPropertyDefinitions.map((property) => {
      return {
        propertySlug: property.slug,
        propertyLabel: property.label,
        propertyType: property.propertyType,
        direction: SortDirection.ASCENDING,
      };
    });
    this.setSort({ sorts: this.DEFAULT_SORTS });
  }
}
