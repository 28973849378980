import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { PresentationCollectionElement, ShowcasePresentationPanel } from '../../presentation';
import { ItemData } from 'src/app/common/item-data/item-data';
import { RootStoreState } from 'src/app/root-store';
import { Store } from '@ngrx/store';
import { PresentationSelectors } from '../../presentation-store';
import { AssortmentsSelectors } from 'src/app/common/assortments/assortments-store';
import { ItemDataSelectionToggleComponent } from '@common/items/item-data-selection-toggle/item-data-selection-toggle.component';
import { map, take, tap } from 'rxjs/operators';
import { ObjectUtil } from '@contrail/util';
import { PresentationService } from '../../presentation.service';
import { AddPinnedCommentsService } from '../../presentation-pinned-comments/add-pinned-comments.service';
import { PresentationItemPinnedComments } from '../../presentation-item-pinned-comments/presentation-item-pinned-comments.component';
import { ItemModalLauncher } from '@common/items/item-modal-launcher';
import { DocumentAnnotationService } from '../../document/document-annotation/document-annotation-service';
import { AnnotationOption } from '../../document/document-annotation/document-annotation-options';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
import { ShowcaseService } from 'src/app/showcase/showcase.service';

@Component({
  selector: 'app-presentation-collection-panel',
  templateUrl: './presentation-collection-panel.component.html',
  styleUrls: ['./presentation-collection-panel.component.scss'],
})
export class PresentationCollectionPanelComponent implements OnInit, OnChanges, OnDestroy {
  constructor(
    private store: Store<RootStoreState.State>,
    private presentationService: PresentationService,
    private showcaseService: ShowcaseService,
    private addPinnedCommentsService: AddPinnedCommentsService,
    private modalLauncher: ItemModalLauncher,
    private documentAnnotationService: DocumentAnnotationService,
  ) {
    this.annotationOptions = this.documentAnnotationService.getAnnotationOptions();
  }

  @Input() panel: ShowcasePresentationPanel;
  @Input() visible = true;

  public presentation: any;
  public itemData$: Observable<ItemData>;
  public itemPresentationElement: any;
  public dataSelectionToggleComponent = ItemDataSelectionToggleComponent;
  public itemPinnedCommentsComponent = PresentationItemPinnedComments;
  public editorMode$: Observable<any>;
  public editorMode = 'EDIT';

  public includeCommentWidget = true;
  private subscriptions = new Subscription();
  itemsMissingFromSource: any[];
  annotationOptions: AnnotationOption[];

  ngOnInit(): void {
    this.editorMode$ = this.store.select(EditorModeSelectors.editorMode);
    this.subscriptions.add(
      this.store.select(PresentationSelectors.currentShowcasePresentation).subscribe((showcasePresentation) => {
        this.presentation = showcasePresentation.presentation;
      }),
    );
    this.subscriptions.add(
      this.editorMode$.subscribe((editorMode) => {
        this.editorMode = editorMode;
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges() {
    if (!this.panel?.collectionElement) {
      return;
    }
    this.setItem(this.panel.collectionElement);
  }

  async setItem(element: PresentationCollectionElement) {
    this.itemPresentationElement = element;
    this.itemData$ = combineLatest([
      this.store.select(AssortmentsSelectors.selectBackingAssortmentItemByFamily(element.value)),
      this.store.select(AssortmentsSelectors.sourceAssortmentItemData),
    ]).pipe(
      map(([backingAssortmentItem, sourceAssortmentItems]) => {
        const sourceAssortmentItem = sourceAssortmentItems?.find(
          (sourceAssortmentItem) => sourceAssortmentItem.id === backingAssortmentItem?.properties.itemId,
        );
        if (sourceAssortmentItem) {
          backingAssortmentItem.properties = ObjectUtil.mergeDeep(
            ObjectUtil.cloneDeep(sourceAssortmentItem.properties),
            backingAssortmentItem.properties,
          );
        }
        if (backingAssortmentItem?.projectItem) {
          backingAssortmentItem.properties = ObjectUtil.mergeDeep(
            ObjectUtil.cloneDeep(backingAssortmentItem.properties),
            backingAssortmentItem.projectItem,
          );
        }
        // remove duplicate options
        const options = [];
        if (backingAssortmentItem && backingAssortmentItem.options) {
          backingAssortmentItem.options.forEach((option) => {
            if (!options.find((localOption) => option.id === localOption.id)) {
              options.push(option);
            }
          });
          backingAssortmentItem.options = options;
        }

        backingAssortmentItem?.options.forEach((option) => {
          this.annotationOptions?.forEach((annotationOption) => {
            if (option.properties[annotationOption.property]) {
              if (!option.annotations) {
                option.annotations = [];
              }
              if (!option.annotations.includes(annotationOption.svgIcon)) {
                option.annotations.push(annotationOption.svgIcon);
              }
            }
          });
        });

        return backingAssortmentItem;
      }),
    );
    // this.currentItem = this.sourceAssortment?.getItemDataForItem(element.value);
    if (this.showcaseService.showSourceAssortmentWarning) {
      this.itemsMissingFromSource = await this.presentationService.getStatusMessages(
        this.itemPresentationElement.children.map((child) => child.value),
      );
    } else {
      this.itemsMissingFromSource = [];
    }
  }

  handleMenuEvent(event) {
    console.log(event);
    if (event.menuType === 'comments') {
      this.addPinnedCommentsService.addItemComment(event.itemId, this.panel.frame.id, this.editorMode);
    } else if (event.menuType === 'viewItem') {
      this.viewItem(event.itemId);
    }
  }

  viewItem(itemId) {
    combineLatest([
      this.store.select(AssortmentsSelectors.selectSourceItem(itemId)),
      this.store.select(AssortmentsSelectors.selectItemFromBackingAssortment(itemId)),
    ])
      .pipe(
        take(1),
        tap(([sourceItem, backingItem]) => {
          if (sourceItem) {
            this.modalLauncher.openModal(sourceItem, this.editorMode);
          } else if (backingItem) {
            this.modalLauncher.openModal(new ItemData(backingItem), this.editorMode);
          }
        }),
      )
      .subscribe();
  }
}
