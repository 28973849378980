import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { ShowcaseModule } from '../showcase/showcase.module';
import { HomeCtaComponent } from './home-cta/home-cta.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [HomeComponent, HomeCtaComponent],
  imports: [CommonModule, RouterModule],
  exports: [HomeComponent],
})
export class HomeModule {}
