<div id="navigator" #navigator *ngIf="frames" class="navigator">
  <button class="btn-36" mat-button (click)="prev()">
    <mat-icon> arrow_back_ios_new </mat-icon>
  </button>
  <button class="btn-36" mat-button [matMenuTriggerFor]="menu">
    <mat-icon> menu </mat-icon>
  </button>
  <button class="btn-36" mat-button (click)="next()">
    <mat-icon> arrow_forward_ios </mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <ng-container *ngFor="let frame of frames">
      <button
        *ngIf="['home', 'document', 'grid', 'iframe'].includes(frame.type)"
        mat-menu-item
        (click)="goToFrame(frame)"
      >
        {{ frame.name }}
      </button>
      <button *ngIf="frame.type === 'collection'" mat-menu-item [matMenuTriggerFor]="subMenu">{{ frame.name }}</button>
      <mat-menu #subMenu="matMenu">
        <ng-container *ngFor="let element of frame?.collection?.set">
          <button mat-menu-item *ngIf="element.enabled" (click)="goToSetElement(frame, element)">
            {{ element.label }}
          </button>
        </ng-container>
      </mat-menu>
    </ng-container>
  </mat-menu>

  <mat-divider vertical class="divider-line !mx-1"></mat-divider>

  <div class="actions flex items-center">
    <button class="btn-36" mat-button (click)="restartPresentation()">
      <mat-icon svgIcon="showcase_reload_action"></mat-icon>
    </button>
    <button class="btn-36" mat-button (click)="toggleFullscreen()">
      <mat-icon>{{ !isFullscreen ? 'fullscreen' : 'fullscreen_exit' }}</mat-icon>
    </button>
  </div>

  <mat-divider vertical class="divider-line !mx-1"></mat-divider>

  <app-current-assortment-selector
    *ngIf="['EDIT'].includes(editorMode$ | async)"
    [params]="{ embedded: true, displayCount: true }"
  ></app-current-assortment-selector>

  <mat-divider *ngIf="['EDIT'].includes(editorMode$ | async)" vertical class="divider-line !mr-1"></mat-divider>

  <button *ngIf="['EDIT'].includes(editorMode$ | async)" class="btn-36" mat-button [routerLink]="['../explorer']">
    <mat-icon svgIcon="contrail_compass"></mat-icon>
  </button>

  <app-header-comments></app-header-comments>

  <ng-container>
    <button class="btn-36" mat-button [matMenuTriggerFor]="downloadMenu"><mat-icon>download</mat-icon></button>
    <mat-menu #downloadMenu="matMenu" class="menu-md" yPosition="above">
      <button mat-menu-item (click)="download('powerpoint')">Powerpoint</button>
      <button mat-menu-item (click)="download('excel')">Excel</button>
    </mat-menu>
    <app-download-content-indicator></app-download-content-indicator>
  </ng-container>
</div>
