<app-mat-spinner [overlay]="true" [loading]="isLoading" *ngIf="isLoading"></app-mat-spinner>

<div class="itemCardHoverEnable" (contextmenu)="onContextMenu($event)" [attr.id]="itemData.id">
  <div class="top-widgets">
    <app-item-data-selection-toggle
      [itemData]="itemData"
      (isLoading)="handleInProgressOverlay($event)"
    ></app-item-data-selection-toggle>
  </div>
  <div class="absolute top-4 right-7">
    <app-item-pinned-comments [item]="itemData"></app-item-pinned-comments>
  </div>
  <div (click)="openModal()" class="itemHolderCard">
    <div class="image-placeholder" *ngIf="!getImageUrl()">
      <mat-icon>insert_photo</mat-icon>
    </div>
    <div class="image-holder" *ngIf="getImageUrl()">
      <img [attr.src]="getImageUrl() | secureImage | async" />
    </div>

    <div class="item-details">
      <div class="title" [matTooltip]="itemData?.properties.itemName">{{ itemData?.properties.itemName }}</div>
      <div class="optionName" [matTooltip]="itemData?.properties.optionName">
        {{ itemData?.properties.optionName }}
        <img
          src="\assets\primary_color_indicator.png"
          *ngIf="itemData?.properties?.primary === 'Yes'"
          class="primary_color_indicator"
        />
      </div>

      <div class="meta" *ngIf="metaViewDefinition?.properties.length > 0">
        <div *ngFor="let prop of metaViewDefinition?.properties">
          <app-property-value
            *ngIf="itemData?.properties[prop.slug]"
            [value]="itemData?.properties[prop.slug]"
            [property]="prop.propertyDefinition"
            [propertyType]="prop.propertyType"
          >
          </app-property-value>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>
<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent let-target="target">
    <button mat-menu-item (click)="openComment()">Comment</button>
    <button mat-menu-item (click)="openModal()">Details</button>
  </ng-template>
</mat-menu>
