import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { ItemData } from '../../item-data/item-data';
import { ViewDefinition } from '../../views/view-definitions/view-definition';
import { ViewManagerService } from '../../views/view-manager.service';

@Component({
  selector: 'app-item-data-chooser-card',
  templateUrl: './item-data-chooser-card.component.html',
  styleUrls: ['./item-data-chooser-card.component.scss'],
})
export class ItemDataChooserCardComponent implements OnChanges, OnInit {
  @Output() add = new EventEmitter();
  @Output() toggleSelection = new EventEmitter();
  @Input() dataObj: any;
  @Input() level: string;
  @Input() allowAdd: boolean;
  @Input() allowAddMultiple = false;
  @Input() isExistingItem;
  @Input() selectedItemIndex: any;
  public metaViewDefinition: ViewDefinition;
  public itemInCollection = false;
  public justAdded = false;
  constructor(
    private viewsService: ViewManagerService,
    private store: Store<RootStoreState.State>,
  ) {}

  async ngOnInit() {
    if (this.dataObj) {
      this.metaViewDefinition = await this.viewsService.getView('common:item_chooser_card', this.dataObj.item.typeId);
    }
  }
  async ngOnChanges() {}

  addItemData(itemData: ItemData) {
    if (this.isExistingItem || this.justAdded) {
      return;
    }
    console.log('Add Entity: ', itemData);
    this.justAdded = true;
    this.add.emit(itemData);
    setTimeout(() => {
      this.justAdded = false;
    }, 3000);
  }
  getAddIcon() {
    return this.isExistingItem || this.justAdded ? 'add_circle' : 'add_circle_outline';
  }

  toggleCheckbox(event) {
    this.toggleSelection.emit({ itemData: this.dataObj, selected: event.checked });
  }
}
