import { Entities, SortOrderOptions } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { SortDefinition, SortDirection } from '../../components/sort/sort-definition';
import { combineLatest, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { ItemData } from '../../item-data/item-data';
import { FilterHelper } from '../../types/filters/filter-helper';
import { ChooserFilterConfig, ItemDataChooserDataSource } from './item-data-chooser-data-source';

export class ItemLibraryChooserDataSource extends ItemDataChooserDataSource {
  constructor(
    protected store: Store<RootStoreState.State>,
    protected filterConfigSubject: Observable<ChooserFilterConfig>,
    protected sortConfigSubject: Observable<SortDefinition[]>,
    protected existingItemIdsSubject: Observable<any>,
    protected showAllSubject: Observable<any>,
  ) {
    super(store, filterConfigSubject, sortConfigSubject, existingItemIdsSubject, showAllSubject, null);
    this.initFilteredDataObservable();
    this.initResultsObservable();
  }

  protected async initFilteredDataObservable() {
    this.filteredData$ = combineLatest([this.filterConfigSubject, this.sortConfigSubject]).pipe(
      switchMap(async ([filterConfig, sortConfig]) => {
        if (!filterConfig || !sortConfig) {
          return;
        }
        let data;
        // ITEM LIBRARY (SEARCH)
        const relations = [];
        let searchTerm = filterConfig.searchTerm.trim() || '';
        const filterDefinition = filterConfig.filterDefintion;
        const criteria = filterConfig.baseCriteria;
        if (!searchTerm?.endsWith('*')) {
          searchTerm += '*';
        }
        console.log('criteria: ', criteria, filterDefinition);
        let apiCriteria = Object.assign({}, criteria);
        const filterCriteria = FilterHelper.toSimpleCriteria(filterDefinition);
        console.log('filterCriteria: ', filterCriteria);
        if (filterCriteria) {
          apiCriteria = Object.assign(apiCriteria, filterCriteria);
        }
        this.loadingSubject.next(true);
        const sortOrders = sortConfig.map((sortDefinition) => {
          return {
            order: sortDefinition.direction === SortDirection.ASCENDING ? SortOrderOptions.ASC : SortOrderOptions.DESC,
            orderField: sortDefinition.propertySlug,
          };
        });
        data = await new Entities().get({
          entityName: 'item',
          relations,
          criteria: apiCriteria,
          take: 100,
          search: searchTerm,
          order: sortOrders,
        });
        this.loadingSubject.next(false);
        data = data.map((obj) => new ItemData(obj));
        console.log('ItemLibraryChooserDataSource: ', data);
        return data;
      }),
    );
  }
}
