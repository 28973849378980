import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExplorerComponent } from './explorer.component';
import { ShowcaseModule } from '../showcase/showcase.module';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ComponentsModule } from '../common/components/components.module';
import { CommentsModule } from '@common/comments/comments.module';
import { ItemsModule } from '@common/items/items.module';

@NgModule({
  declarations: [ExplorerComponent],
  imports: [
    CommonModule,
    ShowcaseModule,
    ItemsModule,
    MatIconModule,
    MatButtonModule,
    ComponentsModule,
    CommentsModule,
  ],
})
export class ExplorerModule {}
