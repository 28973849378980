<div class="header" *ngIf="showHeader">
  <div class="title">Item Chooser</div>
  <div (click)="close.emit(); selectedItems = []" class="cursor-pointer flex-center">
    <mat-icon>close</mat-icon>
  </div>
</div>

<div class="chooser" *ngIf="!showSelectSource">
  <div class="source-wrapper centered-vertically" *ngIf="allowSourceChange">
    <div (click)="toggleSourceSourceSelector(true)" class="centered-vertically">
      <img
        width="23"
        height="23"
        *ngIf="(chooserSourceOption$ | async)?.sourceType === 'ITEM_LIBRARY'"
        class="source-icon"
        src="assets/images/library_icon.svg"
      />
      <img
        *ngIf="(chooserSourceOption$ | async)?.sourceType !== 'ITEM_LIBRARY'"
        class="source-icon"
        src="assets/images/plan_icon.svg"
      />
      <span>{{ (chooserSourceOption$ | async)?.name }}</span>
    </div>
    <div class="cursor-pointer text-accent" (click)="toggleSourceSourceSelector(true)">Change</div>
  </div>
  <div class="search-bar-wrapper">
    <app-search-bar [placeholder]="'Search items'"></app-search-bar>
  </div>
  <div class="menu-wrapper" *ngIf="showFilter">
    <div class="sort-filter-menu">
      <app-menu
        name="Filter"
        icon="filter_list"
        position="left"
        [highlight]="filterDefinition?.filterCriteria?.propertyCriteria?.length"
      >
        <app-filter-panel
          [equalsOnly]="dataSourceType === 'ITEM_LIBRARY'"
          [filterDefinition]="filterDefinition"
          [propertyTypeDefaultFilterConditions]="propertyTypeDefaultFilterConditions"
          (filterCriteriaChange)="setFilterCriteria($event)"
        >
        </app-filter-panel>
      </app-menu>

      <app-menu name="Sort" icon="sort" position="left" [highlight]="currentSorts?.length">
        <app-sort-panel
          data-test="sort-panel"
          [currentSorts]="currentSorts"
          [properties]="sortProperties"
          (performSort)="performSort($event)"
        >
        </app-sort-panel>
      </app-menu>
    </div>

    <!--<div class="clearAll cursor-pointer text-accent" (click)="clearFilters()">Clear</div>-->
    <div
      class="cursor-pointer text-accent select-all-link"
      data-test="select-all-items-link"
      *ngIf="
        allowAddMultipleEntities &&
        (dataSource?.results$ | async)?.length > 0 &&
        numberOfEligibleItems !== selectedItems.length
      "
      (click)="handleToggleSelectAll()"
    >
      Select all
    </div>
    <div
      class="cursor-pointer text-accent select-all-link"
      data-test="deselect-all-items-link"
      *ngIf="
        allowAddMultipleEntities &&
        (dataSource?.results$ | async)?.length > 0 &&
        numberOfEligibleItems > 0 &&
        numberOfEligibleItems === selectedItems.length
      "
      (click)="handleToggleSelectAll()"
    >
      Deselect all
    </div>

    <div class="show-all-control" *ngIf="showAllControl">
      <app-menu name="" icon="more_horiz" position="left" [highlight]="dateFilter || !showAll">
        <div class="title">Advanced Options</div>
        <mat-divider></mat-divider>
        <div class="advanced-option">
          <mat-slide-toggle color="primary" (change)="toggleShowAll($event)" [checked]="false" labelPosition="before"
            >Eligible only</mat-slide-toggle
          >
          <app-date-filter
            *ngIf="(chooserSourceOption$ | async)?.sourceType !== 'ITEM_LIBRARY'"
            [dateFilterAttribute]="dateFilterAttribute"
            (dateFilterChanged)="dateFilterChanged($event)"
          >
          </app-date-filter>
        </div>
      </app-menu>
      <div class="count">{{ ((dataSource?.results$ | async)?.length | number) || 0 }}</div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="data-pane" *ngIf="(dataSource?.results$ | async)?.length && !(dataSource?.loading$ | async)">
    <virtual-scroller
      [style.height]="resultsHeight"
      #scroll
      [items]="dataSource?.results$ | async"
      class="narrow-scroll"
    >
      <app-item-data-chooser-card
        data-test="item-data-chooser-card"
        [draggable]="draggable && !testExisting(dataObj)"
        [id]="dataObj.id"
        (dragstart)="startDrag($event)"
        (click)="handleClick(dataObj)"
        (add)="addItemData($event)"
        (toggleSelection)="toggleSelection($event)"
        class="data-row"
        [dataObj]="dataObj"
        [level]="level"
        [allowAdd]="allowAddEntity"
        [allowAddMultiple]="allowAddMultipleEntities"
        [isExistingItem]="testExisting(dataObj)"
        [selectedItemIndex]="getSelectedItemIndex(dataObj)"
        *ngFor="let dataObj of scroll.viewPortItems"
      >
      </app-item-data-chooser-card>
    </virtual-scroller>
  </div>
  <mat-divider></mat-divider>
  <div
    class="action"
    *ngIf="
      selectedItems.length > 0 &&
      allowAddMultipleEntities &&
      (dataSource?.results$ | async)?.length &&
      !(dataSource?.loading$ | async)
    "
  >
    <button
      data-test="add-selected-items-button"
      mat-raised-button
      color="primary"
      (click)="handleAddSelectedItems($event)"
    >
      ADD SELECTED
    </button>
  </div>
  <app-empty-placeholder
    class="!m-auto"
    *ngIf="(dataSource?.results$ | async)?.length === 0 && !(dataSource?.loading$ | async)"
    [title]="title"
    [icon]="icon"
    [footer]="footer"
  ></app-empty-placeholder>
  <div class="loading-container" *ngIf="dataSource?.loading$ | async">
    <mat-progress-spinner diameter="50" mode="indeterminate"> </mat-progress-spinner>
    <div class="message">Searching...</div>
  </div>
</div>
<div *ngIf="showSelectSource">
  <app-item-data-source-selector
    (selectSource)="handleSourceChange($event)"
    [sourceAssortment]="sourceAssortment"
    (cancel)="toggleSourceSourceSelector(false)"
  >
  </app-item-data-source-selector>
</div>
