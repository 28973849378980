import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { Observable, from, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { Entities } from '@contrail/sdk';

import { AuthSelectors } from '@common/auth/auth-store';
import { EditorModeActions } from '@common/editor-mode/editor-mode-store';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { WorkspacePrincipalRole } from '@common/workspaces/workspace-principal';
import { ShowcaseService } from './showcase.service';
import { ShowcaseActions } from './showcase-store';
import { ViewManagerService } from '@common/views/view-manager.service';
import { AssortmentsActions } from '@common/assortments/assortments-store';
import { CustomFontsActions } from '@common/custom-fonts/custom-fonts-store';

@Injectable({
  providedIn: 'root',
})
export class ShowcaseEditorModeResolver implements Resolve<string> {
  constructor(
    private viewService: ViewManagerService, // keep ViewManagerService
    private store: Store<RootStoreState.State>,
    private showcaseService: ShowcaseService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    return from(this.getEditorModeForUser(route)).pipe(
      switchMap((mode) => {
        if (mode) {
          this.store.dispatch(EditorModeActions.setEditorMode({ editorMode: mode }));
          this.store.dispatch(CustomFontsActions.loadCustomFonts());
        }
        return of(mode);
      }),
    );
  }

  private async getEditorModeForUser(route: ActivatedRouteSnapshot): Promise<EditorMode> {
    let sharedLink;
    this.store
      .select(AuthSelectors.sharedLink)
      .pipe(
        take(1),
        tap((link) => (sharedLink = link)),
      )
      .subscribe();

    await this.viewService.initViewMap();

    if (sharedLink) {
      return EditorMode[sharedLink.accessLevel];
    }

    const showcaseId = route.params.id;
    const showcase = await this.showcaseService.getShowcaseById(showcaseId);
    const showSourceAssortmentWarning =
      showcase?.showSourceAssortmentWarning !== false && showcase?.sourceAssortmentId ? true : false;
    this.showcaseService.setShowSourceAssortmentWarning(showSourceAssortmentWarning);
    this.store.dispatch(AssortmentsActions.setSourceAssortmentLoaded({ sourceAssortmentLoaded: false }));
    this.store.dispatch(ShowcaseActions.loadCurrentShowcaseSuccess({ showcase }));

    // load showcase level default viewDefinitions
    this.viewService.getView('showcase:item_details', null, `showcase:${showcaseId}`);
    this.viewService.getView('showcase:grid_frame', null, `showcase:${showcaseId}`);

    const userRole = await new Entities().get({
      entityName: 'workspace',
      id: showcase.workspaceId,
      relation: 'callerRole',
    });
    if (userRole.role === WorkspacePrincipalRole.VIEWER) {
      return EditorMode.VIEW;
    }

    return route.queryParams.mode || EditorMode.EDIT;
  }
}
