import { SortDefinition } from '../../components/sort/sort-definition';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { AssortmentUtil } from '../../assortments/assortment-util';
import { AssortmentsService } from '../../assortments/assortments.service';
import { FilterObjects } from '../../components/filter/filter-objects';
import { ItemData } from '../../item-data/item-data';
import { ChooserFilterConfig, ItemDataChooserDataSource } from './item-data-chooser-data-source';
import { FilterDefinition } from '../../types/filters/filter-definition';

export class AssortmentChooserDataSource extends ItemDataChooserDataSource {
  protected assortmentSubject: Subject<Array<ItemData>> = new BehaviorSubject([]);
  protected assortmentItemData$ = this.assortmentSubject.asObservable();

  constructor(
    protected store: Store<RootStoreState.State>,
    protected filterConfigSubject: Observable<ChooserFilterConfig>,
    protected sortConfigSubject: Observable<SortDefinition[]>,
    protected existingItemIdsSubject: Observable<any>,
    protected showAllSubject: Observable<any>,
    protected dateFilterSubject: Observable<FilterDefinition>,
    protected assortmentId,
  ) {
    super(store, filterConfigSubject, sortConfigSubject, existingItemIdsSubject, showAllSubject, dateFilterSubject);
    this.initFilteredDataObservable();
    this.initResultsObservable();
    console.log('AssortmentChooserDataSource: CONSTRUCTOR');
    this.loadAssortment();
  }

  protected async initFilteredDataObservable() {
    console.log('AssortmentChooserDataSource: initFilteredDataObservable INIT: ', this.assortmentItemData$);
    this.filteredData$ = combineLatest([
      this.filterConfigSubject,
      this.dateFilterSubject,
      this.sortConfigSubject,
      this.assortmentItemData$,
    ]).pipe(
      switchMap(async ([filterConfig, dateFilter, sortConfig, assortmentItemData]) => {
        console.log('AssortmentChooserDataSource: filteredDataObservable');

        let data;
        const criteria = filterConfig.baseCriteria;
        const searchTerm = filterConfig.searchTerm;
        const filterDefinition = filterConfig.filterDefintion;

        // SOURCE ASSORTMENT CONSTRAINT, OPTION LEVEL
        data = assortmentItemData;
        data = this.filterLocalItemData({ searchTerm, itemFamilyId: criteria.itemFamilyId }, data);
        if (filterDefinition) {
          data = FilterObjects.filter(data, filterDefinition);
        }
        if (dateFilter) {
          data = this.filterByAssortmentItems(data, dateFilter);
        }
        // data = FilterUtil.filterDroppedItems(data, auth?.currentOrg?.orgSlug);
        console.log('AssortmentChooserDataSource: ', data);
        this.sortData(data, sortConfig);
        return data;
      }),
    );
  }

  private async loadAssortment() {
    this.loadingSubject.next(true);
    const assortment = await AssortmentsService.getAssortment(this.assortmentId);
    const assortmentItems = assortment?.assortmentItems;
    const itemData = AssortmentUtil.convertAssortmentItemsToItemData(assortmentItems);
    console.log('AssortmentChooserDataSource: itemData: ', itemData);
    this.assortmentSubject.next(itemData);
    this.loadingSubject.next(false);
  }
}
