<div class="header">
  <div class="title">{{ itemData?.properties.name }}</div>
  <div class="subTitle">{{ itemData?.properties.optionName }}</div>
</div>

<div class="body">
  <div class="left flex-center h-full w-fit m-auto">
    <app-item-image-viewer class="h-full" [itemData]="itemData"></app-item-image-viewer>
  </div>

  <div class="right">
    <div class="item-info">
      <mat-tab-group [disablePagination]="true">
        <mat-tab label="Summary">
          <app-item-properties-panel
            *ngIf="viewDefinition"
            [itemData]="itemData"
            [viewDefinition]="viewDefinition"
          ></app-item-properties-panel>
        </mat-tab>
      </mat-tab-group>
    </div>
    <!-- <div class="actions">
      <app-add-item-to-list *ngIf="['EDIT'].includes(editorMode)" [itemData]="itemData"></app-add-item-to-list>
    </div> -->
  </div>
</div>
