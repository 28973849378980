import { Types } from '@contrail/sdk/lib/types';
import { EditableCheck, TypeConstraintsHelper } from '@contrail/type-validation/lib/constraints';
import { TypePropertyFormConfiguration } from '../../type-property-form/type-property-form.component';

export class TypePropertyFormHelper {
  public static async isDisabled(propertyFormConfiguration: TypePropertyFormConfiguration, entity?: any) {
    if (propertyFormConfiguration.isFilter) {
      return false;
    }
    let disabled = false;
    let editableCheck: EditableCheck;
    if (entity) {
      const type = await new Types().getType({ id: entity.typeId });
      editableCheck = await TypeConstraintsHelper.isPropertyEditable(
        type,
        propertyFormConfiguration.typeProperty,
        entity,
      );
    }
    disabled = propertyFormConfiguration.editable === false || editableCheck?.editable === false;
    return disabled;
  }
}
