import { Injectable } from '@angular/core';
import { Entities, Types } from '@contrail/sdk';
import { Type } from '@contrail/types';
import { Store } from '@ngrx/store';
import { RootStoreState, UserSessionSelectors } from 'src/app/root-store';
import { AnalyticsService } from '../analytics/analytics.service';
import { AuthSelectors } from '../auth/auth-store';
import { AuthService, User } from '../auth/auth.service';
import { CommentsActions } from './comments-store';
import { PositionDefinition } from '@contrail/documents';

export enum COMMENTS_ACTION_TYPE {
  'APPROVED',
  'REJECTED',
}

export interface CommentOwnerInfo {
  id?: string;
  name?: string;
  entityType?: string;
  modalTitle?: string;
  value?: string;
  property?: {
    slug?: string;
    label?: string;
    propertyType?: string;
  };
  subContextReference?: string;
  documentPosition?: PositionDefinition;
  documentElementId?: string;
  gridSectionId?: string;
}
export interface Comment {
  id?: string;
  text?: string;
  ownedByReference?: string;
  contextReference?: string;
  ownedByPropertySlug?: string; // comment is about a particular property.
  subContextReference?: string; // comment belongs to a frame.
  documentPosition?: PositionDefinition; // comment is at a certain document or element position.
  documentElementId?: string; // comment is about a particular element.
  gridSectionId?: string; // comment is about a particular grid section.
  changeSuggestion?: ChangeSuggestion; // change suggestion
  status?: string; // open/closed
  ownedBy?: any;
  context?: any;
  updatedOn?: Date;
  createdOn?: Date;
  createdBy?: User;
  updatedBy?: User;
  updatedById?: string;
  createdById?: string;
  orgId?: string;
  colorHexCode?: string;
}
export interface ChangeSuggestion {
  changeType?: string;
  changeDetails: {
    propertySlug?: string;
    newValue?: string;
    oldValue?: string;
  };
  changeStatus?: string; // pending/approved/rejected
}

@Injectable({
  providedIn: 'root',
})
export class CommentsService {
  public contextReference;
  private user;

  constructor(
    private store: Store<RootStoreState.State>,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
  ) {
    this.store.select(UserSessionSelectors.currentSessionId).subscribe((sessionId) => {
      console.log(sessionId);
      if (sessionId) {
        this.contextReference = sessionId;
        this.store.dispatch(CommentsActions.loadCommentsSuccess({ data: [] })); // Set comments to be empty when switching between showcases
        this.store.dispatch(CommentsActions.loadComments({ contextReference: this.contextReference }));
      }
    });
    this.store.select(AuthSelectors.selectAuthContext).subscribe((authContext) => {
      if (authContext) {
        this.user = authContext.user;
      }
    });
  }

  async loadComments(contextReference: string) {
    const option = {
      entityName: 'comment',
      criteria: { contextReference },
      relations: ['ownedBy', 'ownedBy.item', 'createdBy'],
    };
    return await new Entities().get(option);
  }

  async addComment(text, ownerInfo: CommentOwnerInfo, changeSuggestion: ChangeSuggestion): Promise<Comment> {
    let ownedByReference;
    this.analyticsService.emitEvent({ eventName: 'ADD_COMMENT', eventTarget: ownerInfo?.id });

    if (!ownerInfo) {
      ownedByReference = this.contextReference;
    } else {
      ownedByReference = ownerInfo.entityType + ':' + ownerInfo.id;
    }
    let comment: Comment = {
      text,
      ownedByReference,
      contextReference: this.contextReference,
      changeSuggestion,
      ownedByPropertySlug: ownerInfo?.property?.slug,
      subContextReference: ownerInfo?.subContextReference,
      documentPosition: ownerInfo?.documentPosition,
      documentElementId: ownerInfo?.documentElementId,
      gridSectionId: ownerInfo?.gridSectionId,
    };
    comment.status = 'open';

    console.log(`newComment ${JSON.stringify(comment)}`);

    const option = {
      entityName: 'comment',
      object: comment,
    };
    comment = await new Entities().create(option);

    const newComment = await new Entities().get({
      entityName: 'comment',
      id: comment.id,
      relations: ['ownedBy'],
    });
    newComment.createdBy = this.user;
    return newComment;
  }

  async updateComment(comment: Comment): Promise<Comment> {
    this.analyticsService.emitEvent({ eventName: 'UPDATE_COMMENT' });
    const option = {
      entityName: 'comment',
      id: comment.id,
      object: comment,
    };

    await new Entities().update(option);

    const newComment = await new Entities().get({
      entityName: 'comment',
      id: comment.id,
      relations: ['ownedBy'],
    });

    newComment.updatedBy = this.user;
    newComment.createdBy = comment.createdBy;

    // console.log(`The updated comment is ${JSON.stringify(updated)}`);
    console.log(` comment update is successful`);

    return newComment;
  }

  async deleteComment(comment: Comment) {
    this.analyticsService.emitEvent({ eventName: 'DELETE_COMMENT' });
    const option = {
      entityName: 'comment',
      id: comment.id,
    };
    await new Entities().delete(option);
    return comment;
  }

  canUpdateComment(comment: Comment, accessLevel = 'EDIT'): boolean {
    if (accessLevel === 'VIEW') {
      return false;
    } else if (accessLevel === 'COMMENT') {
      return this.isCommentOwner(comment);
    }
    return this.authService.isAdmin() || this.isCommentOwner(comment);
  }
  canDeleteComment(comment: Comment, accessLevel = 'EDIT'): boolean {
    if (accessLevel === 'VIEW') {
      return false;
    } else if (accessLevel === 'COMMENT') {
      return this.isCommentOwner(comment);
    }
    return this.authService.isAdmin() || this.isCommentOwner(comment);
  }
  isCommentOwner(comment: Comment): boolean {
    return this.user && comment.createdBy?.id === this.user?.id;
  }

  async getProperty(comment: Comment, propertySlug) {
    const ownerTypeDef: Type = await this.getOwnedByType(comment);
    const typeProperties = ownerTypeDef.typeProperties;
    if (typeProperties) {
      return typeProperties.find((prop) => prop.slug === propertySlug);
    }
    return;
  }

  async getOwnedByType(comment: Comment) {
    if (!comment?.ownedByReference) {
      return;
    }
    // get by root / path
    const root = comment?.ownedByReference.split(':')[0];
    return await new Types().getByRootAndPath({ root, path: root });
  }

  public static isGridSectionComment(comment: Comment, subContextReference, gridSectionId) {
    return (
      !comment.documentElementId &&
      !comment.documentPosition &&
      comment?.subContextReference &&
      comment.subContextReference === subContextReference &&
      comment?.gridSectionId &&
      comment.gridSectionId === gridSectionId
    );
  }

  public static isEntityOwnedComment(comment: Comment, ownedByReference, subContextReference) {
    return (
      !comment.documentElementId &&
      !comment.documentPosition &&
      !comment.gridSectionId &&
      comment?.subContextReference &&
      comment.subContextReference === subContextReference &&
      comment?.ownedByReference === ownedByReference
    );
  }

  public static isDocumentComment(comment: Comment, subContextReference) {
    return (
      (comment.documentElementId || comment.documentPosition) && comment?.subContextReference === subContextReference
    );
  }

  public static filterByOwnerInfo(comment: Comment, ownerInfo: CommentOwnerInfo) {
    return (
      // either comment is owned by a property
      (comment.ownedByPropertySlug &&
        comment.ownedByPropertySlug === ownerInfo?.property?.slug &&
        comment.ownedByReference?.indexOf(ownerInfo.id) > -1) || // or comment is owned by document element
      (comment.documentPosition &&
        comment.documentPosition?.x === ownerInfo?.documentPosition?.x &&
        comment.documentPosition?.y === ownerInfo?.documentPosition?.y &&
        (comment.documentElementId === ownerInfo?.documentElementId || comment.documentElementId == null)) || // or comment is owned by sub context reference (presentation frame preview or item)
      (!ownerInfo.documentPosition &&
        !ownerInfo.documentElementId &&
        !ownerInfo.gridSectionId &&
        ownerInfo?.subContextReference &&
        ownerInfo?.entityType &&
        ownerInfo?.id &&
        CommentsService.isEntityOwnedComment(
          comment,
          `${ownerInfo.entityType}:${ownerInfo.id}`,
          ownerInfo.subContextReference,
        )) || // or comment is owned by grid section
      (!ownerInfo?.documentPosition &&
        !ownerInfo?.documentElementId &&
        ownerInfo?.subContextReference &&
        ownerInfo?.gridSectionId &&
        CommentsService.isGridSectionComment(comment, ownerInfo.subContextReference, ownerInfo.gridSectionId))
    );
  }
}
