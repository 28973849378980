import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CommentOwnerInfo } from 'src/app/common/comments/comments.service';
import { PositionDefinition } from '@contrail/documents';
import { CommentsActions } from 'src/app/common/comments/comments-store';

export const ITEM_PINNED_COMMENT_ID_PREFIX = 'item-pinned-comment-';
export const FRAME_ID_PREFIX = 'frame-';
@Injectable({
  providedIn: 'root',
})
export class AddPinnedCommentsService {
  private readonly PINNED_COMMENT_OFFSET = 4;

  constructor(private store: Store<RootStoreState.State>) {}

  /**
   * Adds comment overlay pinned to a @documentPosition converted into window position
   * @param ownerInfo
   * @param documentPosition
   */
  public addCommentToDocumentElement(
    ownerInfo: CommentOwnerInfo,
    documentPosition: PositionDefinition,
    svgId: string,
    commentsAccessLevel = 'EDIT',
  ) {
    this.store.dispatch(CommentsActions.setCommentsAccessLevel({ commentsAccessLevel }));
    this.store.dispatch(
      CommentsActions.showCommentOverlay({
        ownerInfo,
        position: {
          x: documentPosition.x,
          y: documentPosition.y,
        },
      }),
    );
  }

  /**
   * Adds comment overlay pinned to an HTML element with id @elementId
   * @param ownerInfo
   * @param elementId
   */
  public addCommentToHTMLElement(
    ownerInfo: CommentOwnerInfo,
    elementId: string,
    frameId?: string,
    commentsAccessLevel = 'EDIT',
  ) {
    if (frameId) {
      elementId = `${FRAME_ID_PREFIX}${frameId}-${elementId}`;
    }
    const pinnedCommentEl = document.getElementById(elementId);

    if (!pinnedCommentEl) {
      return;
    }
    const pinnedCommentPosition = pinnedCommentEl.getBoundingClientRect();
    if (frameId) {
      ownerInfo.subContextReference = `presentation-frame:${frameId}`;
    }
    this.store.dispatch(CommentsActions.setCommentsAccessLevel({ commentsAccessLevel }));
    this.store.dispatch(
      CommentsActions.showCommentOverlay({
        ownerInfo,
        position: {
          x: pinnedCommentPosition.left,
          y: pinnedCommentPosition.bottom + this.PINNED_COMMENT_OFFSET,
        },
      }),
    );
  }

  public addItemComment(itemValue: string, frameId: string, commentsAccessLevel = 'EDIT') {
    this.addCommentToHTMLElement(
      {
        entityType: 'item',
        id: itemValue,
      },
      ITEM_PINNED_COMMENT_ID_PREFIX + itemValue,
      frameId,
      commentsAccessLevel,
    );
  }
}
