import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { IContent } from '../content-holder-details/content-holder-details.component';

@Component({
  selector: 'app-content-info-panel',
  template: `
    <div>
      <div class="flex-between-center h-14">
        <h6 [matTooltip]="contentFileName" matTooltipPosition="above" class="ml-4">
          {{ contentFileName | shorten: 25 }}
        </h6>
      </div>
      <mat-divider></mat-divider>

      <mat-expansion-panel class="mat-elevation-z0" expanded>
        <mat-expansion-panel-header>
          <mat-panel-title> Details </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="my-3 content-details">
          <div class="info-label-value">
            <p>Extension</p>
            <p>{{ contentExtension | uppercase }}</p>
          </div>
          <div class="info-label-value">
            <p>Type</p>
            <p>{{ contentType | uppercase }}</p>
          </div>
          <div class="info-label-value">
            <p>Size</p>
            <p>{{ contentSize | number }} KB</p>
          </div>
          <div class="info-label-value">
            <p>Dimensions</p>
            <p>{{ 'N/A' }}</p>
          </div>
          <div class="info-label-value">
            <p>Created on</p>
            <p>{{ contentCreatedOn | date: 'MM/dd/yyyy' }}</p>
          </div>
          <div class="info-label-value">
            <p>Last updated on</p>
            <p>{{ contentCreatedOn | date: 'MM/dd/yyyy' }}</p>
          </div>
        </div>

        <button
          *ngIf="(!downloadBtn || configureBtn) && is3D"
          mat-raised-button
          color="primary"
          class="!mx-6 !my-4 w-64"
          (click)="emitConfigure()"
        >
          CONFIGURE
        </button>
        <div *ngIf="downloadBtn && configureBtn && is3D" class="-mt-4"></div>
        <button *ngIf="downloadBtn" mat-raised-button color="primary" class="!mx-6 !my-4 w-64" (click)="download()">
          <mat-icon>download</mat-icon> <span class="ml-2">DOWNLOAD</span>
        </button>
      </mat-expansion-panel>
      <mat-divider></mat-divider>
    </div>
  `,
  styles: [
    `
      .info-label-value {
        @apply text-black60 text-sm flex;
        p:first-child {
          @apply min-w-[140px] font-medium;
        }
      }
    `,
  ],
})
export class ContentInfoPanelComponent implements OnChanges {
  public is3D: boolean;
  @Output() openConfigure = new EventEmitter();
  @Output() downloadContent = new EventEmitter();
  @Input() content: IContent;
  @Input() downloadBtn: boolean = false;
  @Input() configureBtn: boolean = false;

  constructor() {}

  ngOnChanges(): void {
    if (this.content) {
      this.is3D = this.content?.primaryFile?.contentType?.indexOf('gltf-binary') > -1;
    }
  }

  get contentFileName() {
    return this.content?.primaryFile?.fileName || '';
  }
  get contentExtension() {
    return this.contentFileName?.split('.')?.pop();
  }
  get contentSize() {
    return Math.round(this.content?.primaryFile?.size / 1000);
  }
  get contentUpdatedOn() {
    return this.content?.primaryFile?.updatedOn;
  }
  get contentCreatedOn() {
    return this.content?.primaryFile?.createdOn;
  }
  get contentType() {
    return this.content?.primaryFile?.contentType;
  }

  emitConfigure() {
    this.openConfigure.emit(true);
  }
  download() {
    this.downloadContent.emit(this.content);
  }
}
