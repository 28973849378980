import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { ShowcasePresentationPanel } from '../presentation';
import { PresentationService } from '../presentation.service';

@Component({
  selector: 'app-presentation-panel',
  templateUrl: './presentation-panel.component.html',
  styleUrls: ['./presentation-panel.component.scss'],
})
export class PresentationFramePanel implements OnChanges, OnDestroy {
  @Input() panel: ShowcasePresentationPanel;
  @Input() viewMode: string;
  public isVisible = false;

  constructor() {}

  ngOnChanges(): void {
    this.setVisible(this.isVisible);
  }

  ngOnDestroy() {}

  setVisible(val: boolean) {
    console.log('set visible: ', val, this.viewMode);
    this.isVisible = this.viewMode === 'SLIDE' || val;
  }
}
