<div [id]="elementId" class="w-6 h-6" *ngIf="showPinnedComments">
  <ng-container *ngIf="pinnedComment$ | async as pinnedComment">
    <span
      class="flex items-center justify-center cursor-pointer w-6 h-6 bg-white rounded-tl-[50%] rounded-tr-[50%] rounded-br-[50%] rounded-bl-sm shadow-md"
      matBadge="{{ pinnedComment?.count }}"
      matBadgeColor="primary"
      [matBadgeSize]="'small'"
      [matBadgeHidden]="pinnedComment.count <= 1"
      (click)="showComments(pinnedComment.firstComment, $event)"
    >
      <app-avatar [user]="pinnedComment.firstComment.createdBy" size="18px"></app-avatar>
    </span>
  </ng-container>
</div>
