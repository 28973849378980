import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription, take } from 'rxjs';
import { PresentationCollectionElement, PresentationFrame, ShowcasePresentation } from '../presentation';
import { SessionService } from 'src/app/session/session.service';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { PresentationSelectors } from '../presentation-store';
import { PresentationNavigatorService } from './presentation-navigator.service';
import { ExportsService } from '@common/exports/exports.service';
import { ShowcaseSelectors } from 'src/app/showcase/showcase-store';
import { Showcase } from 'src/app/showcase/showcase';
import { AuthService } from '@common/auth/auth.service';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';

const KEY_EVENT_IGNORE_PATHS: Array<string> = [
  'app-save-list-form',
  'collection-frame-create-template-view',
  'app-comment-overlay',
  'app-comment-form',
  'app-create-grid-frame-section',
  'app-update-grid-frame-section',
  'app-item-data-chooser',
  'color-picker',
  'app-component-editor',
  'app-item-details',
  'app-new-grid-frame-modal',
  'app-search-bar',
  'app-page-header',
  'mat-selection-list',
  'app-search-replace',
];

@Component({
  selector: 'app-presentation-navigator',
  templateUrl: './presentation-navigator.component.html',
  styleUrls: ['./presentation-navigator.component.scss'],
})
export class PresentationNavigatorComponent implements OnInit, AfterViewInit, OnDestroy {
  shareLink = false;
  editorMode$: Observable<any>;

  constructor(
    private store: Store<RootStoreState.State>,
    private authService: AuthService,
    private sessionService: SessionService,
    private presentationNavigatorService: PresentationNavigatorService,
    private exportService: ExportsService,
  ) {
    if (authService.shareToken) this.shareLink = true;
  }

  @ViewChild('navigator') navigatorElement: ElementRef;
  public isFullscreen = false;
  public presentation: ShowcasePresentation;
  public frames: Array<PresentationFrame>;

  private presentationSub: Subscription;
  private showcase: Showcase;

  subscribe() {
    this.editorMode$ = this.store.select(EditorModeSelectors.editorMode);
    this.presentationSub = this.store.select(PresentationSelectors.currentShowcasePresentation).subscribe((pres) => {
      if (!pres) {
        return;
      }
      this.presentation = pres;
      this.frames = this.presentation.presentation.frames.filter((frame) => !frame.disabled);
    });

    document.documentElement.addEventListener('fullscreenchange', this.handleFullScreenChange.bind(this));
  }
  unsubscribe() {
    this.presentationSub.unsubscribe();
    document.documentElement.removeEventListener('fullscreenchange', this.handleFullScreenChange.bind(this));
  }

  handleFullScreenChange(event) {
    console.log('fullscreen change: ', event, this.navigatorElement.nativeElement);
    if (document.fullscreenElement) {
      this.isFullscreen = true;
      this.navigatorElement.nativeElement.classList.add('fullscreen');
    } else {
      this.isFullscreen = false;
      this.navigatorElement.nativeElement.classList.remove('fullscreen');
    }
    this.sessionService.setFullscreenMode(this.isFullscreen);
  }

  ngOnInit(): void {
    this.subscribe();
  }

  ngAfterViewInit() {
    this.store
      .select(ShowcaseSelectors.currentShowcase)
      .pipe(take(1))
      .subscribe((showcase) => {
        this.showcase = showcase;
      });
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  toggleFullscreen() {
    // https://www.w3schools.com/howto/howto_js_fullscreen.asp
    const elem: any = document.documentElement;
    if (!this.isFullscreen) {
      elem.requestFullscreen();
    } else {
      this.restartPresentation();
      document.exitFullscreen();
    }
  }

  public goToFrame(frame: PresentationFrame) {
    this.presentationNavigatorService.goToFrame(frame);
  }

  public goToSetElement(frame: PresentationFrame, element: PresentationCollectionElement) {
    this.presentationNavigatorService.goToSetElement(frame, element);
  }

  public prev() {
    this.presentationNavigatorService.prev();
  }

  public next() {
    this.presentationNavigatorService.next();
  }

  /** Handles key events for pagination. */
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: any) {
    // SKIP the  key event handler if the request is coming from the component to be skipped : Start - Ravi - 11/9
    if (!this.isEventAllowed(event)) {
      return;
    }
    // SKIP the  key event handler if the request is coming from the component to be skipped : End

    if (event.code === 'ArrowLeft' || event.code === 'ArrowUp') {
      this.prev();
    }
    if (event.code === 'ArrowRight' || event.code === 'ArrowDown' || event.code === 'Space') {
      this.next();
    }
  }

  moveToFrame(panel) {
    this.presentationNavigatorService.moveToFrame(panel);
  }

  restartPresentation() {
    this.presentationNavigatorService.restartPresentation();
  }

  isEventAllowed(event: any) {
    for (const el of event.composedPath()) {
      // CHECK IF ORIGINATING FROM A MODAL
      if (KEY_EVENT_IGNORE_PATHS.includes(el.tagName?.toLowerCase())) {
        return false;
      }
    }
    return true;
  }

  download(type) {
    if (type === 'excel' && this.showcase) {
      const options = { reportType: 'excel' };
      const payload = { entityReference: `assortment:${this.showcase?.backingAssortmentId}` };
      this.exportService.initDownloadEntityReference(options, payload);
    } else if (type === 'powerpoint' && this.showcase) {
      const options = { reportType: 'powerpoint' };
      const payload = {
        showcaseWarnings: false,
        entityReference: `showcase:${this.showcase?.id}`,
      };
      this.exportService.initDownloadEntityReference(options, payload);
    }
  }
}
