import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ItemModalLauncher } from '../item-modal-launcher';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { ItemData } from 'src/app/common/item-data/item-data';
import { ViewManagerService } from 'src/app/common/views/view-manager.service';
import { ViewDefinition } from '@contrail/client-views';
import { RootStoreState } from '@rootstore';
import { CommentsActions } from '@common/comments/comments-store';
import { Store } from '@ngrx/store';
import { CommentOwnerInfo } from '@common/comments/comments.service';

@Component({
  selector: 'app-item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.scss'],
})
export class ItemCardComponent implements OnInit {
  constructor(
    private store: Store<RootStoreState.State>,
    private viewsService: ViewManagerService,
    private modalLauncher: ItemModalLauncher,
  ) {}

  @Input() itemData: ItemData;

  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };
  metaViewDefinition: ViewDefinition;
  isLoading = false;
  private readonly PINNED_COMMENT_OFFSET = 4;
  ownerInfo: CommentOwnerInfo;

  async ngOnInit() {
    this.isLoading = false;

    this.ownerInfo = {
      id: this.itemData.id,
      entityType: 'item',
    };

    // this.metaViewDefinition = await this.viewsService.getView('showcase:item_card', this.itemData.item.typeId);
    this.metaViewDefinition = await this.viewsService.getView('showcase:item_card');
  }

  getImageUrl() {
    return this.itemData?.properties.mediumViewableDownloadUrl || this.itemData?.thumbnail;
  }

  openModal() {
    this.modalLauncher.openModal(this.itemData);
  }

  onContextMenu(event: MouseEvent) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { target: this.itemData };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }
  handleInProgressOverlay(event) {
    this.isLoading = event;
  }

  openComment() {
    const pinnedCommentEl = document.getElementById(this.itemData.id);
    if (!pinnedCommentEl) {
      return;
    }
    const pinnedCommentPosition = pinnedCommentEl.getBoundingClientRect();
    this.store.dispatch(
      CommentsActions.showCommentOverlay({
        ownerInfo: this.ownerInfo,
        position: {
          x: pinnedCommentPosition.left,
          y: pinnedCommentPosition.bottom + this.PINNED_COMMENT_OFFSET,
        },
      }),
    );
  }
}
