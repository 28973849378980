import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-home-cta',
  templateUrl: './home-cta.component.html',
  styleUrls: ['./home-cta.component.scss'],
})
export class HomeCtaComponent implements OnInit {
  @Input() text;
  @Input() image;
  @Input() route;
  constructor() {}

  ngOnInit(): void {}
}
