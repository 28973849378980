import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsService } from './analytics.service';
import { GoogleAnalyticsService } from './google-analytics';
import { UserAnalyticsService } from './user-analytics.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [AnalyticsService],
})
export class AnalyticsModule {
  constructor(
    private analyticsService: AnalyticsService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private userAnalyticsService: UserAnalyticsService,
  ) {
    this.analyticsService.registerService(this.googleAnalyticsService);
    this.analyticsService.registerService(this.userAnalyticsService);
  }
}
