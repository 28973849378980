<app-mat-spinner [overlay]="true" [loading]="isLoading" *ngIf="isLoading"></app-mat-spinner>

<app-item-color
  *ngFor="let item of itemColorsFromConfig"
  [itemColor]="item"
  [image]="getImage(item)"
  [metaViewDefinition]="metaViewDefinition"
  [dataSelectionToggleComponent]="dataSelectionToggleComponent"
  [itemPinnedCommentsComponent]="itemPinnedCommentsComponent"
  [includeCommentWidget]="includeCommentWidget"
  (itemColorFocusEvent)="focusItemColor($event)"
  [itemMissingFromSource]="itemsMissingFromSource?.includes(item.id)"
  [frameId]="frameId"
  [editorMode]="editorMode"
  (menuEvent)="handleMenuEvent($event)"
></app-item-color>
