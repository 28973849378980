<div class="p-1">
  <div [attr.id]="'container-' + id" class="mainView" *ngIf="selectedViewable">
    <ng-container *ngIf="selectedViewable?.type === 'image'">
      <canvas
        *ngIf="canvasShow"
        [attr.id]="canvasId"
        [attr.width]="size"
        [attr.height]="size"
        [class.opacity-0]="imageView"
        [class.opacity-100]="!imageView"
      ></canvas>
      <img
        *ngIf="imageView"
        [attr.id]="'image-' + id"
        [ngStyle]="{ width: size, height: size }"
        [src]="selectedViewable.location | secureImage | async"
        alt="content image"
        (click)="clickImage()"
      />
      <div class="canvas-buttons" [class.cursor-none]="imageView" (click)="clickImage()">
        <span class="material-icons" matTooltip="Zoom in" id="zoomIn" (click)="zoomIn()">zoom_in</span>
        <span class="material-icons" matTooltip="Zoom out" id="zoomOut" (click)="zoomOut()">zoom_out</span>
        <span class="material-icons" matTooltip="Reset" id="resetView" (click)="resetView()"
          >settings_backup_restore</span
        >
      </div>
    </ng-container>

    <ng-container *ngIf="is3d">
      <app-webgl-viewer *ngIf="useVibeViewer" [size]="size" [content]="selectedViewable?.content"></app-webgl-viewer>

      <iframe
        *ngIf="!useVibeViewer"
        [src]="selectedViewable?.content?.embedLink | safe: 'resourceUrl'"
        class="w-full h-full"
        frameborder="0"
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
      ></iframe>
    </ng-container>
  </div>
</div>

<div class="tray narrow-scroll">
  <div *ngFor="let viewable of viewables" (click)="selectContent(viewable)" class="viewableThumb hover:scale-105">
    <img *ngIf="viewable.type === 'image'" [src]="viewable.thumbnailLocation || viewable.location" />
    <div *ngIf="viewable.type === 'glb'" class="flex-center"><mat-icon class="scale-125">3d_rotation</mat-icon></div>
  </div>
</div>
