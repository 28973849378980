import { ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { DocumentTextService, TextHyperlinkDetails } from '../document-text.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { DocumentService } from '../../document.service';

@Component({
  selector: 'app-text-hyperlink',
  templateUrl: './text-hyperlink.component.html',
  styleUrls: ['./text-hyperlink.component.scss'],
})
export class TextHyperlinkComponent {
  private validUXNavElements = ['app-text-hyperlink'];
  @ViewChild('url', { static: false }) urlInput: ElementRef;
  showOverlay = false;
  textHyperlinkDetails: TextHyperlinkDetails;
  public currentValue = '';
  mode = 'view';
  textElement;
  private pattern =
    /^(?:(?:https?):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  public urlControl = new UntypedFormControl(null, [Validators.required, Validators.pattern(this.pattern)]);
  valueChanged = false;
  yPositionAdjustment = 0;

  constructor(
    private documentService: DocumentService,
    private documentTextService: DocumentTextService,
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.urlControl = new UntypedFormControl(null, [Validators.required, Validators.pattern(this.pattern)]);
    this.documentTextService.textHyperlinkDetailslinkObs.subscribe((textHyperlinkDetails) => {
      setTimeout(() => {
        if (!textHyperlinkDetails?.textElement) {
          this.closeOverlay(null, true);
        } else if (textHyperlinkDetails?.position) {
          this.textElement = textHyperlinkDetails.textElement;
          this.textHyperlinkDetails = textHyperlinkDetails;
          this.currentValue = textHyperlinkDetails.url;
          this.urlControl.setValue(this.currentValue);
          this.showOverlay = true;
          this.mode = textHyperlinkDetails.mode;
          if (this.mode !== 'view') {
            this.changeDetectorRef.detectChanges();
            if (!this.currentValue) {
              this.urlInput.nativeElement.focus(); // focus on the input field if not in view mode and when adding url the first time.
            }
          }
        }
      }, 100);
    });
    this.urlControl.valueChanges.subscribe((changedValue) => {
      this.valueChanged = changedValue !== this.currentValue;
    });
  }

  @HostListener('document:click', ['$event'])
  click(event) {
    this.closeOverlay(event);
  }

  @HostListener('document:contextmenu', ['$event'])
  contextmenu(event) {
    this.closeOverlay(event);
  }

  closeOverlay(event, force = false) {
    if (force) {
      this.showOverlay = false;
      return;
    }
    const src = event.srcElement;
    const validNavs = this.validUXNavElements.filter((ele) => src.getAttribute('class')?.indexOf(ele) > -1);

    if (validNavs?.length) {
      return;
    }
    if (this.showOverlay && !this.elementRef.nativeElement.contains(event.target)) {
      this.showOverlay = false;
    }
  }
}
