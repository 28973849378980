import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of as observableOf, from } from 'rxjs';
import { catchError, map, mergeMap, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/common/analytics/analytics.service';
import { AssortmentsActions } from 'src/app/common/assortments/assortments-store';
import { LocalizationService } from 'src/app/common/localization/localization.service';
import { WebSocketService } from 'src/app/common/web-socket/web-socket.service';
import { PresentationActions } from 'src/app/presentation/presentation-store';
import { RootStoreState } from 'src/app/root-store';
import { ShowcaseActions } from '.';
import { ShowcaseService } from '../showcase.service';

@Injectable()
export class ShowcaseEffects {
  constructor(
    private actions$: Actions,
    private webSocketService: WebSocketService,
    private showcaseService: ShowcaseService,
    private localizationService: LocalizationService,
    private analyticsService: AnalyticsService,
    private store: Store<RootStoreState.State>,
    private router: Router,
  ) {}

  loadShowcase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowcaseActions.ShowcaseActionTypes.LOAD_CURRENT_SHOWCASE),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.showcaseService.getShowcaseById(action.id)).pipe(
          map((showcase) => {
            if (!showcase) {
              this.router.navigateByUrl('/showcase-not-found');
              return;
            }
            const showSourceAssortmentWarning = showcase?.showSourceAssortmentWarning === false ? false : true;
            this.showcaseService.setShowSourceAssortmentWarning(showSourceAssortmentWarning);

            return ShowcaseActions.loadCurrentShowcaseSuccess({ showcase });
          }),
          catchError((error) => observableOf(ShowcaseActions.loadCurrentShowcaseFailure({ error }))),
        );
      }),
    ),
  );

  loadShowcaseSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowcaseActions.ShowcaseActionTypes.LOAD_CURRENT_SHOWCASE_SUCCESS),
        tap((action: any) => {
          const showcase = action.showcase;
          this.localizationService.setCurrencyCode(showcase.preferredCurrency || 'USD');
          setTimeout(() => {
            this.analyticsService.emitEvent({
              eventName: 'SHOWCASE_ACCESS',
              eventCategory: 'ACCESS',
              eventContextProjectId: showcase.rootWorkspaceId,
              eventContextWorkspaceId: showcase.workspaceId,
              eventLabel: `Showcase ${showcase.name}`,
              eventTarget: `showcase:${showcase.id}`,
              eventContext: `showcase:${showcase.id}`,
            });
          }, 1000);
          this.store.dispatch(
            AssortmentsActions.loadSourceAssortment({ sourceAssortmentId: showcase.sourceAssortmentId }),
          );
          this.store.dispatch(PresentationActions.loadPresentation({ showcaseId: showcase.id }));

          // Load store with backing assortment
          if (showcase?.backingAssortmentId) {
            this.store.dispatch(
              AssortmentsActions.loadBackingAssortment({ assortmentId: showcase?.backingAssortmentId }),
            );
          }
        }),
      ),
    { dispatch: false },
  );
}
