import { createAction, props } from '@ngrx/store';
import { SessionAssortment } from '../session-assortments.service';

export enum SessionAssortmentsActionTypes {
  SET_SESSION_ID = '[SessionAssortments] Set Session Id',
  SET_SESSION_ASSORTMENTS = '[SessionAssortments] Set SessionAssortments',

  CREATE_SESSION_ASSORTMENT = '[SessionAssortments] Create SessionAssortment',
  CREATE_SESSION_ASSORTMENT_SUCCESS = '[SessionAssortments] Create SessionAssortment Success',
  CREATE_SESSION_ASSORTMENT_FAILURE = '[SessionAssortments] Create SessionAssortment Failure',

  DELETE_SESSION_ASSORTMENT = '[SessionAssortments] Delete SessionAssortment',
  DELETE_SESSION_ASSORTMENT_SUCCESS = '[SessionAssortments] Delete SessionAssortment Success',
  DELETE_SESSION_ASSORTMENT_FAILURE = '[SessionAssortments] Delete SessionAssortment Failure',

  ARCHIVE_SESSION_ASSORTMENT = '[SessionAssortments] Archive SessionAssortment',
  ARCHIVE_SESSION_ASSORTMENT_SUCCESS = '[SessionAssortments] Archive SessionAssortment Success',
  ARCHIVE_SESSION_ASSORTMENT_FAILURE = '[SessionAssortments] Archive SessionAssortment Failure',

  UPDATE_SESSION_ASSORTMENT = '[SessionAssortments] Update SessionAssortment',
  UPDATE_SESSION_ASSORTMENT_SUCCESS = '[SessionAssortments] Update SessionAssortment Success',
  UPDATE_SESSION_ASSORTMENT_FAILURE = '[SessionAssortments] Update SessionAssortment Failure',

  COPY_SESSION_ASSORTMENT = '[SessionAssortments] Copy SessionAssortment',
  COPY_SESSION_ASSORTMENT_SUCCESS = '[SessionAssortments] Copy SessionAssortment Success',
  COPY_SESSION_ASSORTMENT_FAILURE = '[SessionAssortments] Copy SessionAssortment Failure',

  SET_CURRENT_SESSION_ASSORTMENT = '[SessionAssortments] Set Current SessionAssortment',

  ADD_SESSION_ASSORTMENT = '[SessionAssortments] Add SessionAssortment',
}
export const setSessionId = createAction(SessionAssortmentsActionTypes.SET_SESSION_ID, props<{ sessionId: string }>());
export const setSessionAssortments = createAction(
  SessionAssortmentsActionTypes.SET_SESSION_ASSORTMENTS,
  props<{ sessionAssortments: Array<SessionAssortment> }>(),
);

export const createSessionAssortment = createAction(
  SessionAssortmentsActionTypes.CREATE_SESSION_ASSORTMENT,
  props<{ sessionAssortment: SessionAssortment }>(),
);

export const createSessionAssortmentSuccess = createAction(
  SessionAssortmentsActionTypes.CREATE_SESSION_ASSORTMENT_SUCCESS,
  props<{ sessionAssortment: SessionAssortment }>(),
);

export const createSessionAssortmentFailure = createAction(
  SessionAssortmentsActionTypes.CREATE_SESSION_ASSORTMENT_FAILURE,
  props<{ error: any }>(),
);

export const updateSessionAssortment = createAction(
  SessionAssortmentsActionTypes.UPDATE_SESSION_ASSORTMENT,
  props<{ sessionAssortment: SessionAssortment }>(),
);

export const updateSessionAssortmentSuccess = createAction(
  SessionAssortmentsActionTypes.UPDATE_SESSION_ASSORTMENT_SUCCESS,
  props<{ sessionAssortment: SessionAssortment }>(),
);

export const updateSessionAssortmentFailure = createAction(
  SessionAssortmentsActionTypes.UPDATE_SESSION_ASSORTMENT_FAILURE,
  props<{ error: any }>(),
);

export const copySessionAssortment = createAction(
  SessionAssortmentsActionTypes.COPY_SESSION_ASSORTMENT,
  props<{ sessionAssortment: SessionAssortment }>(),
);

export const copySessionAssortmentSuccess = createAction(
  SessionAssortmentsActionTypes.COPY_SESSION_ASSORTMENT_SUCCESS,
  props<{ sessionAssortment: SessionAssortment }>(),
);

export const copySessionAssortmentFailure = createAction(
  SessionAssortmentsActionTypes.COPY_SESSION_ASSORTMENT_FAILURE,
  props<{ error: any }>(),
);

export const archiveSessionAssortment = createAction(
  SessionAssortmentsActionTypes.ARCHIVE_SESSION_ASSORTMENT,
  props<{ sessionAssortment: SessionAssortment }>(),
);

export const archiveSessionAssortmentSuccess = createAction(
  SessionAssortmentsActionTypes.ARCHIVE_SESSION_ASSORTMENT_SUCCESS,
  props<{ sessionAssortment: SessionAssortment }>(),
);

export const archiveSessionAssortmentFailure = createAction(
  SessionAssortmentsActionTypes.ARCHIVE_SESSION_ASSORTMENT_FAILURE,
  props<{ error: any }>(),
);

export const setCurrentSessionAssortment = createAction(
  SessionAssortmentsActionTypes.SET_CURRENT_SESSION_ASSORTMENT,
  props<{ sessionAssortment: SessionAssortment }>(),
);

export const addSessionAssortment = createAction(
  SessionAssortmentsActionTypes.ADD_SESSION_ASSORTMENT,
  props<{ sessionAssortment: SessionAssortment }>(),
);
