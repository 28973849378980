import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewManagerService } from 'src/app/common/views/view-manager.service';
import { ItemData } from 'src/app/common/item-data/item-data';
import { ItemsService } from '../items.service';

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss'],
})
export class ItemDetailsComponent implements OnInit {
  @Input() itemData: ItemData;
  @Input() editorMode = 'EDIT';

  public viewDefinition;
  public viewables = [];

  constructor(
    private itemsService: ItemsService,
    private viewService: ViewManagerService,
  ) {}

  async ngOnInit() {
    this.viewDefinition = await this.viewService.getView('showcase:item_details', this.itemData.item.typeId);
  }

  getImageUrl() {
    return this.itemsService.getImageUrl(this.itemData.properties.thumbnail);
  }
}
