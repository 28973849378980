import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionComponent } from './session.component';
import { GridBoardModule } from '../grid-board/grid-board.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ComponentsModule } from '../common/components/components.module';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatDividerModule } from '@angular/material/divider';
import { ListModalComponent } from './save-list/list-modal/list-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { SaveListFormComponent } from './save-list/save-list-form/save-list-form.component';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { OverlayModule } from '@angular/cdk/overlay';
import { CurrentAssortmentSelectorComponent } from './current-assortment-selector/current-assortment-selector.component';
import { TypesModule } from '../common/types/types.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ItemDataChooserModule } from '../common/item-data-chooser/item-data-chooser.module';
import { MatBadgeModule } from '@angular/material/badge';
import { CommentsModule } from '@common/comments/comments.module';
import { ExportsModule } from '@common/exports/exports.module';
@NgModule({
  declarations: [SessionComponent, SaveListFormComponent, ListModalComponent, CurrentAssortmentSelectorComponent],
  imports: [
    CommonModule,
    GridBoardModule,
    DragDropModule,
    ComponentsModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    MatMenuModule,
    MatDividerModule,
    MatToolbarModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    OverlayModule,
    TypesModule,
    ScrollingModule,
    ItemDataChooserModule,
    CommentsModule,
    ExportsModule,
  ],
  exports: [CurrentAssortmentSelectorComponent],
})
export class SessionModule {}
