<div [ngClass]="{ 'list-header-action': !isSticky, hideHeader: isSticky }">
  <div class="list-header-left">
    <div class="search">
      <app-search-bar></app-search-bar>
    </div>
    <div class="sortBy">
      <app-sort [properties]="typeProperties" (triggerSort)="applySort($event)"></app-sort>
    </div>
    <div class="filter">
      <app-menu
        name="Filter"
        icon="filter_icon"
        isSVGIcon="true"
        [highlight]="filterDefinition?.filterCriteria?.propertyCriteria?.length"
      >
        <app-filter-panel
          [sourceValues]="filteredResults$ | async"
          [filterDefinition]="filterDefinition"
          (filterCriteriaChange)="setFilterCriteria($event)"
          [propertyTypeDefaultFilterConditions]="propertyTypeDefaultFilterConditions"
        >
        </app-filter-panel>
      </app-menu>
      <div class="clearAll" (click)="clearFilters()">Clear</div>
      <div class="count" *ngIf="showCount && (filteredResults$ | async)?.length > -1">
        {{ (filteredResults$ | async)?.length | number }}
      </div>
    </div>
    <div class="layout-icons">
      <!-- <button (click)="setDisplayView('list')" mat-icon-button aria-label="Show list view">
        <mat-icon>dehaze</mat-icon>
      </button>
      <button (click)="setDisplayView('grid')" mat-icon-button aria-label="Show grid view">
        <mat-icon>apps</mat-icon>
      </button> -->
      <button (click)="downloadCSV()" mat-icon-button aria-label="downloadVCSV">
        <mat-icon>download</mat-icon>
      </button>
    </div>
  </div>
  <div class="current-assort-selector">
    <app-current-assortment-selector></app-current-assortment-selector>
  </div>
</div>

<div [ngClass]="{ hideHeader: !isSticky, 'current-assort-selector-sticky': isSticky }">
  <app-current-assortment-selector></app-current-assortment-selector>
</div>

<div class="item-pagination">
  <app-pagination
    #header
    [data]="filteredResults$ | async"
    [pageEvent]="pageEvent"
    [inputPageSize]="pageSize"
    (currentPageInfo)="applyPagination($event)"
    *ngIf="(filteredResults$ | async)?.length > -1"
  ></app-pagination>
</div>

<div class="list-header">
  <div *ngIf="view === 'grid' && !gridBoard" class="grid">
    <app-item-card
      *ngFor="let itemData of (filteredResults$ | async)?.slice(fromIndex, toIndex)"
      [itemData]="itemData"
      data-test="explorer-item-card"
    >
    </app-item-card>
  </div>
  <div *ngIf="view === 'list'" class="list">
    <app-item-list-item
      *ngFor="let itemData of (filteredResults$ | async)?.slice(fromIndex, toIndex)"
      [itemData]="itemData"
    >
    </app-item-list-item>
  </div>
</div>

<div class="item-pagination">
  <app-pagination
    #footer
    [data]="filteredResults$ | async"
    [pageEvent]="pageEvent"
    [inputPageSize]="pageSize"
    (currentPageInfo)="applyPagination($event)"
    *ngIf="(filteredResults$ | async)?.length > -1"
  ></app-pagination>
</div>
