import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  // total timeout = 60 seconds
  private TIMEOUT_POLLING_COUNT_LIMIT = 6;
  private DEBOUNCE_TIME = 10000;

  private jobPollingCount = new Map<string, number>();

  private pendingJobs: Array<string> = [];
  private pendingJobsSubject: Subject<string[]> = new BehaviorSubject(null);
  pendingJobsObservable: Observable<string[]> = this.pendingJobsSubject.asObservable();

  // private completedJobs:Array<any> = [];
  // private completedJobsSubject: Subject<any[]> = new BehaviorSubject(null);
  // completedJobsObservable: Observable<any[]> = this.completedJobsSubject.asObservable();

  private completedJobs = '';
  private completedJobsSubject: Subject<string> = new BehaviorSubject(null);
  completedJobsObservable: Observable<string> = this.completedJobsSubject.asObservable();

  private errorSubject: Subject<boolean> = new BehaviorSubject(null);
  errorObservable: Observable<boolean> = this.errorSubject.asObservable();

  private initiateDownloadSubject: Subject<string> = new BehaviorSubject(null);
  initiateDownloadObservable: Observable<string> = this.initiateDownloadSubject.asObservable();

  constructor() {
    this.initiateDownloadObservable.pipe(debounceTime(this.DEBOUNCE_TIME)).subscribe((value) => {
      if (value) {
        this.getDownloadedURL(value);
      }
    });
  }

  initDownloadPolling(jobId: string) {
    this.pendingJobs.push(jobId);
    this.pendingJobsSubject.next(this.pendingJobs);

    this.initiateDownloadSubject.next(jobId);
    this.updatePollingCount(jobId);
  }

  async getDownloadedURL(jobId) {
    console.log(`getDownloadedURL - jobId ${JSON.stringify(jobId)}`);
    const option = {
      entityName: 'export',
      id: jobId,
    };
    const jobStatusData = await new Entities().get(option);
    console.log(` jobStatusData ${JSON.stringify(jobStatusData)}`);

    if (jobStatusData && jobStatusData?.status === 'completed') {
      this.pendingJobs = this.pendingJobs.filter((data) => data !== jobId);

      // const content = {jobId,path:jobStatusData?.path}
      // this.completedJobs.push(content);
      this.completedJobs = jobStatusData?.path;

      this.completedJobsSubject.next(this.completedJobs);
      this.pendingJobsSubject.next(this.pendingJobs);
    }

    if (this.pendingJobs.length > 0) {
      const nextPendingJob = this.pendingJobs[0];
      try {
        this.updatePollingCount(nextPendingJob);
        this.initiateDownloadSubject.next(nextPendingJob);
      } catch (err) {
        console.error(' Error : for jobId ' + nextPendingJob, err);

        this.pendingJobs = this.pendingJobs.filter((data) => data !== nextPendingJob);
        this.pendingJobsSubject.next(this.pendingJobs);

        if (this.pendingJobs?.length) {
          this.initiateDownloadSubject.next(this.pendingJobs[0]);
        }

        //   this.pollingCount.forEach((value, key) => {
        //     console.log(`Key ${key} value ${value}`);
        // });

        this.errorSubject.next(true);
      }
    }
  }

  private updatePollingCount(jobId) {
    let count = 0;
    if (this.jobPollingCount.has(jobId)) {
      count = this.jobPollingCount.get(jobId);
    }

    count += 1;
    this.jobPollingCount.set(jobId, count);
    if (count > this.TIMEOUT_POLLING_COUNT_LIMIT) {
      throw Error(
        ` PDF generation  timed out for jobid ${jobId}. Exceeded ${this.TIMEOUT_POLLING_COUNT_LIMIT} polling attempts with debounce duration of ${this.DEBOUNCE_TIME / 1000} seconds`,
      );
    }
  }
}
