import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ItemsModule } from '@common/items/items.module';
import { PipesModule } from '@common/pipes/pipes.module';
import { GridsterModule } from 'angular-gridster2';
import { CommentsModule } from '../common/comments/comments.module';
import { ComponentsModule } from '../common/components/components.module';
import { ExporterModule } from '../common/components/service/exporter/exporter.module';
import { TypesModule } from '../common/types/types.module';
import { GridBoardItemComponent } from './grid-board-item/grid-board-item.component';
import { GridBoardComponent } from './grid-board.component';

@NgModule({
  declarations: [GridBoardComponent, GridBoardItemComponent],
  imports: [
    CommonModule,
    DragDropModule,
    BrowserAnimationsModule,
    GridsterModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    ExporterModule,
    TypesModule,
    CommentsModule,
    ComponentsModule,
    PipesModule,
    ItemsModule,
  ],
  exports: [GridBoardComponent],
})
export class GridBoardModule {}
