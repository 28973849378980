export interface AnnotationOption {
  type: string;
  svgIcon: string;
  label: string;
  property: string;
  image: string;
  category: string;
}

export interface AnnotatedElement {
  id: string;
  annotations: AnnotationOption[];
}

export const AnnotationOptions: Array<AnnotationOption> = [
  {
    type: 'annotation-dropped',
    category: 'property',
    svgIcon: 'dropped',
    label: 'Dropped',
    property: 'isDropped',
    image: '/assets/images/dropped.svg',
  },
  {
    type: 'annotation-primary',
    category: 'property',
    svgIcon: 'primary',
    label: 'Primary',
    property: 'isPrimary',
    image: '/assets/images/primary.svg',
  },
  {
    type: 'annotation-carryover',
    category: 'property',
    svgIcon: 'carryover',
    label: 'Carryover',
    property: 'isCarryover',
    image: '/assets/images/carryover.svg',
  },
];
