import { Component, OnInit, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { EVENT_CATEGORY } from '@common/analytics/user-analytics.service';
import { AnalyticsService } from 'src/app/common/analytics/analytics.service';
import { ItemData } from 'src/app/common/item-data/item-data';

@Component({
  selector: 'app-item-modal',
  templateUrl: './item-modal.component.html',
  styleUrls: ['./item-modal.component.scss'],
})
export class ItemModalComponent implements OnInit {
  public itemData: ItemData;
  public editorMode = 'EDIT';
  constructor(
    public dialogRef: MatDialogRef<ItemModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { itemData: ItemData; editorMode: any },
    private analyticsService: AnalyticsService,
  ) {
    this.itemData = data.itemData;
  }

  ngOnInit(): void {
    this.editorMode = this.data.editorMode;
    if (this.itemData) {
      this.analyticsService.emitEvent({
        eventName: 'VIEW_ITEM_MODAL',
        eventCategory: 'VIEW',
        eventTarget: `item:${this.itemData.id}`,
        eventContextProjectId: 'NA',
        eventContextWorkspaceId: 'NA',
        eventLabel: `Item ${this.itemData.id}`,
        eventContext: `showcase viewer`,
      });
    }
    this.analyticsService.emitEvent({
      eventName: 'VIEW_ITEM_DETAILS',
      eventCategory: EVENT_CATEGORY.VIEW,
      eventTarget: `item:${this.itemData.id}`,
      eventLabel: this.itemData.getName(),
    });
  }

  handleCloseModal(event) {
    console.log(event);
    this.dialogRef.close();
  }
}
