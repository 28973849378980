import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { SessionAssortmentsActions } from '../../session-assortments-store';
import { ListAction } from '../../session.service';

@Component({
  selector: 'app-save-list-form',
  templateUrl: './save-list-form.component.html',
  styleUrls: ['./save-list-form.component.scss'],
})
export class SaveListFormComponent implements OnInit {
  @Input() data: any;

  @Output() onFormSubmitted = new EventEmitter();
  public form;
  public isSubmitted = false;

  constructor(private store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.data.name, [Validators.required, Validators.maxLength(60)]),
    });
  }

  hasError(controlName: string, errorName: string) {
    return this.form.controls[controlName].hasError(errorName);
  }

  async onSubmit(formData) {
    this.isSubmitted = !this.isSubmitted;
    let session;
    const actionType = this.data.action;

    if (actionType === ListAction.CREATE) {
      this.store.dispatch(
        SessionAssortmentsActions.createSessionAssortment({ sessionAssortment: { name: formData.name } }),
      );
    } else if (actionType === ListAction.UPDATE) {
      this.store.dispatch(
        SessionAssortmentsActions.updateSessionAssortment({
          sessionAssortment: { name: formData.name, id: this.data.id, assortmentId: this.data.assortmentId },
        }),
      );
    } else if (actionType === ListAction.COPY) {
      this.store.dispatch(
        SessionAssortmentsActions.copySessionAssortment({
          sessionAssortment: { name: formData.name, id: this.data.id, assortmentId: this.data.assortmentId },
        }),
      );
    }

    this.isSubmitted = !this.isSubmitted;
    this.onFormSubmitted.emit(session);
  }
}
