import { Injectable } from '@angular/core';
import { EVENT_CATEGORY } from '@common/analytics/user-analytics.service';
import { Entities } from '@contrail/sdk';
import { AnalyticsService } from '../common/analytics/analytics.service';

export interface SessionAssortment {
  id?: string;
  showcaseSessionId?: string;
  isArchived?: boolean;
  name?: string;
  assortmentId?: string;
  layoutConfiguration?: { [key: string]: any };
}

@Injectable({
  providedIn: 'root',
})
export class SessionAssortmentsService {
  constructor(private analyticsService: AnalyticsService) {}

  async create(sessionId: string, sessionAssortment: SessionAssortment): Promise<SessionAssortment> {
    const option = {
      entityName: 'showcase-session',
      id: sessionId,
      object: sessionAssortment,
      relation: 'assortments',
    };
    let assortment = await new Entities().create(option);
    this.analyticsService.emitEvent({
      eventName: 'CREATE_SESSION_ASSORTMENT',
      eventTarget: `session-assortment:${sessionAssortment.id}`,
      eventCategory: EVENT_CATEGORY.CREATE,
    });
    return assortment;
  }

  async update(sessionId: string, sessionAssortment: SessionAssortment): Promise<SessionAssortment> {
    const option = {
      entityName: 'showcase-session',
      id: sessionId,
      relation: 'assortments',
      relationId: sessionAssortment.id,
      object: {
        name: sessionAssortment.name,
        isArchived: false,
        layoutConfiguration: sessionAssortment.layoutConfiguration,
      },
    };
    let assortment = await new Entities().update(option);
    this.analyticsService.emitEvent({
      eventName: 'UPDATE_SESSION_ASSORTMENT',
      eventTarget: `session-assortment:${sessionAssortment.id}`,
      eventCategory: EVENT_CATEGORY.UPDATE,
    });
    return assortment;
  }

  async archive(sessionAssortment: SessionAssortment): Promise<SessionAssortment> {
    const option = {
      entityName: 'showcase-session',
      id: sessionAssortment.showcaseSessionId,
      object: { name: sessionAssortment.name, isArchived: true },
      relation: 'assortments',
      relationId: sessionAssortment.id,
    };
    let assortment = await new Entities().update(option);
    this.analyticsService.emitEvent({
      eventName: 'ARCHIVE_SESSION_ASSORTMENT',
      eventCategory: EVENT_CATEGORY.ARCHIVE,
      eventTarget: `session-assortment:${sessionAssortment.id}`,
    });
    return assortment;
  }

  async copy(sessionId: string, sessionAssortment: SessionAssortment): Promise<SessionAssortment> {
    const option = {
      entityName: 'showcase-session',
      id: sessionId,
      object: { name: sessionAssortment.name, sourceShowcaseSessionAssortmentId: sessionAssortment.id },
      relation: 'copy',
    };
    let assortment = await new Entities().create(option);
    this.analyticsService.emitEvent({
      eventName: 'COPY_SESSION_ASSORTMENT',
      eventCategory: EVENT_CATEGORY.OTHER,
      eventTarget: `session-assortment:${sessionAssortment.id}`,
    });
    return assortment;
  }
}
