import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Subject, takeUntil } from 'rxjs';
import { DownloadedJob, DownloadService } from '../download.service';

@Component({
  selector: 'app-download-content-snack',
  template: `
    <div class="flex-center bg-white h-12 w-32 rounded shadow-md shadow-gray-700" *ngIf="downloading$ | async">
      <mat-spinner [diameter]="24"></mat-spinner>
      <span class="text-black80 ml-2"> Exporting ... </span>
    </div>
  `,
  styles: [],
})
export class DownloadContentSnackComponent implements OnInit, OnDestroy {
  private PDF_GEN_TIMEOUT_MESSAGE = 'Error while exporting data. Please try again.';

  public numberOfPendingJobs = 0;

  public numberOfCompletedJobs = 0;

  private snackBarRef;
  public downloading$ = this.downloadService.downloading$;
  private destroy$ = new Subject();

  constructor(
    private downloadService: DownloadService,
    private snackBar: MatSnackBar,
  ) {
    // reset the completed jobs
    this.downloadService.resetJobQueue();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.downloadService.pendingJobsObservable.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (!data) {
        this.numberOfPendingJobs = 0;
        return;
      }
      console.log(`download service has received ${JSON.stringify(data)}  - ${data?.length} job ids`);
      this.numberOfPendingJobs = data?.length;
      // if (this.numberOfPendingJobs > 0) {
      //   this.openSnackBar(`Preparing for download... ${this.numberOfPendingJobs}`, '', 8000);
      // }
    });

    this.downloadService.completedJobsObservable$.pipe(takeUntil(this.destroy$)).subscribe((data: DownloadedJob) => {
      if (!data) {
        this.numberOfCompletedJobs = 0;
        return;
      } else {
        const path = data.path;
        if (path instanceof Blob) {
          const url = URL.createObjectURL(path);
          this.download(url, data.name);
          setTimeout(() => {
            URL.revokeObjectURL(url);
          }, 1000);
        } else {
          this.openInNewTab(path);
        }
      }
      console.log(`download service has received ${JSON.stringify(data)}`);
      // this.numberOfCompletedJobs = data?.length;
      this.snackBarRef?.dismiss();
    });

    this.downloadService.errorObservable$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (!data) {
        return;
      } else {
        this.openSnackBar(this.PDF_GEN_TIMEOUT_MESSAGE, 'Error');
      }
    });
  }

  private download(data, name) {
    const link = document.createElement('a');
    link.download = `${name ?? 'export'}.pdf`;
    link.href = data;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private openInNewTab(data) {
    window.open(data, '_blank');
  }

  openSnackBar(message: string, action: string, duration = 5000) {
    this.snackBarRef = this.snackBar.open(message, action, {
      duration,
    });
  }
}
