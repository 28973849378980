import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { ShowcaseService } from '../showcase/showcase.service';
import { Entities } from '@contrail/sdk';
import { DownloadService } from '../main/header/download/download.service';
import { AnalyticsService } from '../common/analytics/analytics.service';
import { v4 as uuid } from 'uuid';
import { AuthService } from '../common/auth/auth.service';
import { ItemData } from '../common/item-data/item-data';
import { RootStoreState, UserSessionActions } from '../root-store';
import { Store } from '@ngrx/store';
import { SessionAssortmentsActions, SessionAssortmentsSelectors } from './session-assortments-store';
import { LoadingIndicatorActions } from '../common/loading-indicator/loading-indicator-store';
import { ShowcaseSelectors } from '../showcase/showcase-store';
import { Assortment } from '../common/assortments/assortment';
import { EVENT_CATEGORY } from '@common/analytics/user-analytics.service';
import { Showcase } from '../showcase/showcase';

export enum ListAction {
  COPY,
  UPDATE,
  CREATE,
  ARCHIVE,
}

export interface SessionAssortment {
  assortmentId: string;
  layoutConfiguration: any;
  id: string;
  showcaseSessionId: string;
  isArchived: boolean;
  name: string;
  createdOn: any;
  updatedOn: any;
}
interface Item {
  [key: string]: any;
}
@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(
    private showcaseService: ShowcaseService,
    private analyticsService: AnalyticsService,
    private downloadService: DownloadService,
    private store: Store<RootStoreState.State>,
    private authService: AuthService,
  ) {
    this.initialize();
  }
  private currentAssortmentObject: Assortment = null;
  private currentAssortmentSubject: Subject<Assortment> = new BehaviorSubject(null);
  public currentAssortment: Observable<Assortment> = this.currentAssortmentSubject.asObservable();

  private currentFullscreenMode = false;
  private fullscreenModeSubject: Subject<boolean> = new BehaviorSubject(null);
  public fullscreenMode: Observable<boolean> = this.fullscreenModeSubject.asObservable();
  private showcase: Showcase;

  initialize() {
    this.store.select(ShowcaseSelectors.currentShowcase).subscribe(async (showcase) => {
      if (showcase && showcase.id) {
        this.showcase = showcase;
        const session = showcase.showcaseSessions[0];

        this.store.dispatch(UserSessionActions.loadRemoteUsers({ sessionId: 'showcase:' + showcase.id }));
        this.store.dispatch(UserSessionActions.joinSession({ sessionId: 'showcase:' + showcase.id }));

        const notArchivedSessionAssortments = session.showcaseSessionAssortments.filter(
          (assortment) => assortment.isArchived !== true,
        );
        this.store.dispatch(SessionAssortmentsActions.setSessionId({ sessionId: session.id }));
        this.store.dispatch(
          SessionAssortmentsActions.setSessionAssortments({ sessionAssortments: notArchivedSessionAssortments }),
        );
        this.store.dispatch(
          SessionAssortmentsActions.setCurrentSessionAssortment({
            sessionAssortment: notArchivedSessionAssortments[0],
          }),
        );

        if (session.showcaseSessionAssortments.length > 0) {
          this.selectAssortment(session.showcaseSessionAssortments[0]);
        }
      }
    });
    this.store
      .select(SessionAssortmentsSelectors.selectCurrentSessionAssortment)
      .subscribe(async (sessionAssortment) => {
        this.selectAssortment(sessionAssortment);
      });

    this.store.select(SessionAssortmentsSelectors.selectSessionAssortments).subscribe(async (assortments) => {
      if (!this.currentAssortmentObject?.id) {
        return;
      }
      const updatedCurrentAssort = assortments.filter(
        (assort) => assort.assortmentId === this.currentAssortmentObject?.id,
      )[0];
      if (updatedCurrentAssort?.id) {
        // handle assortment rename
        if (this.currentAssortmentObject.name !== updatedCurrentAssort.name) {
          await this.setCurrentAssortment(updatedCurrentAssort);
          this.store.dispatch(
            SessionAssortmentsActions.setCurrentSessionAssortment({ sessionAssortment: updatedCurrentAssort }),
          );
        }
      }
    });
  }

  public async selectAssortment(sessionAssortment) {
    if (!sessionAssortment?.assortmentId) {
      return;
    }
    if (this.currentAssortmentObject?.id === sessionAssortment.assortmentId) {
      return; // COULD CAUSE ISSUES
    }
    await this.setCurrentAssortment(sessionAssortment);
  }

  private async setCurrentAssortment(sessionAssortment) {
    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Loading Assortment' }));
    this.currentAssortmentObject = new Assortment(sessionAssortment.assortmentId);
    await this.currentAssortmentObject.load();
    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));

    this.currentAssortmentSubject.next(this.currentAssortmentObject);
  }

  private async addToAssortment(assortmentId, collection: Array<ItemData>, func) {
    if (!collection) {
      console.error('Invalid call to addToAssortment: object to add was not specified');
    }
    const assortment = this.getAssortmentForAction(assortmentId);
    await assortment[func](collection);
    this.broadcastAssortmentChange(assortmentId);
    return Promise.resolve(collection);
  }
  private getAssortmentForAction(assortmentId): Assortment {
    let assortment;
    assortmentId = assortmentId || this.currentAssortmentObject.id;
    const isCurrentAssortment = assortmentId === this.currentAssortmentObject.id;
    if (isCurrentAssortment) {
      assortment = this.currentAssortmentObject;
    } else {
      assortment = new Assortment(assortmentId);
    }
    return assortment;
  }
  private broadcastAssortmentChange(assortmentId) {
    const isCurrentAssortment = assortmentId === this.currentAssortmentObject.id;
    if (isCurrentAssortment) {
      console.log('broadcastAssortmentChange: ', this.currentAssortmentObject);
      this.currentAssortmentSubject.next(this.currentAssortmentObject);
    }
  }

  public async addItems(items: Array<ItemData>, assortmentId?: any): Promise<any> {
    this.analyticsService.emitEvent({
      eventName: 'ADD_ITEMS',
      eventCategory: EVENT_CATEGORY.OTHER,
      eventTarget: `assortment:${assortmentId}`,
      eventContext: `showcase:${this.showcase.id}`,
    });
    return this.addToAssortment(assortmentId, items, 'addItems');
  }

  public async addItem(item: ItemData, assortmentId: string) {
    const event = {
      eventName: 'ADD_ITEM',
      eventTarget: `item:${item.id}`,
      eventLabel: item.getName(), // properties.itemName + ' - ' + item.properties.name
      EVENT_CATEGORY: EVENT_CATEGORY.OTHER,
      eventContext: `showcase:${this.showcase.id}`,
    };
    this.analyticsService.emitEvent(event);

    return await this.addItems([item], assortmentId);
  }

  public async removeItem(removedItem: any) {
    await this.currentAssortmentObject.removeItem(removedItem.id);
    this.analyticsService.emitEvent({
      eventName: 'REMOVE_ITEM',
      eventTarget: `item:${removedItem.properties.itemId}`,
      eventLabel: removedItem.properties.itemName,
      eventCategory: EVENT_CATEGORY.OTHER,
      eventContext: `showcase:${this.showcase.id}`,
    });
    this.currentAssortmentSubject.next(this.currentAssortmentObject);
  }

  public setFullscreenMode(val: boolean) {
    this.analyticsService.emitEvent({ eventName: 'TOGGLE_FULL_SCREEN' });
    this.currentFullscreenMode = val;
    this.fullscreenModeSubject.next(this.currentFullscreenMode);
  }

  async downloadPDF(showcaseAssortmentId: string, showcaseAssortmentName) {
    this.analyticsService.emitEvent({
      eventName: 'PDF_EXPORT',
      eventTarget: `showcase-assortment:${showcaseAssortmentId}`,
      eventLabel: showcaseAssortmentName,
      eventCategory: EVENT_CATEGORY.EXPORT,
      eventContext: `showcase:${this.showcase.id}`,
    });
    const jobId = await this.getJobId(showcaseAssortmentId, 'pdf');
    this.checkDownloadProgress(jobId);

    const option = {
      entityName: 'export',
      object: { id: showcaseAssortmentId, jobId },
    };
    await new Entities().create(option);
    return jobId;
  }
  public checkDownloadProgress(jobId: string) {
    this.downloadService.initDownloadPolling(jobId);
  }

  async getJobId(id: string, reportType: string) {
    const authContext = await this.authService.getAuthContext();
    return `${id}:${await uuid()}:${reportType}:${authContext.user.id}:${authContext.currentOrg.orgSlug}`;
  }
}
