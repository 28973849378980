import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { AvatarComponent } from './avatar/avatar.component';
import { ConfirmationBoxComponent } from './confirmation-box/confirmation-box';
import { EmptyPlaceholderComponent } from './empty-placeholder/empty-placeholder.component';
import { ListItemComponent } from './list/list-item/list-item.component';
import { ListComponent } from './list/list.component';
import { MenuComponent } from './menu/menu.component';
import { ModalComponent } from './modal/modal.component';
import { PageSectionActionsComponent } from './page-section/page-section-actions/page-section-actions.component';
import { PageSectionBodyComponent } from './page-section/page-section-body/page-section-body.component';
import { PageSectionHeaderComponent } from './page-section/page-section-header/page-section-header.component';
import { PageSectionSubTitleComponent } from './page-section/page-section-sub-title/page-section-sub-title.component';
import { PageSectionTitleComponent } from './page-section/page-section-title/page-section-title.component';
import { MatDividerModule } from '@angular/material/divider';
import { PageSectionComponent } from './page-section/page-section.component';
import { PaginationComponent } from './pagination/pagination.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { TabNavigationComponent } from './tab-navigation/tab-navigation.component';
import { AppSwitchComponent } from './app-switch/app-switch.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatSpinnerComponent } from '@standalone-components/mat-spinner/mat-spinner.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { PipesModule } from '@pipes/pipes.module';
import { ObjectReferenceChipComponent } from './object-reference-chip/object-reference-chip.component';

@NgModule({
  declarations: [
    SearchBarComponent,
    ModalComponent,
    TabNavigationComponent,
    AvatarComponent,
    PaginationComponent,
    MenuComponent,
    PageSectionComponent,
    PageSectionHeaderComponent,
    PageSectionSubTitleComponent,
    PageSectionTitleComponent,
    PageSectionBodyComponent,
    ListComponent,
    ListItemComponent,
    PageSectionActionsComponent,
    ConfirmationBoxComponent,
    ObjectReferenceChipComponent,
    EmptyPlaceholderComponent,
    AppSwitchComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    FormsModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatMenuModule,
    MatInputModule,
    PipesModule,

    // standalone components
    MatSpinnerComponent,
  ],
  exports: [
    SearchBarComponent,
    ModalComponent,
    TabNavigationComponent,
    AvatarComponent,
    PaginationComponent,
    MenuComponent,
    PageSectionComponent,
    PageSectionHeaderComponent,
    PageSectionSubTitleComponent,
    PageSectionTitleComponent,
    ListComponent,
    ListItemComponent,
    PageSectionActionsComponent,
    PageSectionBodyComponent,
    ObjectReferenceChipComponent,
    EmptyPlaceholderComponent,
    AppSwitchComponent,

    // standalone components
    MatSpinnerComponent,
  ],
})
export class ComponentsModule {}
