<mat-sidenav-container>
  <mat-sidenav #sidenav mode="side" position="end">
    <app-context-comments-list
      class="w-full h-full"
      *ngIf="showCommentsSideBar$ | async"
      [accessLevel]="editorMode$ | async"
      [commentTypeOptions]="commentTypeOptions"
      [getCommentType]="getCommentType"
      [contextReferenceTypesObservable]="contextReferenceTypes$"
      (contextReferenceValueChange)="updateSelectedContextReference($event)"
    >
      <div class="cursor-pointer flex-center" (click)="handleClose()"><mat-icon>close</mat-icon></div>
    </app-context-comments-list>
  </mat-sidenav>
</mat-sidenav-container>
