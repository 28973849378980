import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ShowcaseService } from 'src/app/showcase/showcase.service';
import { SessionService } from 'src/app/session/session.service';
import { ItemData } from 'src/app/common/item-data/item-data';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { SessionAssortmentsSelectors } from 'src/app/session/session-assortments-store';
@Component({
  selector: 'app-add-item-to-list',
  templateUrl: './add-item-to-list.component.html',
  styleUrls: ['./add-item-to-list.component.scss'],
})
export class AddItemToListComponent implements OnInit {
  constructor(
    private sessionService: SessionService,
    private showcaseService: ShowcaseService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {
    this.store.select(SessionAssortmentsSelectors.selectSessionAssortments).subscribe((assortments) => {
      this.assortments = assortments;
    });
    this.sessionService.currentAssortment.subscribe((assortment) => {
      this.currentAssortment = assortment;
    });
    this.defaultSelected = this.assortments[0];
    this.assortmentSelectionControl = new UntypedFormControl(this.defaultSelected);
  }

  @Input() itemData: ItemData;
  @Input() itemsList: Array<ItemData>;

  @Output() processingCompleted = new EventEmitter<boolean>();

  public assortments;
  public currentAssortment;
  assortmentSelectionControl;
  isLoading = false;
  public selectedAssortmentData;
  defaultSelected: any;

  ngOnInit() {}
  // methods used when the component is loaded as UI dropdown instead of a modal
  selectedAssortment(assortment) {
    this.selectedAssortmentData = assortment;
    // console.log(`assortment ${JSON.stringify(assortment)}`);
  }

  async initAddToList() {
    await this.addToList(this.selectedAssortmentData);
  }
  async addToList(assortment) {
    if (!this.itemData && !this.itemsList) {
      return;
    }
    this.isLoading = true;
    let collection = [];
    let successMessage = '';
    if (this.itemData) {
      collection = [this.itemData];
      successMessage = `Successfully added ${this.itemData.properties.name} to your list ${assortment.name}`;
    } else if (this.itemsList) {
      collection = this.itemsList;
      successMessage = `Successfully added ${this.itemsList.length} 'items' to your list ${assortment.name}`;
    }
    try {
      let result;
      result = await this.sessionService.addItems(collection, assortment.assortmentId);
      this.openSnackBar(successMessage, '');
    } catch (err) {
      this.openSnackBar(`Error while adding to list`, '');
      console.error(`error ${err.message} `);
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
    this.isLoading = false;

    this.processingCompleted.emit(true);
  }
}
